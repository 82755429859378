import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { debounceTime, fromEvent } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Configurations } from '../../../configurations';
import { DataProviderService } from '../../../core/controller/services/data-provider.service';
import { DateService } from '../../../core/controller/services/date.service';
import { HelperService } from '../../../core/controller/services/helper.service';
import { NavigationService } from '../../../core/controller/services/navigation.service';
import { PiechartOptions } from '../../../core/view/charts/options/piechart-options';
import { LimitSizeArrayModel } from '../../../core/model/helper-models/limit-size-array.model';
import { DataPoint } from '../../../core/model/helper-models/datapoint.model';
import { TranslateService } from '@ngx-translate/core';
import { Thing } from '../../../core/model/thing.model';
import { environment } from '../../../../environments/environment';
import { EventService } from '../../../core/controller/services/event.service';

@UntilDestroy()
@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: [
        './dashboard.component.scss',
        '../../../core/view/tile/tile.scss',
        '../../../../assets/css/template.scss'
    ]
})
/**
 * Dashboard Seite
 */
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {
    /**
     * Wird verwendet um auf die Methoden von Rafael in der 3D Darstellung zuzugreifen
     */
    @ViewChild('unityIframe', { static: false }) unityIframe: ElementRef;

    /**
     * StoreWare Objekt um Lagerstatistik anzuzeigen
     */
    public storeware: Thing;

    /**
     * Route zum 3D Modell
     */
    public frameSrc: any;

    /**
     * Optionen für die Lagerfüllstand PieChart
     */
    public loadOptionsPie = new PiechartOptions('Lagerfüllstand', '', '', [
        'Leere Behälter',
        'Befüllte Behälter'
    ]);

    /**
     * Gassenfüllstand Namen
     */
    aisleFillLevelAisleWms = new LimitSizeArrayModel<Array<DataPoint<string>>>(1000);
    /**
     * Gassenfüllstand Werte
     */
    aisleFillLevelValue = new LimitSizeArrayModel<Array<DataPoint<string>>>(1000);

    private loadedIntervall: any;

    private pageChangeIntervall: any;

    private lagerfuellstand: string;
    private LeBehaelter: string;
    private BeBehealter: string;
    /**
     *
     * @param data
     * @param _getData
     * @param _date
     * @param helper
     * @param nav
     */
    constructor(
        public data: DataProviderService,
        public translate: TranslateService,
        private _date: DateService,
        public helper: HelperService,
        public nav: NavigationService,
        private event: EventService,
        private cdr: ChangeDetectorRef
    ) {
        this.translate.get('Lagerfüllstand').subscribe((x: string) => {
            this.lagerfuellstand = x;
        });
        this.translate.get('Leere Behälter').subscribe((x: string) => {
            this.LeBehaelter = x;
        });
        this.translate.get('Befüllte Behälter').subscribe((x: string) => {
            this.BeBehealter = x;
            this.loadOptionsPie = new PiechartOptions(this.lagerfuellstand, '', '', [
                this.LeBehaelter,
                this.BeBehealter
            ]);
        });
    }

    /**
     * Holt StoreWare Objekt um StoreWare Daten anzuzeigen
     */
    ngOnInit() {
        this._date.auto = true;

        Configurations.InitaliseComplete.subscribe((x) => {
            if (x) {
                this.nav.setActiveCustomerDetails();
            }
        });
    }

    /**
     *
     */
    ngOnDestroy() {
        clearInterval(this.loadedIntervall);
        clearInterval(this.pageChangeIntervall);
    }

    /**
     * Setzt die Links im 3D Modell
     */
    set3DLinks() {
        //rewrite foreach to for and set env.storageUrl to class field
        this.data.activeSystem.getAllThings().forEach((thing) => {
            const layoutId = thing.layoutPosition;
            const url = environment.redirectUri + this.nav.buildThingUrl(thing);

            console.log(layoutId + ' : ' + url);

            // Hier wird Link gesetzt
            this.unityIframe.nativeElement.contentWindow.postMessage(
                { 'function': 'SetLinkThing', 'layoutId': layoutId, 'url': url },
                environment.storageUrl
            );

            let thingstatus =  '';
            if (thing.type === 'OLS')
            {
                thingstatus = thing.status.value + 'ff'
            }
            else
            {
                thingstatus = thing.controlCabinet.status.value + 'ff'
            }
            console.log(thingstatus);

            this.unityIframe.nativeElement.contentWindow.postMessage(
                {
                    'function': 'SetColorThing',
                    'layoutId': layoutId,
                    'color': thingstatus
                },
                environment.storageUrl
            );

            this.event.ControlLightEvent.subscribe((x) => {
                console.log('ControlLightEvent in dashboard', x);
            });

            thing.outdatedProperties.subscribe((x) => {
                if (x != null) {
                    if (x.length > 0) {
                        this.unityIframe.nativeElement.contentWindow.postMessage(
                            { 'function': 'ErrorThing', 'layoutId': layoutId },
                            environment.storageUrl
                        );
                    } else {
                        this.unityIframe.nativeElement.contentWindow.postMessage(
                            { 'function': 'NoErrorThing', 'layoutId': layoutId },
                            environment.storageUrl
                        );
                    }
                }
            });

            /*
            this.unityIframe.nativeElement.contentWindow.postMessage(
                {'function': 'SetColorThing', 'layoutId': layoutId, 'color': '#bd230fff'},
                environment.storageUrl);
            */
        });
    }

    /**
     * Lädt 3D Modell
     */
    ngAfterViewInit(): void {
        Configurations.InitaliseComplete.subscribe((x) => {
            if (!x) {
                return;
            }

            this.frameSrc = environment.storageUrl + this.data.activeSystem.path3D;

            fromEvent<MessageEvent>(window, 'message')
                .pipe(untilDestroyed(this))
                .subscribe((event) => {
                    if (!event.data.includes('loaded')) {
                        return;
                    }

                    if (this.data.activeCompany.id === '649d71726663955656359ad4') {
                        this.setUnityFrameView();
                        this.set3DLinks();
                    }
                    else
                    {
                        this.set3DLinks();
                    }

                });

            this.cdr.markForCheck();
        });
    }

    private setUnityFrameView() {
        let viewCount = 0;

            switch (this.data.activeSystem.name) {
                case 'AKL':
                    viewCount = 0;
                    break;
                case 'Sequenzer':
                    viewCount = 1;
                    break;
                case 'Picktower':
                    viewCount = 2;
                    break;
            }

        this.unityIframe.nativeElement.contentWindow.postMessage(
            { 'function': 'SetView', 'view': viewCount },
            environment.storageUrl
        );
    }
}
