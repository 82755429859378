import {Component, Input, OnInit} from '@angular/core';
import {LimitSizeArrayModel} from '../../../../core/model/helper-models/limit-size-array.model';
import {DataPoint} from '../../../../core/model/helper-models/datapoint.model';
import {GetThingDataService} from '../../../../core/controller/services/get-thing-data.service';
import {BaseSubcomp} from '../../../base-subcomp';
import {Thing} from '../../../../core/model/thing.model';
import {firstValueFrom} from 'rxjs/internal/firstValueFrom';


@Component({
    selector: 'app-ssagm-subcomp-parent',
    templateUrl: './ssagm-subcomp-parent.component.html',
    styleUrls: ['./ssagm-subcomp-parent.component.scss']
})
export class SsagmSubcompParentComponent extends BaseSubcomp implements OnInit
{

    constructor(private getData: GetThingDataService)
    {
        super();
    }

    @Input() Infeeds: Thing[];
    @Input() Endstellen: Thing[];
    @Input() DTM: Thing[];

    firstDTM: Thing;

    async ngOnInit()
    {
        if (this.Thing == null) return;

        let things = [];
        things.push(this.Thing);
        things.push(this.Thing.controlCabinet);
        things = things.concat(this.DTM);
        this.firstDTM = this.DTM[0];
        things = things.concat(this.Infeeds);
        things = things.concat(this.Endstellen);

        this.getData.setThings(things);

    }

}
