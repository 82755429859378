import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {DataPoint} from '../../model/helper-models/datapoint.model';

@Pipe({
    name: 'calcDistance'
})
export class CalcDistancePipe extends DecimalPipe implements PipeTransform
{
    /**
     * Rechnet Kilometer und Meter in eine Zahl und formatiert das Ergebnis
     * Wird benötigt um null zurück zu geben. (letzte Wartung)
     * @param pValue Tuple [0] = Kilometer, [1] = Meter
     * @param pFormat Formatierungsformat für Decimal Pipe z.B. '1.0-2'
     */
    transform(pValue: any, pFormat: string): any
    {
        if (pValue[0] == null || pValue[1] == null) return null;

        if (pFormat == null)
        {
            return new DataPoint(pValue[0].value + (pValue[1].value / 1000), pValue[0].timestamp);
        }
        else
        {
            return super.transform(pValue[0].value + (pValue[1].value / 1000), pFormat);
        }
    }
}
