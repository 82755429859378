import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'getKeyValue'
})
export class GetKeyValuePipe implements PipeTransform
{

    transform(value: Map<any, any>, key: string): any
    {
        return value.get(key);
    }

}
