<div *ngIf="thing">

    <app-thing-banner [items]="[['Status', 'Status'], ['Aktuelle Position' , 'CurrentPosition'],
                        ['Geschwindigkeit', 'Speed'], ['Position', 'Position'], ['Buttons', 'Buttons']]"></app-thing-banner>

    <!--Überschrift-->
    <div class="row">
        <div class="col-lg-12">
            <div class="heading">
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-6">
                        <h2>{{thing.displayName + ' ' + location.name + ' - ' + system.name}}</h2>
                    </div>
                    <div class="col-12 col-md-6 col-lg-6">
                        <app-datepicker-popup [thing]="thing"></app-datepicker-popup>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--Status-->
    <div class="row">
        <div class="col-lg-12 subheading">
            <h3 id="Status">Status</h3>
        </div>

        <div class="col-9">
            <div class="row">

                <app-text-tile class="col-lg-4"
                               heading="Lokalisierungs Qualität"
                               unit="(%)"
                               [error]="thing.GDP_STATUS.LOCALIZATION_QUALITY.isError"
                               [datapoint]="thing.GDP_STATUS.LOCALIZATION_QUALITY?.TimeseriesData?.currentDatapoint"
                               [historicValues]="[thing.GDP_STATUS.LOCALIZATION_QUALITY?.TimeseriesData?.values]"
                               [historicLegend]="['Lokalisierungs Qualität']">
                </app-text-tile>

                <app-text-tile class="col-lg-4"
                               heading="Abgeschlossene Aufträge"
                               unit="(ST)"
                               [error]="thing.GDP_STATUS.ORDERS_FINISHED.isError"
                               [datapoint]="thing.GDP_STATUS.ORDERS_FINISHED?.TimeseriesData?.currentDatapoint"
                               [historicValues]="[thing.GDP_STATUS.ORDERS_FINISHED?.TimeseriesData?.values]"
                               [historicLegend]="['Abgeschlossene Aufträge']">
                </app-text-tile>

                <app-text-tile class="col-lg-4"
                               heading="Distanz"
                               unit="(m)"
                               [error]="thing.GDP_STATUS.DISTANCE.isError"
                               [directInput]="thing.GDP_STATUS.DISTANCE?.TimeseriesData?.currentDatapoint?.value | number : '1.0-2'"
                               [historicValues]="[thing.GDP_STATUS.DISTANCE?.TimeseriesData?.values]"
                               [historicLegend]="['Distanz']">
                </app-text-tile>

                <app-text-tile class="col-lg-4 gap"
                               heading="Status"
                               [error]="thing.GDP_STATUS.STATUS.isError"
                               [datapoint]="thing.GDP_STATUS.STATUS?.TimeseriesData?.currentDatapoint"
                               [historicValues]="[thing.GDP_STATUS.STATUS?.TimeseriesData?.values]"
                               [historicLegend]="['Status']">
                </app-text-tile>

                <app-text-tile class="col-lg-4 gap"
                               heading="Akku"
                               unit="(%)"
                               [error]="thing.GDP_STATUS.AKKU.isError"
                               [datapoint]="thing.GDP_STATUS.AKKU?.TimeseriesData?.currentDatapoint"
                               [historicValues]="[thing.GDP_STATUS.AKKU?.TimeseriesData?.values]"
                               [historicLegend]="['Akku']">
                </app-text-tile>

                <app-text-tile class="col-lg-4 gap"
                               heading="Gerät"
                               [error]="thing.GDP_STATUS.DEVICE.isError"
                               [datapoint]="thing.GDP_STATUS.DEVICE?.TimeseriesData?.currentDatapoint"
                               [historicValues]="[thing.GDP_STATUS.DEVICE?.TimeseriesData?.values]"
                               [historicLegend]="['Gerät']">
                </app-text-tile>
            </div>
        </div>

        <app-image-tile class="col-lg-3"
                        heading="3D Darstellung GDP"
                        alt="GDP Bild"
                        [ain]="thing.ainUrl"
                        [equipmentNumber]="thing.equipmentNumber"
                        [src]="thing.model3D">
        </app-image-tile>
    </div>

    <!--Aktuelle Position-->
    <div class="row">
        <div class="col-lg-12 subheading">
            <h3 id="CurrentPosition">Aktuelle Position</h3>
        </div>

        <app-text-tile class="col-lg-4"
                       heading="Aktuelle Position X"
                       unit=""
                       [error]="thing.GDP_CURRENT_POSITION.CURRENT_POSITION_X.isError"
                       [directInput]="thing.GDP_CURRENT_POSITION.CURRENT_POSITION_X?.TimeseriesData?.currentDatapoint?.value | number : '1.0-2'"
                       [historicValues]="[thing.GDP_CURRENT_POSITION.CURRENT_POSITION_X?.TimeseriesData?.values]"
                       [historicLegend]="['Aktuelle Position X']">
        </app-text-tile>

        <app-text-tile class="col-lg-4"
                       heading="Aktuelle Position Y"
                       unit=""
                       [error]="thing.GDP_CURRENT_POSITION.CURRENT_POSITION_Y.isError"
                       [directInput]="thing.GDP_CURRENT_POSITION.CURRENT_POSITION_Y?.TimeseriesData?.currentDatapoint?.value | number : '1.0-2'"
                       [historicValues]="[thing.GDP_CURRENT_POSITION.CURRENT_POSITION_Y?.TimeseriesData?.values]"
                       [historicLegend]="['Aktuelle Position Y']">
        </app-text-tile>

        <app-text-tile class="col-lg-4"
                       heading="Aktuelle Position Theta"
                       unit=""
                       [error]="thing.GDP_CURRENT_POSITION.CURRENT_POSITION_THETA.isError"
                       [directInput]="thing.GDP_CURRENT_POSITION.CURRENT_POSITION_THETA?.TimeseriesData?.currentDatapoint?.value | number : '1.0-2'"
                       [historicValues]="[thing.GDP_CURRENT_POSITION.CURRENT_POSITION_THETA?.TimeseriesData?.values]"
                       [historicLegend]="['Aktuelle Position Theta']">
        </app-text-tile>
    </div>

    <!--Geschwindigkeit-->
    <div class="row">
        <div class="col-lg-12 subheading">
            <h3 id="Speed">Geschwindigkeit</h3>
        </div>

        <app-text-tile class="col-lg-4"
                       heading="Geschwindigkeit"
                       unit="(m/s)"
                       [error]="thing.GDP_SPEED.SPEED.isError"
                       [datapoint]="thing.GDP_SPEED.SPEED?.TimeseriesData?.currentDatapoint"
                       [historicValues]="[thing.GDP_SPEED.SPEED?.TimeseriesData?.values]"
                       [historicLegend]="['Geschwindgikeit']">
        </app-text-tile>

        <app-text-tile class="col-lg-4"
                       heading="Drehbeschleunigung Rechts"
                       unit="(u/min)"
                       [error]="thing.GDP_SPEED.ROT_SPEED_R.isError"
                       [datapoint]="thing.GDP_SPEED.ROT_SPEED_R?.TimeseriesData?.currentDatapoint"
                       [historicValues]="[thing.GDP_SPEED.ROT_SPEED_R?.TimeseriesData?.values]"
                       [historicLegend]="['Drehbeschleunigung Rechts']">
        </app-text-tile>

        <app-text-tile class="col-lg-4"
                       heading="Drehbeschleunigung Links"
                       unit="(u/min)"
                       [error]="thing.GDP_SPEED.ROT_SPEED_L.isError"
                       [datapoint]="thing.GDP_SPEED.ROT_SPEED_L?.TimeseriesData?.currentDatapoint"
                       [historicValues]="[thing.GDP_SPEED.ROT_SPEED_L?.TimeseriesData?.values]"
                       [historicLegend]="['Drehbeschleunigung Links']">
        </app-text-tile>
    </div>

    <!--Position-->
    <div class="row">
        <div class="col-lg-12 subheading">
            <h3 id="Position">Position</h3>
        </div>

        <app-text-tile class="col-lg-4"
                       heading="Positions ID"
                       [error]="thing.GDP_POSITION.POSITIONID.isError"
                       [datapoint]="thing.GDP_POSITION.POSITIONID?.TimeseriesData?.currentDatapoint"
                       [historicValues]="[thing.GDP_POSITION.POSITIONID?.TimeseriesData?.values]"
                       [historicLegend]="['Position ID']">
        </app-text-tile>

        <app-text-tile class="col-lg-4"
                       heading="Koordinaten"
                       [error]="thing.GDP_POSITION.POSITIONCOORDINATES.isError"
                       [datapoint]="thing.GDP_POSITION.POSITIONCOORDINATES?.TimeseriesData?.currentDatapoint"
                       [historicValues]="[thing.GDP_POSITION.POSITIONCOORDINATES?.TimeseriesData?.values]"
                       [historicLegend]="['Koordinaten']">
        </app-text-tile>

        <app-text-tile class="col-lg-4"
                       heading="Ziel"
                       [error]="thing.GDP_POSITION.DESTINATION.isError"
                       [datapoint]="thing.GDP_POSITION.DESTINATION?.TimeseriesData?.currentDatapoint"
                       [historicValues]="[thing.GDP_POSITION.DESTINATION?.TimeseriesData?.values]"
                       [historicLegend]="['Ziel']">
        </app-text-tile>

        <app-text-tile class="col-lg-4 gap"
                       heading="Positions Typ"
                       [error]="thing.GDP_POSITION.POSITIONTYPE.isError"
                       [datapoint]="thing.GDP_POSITION.POSITIONTYPE.TimeseriesData?.currentDatapoint"
                       [historicValues]="[thing.GDP_POSITION.POSITIONTYPE.TimeseriesData?.values]"
                       [historicLegend]="['Positions Typ']">
        </app-text-tile>

        <app-text-tile class="col-lg-4 gap"
                       heading="Orientierung"
                       [error]="thing.GDP_POSITION.ORIENTATION.isError"
                       [datapoint]="thing.GDP_POSITION.ORIENTATION.TimeseriesData?.currentDatapoint"
                       [historicValues]="[thing.GDP_POSITION.ORIENTATION.TimeseriesData?.values]"
                       [historicLegend]="['Orientierung']">
        </app-text-tile>

        <app-text-tile class="col-lg-4 gap"
                       heading="Transport Reihenfolge"
                       [error]="thing.GDP_POSITION.TRANSPORTORDER.isError"
                       [datapoint]="thing.GDP_POSITION.TRANSPORTORDER.TimeseriesData?.currentDatapoint"
                       [historicValues]="[thing.GDP_POSITION.TRANSPORTORDER.TimeseriesData?.values]"
                       [historicLegend]="['Transport Reihenfolge']">
        </app-text-tile>
    </div>

    <!--Buttons-->
    <div class="row">
        <div class="col-lg-12 subheading">
            <h3 id="Buttons">Buttons</h3>
        </div>

        <app-text-tile class="col-lg-6"
                       heading="Linker Button"
                       [error]="thing.GDP_BUTTONS.BUTTON_LEFT.isError"
                       [datapoint]="thing.GDP_BUTTONS.BUTTON_LEFT.TimeseriesData?.currentDatapoint"
                       [historicValues]="[thing.GDP_BUTTONS.BUTTON_LEFT.TimeseriesData?.values]"
                       [historicLegend]="['Linker Button']">
        </app-text-tile>

        <app-text-tile class="col-lg-6"
                       heading="Rechter Button"
                       [error]="thing.GDP_BUTTONS.BUTTON_RIGHT.isError"
                       [datapoint]="thing.GDP_BUTTONS.BUTTON_RIGHT.TimeseriesData?.currentDatapoint"
                       [historicValues]="[thing.GDP_BUTTONS.BUTTON_RIGHT.TimeseriesData?.values]"
                       [historicLegend]="['Rechter Button']">
        </app-text-tile>
    </div>

    <div class="row gap"></div>
</div>

<app-monitoring-console *ngIf="data.showMonitoring && thing.errorCount > 0"
                        [criticalThingToShowInConsole]="[thing]"></app-monitoring-console>
