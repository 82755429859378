<div class="tile" [ngClass]="{'errorTile' : error}">

    <div class="tileHead">
        {{heading}}
        <div class="unit">
            {{unit}}
        </div>
    </div>

    <div class="tileBody">
        <div *ngIf="value != null else placeholdGauge">
            <app-gaugechart [value]="value"
                            [setOptions]="options">
            </app-gaugechart>
        </div>
    </div>
</div>

<ng-template #placeholdGauge>
    <app-placehold-gaugechart></app-placehold-gaugechart>
</ng-template>
