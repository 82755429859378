import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {MonitoringLevel} from '../../../core/model/MonitoringLevels.enum';
import {LimitSizeArrayModel} from '../../../core/model/helper-models/limit-size-array.model';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
    selector: 'app-ssif-statistic',
    templateUrl: './ssif-statistic.component.html',
    styleUrls: ['./ssif-statistic.component.scss', './../card.scss']
})
export class SsifStatisticComponent extends BaseViewElement implements OnInit, OnDestroy, OnChanges
{
    @Input() Infeed: PropertySet;
    @Input() Crash: PropertySet;

    AmountInfeed: Property<any>;
    AmountFlapMovements: Property<any>;
    AmountError: Property<any>;
    AmountCrash: Property<any>;


    constructor()
    {
        super('ssif-statistic');
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit(result);
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.Infeed == null) return;
        this.AmountInfeed = this.Infeed.properties.get('ANZ_EINSCHLEUSUNGEN');
        this.AmountFlapMovements = this.Infeed.properties.get('ANZ_KLAPPENBEWEGUNGEN');
        this.AmountError = this.Infeed.properties.get('ANZ_FEHLER');

        if (this.Crash == null) return;
        this.AmountCrash = this.Crash.properties.get('ANZ_CRASH');
    }

}
