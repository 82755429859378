<app-group-header [Heading]="'Spannsystem Kette' | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>
<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Dehnung Kette in %' | translate"
                      [Error]="ChainLength?.isError"
                      [ErrorLevel]="ChainLength?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [ChartType]="'gauge'"
                      [Values]="[stretchingOffsetPercent]"
                      [Unit]="'%'"
                      [min]="0"
                      [max]="1.5"
                      [Legend]="['Aktuell' | translate, 'Max' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>


    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Dehnung Kette in mm' | translate"
                      [Error]="ChainLength?.isError"
                      [ErrorLevel]="ChainLength?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [ChartType]="'gauge'"
                      [Values]="[stretchingOffsetMillimeter]"
                      [Unit]="'mm'"
                      [min]="1735"
                      [max]="1761"
                      [Legend]="['Aktuell' | translate, 'Max' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>


</div>
