<ng-container
>
    <h2>
        <span style="float: right">
            <button mat-button (click)="closeModal()">
                <mat-icon>close</mat-icon>
            </button>
        </span>
    </h2>
    <app-treechart [(data)]="data['entity_structure']"></app-treechart>
    <!--<div class="test">-->
        <!--{{ data['entity_structure'] | json }}-->
    <!--</div>-->
</ng-container>
