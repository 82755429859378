<ng-container *ngIf="RollIn?.TimeseriesData.values[RollIn?.TimeseriesData.values.length-1]?.value > 0">
    <app-group-header [Heading]="'Einlagerungen' | translate"
                      [chart]="chart"
                      [info]="info"
                      [noChart]="true"
                      (chartEvent)="getChartEvent($event)"
                      (infoEvent)="getInfoEvent($event)">
    </app-group-header>
    <div class="card-group">
        <app-default-card #card class="card" style="border: none; border-radius: unset;"
                          [Error]="RollIn?.isError"
                          [ErrorLevel]="RollIn?.status"
                          [showChart]="chart"
                          [showInfo]="info"
                          [Values]="[RollIn?.TimeseriesData]"
                          [MaintenanceValue]="RollIn?.ValueLastService"
                          [Legend]="['Einlagerungen' | translate, 'Einlagerung bei Wartung' | translate]"
                          [resizeEvent]="childResizeEvent"></app-default-card>
        <!--
        <app-triple-card #card
                         class="card" style="border: none; border-radius: unset;"
                         [Error]="RollInError"
                         [ErrorLevel]="RollInErrorLevel"
                         [showChart]="chart"
                         [showInfo]="info"
                         [Value]="[RollInValue]"
                         [MaintenanceValue]="RollInLastMaintenance | number : '1.0-0'"
                         [Legend]="['Einlagerungen' | translate, 'Einlagerung bei Wartung' | translate]"
                         [resizeEvent]="childResizeEvent"></app-triple-card>
                         -->
    </div>
</ng-container>

<ng-container *ngIf="RollOut?.TimeseriesData.values[RollOut?.TimeseriesData.values.length-1]?.value > 0">
    <app-group-header [Heading]="'Auslagerungen' | translate"
                      [chart]="chart"
                      [info]="info"
                      [noChart]="true"
                      (chartEvent)="getChartEvent($event)"
                      (infoEvent)="getInfoEvent($event)">
    </app-group-header>
    <div class="card-group">
        <app-default-card #card class="card" style="border: none; border-radius: unset;"
                          [Error]="RollOut?.isError"
                          [ErrorLevel]="RollOut?.status"
                          [showChart]="chart"
                          [showInfo]="info"
                          [Values]="[RollOut?.TimeseriesData]"
                          [MaintenanceValue]="RollOut?.ValueLastService"
                          [Legend]="['Auslagerungen' | translate, 'Auslagerungen bei Wartung' | translate]"
                          [resizeEvent]="childResizeEvent"></app-default-card>
        <!--
        <app-triple-card #card
                         class="card" style="border: none; border-radius: unset;"
                         [Error]="RollOutError"
                         [ErrorLevel]="RollOutErrorLevel"
                         [showChart]="chart"
                         [showInfo]="info"
                         [Value]="[RollOutValue]"
                         [MaintenanceValue]="RollOutLastMaintenance.value | number : '1.0-0'"
                         [Legend]="['Auslagerungen' | translate, 'Auslagerungen bei Wartung' | translate]"
                         [resizeEvent]="childResizeEvent"></app-triple-card>
                         -->
    </div>
</ng-container>

<ng-container *ngIf="Relocation?.TimeseriesData.values[Relocation?.TimeseriesData.values.length-1]?.value > 0">
    <app-group-header [Heading]="'Umlagerungen' | translate"
                      [chart]="chart"
                      [info]="info"
                      [noChart]="true"
                      (chartEvent)="getChartEvent($event)"
                      (infoEvent)="getInfoEvent($event)">
    </app-group-header>
    <div class="card-group">
        <app-default-card #card class="card" style="border: none; border-radius: unset;"
                          [Error]="Relocation?.isError"
                          [ErrorLevel]="Relocation?.status"
                          [showChart]="chart"
                          [showInfo]="info"
                          [Values]="[Relocation?.TimeseriesData]"
                          [MaintenanceValue]="Relocation?.ValueLastService"
                          [Legend]="['Umglagerungen' | translate, 'Umlagerungen bei Wartung' | translate]"
                          [resizeEvent]="childResizeEvent"></app-default-card>
        <!--
        <app-triple-card #card
                         class="card" style="border: none; border-radius: unset;"
                         [Error]="RelocationError"
                         [ErrorLevel]="RelocationErrorLevel"
                         [showChart]="chart"
                         [showInfo]="info"
                         [Value]="[RelocationValue]"
                         [MaintenanceValue]="RelocationLastMaintenance.value | number : '1.0-0'"
                         [Legend]="['Umglagerungen' | translate, 'Umlagerungen bei Wartung' | translate]"
                         [resizeEvent]="childResizeEvent"></app-triple-card>-->
    </div>
</ng-container>

<ng-container *ngIf="Relocation?.TimeseriesData.values[Relocation?.TimeseriesData.values.length-1]?.value == 0 &&
                     RollOut?.TimeseriesData.values[RollOut?.TimeseriesData.values.length-1]?.value == 0 &&
                     RollIn?.TimeseriesData.values[RollIn?.TimeseriesData.values.length-1]?.value == 0">
    <app-group-header [Heading]="'Ein-/Aus-/Umlagerungen' | translate"
                      [chart]="chart"
                      [info]="info"
                      [noChart]="true"
                      (chartEvent)="getChartEvent($event)"
                      (infoEvent)="getInfoEvent($event)">
    </app-group-header>
    <div style="height: calc(100% - {{groupHeaderHeight}});"
         class="card rounded-0">
        <div class="text-center" #card [style.width]="width" [style.height]="height">
            <img title="Keine aktuellen Daten vorhanden!" [style.width]="width" [style.height]="height"
                 [src]="environment.storageUrl + '/icons/cloud_off_black_18dp.png'"/>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="Statistic == null">
    <app-group-header [Heading]="'Ein-/Aus-/Umlagerungen' | translate"
                      [chart]="chart"
                      [info]="info"
                      [noChart]="true"
                      (chartEvent)="getChartEvent($event)"
                      (infoEvent)="getInfoEvent($event)">
    </app-group-header>
    <div style="height: calc(100% - {{groupHeaderHeight}});"
         class="card rounded-0">
        <div class="text-center" #card [style.width]="width" [style.height]="height">
            <img title="Keine aktuellen Daten vorhanden!" [style.width]="width" [style.height]="height"
                 [src]="environment.storageUrl + '/icons/cloud_off_black_18dp.png'"/>
        </div>
    </div>
</ng-container>
