import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {environment} from '../../../../../environments/environment';

@Component({
    selector: 'app-group-header',
    templateUrl: './group-header.component.html',
    styleUrls: ['./group-header.component.scss']
})
export class GroupHeaderComponent implements OnInit
{
    @Input() Heading: string;
    @Input() chart = false;
    @Input() info = false;
    @Input() noText = false;
    @Input() noChart = false;
    @Input() noInfo = false;
    @Output() chartEvent = new EventEmitter<boolean>();
    @Output() infoEvent = new EventEmitter<boolean>();

    setText()
    {
        this.chartEvent.emit(false);
        this.chart = false;
        this.infoEvent.emit(false);
        this.info = false;
    }

    setChart()
    {
        this.chartEvent.emit(true);
        this.chart = true;
        this.infoEvent.emit(false);
        this.info = false;
    }

    setInfo()
    {
        this.infoEvent.emit(true);
        this.info = true;
    }

    constructor()
    {
    }

    ngOnInit(): void
    {
    }

    protected readonly environment = environment;
}
