<div class="componentContainer">
    <div class="tile firstDiv">
        <div class="tileHead">
            {{'Testfahrten' | translate}}
        </div>

        <div class="mat-elevation-z8">
            <div *ngIf="this.loadingBool; else loading">
                <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="dataSource">

                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                            <!--<mat-checkbox (change)="$event ? masterToggle() : null"
                                          [checked]="selection.hasValue() && isAllSelected()"
                                          [indeterminate]="selection.hasValue() && !isAllSelected()">
                            </mat-checkbox>-->
                        </th>
                        <td mat-cell *matCellDef="let row" class="paddedCheckbox">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                          (change)="$event ? selection.toggle(row) : null"
                                          [checked]="selection.isSelected(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef class="paddedContent">
                            <div mat-sort-header="name">
                                {{'Name' | translate}}
                            </div>
                            <mat-form-field>
                                <mat-label>{{'Name' | translate}}</mat-label>
                                <mat-select [formControl]="nameFilter">
                                    <mat-option [value]="">--</mat-option>
                                    <mat-option *ngFor="let name of filterDropdown.names" [value]="name">
                                        {{name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let element" class="paddedInline"> {{element.thingName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef class="paddedContent">
                            <div mat-sort-header="type">
                                {{'Typ' | translate}}
                            </div>
                            <mat-form-field>
                                <mat-label>{{'Typ' | translate}}</mat-label>
                                <mat-select [formControl]="typeFilter">
                                    <mat-option [value]="">--</mat-option>
                                    <mat-option *ngFor="let type of filterDropdown.types" [value]="type">
                                        {{type}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let element" class="paddedInline"> {{element.thingType}} </td>
                    </ng-container>

                    <ng-container matColumnDef="eventtype">
                        <th mat-header-cell *matHeaderCellDef class="paddedContent">
                            <div mat-sort-header="eventtype">
                                {{'Eventtyp' | translate}}
                            </div>
                            <mat-form-field>
                                <mat-label>{{'Eventtyp' | translate}}</mat-label>
                                <mat-select [formControl]="eventtypeFilter">
                                    <mat-option [value]="">--</mat-option>
                                    <mat-option *ngFor="let eventtype of filterDropdown.eventtypes" [value]="eventtype">
                                        {{eventtype}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let element" class="paddedInline"> {{element.eventType}} </td>
                    </ng-container>

                    <ng-container matColumnDef="starttime">
                        <th mat-header-cell *matHeaderCellDef class="paddedContent">
                            <div class="row">
                                <div mat-sort-header="starttime" class="col">
                                    {{'Startzeit' | translate}}
                                </div>
                                <div class="col" class="align-right">
                                    <button (click)="resetDateFilter()" mat-button align="right" vertical-align="top"
                                            class="smallerButton">
                                        {{'Filter löschen' | translate}}
                                    </button>
                                </div>
                            </div>
                            <mat-form-field>
                                <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                                    <input matStartDate formControlName="begin" placeholder="Start date">
                                    <input matEndDate formControlName="end" placeholder="End date">
                                </mat-date-range-input>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker></mat-date-range-picker>

                                <mat-error *ngIf="range.controls.begin.hasError('matStartDateInvalid')">Invalid start
                                    date
                                </mat-error>
                                <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date
                                </mat-error>
                                <!--
    <input matInput
           [placeholder]="'Datum auswählen' | translate"
           [satDatepicker]="picker2"
           [formControl]="date"
           readonly>
    <sat-datepicker #picker2 [rangeMode]="true" [selectFirstDateOnClose]="true">
    </sat-datepicker>
    <sat-datepicker-toggle matSuffix [for]="picker2"></sat-datepicker-toggle>
        -->
                            </mat-form-field>
                        </th>
                        <td type="date" mat-cell *matCellDef="let element"
                            class="paddedInline"> {{element.startTime | date: 'long'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef class="paddedContent">
                            <div mat-sort-header="status">
                                {{'Status' | translate}}
                            </div>
                            <mat-form-field>
                                <mat-label>{{'Status' | translate}}</mat-label>
                                <mat-select [formControl]="statusFilter">
                                    <mat-option [value]="">--</mat-option>
                                    <mat-option *ngFor="let status of filterDropdown.status" [value]="status">
                                        {{status}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let element" class="paddedInline"> {{element.status}} </td>
                    </ng-container>

                    <!-- <ng-container matColumnDef="changeStatuus">
                      <th mat-header-cell *matHeaderCellDef class="paddedContent">
                        <div mat-sort-header="changeStatus">
                          changeStatus
                        </div>
                      </th>
                      <td mat-cell *matCellDef="let element" class="paddedInline" > changeStatus </td>
                    </ng-container> -->

                    <ng-container matColumnDef="changeStatus">
                        <th mat-header-cell *matHeaderCellDef class="paddedContent">
                            <!-- <button mat-button (click)="changeStatusMultiElements()">Change Status</button> -->
                            <button mat-button data-toggle="modal" data-target="#changeMultipleStatusPopup">
                                {{'Status ändern' | translate}}
                            </button>
                        </th>
                        <td mat-cell *matCellDef="let element" class="paddedInline">
                            <!-- <button mat-button (click)="changeStatus(element)">Change Status</button> -->
                            <button mat-button (click)="singleSelectedTestdrive(element)" data-toggle="modal"
                                    data-target="#changeSingleStatusPopup">
                                {{'Status ändern' | translate}}
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
            <div class="row">
                <div class="col">
                    <label style="margin-left: 5px; margin-top: 10px;">{{selectedCount}}
                        /{{allCount}} {{'ausgewählt' | translate}}</label>
                </div>
                <div class="col">
                    <button style="margin-left: 5px; margin-top: 10px;" mat-button (click)="compareTestdrives()">
                        {{'Ausgewählte Testfahrten vergleichen' | translate}}
                    </button>
                    <button [disabled]="this.selection.selected.length != 1" style="margin-left: 5px; margin-top: 10px;"
                            mat-button (click)="showThreshold()">
                        {{'Ausgewählte Thresholds anzeigen' | translate}}
                    </button>
                </div>

                <div class="col">
                    <button style="margin-left: 5px; margin-top: 10px;" mat-button data-toggle="modal"
                            data-target="#confBandsCheckPopup">
                        {{'Confidence Bands berechnen' | translate}}
                    </button>
                </div>
                <div class="col">
                    <form>
                        <mat-form-field class="example-full-width">
                            <input matInput placeholder="{{'Toleranz' | translate}}"
                                   value="{{confidenceBandsTolerance}}">
                            <!-- <input matInput placeholder="tolerance" value="0.1" [formControl]="confidenceBandsTolerance"> -->
                        </mat-form-field>
                    </form>
                </div>
                <div class="col">
                    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!this.chartsLoadingBool; else loading"></div>

    <!-- Zeige eine Threshold -->
    <div [ngSwitch]="this.selectionMode.valueOf()">
        <div *ngSwitchCase="1">
            <div style="margin: 25px">
                <div class="row"
                     *ngIf="thing && (thing.TESTDRIVE_EVALUATION_HORIZONTAL ||  thing.TESTDRIVE_EVALUATION_VERTICAL)">

                    <div class="col-lg-12 subheading">
                        <h3 id="TestrunEvaluatet">{{'Berechnete Testfahrt Werte' | translate}}</h3>
                    </div>

                    <app-calculated-testdrive-tile class="col-lg-6"
                                                   *ngIf="thing.TESTDRIVE_EVALUATION_HORIZONTAL && thing.TESTDRIVE_EVALUATION_HORIZONTAL.TESTDRIVE_EVALUATION_H.X_1.upperThreshold != null"
                                                   [propSet]="thing.TESTDRIVE_EVALUATION_HORIZONTAL.TESTDRIVE_EVALUATION_H"
                                                   [heading]="'Berechnete Werte Horizontal' | translate">
                    </app-calculated-testdrive-tile>

                    <app-calculated-testdrive-tile class="col-lg-6"
                                                   *ngIf="thing.TESTDRIVE_EVALUATION_VERTICAL.TESTDRIVE_EVALUATION_V.X_1.upperThreshold != null"
                                                   [propSet]="thing.TESTDRIVE_EVALUATION_VERTICAL.TESTDRIVE_EVALUATION_V"
                                                   [heading]="'Berechnete Werte Vertikal' | translate">
                    </app-calculated-testdrive-tile>
                </div>
            </div>
        </div>

        <!-- Zeige mehrere Thresholds -->
        <div *ngSwitchCase="2">
            <div style="margin: 100px">
                MEEEEEEEEEEEEEHR
            </div>
        </div>

        <!-- Zeige TestdriveEvent Diagramme -->
        <div *ngSwitchCase="0">
            <div *ngIf="tmpDisplayedCharts[0]">
                <div class="row" style="padding: 4px;">
                    <div *ngFor="let chart of tmpDisplayedCharts" class="col-lg-4" style="padding: 0px!important;">
                        <div class="tile charts" style="height: 400px;">
                            <div class="tileHead">{{chart.title}}</div>
                            <app-linechart [confidencebands]="chart.confidenceBand" [legend]="chart.legend"
                                           [value]="chart.value"></app-linechart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<!-- check conf bands Popup  -->
<div class="modal fade" id="confBandsCheckPopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{'Bestätigen' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                {{'Sind sie sicher, dass sie die ConfidenceBands für die folgenden Testfahrten berechnen wollen?' | translate}}
                <div *ngFor="let thing of selection.selected">
                    {{thing.thingName}} - {{thing.startTime | date: 'long'}}
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal"
                        (click)="calculateConfidenceBands()">{{'Berechnen' | translate}}</button>
                <button type="button" class="btn btn-primary">{{'Abbrechen' | translate}}</button>
            </div>
        </div>
    </div>
</div>

<!-- change single Status Popup  -->
<div class="modal fade" id="changeSingleStatusPopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{'Bestätigung benötigt' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                {{'Bitte wählen sie den Status zu dem sie wechseln wollen.' | translate}}
                <div>{{'Folgende Testfahrt ist ausgewählt:' | translate}}</div>
                <div *ngIf="singleSelectedTestdriveElement">{{singleSelectedTestdriveElement.thingName}}
                    - {{singleSelectedTestdriveElement.startTime | date: 'long'}}</div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="changeStatus(OK)">OK
                </button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="changeStatus(Fehlerhaft)">
                    Warning
                </button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="changeStatus(Ausstehend)">
                    Pending
                </button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="changeStatus(Undefined)">
                    Undefined
                </button>
                <button type="button" class="btn btn-primary">Cancel</button>
            </div>
        </div>
    </div>
</div>

<!-- change multiple Status Popup  -->
<div class="modal fade" id="changeMultipleStatusPopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{'Bestätigung benötigt' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <span>{{'Bitte wählen sie den Status zu dem sie wechseln wollen.' | translate}}</span>
                <div>{{'Folgende Testfahrten sind ausgewählt:' | translate}}</div>
                <div *ngFor="let thing of selection.selected">
                    {{thing.thingName}} - {{thing.startTime | date: 'long'}}
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal"
                        (click)="changeStatusMultiElements(OK)">OK
                </button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal"
                        (click)="changeStatusMultiElements(Fehlerhaft)">Warning
                </button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal"
                        (click)="changeStatusMultiElements(Ausstehend)">Pending
                </button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal"
                        (click)="changeStatusMultiElements(Undefined)">Undefined
                </button>
                <button type="button" class="btn btn-primary">Cancel</button>
            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="row">
        <div class="col-lg-12 text-center">
            <div class="spinner-grow text-danger" role="status" style="height: 100px; width: 100px;">
                <span class="visually-hidden">Loading...</span>
            </div>
            <div style="font-size: 30px">
                {{'Daten werden abgerufen' | translate}}
            </div>
        </div>
    </div>
</ng-template>
