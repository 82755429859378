import {Component, Input, OnInit} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {Thing} from '../../../core/model/thing.model';


@Component({
    selector: 'app-ssagm-stat-es',
    templateUrl: './ssagm-stat-es.component.html',
    styleUrls: ['./ssagm-stat-es.component.scss']
})
export class SsagmStatEsComponent extends BaseViewElement implements OnInit
{

    @Input() Endstellen: Array<Thing>;


    constructor()
    {
        super('ssagm-stat-es');
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit();
    }
}
