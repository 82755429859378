import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HelpPageComponent} from '../help-page.component';
import {environment} from '../../../../../environments/environment';

// , '../../card-component/card.component.scss'

@Component({
    selector: 'app-help-start-page',
    templateUrl: './help-start-page.component.html',
    styleUrls: ['./help-start-page.component.css', '../helpPageModule.scss',
        '../../monitoring/monitoring-thing/monitoring-thing.component.scss',
        '../../monitoring/monitoring.scss']
})
export class HelpStartPageComponent extends HelpPageComponent implements OnInit
{

    constructor(translate: TranslateService, private route: ActivatedRoute, private router: Router)
    {
        super(translate);
    }

    ngOnInit(): void
    {

    }
    public readonly environment = environment;
}
