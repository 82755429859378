<div *ngIf="location != null">

    <!--Banner-->
    <div class="row" #banner id="banner">
        <div *ngIf="isOneLine; else monitoringDropdown" class="col-lg-12 monitoringItem">
            <ul class="list-inline">
                <li class="list-inline-item">
                    <a (click)="nav.navigateBack('Customer')" [routerLink]="['/']">
                        {{'Unternehmen' | translate}}
                        ({{location.parentCompany.name}})
                    </a>
                </li>
                <li class="list-inline-item"> ></li>
                <li class="list-inline-item">
                    <a (click)="nav.navigateBack('Location')" [routerLink]="['/' + location.parentCompany.name]">
                        {{'Standort' | translate}}
                        ({{location.name}})
                    </a>
                </li>
                <li class="list-inline-item"> ></li>
                <li class="list-inline-item">{{'Anlage' | translate}}</li>
            </ul>
        </div>

        <ng-template class="col-12" #monitoringDropdown>
            <button class="btn btn-secondary" type="button" style="margin-left: 10px;"
                    (click)="nav.navigateBack('Location')" [routerLink]="['/' + location.parentCompany.name]">
                <img [src]="environment.storageUrl + '/icons/keyboard_backspace-white-18dp.svg'"/>
            </button>
        </ng-template>
    </div>
    <div class="row">

        <div *ngFor="let sys of systems | orderBy:'name'"
             class="col-lg-2 col-md-3 col-sm-4 col-12 monitoring-card">

            <app-card-component [customerDetail]="sys"></app-card-component>
        </div>
    </div>
</div>
