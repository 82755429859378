import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ChartsModule} from '../../core/controller/modules/charts.module';
import {DatepickerModule} from '../../core/controller/modules/datepicker.module';
import {PipesModule} from '../../core/controller/modules/pipes.module';
import {ProgressBarModule} from '../../core/controller/modules/progress-bar.module';
import {TileModule} from '../../core/controller/modules/tile.module';
import {ConverComponent} from './conver/conver.component';
import {PrbgComponent} from './prbg/prbg.component';
import {FtsComponent} from './fts/fts.component';
import {GdpComponent} from './gdp/gdp.component';
import {HcvComponent} from './hcv/hcv.component';
import {LscComponent} from './lsc/lsc.component';
import {MlsComponent} from './mls/mls.component';
import {OlpsComponent} from './olps/olps.component';
import {OlsComponent} from './ols/ols.component';
import {RbgComponent} from './rbg/rbg.component';
import {SilverRoutingModule} from './silver-routing.module';
import {VcvComponent} from './vcv/vcv.component';
import {MonitoringModule} from '../../core/controller/modules/monitoring.module';
import {TranslateModule} from '@ngx-translate/core';
import {BannerModule} from '../../core/controller/modules/banner.module';
import {OlseComponent} from './olse/olse.component';
import {CthCustomComponent} from './cth-custom/cth-custom.component';
import {RbgSubcompParentComponent} from './rbg/rbg-subcomp-parent/rbg-subcomp-parent.component';
import {GridsterModule} from 'angular-gridster2';
import {SubComponentModule} from '../../core/controller/modules/sub-component.module';
import {MlsSubcompParentComponent} from './mls/mls-subcomp-parent/mls-subcomp-parent.component';
import {VcvSubcompParentComponent} from './vcv/vcv-subcomp-parent/vcv-subcomp-parent.component';
import {OlsSubcompParentComponent} from './ols/ols-subcomp-parent/ols-subcomp-parent.component';
import {OlseSubcompParentComponent} from './olse/olse-subcomp-parent/olse-subcomp-parent.component';
import {PrbgSubcompParentComponent} from './prbg/prbg-subcomp-parent/prbg-subcomp-parent.component';
import {CthCustomSubcompParentComponent} from './cth-custom/cth-custom-subcomp-parent/cth-custom-subcomp-parent.component';
import {ConverSubcompParentComponent} from './conver/conver-subcomp-parent/conver-subcomp-parent.component';
import {LscSubcompParentComponent} from './lsc/lsc-subcomp-parent/lsc-subcomp-parent.component';
import {SsagmComponent} from './ssagm/ssagm.component';
import {SsdtmComponent} from './ssdtm/ssdtm.component';
import {SsesComponent} from './sses/sses.component';
import {SsifComponent} from './ssif/ssif.component';
import {SsagmSubcompParentComponent} from './ssagm/ssagm-subcomp-parent/ssagm-subcomp-parent.component';
import {SsifSubcompParentComponent} from './ssif/ssif-subcomp-parent/ssif-subcomp-parent.component';
import {SsesSubcompParentComponent} from './sses/sses-subcomp-parent/sses-subcomp-parent.component';
import {SsdtmSubcompParentComponent} from './ssdtm/ssdtm-subcomp-parent/ssdtm-subcomp-parent.component';
import {SscrrComponent} from './sscrr/sscrr.component';
import {SscrrSubcompParentComponent} from './sscrr/sscrr-subcomp-parent/sscrr-subcomp-parent.component';
import {FtsSubcompParentComponent} from './fts/fts-subcomp-parent/fts-subcomp-parent.component';
import {BotComponent} from './bot/bot.component';
import {BotSubcompParentComponent} from './bot/bot-subcomp-parent/bot-subcomp-parent.component';
import {OlsxComponent} from './olsx/olsx.component';
import {OlsxSubcompParentComponent} from './olsx/olsx-subcomp-parent/olsx-subcomp-parent.component';
import { ThingComponent } from './thing/thing.component';
import { ThingSubcompParentComponent } from './thing/thing-subcomp-parent/thing-subcomp-parent.component';
import { SeqComponent } from './seq/seq.component';
import { SeqSubcompParentComponent } from './seq/seq-subcomp-parent/seq-subcomp-parent.component';
import { OlpsSubcompParentComponent } from './olps/olps-subcomp-parent/olps-subcomp-parent.component';

@NgModule({
    imports: [
        CommonModule,
        SilverRoutingModule,
        RouterModule,
        ChartsModule,
        PipesModule,
        DatepickerModule,
        ProgressBarModule,
        TileModule,
        MonitoringModule,
        TranslateModule,
        BannerModule,
        GridsterModule,
        SubComponentModule
    ],
    declarations: [
        OlsComponent,
        OlseComponent,
        HcvComponent,
        FtsComponent,
        MlsComponent,
        RbgComponent,
        VcvComponent,
        GdpComponent,
        OlpsComponent,
        ConverComponent,
        LscComponent,
        PrbgComponent,
        CthCustomComponent,
        RbgSubcompParentComponent,
        MlsSubcompParentComponent,
        VcvSubcompParentComponent,
        OlsSubcompParentComponent,
        OlsSubcompParentComponent,
        OlseSubcompParentComponent,
        PrbgSubcompParentComponent,
        CthCustomSubcompParentComponent,
        ConverSubcompParentComponent,
        LscSubcompParentComponent,
        SsagmComponent,
        SsdtmComponent,
        SsesComponent,
        SsifComponent,
        SsagmSubcompParentComponent,
        SsifSubcompParentComponent,
        SsesSubcompParentComponent,
        SsdtmSubcompParentComponent,
        SscrrComponent,
        SscrrSubcompParentComponent,
        FtsSubcompParentComponent,
        BotComponent,
        BotSubcompParentComponent,
        OlsxComponent,
        OlsxSubcompParentComponent,
        ThingComponent,
        ThingSubcompParentComponent,
        SeqComponent,
        SeqSubcompParentComponent,
        OlpsSubcompParentComponent,
    ],
    exports: [ThingSubcompParentComponent]
})
export class SilverModule
{
}
