import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {marker} from '../../../packages/bronze/locations/marker';
import {Company} from '../../model/company.model';
import {CLocation} from '../../model/location.model';
import {CSystem} from '../../model/system.model';
import {Thing} from '../../model/thing.model';
import {AuthenticationService} from './authentication.service';
import {lastValueFrom} from 'rxjs/internal/lastValueFrom';
import {environment} from '../../../../environments/environment';
import {ControlCabinet} from '../../model/control-cabinet.model';

@Injectable()
/**
 * Service zum holen der JSON für die Navigation und erstellen eines Model Objekts in diesem Fall ThingTypes
 */
export class InitialisePageService
{
    private devicesApiPath = environment.apiUrl + environment.apiVersion + '/user/thing-details';

    constructor(private _http: HttpClient, private _authService: AuthenticationService)
    {
    }

    /**
     * Holt alle Initialisierungsdaten
     * Gibt customer Array mit allen Kunden/Standorten/Anlagen und things zurück
     **/
    public async getInitialiseData(): Promise<Company[]>
    {
        const headers = await this._authService.getDefaultHttpHeaders();

        const menuRequest = this._http.get(this.devicesApiPath, {headers: headers})
            .pipe(
                map(response =>
                {
                    const companies = new Map<string, Company>();
                    const controlCabinets = new Map<string, Thing>();

                    for (const responseElement of response['result'] as any)
                    {
                        let CompanyObject: Company = null;

                        if (responseElement.customerId == null || responseElement.locationId == null || responseElement.systemId == null)
                        {
                            console.log('Missing Info for Thing: ' + responseElement._id, responseElement);
                            continue;
                        }


                        if (companies.has(responseElement.customerId._id))
                        {
                            CompanyObject = companies.get(responseElement.customerId._id);
                        }
                        else
                        {
                            CompanyObject = new Company(responseElement.customerId._id,
                                responseElement.customerId.name, responseElement.customerId.logoPath);

                            companies.set(CompanyObject.id, CompanyObject);
                        }


                        let LocationObject: CLocation = null;


                        if (CompanyObject.locations.has(responseElement.locationId._id))
                        {
                            LocationObject = CompanyObject.locations.get(responseElement.locationId._id);
                        }
                        else
                        {
                            LocationObject = new CLocation(responseElement.locationId._id, responseElement.locationId.name,
                                this.getCoordinates(responseElement.locationId.coordinates, responseElement.locationId.name));

                            LocationObject.parentCompany = CompanyObject;

                            CompanyObject.locations.set(LocationObject.id, LocationObject);
                        }


                        let SystemObject: CSystem = null;


                        if (LocationObject.systems.has(responseElement.systemId._id))
                        {
                            SystemObject = LocationObject.systems.get(responseElement.systemId._id);
                        }
                        else
                        {
                            SystemObject = new CSystem(responseElement.systemId._id, responseElement.systemId.name,
                                responseElement.systemId.modelPath, responseElement.systemId.powerBi);

                            SystemObject.parentLocation = LocationObject;

                            LocationObject.systems.set(SystemObject.id, SystemObject);
                        }



                        let ControlCabinetObject: Thing = null;

                        if (responseElement.controlCabinetId != null)
                        {
                            if (controlCabinets.has(responseElement.controlCabinetId._id))
                            {
                                ControlCabinetObject = controlCabinets.get(responseElement.controlCabinetId._id);
                            }
                            else
                            {
                                ControlCabinetObject =  new Thing(responseElement.controlCabinetId._id, responseElement.controlCabinetId.name,
                                    'CONTROLCABINET', null, null, null, responseElement.controlCabinetId.name,
                                    null, null, null, LocationObject.name,
                                    SystemObject.name, null, null, null, null,
                                    null);
                                ControlCabinetObject.parentSystem = SystemObject;
                                controlCabinets.set(ControlCabinetObject.id, ControlCabinetObject);
                            }


                        }
                        else
                        {
                                ControlCabinetObject =  new Thing(null, null,
                                    'CONTROLCABINET', null, null, null, null,
                                    null, null, null, LocationObject.name,
                                    SystemObject.name, null, null, null, null,
                                    null);
                                ControlCabinetObject.parentSystem = SystemObject;
                        }


                        let link = null;

                        if (responseElement.ainLink != null && responseElement.ainLink !== '')
                        {
                            link = responseElement.ainLink as string;
                        }

                        const thing = new Thing(responseElement._id,
                            (responseElement.type + ' ' + responseElement.aisle + '/' + responseElement.level), responseElement.type,
                            responseElement.aisle, responseElement.level, responseElement.imagePath, responseElement.displayName,
                            responseElement.equipmentNr, responseElement.layoutPosition, '0', LocationObject.name,
                            SystemObject.name, responseElement.orderNr, link, responseElement.installation, responseElement.layout,
                            responseElement.properties);

                        thing.parentSystem = SystemObject;
                        thing.controlCabinet = ControlCabinetObject;

                        SystemObject.addThing(thing.id, thing);
                    }

                    companies.forEach(company =>
                    {
                        company.locations.forEach(location =>
                        {

                            location.systems.forEach(system =>
                            {
                                // system.getOutdatedProperties();
                                system.groupThingsByIsle();
                                system.fillThingTypes();
                            });
                            // location.getOutdatedProperties();
                        });
                        // company.getOutdatedProperties();
                    });

                    console.log(response);

                    return Array.from(companies.values());


                    /*
                                        for (const company of tmp.customers)
                                        {
                                            const notValidCompany = company._id == null || company.name == null;
                                            if (notValidCompany) continue;

                                            const companyId = company._id;
                                            const companyName = company.name;
                                            const locations = this.getLocations(company.locations);
                                            const logoPath = company.logoPath;

                                            const CompanyObject = new Company(companyId, companyName, locations, logoPath);

                                            for (const location of locations)
                                            {
                                                location.parentCompany = CompanyObject;
                                            }

                                            companies.push(CompanyObject);
                                        }
                                        return companies;*/
                })
            );


        return lastValueFrom(menuRequest);
        /*
         * ACHTUNG der auskommentierte Teil kann zu einer überlastung von SAP führen
         ,
         shareReplay(),
         retryWhen(errors => errors.pipe(( delay(5000), take(10)))));*/
    }

    /**
     * Gibt alle Standorte eines Kunde zurück
     * @param pLocations : json object
     **//*
    private getLocations(pLocations: any): Array<CLocation>
    {
        const locations = new Array<CLocation>();
        if (pLocations == null)
        {
            return locations;
        }

        for (const location of pLocations)
        {
            const coordinates = this.getCoordinates(location.coordinates, location.name);
            const systems = this.getSystems(location.systems, location.name);

            const LocationObject = new CLocation(location._id, location.name, coordinates, systems);

            for (const system of systems)
            {
                system.parentLocation = LocationObject;
            }

            locations.push(LocationObject);
        }

        return locations;
    }*/

    /**
     * Gibt alle Anlagen eines Standorts als CSystem Objekte zurück
     * @param systemsAsJson
     *//*
    private getSystems(systemsAsJson: any, locationName: string): Array<CSystem>
    {
        const systems = new Array<CSystem>();
        if (systemsAsJson == null)
        {
            return systems;

        }

        for (const systemAsJson of systemsAsJson)
        {
            const things = new Array<Thing>();
            systemAsJson.things.forEach(type =>
            {
                things.push(this.getThings(type, type.name, systemAsJson.name, locationName));
            });

            things.push(new Thing('0', 'Dashboard', 'DASHBOARD', '0', '0', '0', 'Dashboard',
                '0', '0', '0',
                '0', '0', '0', '0', 0, null, null));

            const SystemObject = new CSystem(systemAsJson._id, systemAsJson.name, systemAsJson.modelPath, things, systemAsJson.powerBi);

            for (const thing of things)
            {
                thing.parentSystem = SystemObject;
            }

            systems.push(SystemObject);
        }
        return systems;
    }*/

    /**
     * Gibt alle things Objekte eines ThingTypes zurück
     * @param things
     * @param thingType
     *//*
    private getThings(thing: any, thingType: string, systemName: string, locationName: string): Thing
    {
        if (thing == null)
        {
            return null;
        }

        let link = null;

        if (thing.ainLink != null && thing.ainLink != '')
        {
            link = thing.ainLink as string;
            /*
                            if ('url' in paresdJson)
                            {
                                link = paresdJson.url;
                            }
                            // ToDo bei allen als Json konfigurieren
                            else if (paresdJson)
                            {
                                if (paresdJson)
                                {
                                    link = paresdJson;
                                }
                            }*//*
        }

        return new Thing(thing._id, (thing.type + ' ' + thing.aisle + '/' + thing.level), thing.type,
            thing.aisle, thing.level, thing.imagePath, thing.displayName, thing.equipmentNr, thing.layoutPosition,
            '0', locationName, systemName, thing.orderNr, link, thing.installation, thing.layout, thing.properties);


    }*/

    /**
     *  Gibt ein Objekt zurück das von AGM Maps verwendet wird um einen Standort auf der Karte zu setzen.
     */
    private getCoordinates(pCords: any, pLabel: any): marker
    {
        return {
            lat: pCords.lat,
            lng: pCords.long,
            label: pLabel
        };
    }
}
