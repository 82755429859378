<app-group-header [Heading]="('Testfahrt ' + Type | translate) + ' ' + Date"
                  [chart]="chart"
                  [info]="info"
                  [noText]="true"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>

<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Ist-/Soll-Drehzahlmessung' | translate"
                      [Error]="ActualTargetDriveError"
                      [ErrorLevel]="ActualTargetDriveErrorLevel"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="ActualTargetDriveValue"
                      [Unit]="'U/min'"
                      [Legend]="['Ist-Drehzahl' | translate, 'Soll Drehzahl' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Momenten-/Ausgangsstrom' | translate"
                      [Error]="MomentOutputCurrentError"
                      [ErrorLevel]="MomentOutputCurrentErrorLevel"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="MomentOutputCurrentValue"
                      [Unit]="'A'"
                      [Legend]="['Momentenstrom' | translate, 'Ausgangsstrom' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Zwischenkreisspannung' | translate"
                      [Error]="IntermediateCircuitVoltageError"
                      [ErrorLevel]="IntermediateCircuitVoltageErrorLevel"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="IntermediateCircuitVoltageValue"
                      [Unit]="'V'"
                      [Legend]="['Zwischenkreisspannung' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

</div>
<!--

  @Input() ActualTargetDriveError: boolean;
    @Input() ActualTargetDriveValue: DataPoint<number>[][];
    @Input() MomentOutputCurrentError: boolean;
    @Input() MomentOutputCurrentValue: DataPoint<number>[][];
    @Input() IntermediateCircuitVoltageError: boolean;
    @Input() IntermediateCircuitVoltageValue: DataPoint<number>[][];
-->
