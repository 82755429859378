<!--
galileoiotadmin@gebhardt-group.com
-->
<app-help-page-banner [items]=""
                      [returnAddress]="'/HelpPage'"></app-help-page-banner>
<div class="container">
    <h2>{{'Kontakt' | translate}} </h2>
    <br>
    <p>{{'Sie können uns über folgende E-Mail-Adresse erreichen:' | translate}}</p>
    <a href="mailto:galileoiotadmin@gebhardt-group.com">support@intralogistics.io</a>
</div>
