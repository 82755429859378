import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {DataProviderService} from './data-provider.service';
import {NavigationService} from './navigation.service';
import {environment} from '../../../../environments/environment';
import {AuthenticationService} from './authentication.service';
import {UserSettingsService} from './user-settings.service';

@Injectable({
    providedIn: 'root'
})
export class FavoriteService
{
    /**
     * Gespeicherte Favoriten
     */
    private _favorites: Array<any> = new Array<any>();

    private url = environment.apiUrl + environment.apiVersion + '/user/favorites';

    get Favorites(): Array<any>
    {
        return this._favorites;
    }

    constructor(private _http: HttpClient,
                private _data: DataProviderService,
                private _nav: NavigationService,
                private _auth: AuthenticationService,
                private _user: UserSettingsService,
                private _toastr: ToastrService)
    {
        // this.readFavorite();
    }

    /**
     * Fügt einen Favoriten der Datenbank hinzu
     * Optimistic Update
     * @param pFavorite
     */
    public async addFavorite(pFavorite: any): Promise<void>
    {
        this._favorites.push(pFavorite);

        const reqBody = JSON.stringify(this.getDBFav(pFavorite, this._user.User.azureId));

        const headers = await this._auth.getDefaultHttpHeaders();


        this._http.post(this.url, reqBody, {headers: headers}).subscribe(
            data =>
            {
            },
            error =>
            {
                const index = this._favorites.indexOf(pFavorite);
                if (index > -1) this._favorites.splice(index, 1);
                pFavorite.isFavorite = false;

                this._toastr.error('Beim Speichern des Favoriten ist ein Fehler aufgetreten!', null, {positionClass: 'toast-bottom-right'});
            });
    }

    /**
     * Liest alle Favoriten aus der Datenbank
     */
    public readFavorite(): void
    {
        try
        {

            this._user.User.favorites.forEach(
                fav =>
                {
                    switch (fav.type)
                    {
                        case 'COMPANY':
                        {
                            const company = this._data.customer.find(x => x.id === fav.id);

                            if (company == null)
                            {
                                // this.deleteNonExistend(this.getDBFav(fav, this._user.User.azureId));
                                break;
                            }

                            company.isFavorite = true;
                            this._favorites.push(company);
                            break;
                        }
                        case 'LOCATION':
                        {
                            let location = null;
                            const favUrl = fav.id.split('/');
                            this._data.customer.forEach(cus =>
                            {
                                location = Array.from(cus.locations.values()).find(x => x.name === favUrl[favUrl.length - 1]);
                                return;
                            });

                            if (location == null)
                            {
                                // this.deleteNonExistend(this.getDBFav(fav, this._user.User.azureId));
                                break;
                            }

                            location.isFavorite = true;
                            this._favorites.push(location);
                            break;
                        }
                        case 'SYSTEM':
                        {
                            let system = null;

                            const favUrl = fav.id.split('/');
                            this._data.customer.forEach(cus =>
                            {
                                cus.locations.forEach(loc =>
                                {
                                    system = Array.from(loc.systems.values()).find(x => x.name === favUrl[favUrl.length - 1]);
                                    return;
                                });
                                if (system != null) return;
                            });

                            if (system == null)
                            {
                                // this.deleteNonExistend(this.getDBFav(fav, this._user.User.azureId));
                                break;
                            }

                            system.isFavorite = true;
                            this._favorites.push(system);

                            break;
                        }
                        default:
                        {
                            const thing = this._data.things.find(x => x.id === fav.id);

                            if (thing == null)
                            {
                                // this.deleteNonExistend(this.getDBFav(fav, this._user.User.azureId));
                                break;
                            }

                            thing.isFavorite = true;
                            this._favorites.push(thing);

                            break;
                        }
                    }
                });

        } catch (e)
        {
            this._toastr.error('Beim Abrufen der Favoriten ist ein Fehler aufgetreten!', null, {positionClass: 'toast-bottom-right'});
        }
    }

    /**
     * Löscht einen Favoriten aus der Datenbank.
     * Optimistic Update
     * @param pFavorite
     */
    public async deleteFavorite(pFavorite: any): Promise<void>
    {
        const index = this._favorites.indexOf(pFavorite);
        if (index > -1) this._favorites.splice(index, 1);

        const reqBody = JSON.stringify(this.getDBFav(pFavorite, this._user.User.azureId));

        const httpOptions = {
            headers: await this._auth.getDefaultHttpHeaders(),
            body: reqBody
        };

        this._http.delete(this.url, httpOptions).subscribe(
            data =>
            {
            },
            error =>
            {
                this._favorites.push(pFavorite);
                pFavorite.isFavorite = true;

                this._toastr.error('Beim Löschen des Favoriten ist ein Fehler aufgetreten!', null, {positionClass: 'toast-bottom-right'});
            });
    }

    /**
     * Löscht einen Datensatz wenn er im Frontend nicht existiert.
     * @param pValue
     */
    public deleteNonExistend(pValue: dbFav): void
    {
        const reqBody = JSON.stringify(pValue);

        const httpOptions = {
            headers: new HttpHeaders({'Content-Type': 'application/json'}),
            body: reqBody
        };

        this._http.delete('/backend/api/favorites', httpOptions).subscribe(
            data =>
            {
            },
            error =>
            {
                this._toastr.error('Beim Löschen des Favoriten ist ein Fehler aufgetreten!', null, {positionClass: 'toast-bottom-right'});
            });
    }

    /**
     * Wandelt einen Favoriten in ein Datenbank Objekt um.
     * @param pFavorite
     * @param userId
     */
    private getDBFav(pFavorite: any, userId: string): dbFav
    {
        let fav: dbFav;

        if (pFavorite.type === 'LOCATION' || pFavorite.type === 'SYSTEM')
        {
            fav = {azureId: userId, favoriteId: this._nav.buildCustomerDetailURLs(pFavorite), type: pFavorite.type};
        }
        else
        {
            fav = {azureId: userId, favoriteId: pFavorite.id, type: pFavorite.type};
        }

        return fav;
    }
}

/**
 * Objekt aus der Datenbank
 */
interface dbFav
{
    azureId: string;
    favoriteId: string;
    type: string;

}
