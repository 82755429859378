import {Component, Input, OnInit} from '@angular/core';
import {LimitSizeArrayModel} from '../../../core/model/helper-models/limit-size-array.model';
import {MonitoringLevel} from '../../../core/model/MonitoringLevels.enum';
import {BaseViewElement} from '../base-view-element';

@Component({
    selector: 'app-testrun',
    templateUrl: './testrun.component.html',
    styleUrls: ['./testrun.component.scss',
        './../card.scss']
})
export class TestrunComponent extends BaseViewElement implements OnInit
{
    @Input() Type: string;
    @Input() Date: string;
    @Input() ActualTargetDriveError: boolean;
    @Input() ActualTargetDriveErrorLevel: MonitoringLevel;
    @Input() ActualTargetDriveValue: LimitSizeArrayModel<number>[];
    @Input() MomentOutputCurrentError: boolean;
    @Input() MomentOutputCurrentErrorLevel: MonitoringLevel;
    @Input() MomentOutputCurrentValue: LimitSizeArrayModel<number>[];
    @Input() IntermediateCircuitVoltageError: boolean;
    @Input() IntermediateCircuitVoltageErrorLevel: MonitoringLevel;
    @Input() IntermediateCircuitVoltageValue: LimitSizeArrayModel<number>[];

    constructor()
    {
        super('testrun');
        this.chart = true;
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit();
    }
}
