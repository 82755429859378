import {BehaviorSubject} from 'rxjs';
import {DataPoint} from './datapoint.model';

/**
 * Array das begrenzt ist um nicht unendlich viele Daten in den Graphen zu haben
 * und so speicher zu sparen und die performance zu verbessern
 */
export class LimitSizeArrayModel<T>
{
    /**
     * Maximale Anzahl von Werten im Array
     */
    public maxLength: number;

    /**
     * Alle Werte
     */
    public values = new Array<T>();

    /**
     * Wird jedes Mal aufgerufen wenn ein neuer Wert hinzugefügt
     */
    public valuePushed = new BehaviorSubject<T>(null);

    constructor(pMaxLength: number)
    {
        this.maxLength = pMaxLength;
    }

    /**
     * Gibt den aktuellsten Wert zurück.
     */
    public get currentDatapoint()
    {
        if (this.values.length > 0)
        {
            return this.values[this.values.length - 1];
        }
        else
        {
            return new DataPoint<any>(null, null);
        }
    }

    /**
     * Fügt so lange Werte zu values hinzu bis die maxLength erreicht ist.
     * Dann wird der erste Wert ([0]) gelöscht und ans Ende ein neuer eingefügt.
     * @param pDataPoint
     */
    public pushDataPoint(pDataPoint: T): void
    {
        if (this.values.length === this.maxLength)
        {
            this.values.shift();
        }
        else if (this.values.length > this.maxLength)
        {
            console.log('Critical system Error');
            return;
        }

        this.values.push(pDataPoint);
        this.valuePushed.next(pDataPoint);
    }

    /**
     * Löscht alle Daten
     */
    public clear()
    {
        this.values = [];
    }
}

/*

Versuch Array zu erweitern hat nicht funktioniert.
Fehler von mir oder in TypeScript nicht möglich?

export class TestArray<T> extends Array<T>
{
  public maxLength: number;

  constructor(pMaxLength: number)
  {
    super();
    Object.setPrototypeOf(this, new.target.prototype);
    this.maxLength = pMaxLength;
  }

  public pushDataPoint(pDataPoint: T): void
  {
    if (this.length === this.maxLength)
    {
      this.pop();
    }
    else if (this.length > this.maxLength)
    {
      console.log('Critical system Error');
      return;
    }

    this.push(pDataPoint);
  }
}
*/
