import {BehaviorSubject, Subject} from 'rxjs';

export class Configurations
{
    /**
     * Titel für Browser Tab
     */
    public static PageTitle = 'Gebhardt Galileo IoT';

    /**
     * Event das mit true getriggerd wird sobald Anwendung Initalisiert wurde
     */
    public static InitaliseComplete = new BehaviorSubject<boolean>(false);
    public static PullType = new BehaviorSubject<string>(null);
    public static HistoricFinished = new Subject<string>();

    public static OlsString = 'OLS';
    public static OlseString = 'OLSE';
    public static MlsString = 'MLS';
    public static FtsString = 'FTS';
    public static RbgString = 'RBG';
    public static HcvString = 'HCV';
    public static MfrString = 'STW';
    public static VcvString = 'Vcv';
    public static InfoString = 'INFO';
    public static GdpString = 'GDP';
    public static OlpsString = 'OLPS';
    public static ConverString = 'CONVER';
    public static StorewareString = 'STOREWARE';
    public static CthString = 'CTH';
    public static LscString = 'LSC';
    public static SsagmString = 'SSAGM';
    public static SsdtmString = 'SSDTM';
    public static SsesString = 'SSES';
    public static SsifString = 'SSIF';
    public static SscrrString = 'SSCRR';

    public static OlsTypeString = 'OLS';
    public static OlseTypeString = 'OLSE';
    public static MlsTypeString = 'MLS';
    public static FtsTypeString = 'FTS';
    public static RbgTypeString = 'RBG';
    public static HcvTypeString = 'HCV';
    public static MfrTypeString = 'STW';
    public static VcvTypeString = 'VCV';
    public static InfoTypeString = 'INFO';
    public static GdpTypeString = 'GDP';
    public static OlpsTypeString = 'OLPS';
    public static ConverTypeString = 'CONVER';
    public static StorewareTypeString = 'STOREWARE';
    public static CthTypeString = 'CTH';
    public static CthCustomTypeString = 'CTH_CUSTOM';
    public static LscTypeString = 'LSC';
    public static SsagmTypeString = 'SSAGM';
    public static SsdtmTypeString = 'SSDTM';
    public static SsesTypeString = 'SSES';
    public static SsifTypeString = 'SSIF';
    public static SscrrTypeString = 'SSCRR';


    public static LocStoShownLocation = 'shownLocation';
    public static LocStoShownSystem = 'shownSystems';
    public static LocStoTabs = 'tabs';
    public static LocStoActiveCustomerId = 'activeCustomerId';
    public static LocStoActiveLocationName = 'activeLocationName';
    public static LocStoActiveSystemName = 'activeSystemName';

    public static FavoritsToMonitoring = 'favoritsToMonitoring';

    public static pullBackendTime = 10800000; // Alle 3 Stunden
    public static pullTime = 3600000;
    public static chartUpdateTime = 1000;
    public static flowUpdateTime = 10000;
    public static zeroLatency = 0;
    public static uiUpdateTimer = 500;

    // gebhardtgalileo
    public static packageName = 'gebhardt.galileo';

    public static DataPointsVar = 10000;
    public static maxDataPoints = Number.MAX_VALUE;
    public static oneDataPoint = 1;
    public static DataPoints1 = 10;
    public static DataPoints2 = 100;
    public static DataPoints3 = 1000;
    public static DataPoints4 = 10000;
    public static DataPoints5 = 100000;
    public static DataPoints6 = 1000000;


    // Supported Programs
    public static ProgramSupport: string[] =
        [
            'Java',
            'Python',
            'Typescript',
            'Dotnet',
            'Crosser',
            'Go',
            'GalileoEdgeCore'
        ];

    public static PropertySupport: string[] = [
        'Version'
    ];

    static pullTimer: any = false;
    static pullBackendTimer: any = false;

    constructor()
    {
    }
}
