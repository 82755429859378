import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BooleanPipe} from '../pipes/boolean.pipe';
import {OperatingModePipe} from '../pipes/operating-mode.pipe';
import {SafePipe} from '../pipes/safe.pipe';
import {HistoricBooleanPipe} from '../pipes/historic-boolean.pipe';
import {CalcDistancePipe} from '../pipes/calc-distance.pipe';
import {CalcDifferencePipe} from '../pipes/calc-difference.pipe';
import {ErrorColorPipe} from '../pipes/error-color.pipe';
import {CalcPricePipe} from '../pipes/calc-price.pipe';
import {TranslateModule} from '@ngx-translate/core';
import {ReorganisationStatusPipe} from '../pipes/reorganisation-status.pipe';
import {OrderByPipe} from '../pipes/order-by.pipe';
import {TypeofPipe} from '../pipes/typeof.pipe';
import {GetKeyValuePipe} from '../pipes/get-key-value.pipe';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule
    ],
    declarations: [
        SafePipe,
        BooleanPipe,
        OperatingModePipe,
        HistoricBooleanPipe,
        CalcDistancePipe,
        CalcDifferencePipe,
        ErrorColorPipe,
        CalcPricePipe,
        ReorganisationStatusPipe,
        OrderByPipe,
        TypeofPipe,
        GetKeyValuePipe
    ],
    exports: [
        SafePipe,
        BooleanPipe,
        OperatingModePipe,
        HistoricBooleanPipe,
        CalcDistancePipe,
        CalcDifferencePipe,
        ErrorColorPipe,
        CalcPricePipe,
        ReorganisationStatusPipe,
        OrderByPipe,
        TypeofPipe,
        GetKeyValuePipe
    ]
})
export class PipesModule
{
}
