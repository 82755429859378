import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
    selector: 'app-position',
    templateUrl: './position.component.html',
    styleUrls: ['./position.component.scss',
        './../card.scss']
})
export class PositionComponent extends BaseViewElement implements OnInit, OnChanges
{
    @Input() Position: PropertySet;

    XPosition: Property<any>;
    YPosition: Property<any>;
    LastEnginePosition: Property<any>;


    constructor()
    {
        super('position');
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit();
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.Position != null)
        {
            this.XPosition = this.Position.properties.get('X_AXIS_POSITION');
            this.YPosition = this.Position.properties.get('Y_AXIS_POSITION');
            this.LastEnginePosition = this.Position.properties.get('DM3_X_AXIS_LAST_MOTOR_POSITION');
        }
    }

}
