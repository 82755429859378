import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-health-overview',
  templateUrl: './health-overview.component.html',
  styleUrls: ['./health-overview.component.scss']
})
export class HealthOverviewComponent implements OnInit {
    @Input() node: any;

    sassClassConditions: any;
  constructor() { }

  ngOnInit(): void {
      this.sassClassConditions = {
          'card_block_komponente': this.node.data.entity_type === 'komponenten',
          'card_block_general': this.node.data.entity_type !== 'komponenten'
      };
  }

}
