<!--Banner-->
<div class="row" #banner id="banner">

    <div *ngIf="isOneLine; else dropdown" class="col-12 bannerItem">
        <ul class="list-inline">
            <li *ngFor="let item of itemsWithSeperators; let i = index" class="list-inline-item">
                <a *ngIf="i % 2 == 0; else seperator" (click)="scrollToAnchor(item[1])">
                    <!-->[routerLink]='"."' [fragment]="item[1]"-->
                    {{item[0]}}
                </a>
                <ng-template #seperator>
                    |
                </ng-template>
            </li>
        </ul>
    </div>
    <ng-template #dropdown>
        <div class="col-12 dropdown list-inline" style="margin-left: -35px; text-align: right;">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a *ngFor="let item of items" (click)="scrollToAnchor(item[1])" class="dropdown-item">
                    {{item[0]}}
                </a>
            </div>
        </div>
    </ng-template>

</div>
