import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';
import {F} from '@angular/cdk/keycodes';

@Component({
    selector: 'app-sses-flaps',
    templateUrl: './sses-flaps.component.html',
    styleUrls: ['./sses-flaps.component.scss', './../card.scss']
})
export class SsesFlapsComponent extends BaseViewElement implements OnInit, OnDestroy, OnChanges
{
    @Input() Flaps: PropertySet;

    FlapMovements1: Property<any>;
    FlapMovements2: Property<any>;
    FlapMovements3: Property<any>;
    FlapMovements4: Property<any>;

    constructor()
    {
        super('sses-flaps');
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit(result);
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.Flaps == null) return;
        this.FlapMovements1 = this.Flaps.properties.get('KL1');
        this.FlapMovements2 = this.Flaps.properties.get('KL2');
        this.FlapMovements3 = this.Flaps.properties.get('KL3');
        this.FlapMovements4 = this.Flaps.properties.get('KL4');
    }

    protected readonly F = F;
}
