import {HttpClient} from '@angular/common/http';
import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {map, timeout} from 'rxjs/operators';
import {models} from 'powerbi-client';
import {PowerBIReportEmbedComponent} from 'powerbi-client-angular';
import {DataProviderService} from '../../controller/services/data-provider.service';
import {NavigationService} from '../../controller/services/navigation.service';
import {Page} from 'page';
import {Report} from 'report';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {AuthenticationService} from '../../controller/services/authentication.service';
import {firstValueFrom} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-powerbi',
    templateUrl: './powerbi.component.html',
    styleUrls: ['./powerbi.component.scss']
})
/**
 * Hier wird PowerBi eingebettet
 */
export class PowerbiComponent implements OnInit, AfterViewInit, OnDestroy
{

    /**
     *
     * @param _http
     * @param dataProvider
     * @param _router
     * @param navigationService
     */
    constructor(private _http: HttpClient,
                public dataProvider: DataProviderService,
                private _router: Router,
                private _auth: AuthenticationService,
                private navigationService: NavigationService,
                private _translate: TranslateService)
    {
        this.navigationService.ShowHeader = true;
    }
    /**
     * PowerBi Library
     */
    @ViewChild(PowerBIReportEmbedComponent) reportObj!: PowerBIReportEmbedComponent;

    /**
     * PowerBi HTML Container
     */
    private pbiContainerElement: HTMLElement;

    public report: Report;

    /**
     * Config
     */
    config = {
        type: 'report',
        id: undefined,
        embedUrl: undefined,
        tokenType: models.TokenType.Aad,
        accessToken: undefined,
        settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: false,
            // background: models.BackgroundType.Transparent
        }
    };


    ContainerEvents = new Map([
        ['loaded', () => console.log('Report loaded')],
        ['rendered', () => console.log('Report rendered')],
        ['error', (event) => console.log(event.detail)]
    ]);


    /**
     *
     */
    interval;

    /**
     *
     */
    tick: number = 50 * 60 * 1000; // m * s * ms

    /**
     *
     */
    token = '';

    root = new Tree<Page>();
    @ViewChild('rootNav') rootNav: ElementRef;

    public path = '';

    @Input() reportType = 'insight';

    protected readonly environment = environment;

    ngAfterViewInit(): void
    {
        this.initPowerBi();
    }

    /**
     * Holt das Container Element und initalisiert PowerBi
     * Startet den aktualisierungs Timer
     */
    ngOnInit()
    {
        this.startTimer();
    }

    /**
     * Stoppt den Timer
     */
    ngOnDestroy(): void
    {
        this.stopTimer();
        this.navigationService.ShowHeader = true;
    }

    /**
     * Beschreibung hinzufügen
     * @param $event
     */
    onEmbedded($event: any)
    {
    }

    /**
     * Holt in einem interval (tick) einen neuen Token, da der Token jede Stunde abläuft.
     */
    startTimer()
    {
        this.interval = setInterval(() =>
        {
            this.updateSetting();
        }, this.tick);
    }

    /**
     *
     */
    stopTimer()
    {
        clearInterval(this.interval);
    }

    /**
     * Initalisiert PowerBi mit einem Token
     */
    async initPowerBi()
    {
        this.getToken().then(
            async token =>
            {
                this.config['id'] = token['reportId'];
                this.config['embedUrl'] = 'https://app.powerbi.com/reportEmbed?' +
                    'reportId=' + token['reportId'] +
                    '&groupId=' + token['groupId'] +
                    '&language=' + this._translate.currentLang +
                    '&filter=Languages/LanguageId eq \'' + this._translate.currentLang + '\'';
                this.config['accessToken'] = token['token'];

                this.pbiContainerElement = document.getElementById('pbi-containerNew') as HTMLElement;
                if (this.pbiContainerElement == null)
                {
                    this.pbiContainerElement = document.getElementById('pbi-containerOld') as HTMLElement;
                }

                this.report = this.reportObj.powerbi.embed(this.pbiContainerElement, this.config) as Report;

                this.buildNavigation();


                this._translate.onLangChange.subscribe(x =>
                {
                    this.getToken().then(
                        async tokenAfterChange =>
                        {
                            this.config['id'] = tokenAfterChange['reportId'];
                            this.config['embedUrl'] = 'https://app.powerbi.com/reportEmbed?' +
                                'reportId=' + tokenAfterChange['reportId'] +
                                '&groupId=' + tokenAfterChange['groupId'] +
                                '&language=' + this._translate.currentLang +
                                '&filter=Languages/LanguageId eq \'' + this._translate.currentLang + '\'';

                            this.reportObj.powerbi.reset(this.pbiContainerElement);

                            this.report = this.reportObj.powerbi.embed(this.pbiContainerElement, this.config) as Report;

                            this.buildNavigation();
                        });
                });

            });
    }

    buildNavigation()
    {
        this.root = new Tree<Page>();

        this.report.on('loaded', () =>
        {
            this.root.displayName = 'root';

            this.report.getPages().then( async (pages) =>
            {
                if (pages == null) return;
                this.path = await firstValueFrom(this._translate.get(pages[0].displayName));

                for (const page of pages)
                {
                    if (page.displayName.match('Tooltip') || page.visibility === 1)
                    {
                        continue;
                    }

                    const pathArr = page.displayName.split('-');

                    let node = this.root;

                    for (let path of pathArr)
                    {
                        path = path.trim();
                        const name = await firstValueFrom(this._translate.get(path));
                        const foundNode = node.nodes.find(x =>
                        {
                            if (x.displayName === name) return x;
                        });

                        if (foundNode == null)
                        {
                            const tmp = new Tree<Page>();
                            tmp.displayName = name;
                            tmp.value = page;

                            node.nodes.push(tmp);

                            node = tmp;
                        }
                        else
                        {
                            node = foundNode;
                        }
                    }
                }

            });
        });
    }


    /**
     * Aktualisiert den Tokens
     */
    updateSetting()
    {
        // this.report.setAccessToken(this.getToken().ReportId);
        this.getToken().then(
            token =>
            {
                this.report.setAccessToken(token['token']);
            }
        );
    }

    /**
     * Navigiert auf die übergebene PowerBi Page
     * @param pPage
     */
    navigateToReport(pPage: Page)
    {
        let target: any = event.target || event.srcElement;
        // target.offsetParent.classList.add('active');

        const parentSubs = [];

        do
        {
            if (target.offsetParent.tagName === 'UL')
            {
                parentSubs.push(target.offsetParent);
            }

            target = target.offsetParent;
        } while (target.offsetParent != null);

        for (const parent of parentSubs)
        {
            parent.classList.remove('show');
        }

        this.path = pPage.displayName;
        pPage.setActive();
    }

    /**
     * Öffnet unterliegendes Menü in der Navigation
     * @param event
     */
    clickSubmenu(event)
    {
        const target = event.target || event.srcElement;

        const dropdown = target.offsetParent.children[1];

        const neighbours = [];
        let neighbour = target.offsetParent.nextElementSibling;

        while (neighbour)
        {
            neighbours.push(neighbour);
            neighbour = neighbour.nextElementSibling;
        }

        neighbour = target.offsetParent.previousElementSibling;

        while (neighbour)
        {
            neighbours.push(neighbour);
            neighbour = neighbour.previousElementSibling;
        }

        for (const element of neighbours)
        {
            this.removeShow(element.getElementsByTagName('UL')[0]);
        }

        if (dropdown.classList.contains('show'))
        {
            this.removeShow(dropdown);
            // target.offsetParent.classList.remove('active');
        }
        else
        {
            dropdown.classList.add('show');
            // target.offsetParent.classList.add('active');
        }
        event.stopPropagation();
        event.preventDefault();
    }

    /**
     * Schließt unterliegende Menüs in der Navigation
     * @param pElement
     */
    removeShow(pElement: Element)
    {
        if (pElement == null) return;
        if (!pElement.classList.contains('show')) return;

        pElement.classList.remove('show');

        for (const child of pElement.querySelectorAll('ul') as any)
        {
            this.removeShow(child as Element);
        }
    }

    /**
     * Schließt alle offenen Menüs in der Navigation
     */
    removeAll()
    {
        const rooti = this.rootNav.nativeElement as Element;

        this.removeShow(rooti);
    }

    /**
     * Holt mit einem API request ans Backend den Token.
     */
    private async getToken(): Promise<any>
    { // {GroupId: string, ReportId: string} {

        if (this.dataProvider.activeSystem == null) return;

        const route = this._router.url;
        const splitRoute = route.split('/');

        let reportId = null;
        let groupId = null;

        if (splitRoute[splitRoute.length - 1] === 'insightdrive')
        {
            reportId = this.dataProvider.activeSystem.PowerBiConfig.Testdrive.ReportId;
            groupId = this.dataProvider.activeSystem.PowerBiConfig.Testdrive.GroupId;
            this.reportType = 'testdrive';

        }
        else if (splitRoute[splitRoute.length - 1] === 'healthPbi')
        {
            if (this._translate.currentLang === 'en' && this.dataProvider.activeSystem.PowerBiConfig.Health.ReportIdEn != null)
            {
                reportId = this.dataProvider.activeSystem.PowerBiConfig.Health.ReportIdEn;
            }
            else {
                reportId = this.dataProvider.activeSystem.PowerBiConfig.Health.ReportId;
            }

            groupId = this.dataProvider.activeSystem.PowerBiConfig.Health.GroupId;
            this.reportType = 'healthPbi';
        }
        else
        {
            reportId = this.dataProvider.activeSystem.PowerBiConfig.Insight.ReportId;
            groupId = this.dataProvider.activeSystem.PowerBiConfig.Insight.GroupId;
            this.reportType = 'insight';
        }

        const head = await this._auth.getDefaultHttpHeaders();

        head.append('Company', this.dataProvider.activeCompany.name);
        head.append('Location', this.dataProvider.activeLocation.name);
        head.append('System', this.dataProvider.activeSystem.name);
        head.append('ReportType', this.reportType);
        /*
        const httpOptions = {
            headers: new HttpHeaders({
                'Company': this.dataProvider.activeCompany.name,
                'Location': this.dataProvider.activeLocation.name,
                'System': this.dataProvider.activeSystem.name,
                'ReportType': this.reportType
            })
        };*/

        return firstValueFrom(this._http.get(environment.apiUrl + environment.apiVersion + '/powerBI', {headers: head})
            .pipe(
                timeout(10000),
                map(response =>
                {
                    return {token: response['token'], groupId: groupId, reportId: reportId};
                })
            ));
    }
}

/**
 * Klasse in der die Navigationsstruktur abgebildet wird
 */
class Tree<T>
{
    value: T;
    displayName: string;
    nodes: Tree<T>[] = new Array<Tree<T>>();

    public isLeaf(): boolean
    {
        return this.nodes.length === 0;
    }

    public getNodeNames(): Array<string>
    {
        const result = [];

        for (const node of this.nodes)
        {
            result.push(node.displayName);
        }

        return result;
    }
}
