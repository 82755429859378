import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {GridsterItem} from 'angular-gridster2';
import {Subscription} from 'rxjs/internal/Subscription';
import {BaseViewElement} from '../base-view-element';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss',
        './../card.scss']
})
export class ImageComponent extends BaseViewElement implements OnInit, OnDestroy
{

    @Input() heading: string;
    /**
     * Pfad zum Bild
     */
    @Input() src: string;

    /**
     * Bildbeschreibung
     */
    @Input() alt: string;

    /**
     * Equipment Number
     */
    @Input() equipmentNumber: string;

    /**
     * AIN Link
     */
    @Input() ain: string;

    @Input() resizeEvent: EventEmitter<GridsterItem>;

    @ViewChild('imgContainer', {read: ElementRef}) imgContainer: ElementRef;

    resizeSub: Subscription;

    @Input() width = 'auto';
    height = '100%';

    constructor(private _http: HttpClient)
    {
        super('image');
    }

    async ngOnInit()
    {
        this.src = environment.storageUrl + this.src;

        if (this.equipmentNumber != null && this.equipmentNumber !== '' && this.ain == null)
        {
            this.ain = '';//String(await this.getLink());
        }

        this.resizeSub = this.resizeEvent?.subscribe((widget) =>
        {
            if (this.imgContainer != null)
            {

                if (this.imgContainer.nativeElement.offsetWidth > this.imgContainer.nativeElement.offsetHeight)
                {
                    this.width = 'auto';
                    this.height = '100%';
                }
                else
                {
                    this.width = '100%';
                    this.height = 'auto';
                }

            }

        });
    }

    ngOnDestroy()
    {
        this.resizeSub?.unsubscribe();
    }

    async getLink()
    {
        return new Promise((resolve, reject) =>
        {
            const header: HttpHeaders = new HttpHeaders({'equipmentNumber': this.equipmentNumber.toString()});

            this._http.get('/backend/api/ain-link', {headers: header}).subscribe(next =>
            {
                resolve(next['ainLink']);
            });
        });
    }

    onResize(result: any)
    {
    }
}
