<div class="row vertical-center">
    <div class="col-lg-12">
        <div class="row d-none d-md-flex">
            <div class="col-lg-3"></div>
            <div class="col-lg-6 imageContainer">
                <img class="img-responsive image"
                     src="https://gebhardtstorage.blob.core.windows.net/3dmodels/Customers/gebhardt/logo_gebhardt.png"
                     alt="Gebhardt Logo"/>
            </div>
            <div class="col-lg-3"></div>
        </div>
        <div class="row">
            <div class="col-lg-3"></div>
            <div class="col-lg-6 text">
                <h1>{{'Die gesuchte Seite ist nicht vorhanden.' | translate}}</h1>
            </div>
            <div class="col-lg-3"></div>
        </div>
        <div class="row">
            <div class="col-lg-3"></div>
            <div class="col-lg-6 text">
                <h1>{{'Zurück zum' | translate}} <a routerLink="/"
                                                    style="color: var(--geb-red)">{{'Anfang' | translate}}</a></h1>
            </div>
            <div class="col-lg-3"></div>
        </div>
    </div>
</div>
