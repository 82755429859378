<app-group-header [Heading]="'voltage, current, temp' | translate"
                  [chart]="chart"
                  [info]="info"
                  [noChart]="true"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>

<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Strom' | translate"
                      [Error]="STROM?.isError"
                      [ErrorLevel]="STROM?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[STROM?.TimeseriesData]"
                      [Unit]="'A'"
                      [Legend]="['STROM' | translate]"
                      [Info]="''"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Spannung' | translate"
                      [Error]="SPANNUNG?.isError"
                      [ErrorLevel]="SPANNUNG?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[SPANNUNG?.TimeseriesData]"
                      [MaintenanceValue]="SPANNUNG?.ValueLastService"
                      [Unit]="'V'"
                      [Legend]="['Spannung' | translate]"
                      [Info]="''"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Temperatur' | translate"
                      [Error]="TEMPERATUR?.isError"
                      [ErrorLevel]="TEMPERATUR?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[TEMPERATUR?.TimeseriesData]"
                      [MaintenanceValue]="TEMPERATUR?.ValueLastService"
                      [Unit]="''"
                      [Legend]="['Temperatur' | translate]"
                      [Info]="''"
                      [resizeEvent]="childResizeEvent"></app-default-card>

</div>
