import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {GridsterItem} from 'angular-gridster2';
import {DataPoint} from '../../../model/helper-models/datapoint.model';
import {LimitSizeArrayModel} from '../../../model/helper-models/limit-size-array.model';
import {MonitoringLevel} from '../../../model/MonitoringLevels.enum';
import {Configurations} from '../../../../configurations';
import {CardMemento} from '../card-memento';
import {Subscription} from 'rxjs/internal/Subscription';
import {environment} from '../../../../../environments/environment';

@Component({
    selector: 'app-default-card',
    templateUrl: './default-card.component.html',
    styleUrls: ['./default-card.component.scss']
})
export class DefaultCardComponent implements OnInit, OnDestroy, AfterViewInit
{

    @Input()
    get Values(): LimitSizeArrayModel<any>[]
    {
        return this._values;
    }

    set Values(values: LimitSizeArrayModel<any>[])
    {
        this.calcValues(values);
    }

    @Input() MaintenanceValue: DataPoint<number>;

    @ViewChild('card', {read: ElementRef}) card: ElementRef;
    @ViewChild('triplecard', {read: ElementRef}) triplecard: ElementRef;

    @Input() Heading: string;
    @Input() groupHeaderHeight = '50px';
    @Input() Error = false;
    @Input() ErrorLevel: MonitoringLevel;
    // Um Enum in der View zu checken
    monitoringLevel = MonitoringLevel;

    @Input() showChart = false;
    @Input() showInfo = false;

    @Input() Legend: string[];
    @Input() Type = 'number';
    @Input() ChartType = 'line';
    @Input() ChartOption = null;

    @Input() ShowDate = false;
    @Input() Date = null;

    @Input() PipeOptions = null;

    @Input() min = 0;
    @Input() max = 100;

    @Input() backgroundColor: string = null;

    private _values;

    chartData: DataPoint<any>[][] = [];
    textData: DataPoint<any>[] = [];
    @Input() Unit = '';
    @Input() Info: string;

    @Input() customTemplate: TemplateRef<any> = null;
    @Input() customNoData = false;

    @Input() resizeEvent: EventEmitter<GridsterItem>;

    resizeSub: Subscription;

    childResizeEvent: EventEmitter<any> = new EventEmitter<any>();

    fontsize = '30px';
    fontsizetriple = '10px';
    width = 'auto';
    height = '100%';

    history: CardMemento = null;

    noValues = false;

    calcValues(values: LimitSizeArrayModel<any>[])
    {
        this.chartData = [];
        this.textData = [];

        if (values == null)
        {
            if (this.customTemplate == null)
            {
                this.noValues = true;
                return;
            }
        }

        if (this.customTemplate == null)
        {
            if (values.length === 0)
            {
                this.noValues = true;
                return;
            }
        }

        if (this.customTemplate == null)
        {
            if (values[0] == null)
            {
                this.noValues = true;
                return;
            }
        }

        if (values.length === 0) return;

        if (values[0].valuePushed != null)
        {
            // TODO: Refactoren innefizienter quickfix
            values[0].valuePushed.subscribe(() =>
            {
                this.textData = [];

                if (this.Values == null) return;

                for (const value of this.Values)
                {
                    this.textData.push(value.currentDatapoint);
                }
            });
        }

        for (let i = 0; i < values.length; i++)
        {
            if (values[i] != null)
            {

                this.chartData.push(values[i].values);

                this.textData.push(values[i].currentDatapoint);
            }
        }
        this._values = values;
        this.noValues = false;
    }


    ngOnInit(): void
    {
        Configurations.PullType.subscribe((type: string) =>
        {
            if (type == null) return;
            if (type === 'historic')
            {
                if (this.history == null)
                {
                    this.history = this.saveToMemento();
                    this.showChart = true;
                    this.ChartType = 'line';
                }
                this.calcValues(this._values);

            }
            else if (type === 'live')
            {
                if (this.history == null) return;
                this.restoreFromMemento(this.history);
                this.history = null;
            }
        });

        if (this.resizeEvent == null) return;
        this.resizeSub = this.resizeEvent.subscribe((widget) =>
        {
            if (this.card != null)
            {
                let fontSizeWidth;
                if (this.Values != null)
                {
                    if (this.Values.length === 1)
                    {
                        fontSizeWidth = this.card.nativeElement.offsetWidth * 0.07;
                    }
                    else
                    {
                        fontSizeWidth = this.card.nativeElement.offsetWidth * 0.05;
                    }
                }
                else
                {
                    fontSizeWidth = this.card.nativeElement.offsetWidth * 0.07;
                }

                this.fontsize = fontSizeWidth + 'px';

                if (this.card.nativeElement.offsetWidth > this.card.nativeElement.offsetHeight)
                {
                    this.width = 'auto';
                    this.height = '100%';
                }
                else
                {
                    this.width = '100%';
                    this.height = 'auto';
                }

                this.childResizeEvent.emit();
            }

            if (this.triplecard != null)
            {
                const fontSizeWidth = this.triplecard.nativeElement.offsetWidth * 0.13;
                this.fontsizetriple = fontSizeWidth + 'px';
            }

        });
    }

    ngOnDestroy()
    {
        this.resizeSub.unsubscribe();
    }

    public saveToMemento(): CardMemento
    {
        return new CardMemento(this.showChart, this.showInfo, this.Type, this.ChartType);
    }

    public restoreFromMemento(pCardMemento: CardMemento)
    {
        const state = pCardMemento.getState();

        this.showChart = state.showChart;
        this.showInfo = state.showInfo;
        this.Type = state.Type;
        this.ChartType = state.ChartType;
    }

    ngAfterViewInit(): void
    {
        if (this.card != null)
        {
            let fontSizeWidth;
            if (this.Values != null)
            {
                if (this.Values.length === 1)
                {
                    fontSizeWidth = this.card.nativeElement.offsetWidth * 0.07;
                }
                else
                {
                    fontSizeWidth = this.card.nativeElement.offsetWidth * 0.05;
                }
            }
            else
            {
                fontSizeWidth = this.card.nativeElement.offsetWidth * 0.07;
            }

            this.fontsize = fontSizeWidth + 'px';

            if (this.card.nativeElement.offsetWidth > this.card.nativeElement.offsetHeight)
            {
                this.width = 'auto';
                this.height = '100%';
            }
            else
            {
                this.width = '100%';
                this.height = 'auto';
            }

            this.childResizeEvent.emit();
        }

        if (this.triplecard != null)
        {
            const fontSizeWidth = this.triplecard.nativeElement.offsetWidth * 0.13;
            this.fontsizetriple = fontSizeWidth + 'px';
        }

    }

    protected readonly environment = environment;
}
