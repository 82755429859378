import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataProviderService} from '../../../controller/services/data-provider.service';
import {NavigationService} from '../../../controller/services/navigation.service';
import {Configurations} from '../../../../configurations';

@Component({
    selector: 'app-monitoring-customer',
    templateUrl: './monitoring-customer.component.html',
    styleUrls: ['./monitoring-customer.component.scss',
        '../monitoring.scss']
})
/**
 * Komponente in der die Kunden Cards angezeigt werden
 */
export class MonitoringCustomerComponent implements OnInit, OnDestroy
{
    /**
     * Konstruktor
     * @param data
     * @param nav
     */
    constructor(public data: DataProviderService,
                public nav: NavigationService)
    {
    }

    /**
     * Setzt die activeLocation und activeSystem auf null
     */
    ngOnInit()
    {
        Configurations.InitaliseComplete.subscribe(x =>
        {
            if (x)
            {
                this.data.activeLocation = null;
                this.data.activeSystem = null;

                this.nav.MonitoringNavigation = true;
            }
        });
    }

    /**
     * Setzt Monitoring Navigation auf false
     */
    ngOnDestroy(): void
    {
        this.nav.MonitoringNavigation = false;
    }
}
