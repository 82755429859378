import {Directive, Input} from '@angular/core';
import {Thing} from '../core/model/thing.model';


@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class BaseSubcomp
{
    @Input() widget: string;
    @Input() Thing: Thing;
    @Input() resizeEvent;

    @Input() horizontalDate;
    @Input() verticalDate;
}
