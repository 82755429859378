import { Injectable } from '@angular/core';
import {OperationState, Thing} from '../../model/thing.model';
import {environment} from '../../../../environments/environment';
import {lastValueFrom} from 'rxjs/internal/lastValueFrom';
import {AuthenticationService} from './authentication.service';
import {HttpClient} from '@angular/common/http';
import {PropertySet} from '../../model/helper-models/property-set.model';

@Injectable({
  providedIn: 'root'
})
export class ControlLightService {

  controlCabinets = new Map<string, Thing>();
  withoutControlCabinets = new Map<string, Thing>();
    constructor(private _auth: AuthenticationService, private _http: HttpClient) { }

  async getStatus(things: Thing[])
  {
    for (const thing of things)
    {
        if (thing.controlCabinet.id != null)
        {
            if (!this.controlCabinets.has(thing.controlCabinet.id))
            {
                this.controlCabinets.set(thing.controlCabinet.id, thing.controlCabinet);
            }
        } else
        {
            if (!this.withoutControlCabinets.has(thing.id))
            {
                this.withoutControlCabinets.set(thing.id, thing);
            }
        }
    }

    console.log(this.controlCabinets);

    const url = environment.apiUrl + environment.apiVersion;
    const header = await this._auth.getDefaultHttpHeaders();

    const lastControlLight = await lastValueFrom(this._http.get(url + '/signalR/init?propertySet=CONTROL_LIGHT', {headers: header})) as any;

    console.log(lastControlLight);

    for (const singleState of lastControlLight['timeseriesEvents'])
    {
        const lights = {'CONTROL_LIGHT': singleState['measures']};

        // Does this Thing exist
        if (this.controlCabinets.get(singleState['thingId']))
        {
            this.setLightInList(this.controlCabinets, singleState, lights);
        }
        else if (this.withoutControlCabinets.get(singleState['thingId']))
        {
            this.setLightInList(this.withoutControlCabinets, singleState, lights);
        }
    }
  }

  private setLightInList(list: Map<string, Thing>, singleState: any, lights: any)
  {
      if (lights.CONTROL_LIGHT['RED'])
      {
          list.get(singleState['thingId']).status = OperationState.STOP;
          return;
      }
      else if (lights.CONTROL_LIGHT['ORANGE'])
      {
          list.get(singleState['thingId']).status = OperationState.ORANGESTATE;
          return;
      }
      else if (lights.CONTROL_LIGHT['BLUE'])
      {
          list.get(singleState['thingId']).status = OperationState.HAND;
          return;
      }
      else if (lights.CONTROL_LIGHT['GREEN'])
      {
          list.get(singleState['thingId']).status = OperationState.AUTOMATIC;
          return;
      }
      else if (lights.CONTROL_LIGHT['WHITE'])
      {
          list.get(singleState['thingId']).status = OperationState.UNDEFINED;
          return;
      }

      list.get(singleState['thingId']).status = OperationState.UNDEFINED;
  }
}
