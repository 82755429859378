<app-group-header [Heading]="'Fahrwerk' | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>

<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Anzahl Bremsungen' | translate"
                      [Error]="ANZAHL_BREMSUNGEN?.isError"
                      [ErrorLevel]="ANZAHL_BREMSUNGEN?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[ANZAHL_BREMSUNGEN?.TimeseriesData]"
                      [Legend]="['Anzahl Bremsungen' | translate]"
                      [Unit]="'Anzahl'"
                      [Info]="''"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Motordrehzahl' | translate"
                      [Error]="AKTUELLE_MOTORDREHZAHL?.isError"
                      [ErrorLevel]="AKTUELLE_MOTORDREHZAHL?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[AKTUELLE_MOTORDREHZAHL?.TimeseriesData]"
                      [Legend]="['Motordrehzahl' | translate]"
                      [Unit]="'rpm'"
                      [Info]="''"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Schwerlastantriebsrat Drehzahl' | translate"
                      [Error]="SCHWERLAST_ANTRIEBSRAT_DREHZAHL_AKTUELL?.isError"
                      [ErrorLevel]="SCHWERLAST_ANTRIEBSRAT_DREHZAHL_AKTUELL?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[SCHWERLAST_ANTRIEBSRAT_DREHZAHL_AKTUELL?.TimeseriesData]"
                      [Legend]="['Schwerlastantriebsrat Drehzahl' | translate]"
                      [Unit]="'rpm'"
                      [Info]="''"
                      [resizeEvent]="childResizeEvent"></app-default-card>

</div>
