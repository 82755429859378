import { Injectable } from '@angular/core';
import { DataProviderService } from './data-provider.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { AuthenticationService } from './authentication.service';
import { ToastrService } from 'ngx-toastr';
import { DataexplorerNode } from '../../model/dataexplorer-node.model';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MonitoringCloudService
{
    private url = environment.apiUrl + environment.apiVersion + '/monitor/dataexplorer';

    private _cloudData: DataexplorerNode[];

    constructor(private _http: HttpClient,
                private _auth: AuthenticationService,
                private _data: DataProviderService,
                private _toastr: ToastrService) { }

    private generateTree()
    {
        // Für den controlTree, muss alles in children stehen,
        // da es in jedem Objekt gleich heißen muss

        const customers = this._data.customer;
        this._cloudData = [];
        customers.forEach(customer =>
        {
            const newCusomer = new DataexplorerNode(customer.id, customer.name);
            this._cloudData.push(newCusomer);
            // customer['children'] = Array.from(customer.locations.values());
            customer.locations.forEach(location =>
            {
                const newLocation = new DataexplorerNode(location.id, location.name);
                newCusomer.addChild(newLocation);
                // location['children'] = Array.from(location.systems.values());
                location.systems.forEach(system =>
                {
                    const newSystem = new DataexplorerNode(system.id, system.name);
                    newSystem.children = system.getAllThings().map(thing => new DataexplorerNode(thing.id, thing.displayName, thing.controlCabinet.id));
                    newLocation.addChild(newSystem);
                    // Entfernt nicht benötigte keys um ram zu sparen
                    // Object.keys(system).forEach((key) => validKeys.includes(key) || delete system[key]);
                });
            });
        });
    }

    public async getCloudData()
    {
        if (!this._cloudData)
        {
            this.generateTree();
            // Init first node layer of customers
            await this.updateCustomers();
        }
        return this._cloudData;
    }

    public async updateCustomers()
    {
        const data = await this.getExplorerInfo();
        data.forEach(customerInfo =>
        {
            this._cloudData.forEach(node =>
            {
                if (customerInfo.CustomerId === node.id)
                {
                   node.count = customerInfo.ThingCount;
                   node.timestamp = customerInfo.MaxTimestamp;
                   node.loading = false;
                   node.childsLoaded = false;
                }
            });
        });
    }

    public async updateLocation(regTable: string, ids: string[])
    {
        const data = await this.getExplorerInfo(regTable, ids);
        data.forEach(locationInfo =>
        {
            // ToDo .find benutzen
            this._cloudData.forEach(customer =>
            {
                customer.children.forEach(location =>
                {
                    if (location.id === locationInfo.LocationId)
                    {
                        location.count = locationInfo.ThingCount;
                        location.timestamp = locationInfo.MaxTimestamp;
                        location.loading = false;
                        location.childsLoaded = false;
                        customer.childsLoaded = true;
                    }
                });
            });
        });
    }

    public async updateSystem(regTable: string, ids: string[])
    {
        const data = await this.getExplorerInfo(regTable, ids);
        data.forEach(systemInfo =>
        {
            this._cloudData.forEach(customer =>
            {
                customer.children.forEach(location =>
                {
                    location.children.forEach(system =>
                    {
                        if (system.id === systemInfo.SystemId)
                        {
                            system.count = systemInfo.ThingCount;
                            system.timestamp = systemInfo.MaxTimestamp;
                            system.loading = false;
                            system.childsLoaded = false;
                            location.childsLoaded = true;
                        }
                    });
                });
            });
        });
    }

    /**
     * @param regTable is needed for getExplorerInfo. For this func it should be "things"
     * @param systemId the system, which it should update
     * */
    public async updateThing(regTable: string, systemId: string): Promise<void>
    {
        let data = await this.getExplorerInfo(regTable, systemId);
        for (let i = 0; i < data.length; i++)
        {
            this._cloudData.forEach(customer =>
            {
                customer.children.forEach(location =>
                {
                    location.children.forEach(system =>
                    {
                        system.children.forEach(thing =>
                        {
                            if (typeof data[i] !== 'undefined' && (thing.id === data[i].ThingId || thing.controlCabinet === data[i].ThingId))
                            {
                                if (typeof thing.count === 'undefined' || thing.count < data[i].ThingCount)
                                {
                                    thing.count = data[i].ThingCount;
                                }

                                if (typeof thing.timestamp === 'undefined' || data[i].MaxTimestamp < thing.timestamp)
                                {
                                    thing.timestamp = data[i].MaxTimestamp;
                                }
                                thing.loading = false;
                                thing.childsLoaded = false;
                                system.childsLoaded = false;
                                data.splice(i, 1);
                                i--;
                            }
                        });
                    });
                });
            });
        }
        // Add the remaining things, which are missing in the MongoDb
        this.addRemainingThings(data, systemId);
    }

    private addRemainingThings(remainingThings: DataexplorerInfo[], systemId: string)
    {
        for (let i = 0; i < remainingThings.length; i++)
        {
            this._cloudData.forEach(customer =>
            {
                customer.children.forEach(location =>
                {
                    location.children.forEach(system =>
                    {
                        if (system.id === systemId)
                        {
                            const newThing = new DataexplorerNode(remainingThings[i].ThingId, remainingThings[i].ThingId);
                            newThing.timestamp = remainingThings[i].MaxTimestamp;
                            newThing.count = remainingThings[i].ThingCount;
                            system.addChild(newThing);
                        }
                    });
                });
            });
        }
    }

    private async getExplorerInfo(regTable?: string, ids?: string | string[]): Promise<DataexplorerInfo[]>
    {
        let headers = await this._auth.getDefaultHttpHeaders();

        if (!(typeof regTable === 'undefined'))
        {
            headers = headers.set(regTable, ids);
        }

        const httpOptions = {
            headers: headers,
        };

        // this._http.get(this.url, httpOptions).subscribe(
        //     data =>
        //     {
        //         console.log(data);
        //         this.updateSystems(data as DataexplorerInfo[]);
        //     },
        //     error =>
        //     {
        //         this._toastr.error('Beim abrufen der Dataexplorer informationen ist ein Fehler aufgetreten!', null, { positionClass: 'toast-bottom-right' });
        //     });

        return firstValueFrom(this._http.get(this.url, httpOptions)) as Promise<DataexplorerInfo[]>;
    }
}

class DataexplorerInfo
{
    CustomerId: string;
    LocationId: string;
    SystemId: string;
    ThingId: string;
    ThingCount: number;
    MaxTimestamp: Date;
}
