import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
    selector: 'app-fts-chassis',
    templateUrl: './fts-chassis.component.html',
    styleUrls: ['./fts-chassis.component.scss',
        './../card.scss']
})
export class FtsChassisComponent extends BaseViewElement implements OnInit, OnChanges
{

    @Input() Fahrwerk: PropertySet;

    ANZAHL_BREMSUNGEN: Property<any>;
    AKTUELLE_MOTORDREHZAHL: Property<any>;
    SCHWERLAST_ANTRIEBSRAT_DREHZAHL_AKTUELL: Property<any>;

    constructor()
    {
        super('fts-chassis');
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.Fahrwerk != null)
        {
            this.ANZAHL_BREMSUNGEN = this.Fahrwerk.properties.get('ANZAHL_BREMSUNGEN');
            this.AKTUELLE_MOTORDREHZAHL = this.Fahrwerk.properties.get('AKTUELLE_MOTORDREHZAHL');
            this.SCHWERLAST_ANTRIEBSRAT_DREHZAHL_AKTUELL = this.Fahrwerk.properties.get('SCHWERLAST_ANTRIEBSRAT_DREHZAHL_AKTUELL');
        }
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit();
    }

}
