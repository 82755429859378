<app-group-header [Heading]="'Statistik' | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>
<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Anzahl Klappenbewegungen' | translate"
                      [Error]="AmountFlapMovements?.isError"
                      [ErrorLevel]="AmountFlapMovements?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[AmountFlapMovements?.TimeseriesData]"
                      [Legend]="['Anzahl Klappenbewegungen' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Carrier belegt' | translate"
                      [Error]="CarrierInUse?.isError"
                      [ErrorLevel]="CarrierInUse?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[CarrierInUse?.TimeseriesData]"
                      [Type]="'boolean'"
                      [Legend]="['Carrier belegt' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

</div>
