import {Pipe, PipeTransform} from '@angular/core';
import {DataPoint} from '../../model/helper-models/datapoint.model';

@Pipe({
    name: 'historicBoolean'
})
/**
 * Klasse der HistoricBooleanPipe
 */
export class HistoricBooleanPipe implements PipeTransform
{
    /**
     * Wandelt ein boolean Array in ein Number Array um
     * true wird zu 1, false wird zu 0
     * Wird für historische Daten benötigt
     * @param pValue Array<Datapoint<boolean>>
     */
    transform(pValue: Array<DataPoint<boolean>>): Array<DataPoint<number>>
    {
        const result = [];
        if (pValue == null) return result;

        pValue.forEach(x =>
        {
            if (x.value)
            {
                result.push(new DataPoint<number>(1, x.timestamp));
            }
            else
            {
                result.push(new DataPoint<number>(0, x.timestamp));
            }
        });

        return result;
    }
}
