<app-current-voltage *ngIf="widget === 'current-voltage'"
                     [resizeEvent]="resizeEvent"
                     [Currentmeter]="Thing.controlCabinet.propertySets.get('CURRENTMETER')">
</app-current-voltage>

<app-sum-of-power *ngIf="widget === 'sum-of-power'"
                  [resizeEvent]="resizeEvent"
                  [Currentmeter]="Thing.controlCabinet.propertySets.get('CURRENTMETER')">
</app-sum-of-power>

<app-image *ngIf="widget === 'image'"
           [resizeEvent]="resizeEvent"
           [heading]="'Darstellung MLS' | translate"
           [src]="Thing.model3D"
           [equipmentNumber]="Thing.equipmentNumber"
           [alt]="'Darstellung MLS' | translate"
           [ain]="Thing.ainUrl"
           [width]="'auto'">
</app-image>

<app-energy-consumption *ngIf="widget === 'energy-consumption'"
                        [resizeEvent]="resizeEvent"
                        [Energy]="Thing.controlCabinet.propertySets.get('ENERGY')">
</app-energy-consumption>

<app-distance *ngIf="widget === 'distance'"
              [resizeEvent]="resizeEvent"
              [HorizontalDistance]="Thing.propertySets.get('HORIZONTAL_DISTANCE')"
              [VerticalDistance]="Thing.propertySets.get('VERTICAL_DISTANCE')"
              [TelescopeDistance]="Thing.propertySets.get('TELESCOPE_DISTANCE')">
</app-distance>

<app-statistic *ngIf="widget === 'statistic'"
               [resizeEvent]="resizeEvent"
               [Statistic]="Thing.propertySets.get('STATISTICS')">
</app-statistic>

<app-status *ngIf="widget === 'status'"
            [resizeEvent]="resizeEvent"
            [Status]="Thing.propertySets.get('STATUS')">
</app-status>


<app-cm-sensors *ngIf="widget === 'cm-lam'"
                [resizeEvent]="resizeEvent"
                [Type]="'LAM'"
                [Environment]="Thing.propertySets.get('DS_ENV')"
                [Rotation]="Thing.propertySets.get('DS_ROT')"
                [AngularVelocity]="Thing.propertySets.get('DS_ANGVEL')"
                [Acceleration]="Thing.propertySets.get('DS_ACL')">
</app-cm-sensors>

<app-cm-sensors *ngIf="widget === 'cm-mdw'"
                [resizeEvent]="resizeEvent"
                [Type]="'MDW'"
                [Environment]="Thing.propertySets.get('MDW_ENV')"
                [Rotation]="Thing.propertySets.get('MDW_ROT')"
                [AngularVelocity]="Thing.propertySets.get('MDW_ANGVEL')"
                [Acceleration]="Thing.propertySets.get('MDW_ACL')">
</app-cm-sensors>

<app-cm-sensors *ngIf="widget === 'cm-mtop'"
                [resizeEvent]="resizeEvent"
                [Type]="'MTOP'"
                [Environment]="Thing.propertySets.get('MTOP_ENV')"
                [Rotation]="Thing.propertySets.get('MTOP_ROT')"
                [AngularVelocity]="Thing.propertySets.get('MTOP_ANGVEL')"
                [Acceleration]="Thing.propertySets.get('MTOP_ACL')">
</app-cm-sensors>
