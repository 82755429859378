<!-- [items]="[['Anmelden','#LogIn']]"></app-thing-banner> -->
<app-help-page-banner [items]="[['Anmelden','Log in','LogIn'], ['Abmelden','Log out','LogOut']]"
                      [returnAddress]="'/HelpPage'"></app-help-page-banner>
<div class="container">
    <h2>{{'Anmelden & Abmelden' | translate}} </h2>
    <h3 class="subheading" id="LogIn">{{'Anmelden' | translate}}</h3>
    <p>{{'Für die Benutzung des Galileo IOT Dashboards von GEBHARDT eignen sich zur einwandfreien Darstellung insbesondere zwei Internetbrowser:' | translate}}</p>
    <ul>
        <li>{{'Google Chrome' | translate }}</li>
        <li>{{'Mozilla Firefox' | translate }}</li>
    </ul>

    <p>{{ 'Das folgende ' | translate }}<strong>{{ 'Anmeldefenster' | translate }}</strong>{{ ' beim erstmaligen Öffnen des Galileo IoT Dashboards: '| translate}}</p>
    <img id="imageLogIn" src="{{environment.storageUrl}}/models3d/general/helpPage/introduction/Log in & Log out/logInMicrosoft_de.png"
         class="embed-responsive mx-auto d-block">
         <p>{{'Weitere ' | translate }}
            <strong>{{'Anmeldungen' | translate }}</strong>{{' erfolgen dann über SSO.' | translate }}
        </p>
    <p>
        <strong>{{'Kennwort vergessen?' | translate }}</strong>{{' Wenn sie ihr Kennwort vergessen haben, können Sie uns über den Kontakt Button kontaktieren. ' | translate }}
    <h3 class="subheading">{{'Abmelden' | translate }}</h3>
    <p id="LogOut">
        {{'Oben rechts befindet sich der Button, durch welchen man sich abmelden kann:' | translate }}
        <img id="imageButtonLogOut"
             src="{{environment.storageUrl}}/models3d/general/helpPage/introduction/Log in & Log out/buttonLogOut_{{urlLanguage}}.png">
    </p>
</div>