import {CustomerDetail} from './customer-detail.model';
import {TabItem} from './helper-models/tab-item.model';
import {CLocation} from './location.model';
import {Thing} from './thing.model';
import {PowerbiConfigModel} from './powerbi-config.model';

// Anlage
export class CSystem extends CustomerDetail
{
    public id: string;

    public path3D: string;
    public parentLocation: CLocation;

    public dashboard;
    public testdrive;

    public type = 'SYSTEM';

    // Tabs für das aktive system
    public currentTabs = new Array<TabItem>();
    public PowerBiConfig: PowerbiConfigModel;

    // Cache für die Abfrage der things
    public allThingTypes = new Map<string, Thing[]>();

    public allThingsGroupedByTypeAndIsle;
    private things = new Map<string, Thing>();
    addThing(key: string, value: Thing)
    {
        this.things.set(key, value);
    }

    constructor(pId: string, pName: string, pPath3D: string, pPowerBiConfig: Array<any>)
    {
        super();
        this.id = pId;
        this.name = pName;
        this.path3D = pPath3D;
        if (pPowerBiConfig != null)
        {
            this.PowerBiConfig = new PowerbiConfigModel(pPowerBiConfig.filter(x => x.name === 'Health'),
                pPowerBiConfig.filter(x => x.name === 'Insight'),
                pPowerBiConfig.filter(x => x.name === 'testdrive'));
        }


        this.dashboard = new Thing('dashboard', 'Dashboard', 'DASHBOARD', '', '', '', 'Dashboard',
            '', '', '', '', '', '',
            this.name, 0, '', null);

        this.dashboard.parentSystem = this;
        this.dashboard.type = '';

        this.testdrive = new Thing('testdrive', 'Testfahrt', 'TESTFAHRT', '', '', '', 'Testfahrt',
            '', '', '', '', '', '',
            this.name, 0, '', null);

        this.testdrive.parentSystem = this;
        this.testdrive.type = '';

    }

    /**
     * Befüllt allThings
     * @param pAllThings
     */
    public fillThingTypes()
    {
        this.things.forEach(thing =>
        {

            if (!this.allThingTypes.has(thing.type))
            {
                this.allThingTypes.set(thing.type, Array.from(this.things.values()).filter(thin => thin.type === thing.type) as Thing[]);
            }
        });

        /*
        const thing of this.things)
        {
            if (!this.allThingTypes.has(thing.type))
            {
                this.allThingTypes.set(thing.type, this.things.filter(thin => thin.type === thing.type) as Thing[]);
            }
        }*/
    }

    public groupThingsByIsle()
    {
        const groupBy = function (xs, key)
        {
            return xs.reduce(function (rv, x)
            {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        };

        const testArr = new Map<any, Map<any, any>>();

        this.allThingTypes.forEach((value: any, key: string) =>
        {

            const groupedByIsle = groupBy(value, 'aisle');
            const keys = Object.keys(groupedByIsle);
            const isleMap = new Map<any, any>();
            keys.forEach((x) =>
            {
                isleMap.set(x, groupedByIsle[x]);
            });

            testArr.set(key, isleMap);
        });

        this.allThingsGroupedByTypeAndIsle = new Map([...testArr.entries()].sort());

    }


    /**
     * Gibt allThings zurück
     */
    public getAllThings()
    {
        return Array.from(this.things.values());
    }

    public getOutdatedProperties()
    {
        this.getAllThings().forEach(thing =>
        {
            thing.outdatedProperties.subscribe(x =>
            {
                if (x != null)
                {
                    this.errorCount = 0;
                    this.warningCount = 0;
                    this.infoCount = 0;

                    for (let i = 0; i < this.things.size; i++)
                    {
                        this.errorCount += this.things[i].errorCount;
                        this.warningCount += this.things[i].warningCount;
                        this.infoCount += this.things[i].infoCount;
                    }
                }
            });
        });
    }
}
