<app-group-header [Heading]="'Motor Temperatur und Strom' | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>
<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Temperatur' | translate"
                      [Error]="Temperature?.isError"
                      [ErrorLevel]="Temperature?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[Temperature?.TimeseriesData]"
                      [Unit]="'°C'"
                      [Legend]="['Temperatur' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Stromstärke' | translate"
                      [Error]="Current?.isError"
                      [ErrorLevel]="Current?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[Current?.TimeseriesData]"
                      [Unit]="'A'"
                      [Legend]="['Stromstärke Phase 1' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

</div>
