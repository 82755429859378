import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {OWL_DATE_TIME_LOCALE, OwlDateTimeIntl, OwlDateTimeModule, OwlNativeDateTimeModule} from '@danielmoncada/angular-datetime-picker';
import {DatepickerPopupComponent} from '../../view/datepicker-popup/datepicker-popup.component';
import {PipesModule} from './pipes.module';
import {TranslateModule} from '@ngx-translate/core';
import {DefaultIntl} from '../../view/datepicker-popup/DefaultIntl';

@NgModule({
    providers: [
        {provide: OWL_DATE_TIME_LOCALE, useValue: 'de'},
        {provide: OwlDateTimeIntl, useClass: DefaultIntl},
    ],
    imports: [
        CommonModule,
        FormsModule,
        PipesModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        TranslateModule,
    ],
    declarations: [
        DatepickerPopupComponent,
    ],
    exports: [
        DatepickerPopupComponent,
    ]
})
export class DatepickerModule
{
}
