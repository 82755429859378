<app-group-header [Heading]="'Statistik Infeed' | translate"
                  [chart]="chart"
                  [info]="info"
                  [noChart]="true"
                  [noInfo]="true"
                  [noText]="true"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>
<div class="table-responsive" style="height: calc(100% - 50px)">
    <table class="table table-bordered" style="height: 100%; padding: 0; margin: 0;">
        <thead>
        <tr>
            <th style="width: 25%" scope="col">Infeed</th>
            <th style="width: 25%" scope="col">Einschleusungen</th>
            <th style="width: 25%" scope="col">Fehler</th>
            <th style="width: 25%" scope="col">Crash</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let inf of Infeeds | orderBy:'displayName'">
            <th class="align-middle" scope="row">{{inf.displayName}}</th>
            <td class="align-middle">
                <ng-container
                        *ngIf="inf?.propertySets?.get('INFEED')?.properties.get('ANZ_EINSCHLEUSUNGEN')?.TimeseriesData.currentDatapoint.value != null; else noData">
                    {{inf.propertySets?.get('INFEED').properties.get('ANZ_EINSCHLEUSUNGEN').TimeseriesData.currentDatapoint.value}}
                </ng-container>
            </td>
            <td class="align-middle">
                <ng-container
                        *ngIf="inf?.propertySets?.get('INFEED')?.properties.get('ANZ_FEHLER').TimeseriesData.currentDatapoint.value != null; else noData">
                    {{inf?.propertySets.get('INFEED').properties.get('ANZ_FEHLER').TimeseriesData.currentDatapoint.value}}
                </ng-container>
            </td>
            <td class="align-middle">
                <ng-container
                        *ngIf="inf?.propertySets?.get('CRASH')?.properties.get('ANZ_CRASH')?.TimeseriesData.currentDatapoint.value != null; else noData">
                    {{inf?.propertySets.get('CRASH').properties.get('ANZ_CRASH').TimeseriesData.currentDatapoint.value}}
                </ng-container>
            </td>
        </tr>
        </tbody>
    </table>
</div>


<ng-template #noData>
    <img title="Keine aktuellen Daten vorhanden!" class="img-fluid"
         src="../../../../assets/icons/cloud_off_black_18dp.png"/>
</ng-template>


