import { Component } from '@angular/core';
import {BaseSubcomp} from '../../../base-subcomp';

@Component({
  selector: 'app-olps-subcomp-parent',
  templateUrl: './olps-subcomp-parent.component.html',
  styleUrls: ['./olps-subcomp-parent.component.scss']
})
export class OlpsSubcompParentComponent extends BaseSubcomp
{

}

