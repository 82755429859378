<div class="container">
    <div class="row">
        <div class="col-11">
            <div class="row">
                <div class="col-2">
                    <h3>
                        {{this.data.name | translate}}
                    </h3>
                </div>
                <div class="col-1"></div>
                <div class="col-2">
                    <em>
                        {{this.data.data.komponenten_instance_description | translate}}
                    </em>
                </div>
                <div class="col-7"></div>
            </div>
        </div>
        <div class="col-1">
            <button mat-button (click)="closeModal()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div style="height: 0.5%"></div>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <div class="row">
                <figure class="figure">
                    <img src="https://galileoiotfilestorage.blob.core.windows.net/models3d/general/healthPage/{{this.data.data.materialnummer}}.png"
                         class="figure-img img-fluid rounded"
                         style="width: auto; height: 250px;">
                </figure>
            </div>
            <div *ngIf="auth.HealthReset" class="row">
                <div class="col-3">
                    <button type="button" class="btn btn-primary btn-block"
                            (click)="shortenMaintenanceDate(this.data.data.bauteil_name_de,this.data.data.komponenten_name_de,this.data.data.materialnummer)">
                        {{'Austausch vorziehen' | translate}}
                    </button>
                </div>
                <div class="col-1"></div>
                <div class="col-3">
                    <button type="button" class="btn btn-primary btn-block"
                            (click)="extendMaintenanceDate(this.data.data.bauteil_name_de,this.data.data.komponenten_name_de,this.data.data.materialnummer)">
                        {{'Austausch verschieben' | translate}}
                    </button>
                </div>
                <div class="col-1"></div>
                <div class="col-3">
                    <button type="button" class="btn btn-primary btn-block"
                            (click)="resetComponent(this.data.data.bauteil_name_de,this.data.data.komponenten_name_de,this.data.data.materialnummer,this.data.data.komponenten_instance)">
                        {{'Komponente ausgetauscht' | translate}}
                    </button>
                </div>
            </div>
        </div>
        <div class="col-1"></div>
        <div class="col-7">
            <div class="row">
                <table class="table caption-top">
                    <caption *ngIf="this.data.data.maintenanceCriteria[0]">
                        {{'Wartungsinformationen - Prüfkriterien:' | translate}} {{this.data.data.maintenanceCriteria[0]["Criteria"] | translate}}</caption>
                    <caption *ngIf="!this.data.data.maintenanceCriteria[0]">{{'Wartungsinformationen' | translate}}
                    </caption>
                    <thead>
                    <tr>
                        <th scope="col">{{'Aktionsnummer' | translate}}</th>
                        <th scope="col">{{'Aktion' | translate}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let tableRow of this.data.data.maintenanceInformation">
                        <th scope="row">
                            {{tableRow.Aktionsnummer}}
                        </th>
                        <td>{{tableRow.Aktion | translate}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="row">
                <table class="table caption-top">
                    <caption>{{'Messgroessen Details' | translate}}</caption>
                    <thead>
                    <tr>
                        <th scope="col">{{'Name' | translate}}</th>
                        <th scope="col">{{'Wert' | translate}}</th>
                        <th scope="col">{{'Grenzwert' | translate}}</th>
                        <th scope="col">{{'Einheit' | translate}}</th>
                        <th scope="col">{{'Health' | translate}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let tableRow of this.data.data.messgroessen">
                        <td>{{tableRow.messgroessen_name | translate}}</td>
                        <td>{{tableRow.live_value}}</td>
                        <td>{{tableRow.momentaner_grenzwert}}</td>
                        <td>{{tableRow.einheit}}</td>
                        <td>{{tableRow.health_percentage}} %</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="this.data.data.healthMaintenance.length>0" class="row">
                <table class="table caption-top">
                    <caption>{{'Wartungshistorie' | translate}}</caption>
                    <thead>
                    <tr>
                        <th scope="col">{{'User' | translate}}</th>
                        <th scope="col">{{'Typ' | translate}}</th>
                        <th scope="col">{{'Beschreibung' | translate}}</th>
                        <th scope="col">{{'Zeitpunkt' | translate}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let tableRow of this.data.data.healthMaintenance">
                        <td>{{tableRow.iot_user}}</td>
                        <td>{{tableRow.type | translate}}</td>
                        <td>{{tableRow.description | translate}}</td>
                        <td>{{tableRow.parsedTimestamp}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
