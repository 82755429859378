<app-temperature *ngIf="widget === 'temperature'"
                 [resizeEvent]="resizeEvent"
                 [Temperature1Error]="Thing.OLSE_TEMPERATUR.MOTOR_DM1.isError"
                 [Temperature1ErrorLevel]="Thing.OLSE_TEMPERATUR.MOTOR_DM1.status"
                 [Temperature1Value]="[Thing.OLSE_TEMPERATUR.MOTOR_DM1.TimeseriesData]"
                 [Temperature2Error]="Thing.OLSE_TEMPERATUR.MOTOR_DM2.isError"
                 [Temperature2ErrorLevel]="Thing.OLSE_TEMPERATUR.MOTOR_DM2.status"
                 [Temperature2Value]="[Thing.OLSE_TEMPERATUR.MOTOR_DM2.TimeseriesData]">
</app-temperature>

<app-image *ngIf="widget === 'image'"
           [resizeEvent]="resizeEvent"
           [heading]="'Darstellung OLSE' | translate"
           [src]="Thing.model3D"
           [equipmentNumber]="Thing.equipmentNumber"
           [alt]="'Darstellung OLSE' | translate"
           [ain]="Thing.ainUrl"
           [width]="'auto'">
</app-image>

<app-error-messages *ngIf="widget === 'error-messages'"
                    [resizeEvent]="resizeEvent"
                    [MessagesError]="Thing.OLSE_SRC_STATUS.STATUS_CODE.isError"
                    [MessagesErrorLevel]="Thing.OLSE_SRC_STATUS.STATUS_CODE.status"
                    [MessagesValue]="[Thing.OLSE_SRC_STATUS.STATUS_CODE.TimeseriesData,
                                      Thing.OLSE_SRC_STATUS.STATUS_TEXT.TimeseriesData,
                                      Thing.OLSE_SRC_STATUS.DM1_STATUS_CODE.TimeseriesData,
                                      Thing.OLSE_SRC_STATUS.DM3_STATUS_CODE.TimeseriesData]">
</app-error-messages>

<app-movements *ngIf="widget === 'movements'"
               [resizeEvent]="resizeEvent"
               [TotalPicksError]="Thing.OLSE_MOVEMENTS.TOTAL_PICKS.isError"
               [TotalPicksErrorLevel]="Thing.OLSE_MOVEMENTS.TOTAL_PICKS.status"
               [TotalPicksValue]="Thing.OLSE_MOVEMENTS.TOTAL_PICKS.TimeseriesData"
               [TotalDropsError]="Thing.OLSE_MOVEMENTS.TOTAL_DROPS.isError"
               [TotalDropsErrorLevel]="Thing.OLSE_MOVEMENTS.TOTAL_DROPS.status"
               [TotalDropsValue]="Thing.OLSE_MOVEMENTS.TOTAL_DROPS.TimeseriesData"
               [TelescopeMovementsError]="Thing.OLSE_MOVEMENTS.MOVEMENTS_TELESCOPE.isError"
               [TelescopeMovementsErrorLevel]="Thing.OLSE_MOVEMENTS.MOVEMENTS_TELESCOPE.status"
               [TelescopeMovementsValue]="Thing.OLSE_MOVEMENTS.MOVEMENTS_TELESCOPE.TimeseriesData"
               [XAxisMovementsError]="Thing.OLSE_MOVEMENTS.MOVEMENTS_X_AXIS.isError"
               [XAxisMovementsErrorLevel]="Thing.OLSE_MOVEMENTS.MOVEMENTS_X_AXIS.status"
               [XAxisMovementsValue]="Thing.OLSE_MOVEMENTS.MOVEMENTS_X_AXIS.TimeseriesData">
</app-movements>

<app-orders *ngIf="widget === 'orders'"
            [resizeEvent]="resizeEvent"
            [OrdersHourError]="Thing.OLSE_ORDERS.ORDERS_PER_H.isError"
            [OrdersHourErrorLevel]="Thing.OLSE_ORDERS.ORDERS_PER_H.status"
            [OrdersHourValue]="Thing.OLSE_ORDERS.ORDERS_PER_H.TimeseriesData"
            [OrdersAllError]="Thing.OLSE_ORDERS.ORDERS_TOTAL.isError"
            [OrdersAllErrorLevel]="Thing.OLSE_ORDERS.ORDERS_TOTAL.status"
            [OrdersAllValue]="Thing.OLSE_ORDERS.ORDERS_TOTAL.TimeseriesData">
</app-orders>

<app-time *ngIf="widget === 'time'"
          [resizeEvent]="resizeEvent"
          [DistanceError]="Thing.OLSE_DISTANCE.KM_COUNTER_X_AXIS.isError"
          [DistanceErrorLevel]="Thing.OLSE_DISTANCE.KM_COUNTER_X_AXIS.status"
          [DistanceValue]="Thing.OLSE_DISTANCE.KM_COUNTER_X_AXIS.TimeseriesData"
          [TelescopeDistanceError]="Thing.OLSE_DISTANCE.TELESCOPE_DISTANCE_Z_AXIS.isError"
          [TelescopeDistanceErrorLevel]="Thing.OLSE_DISTANCE.TELESCOPE_DISTANCE_Z_AXIS.status"
          [TelescopeDistanceValue]="Thing.OLSE_DISTANCE.TELESCOPE_DISTANCE_Z_AXIS.TimeseriesData"
          [OperationTimeError]="Thing.OLSE_TIME_GENERAL.OPERATION_TIME.isError"
          [OperationTimeErrorLevel]="Thing.OLSE_TIME_GENERAL.OPERATION_TIME.status"
          [OperationTimeValue]="Thing.OLSE_TIME_GENERAL.OPERATION_TIME.TimeseriesData"
          [DrivingTimeError]="Thing.OLSE_TIME_GENERAL.DRIVING_TIME.isError"
          [DrivingTimeErrorLevel]="Thing.OLSE_TIME_GENERAL.DRIVING_TIME.status"
          [DrivingTimeValue]="Thing.OLSE_TIME_GENERAL.DRIVING_TIME.TimeseriesData"
          [WaitTimeError]="Thing.OLSE_TIME_GENERAL.WAIT_TIME.isError"
          [WaitTimeErrorLevel]="Thing.OLSE_TIME_GENERAL.WAIT_TIME.status"
          [WaitTimeValue]="Thing.OLSE_TIME_GENERAL.WAIT_TIME.TimeseriesData"
          [OffTimeError]="Thing.OLSE_TIME_GENERAL.OFF_TIME.isError"
          [OffTimeErrorLevel]="Thing.OLSE_TIME_GENERAL.OFF_TIME.status"
          [OffTimeValue]="Thing.OLSE_TIME_GENERAL.OFF_TIME.TimeseriesData"
          [TimeBetweenErrorsError]="Thing.OLSE_TIME_GENERAL.TIME_BETWEEN_ERRORS.isError"
          [TimeBetweenErrorsErrorLevel]="Thing.OLSE_TIME_GENERAL.TIME_BETWEEN_ERRORS.status"
          [TimeBetweenErrorsValue]="Thing.OLSE_TIME_GENERAL.TIME_BETWEEN_ERRORS.TimeseriesData"
          [TimeIntoLiftError]="Thing.OLSE_TIME_GENERAL.TIME_DRIVE_INTO_LIFT.isError"
          [TimeIntoLiftErrorLevel]="Thing.OLSE_TIME_GENERAL.TIME_DRIVE_INTO_LIFT.status"
          [TimeIntoLiftValue]="Thing.OLSE_TIME_GENERAL.TIME_DRIVE_INTO_LIFT.TimeseriesData"
          [TimeIntoRackError]="Thing.OLSE_TIME_GENERAL.TIME_DRIVE_INTO_RACK.isError"
          [TimeIntoRackErrorLevel]="Thing.OLSE_TIME_GENERAL.TIME_DRIVE_INTO_RACK.status"
          [TimeIntoRackValue]="Thing.OLSE_TIME_GENERAL.TIME_DRIVE_INTO_RACK.TimeseriesData">
</app-time>

<app-position *ngIf="widget === 'position'"
              [resizeEvent]="resizeEvent"
              [XPositionError]="Thing.OLSE_POSITION.X_AXIS_POSITION.isError"
              [XPositionErrorLevel]="Thing.OLSE_POSITION.X_AXIS_POSITION.status"
              [XPositionValue]="Thing.OLSE_POSITION.X_AXIS_POSITION.TimeseriesData"
              [YPositionError]="Thing.OLSE_POSITION.Y_AXIS_POSITION.isError"
              [YPositionErrorLevel]="Thing.OLSE_POSITION.Y_AXIS_POSITION.status"
              [YPositionValue]="Thing.OLSE_POSITION.Y_AXIS_POSITION.TimeseriesData"
              [LastEnginePositionError]="Thing.OLSE_POSITION.DM3_X_AXIS_LAST_MOTOR_POSITION.isError"
              [LastEnginePositionErrorLevel]="Thing.OLSE_POSITION.DM3_X_AXIS_LAST_MOTOR_POSITION.status"
              [LastEnginePositionValue]="Thing.OLSE_POSITION.DM3_X_AXIS_LAST_MOTOR_POSITION.TimeseriesData">
</app-position>

<app-fine-positioning *ngIf="widget === 'fine-position'"
                      [resizeEvent]="resizeEvent"
                      [FinePositioningError]="Thing.OLSE_TIME_FINE_POSITIONING.OLSE_TIME_FINE_POSITIONING.isError"
                      [FinePositioningErrorLevel]="Thing.OLSE_TIME_FINE_POSITIONING.OLSE_TIME_FINE_POSITIONING.status"
                      [FinePositioningValue]="Thing.OLSE_TIME_FINE_POSITIONING.OLSE_TIME_FINE_POSITIONING.TimeseriesData">
</app-fine-positioning>

<app-finger-movements *ngIf="widget === 'finger-movements'"
                      [resizeEvent]="resizeEvent"
                      [FingerMovementsError]="Thing.OLSE_FINGER_1_1.MOVEMENTS.isError"
                      [FingerMovementsErrorLevel]="Thing.OLSE_FINGER_1_1.MOVEMENTS.status"
                      [FingerMovementsValue]="[Thing.OLSE_FINGER_1_1.MOVEMENTS?.TimeseriesData,
                                               Thing.OLSE_FINGER_1_2.MOVEMENTS?.TimeseriesData,
                                               Thing.OLSE_FINGER_2_1.MOVEMENTS?.TimeseriesData,
                                               Thing.OLSE_FINGER_2_2.MOVEMENTS?.TimeseriesData,
                                               Thing.OLSE_FINGER_3_1.MOVEMENTS?.TimeseriesData,
                                               Thing.OLSE_FINGER_3_2.MOVEMENTS?.TimeseriesData]">
</app-finger-movements>

<app-finger-time *ngIf="widget === 'finger-time'"
                 [resizeEvent]="resizeEvent"
                 [FingerTimeOutError]="Thing.OLSE_FINGER_1_1.TIME_EXTEND.isError"
                 [FingerTimeOutErrorLevel]="Thing.OLSE_FINGER_1_1.TIME_EXTEND.status"
                 [FingerTimeOutValue]="[Thing.OLSE_FINGER_1_1.TIME_EXTEND?.TimeseriesData,
                                               Thing.OLSE_FINGER_1_2.TIME_EXTEND?.TimeseriesData,
                                               Thing.OLSE_FINGER_2_1.TIME_EXTEND?.TimeseriesData,
                                               Thing.OLSE_FINGER_2_2.TIME_EXTEND?.TimeseriesData,
                                               Thing.OLSE_FINGER_3_1.TIME_EXTEND?.TimeseriesData,
                                               Thing.OLSE_FINGER_3_2.TIME_EXTEND?.TimeseriesData]"
                 [FingerTimeInError]="Thing.OLSE_FINGER_1_1.TIME_RETRACT.isError"
                 [FingerTimeInErrorLevel]="Thing.OLSE_FINGER_1_1.TIME_RETRACT.status"
                 [FingerTimeInValue]="[Thing.OLSE_FINGER_1_1.TIME_RETRACT?.TimeseriesData,
                                               Thing.OLSE_FINGER_1_2.TIME_RETRACT?.TimeseriesData,
                                               Thing.OLSE_FINGER_2_1.TIME_RETRACT?.TimeseriesData,
                                               Thing.OLSE_FINGER_2_2.TIME_RETRACT?.TimeseriesData,
                                               Thing.OLSE_FINGER_3_1.TIME_RETRACT?.TimeseriesData,
                                               Thing.OLSE_FINGER_3_2.TIME_RETRACT?.TimeseriesData]">
</app-finger-time>

<app-cm-sensors *ngIf="widget === 'cm-mdw'"
                [resizeEvent]="resizeEvent"
                [Type]="''"
                [TemperatureError]="Thing.CMS.CMS_ENV_MDW.TEMPERATURE.isError"
                [TemperatureErrorLevel]="Thing.CMS.CMS_ENV_MDW.TEMPERATURE.status"
                [TemperatureValue]="[Thing.CMS.CMS_ENV_MDW.TEMPERATURE.TimeseriesData]"
                [RotationError]="Thing.CMS.CMS_ROT_MDW.ROTATION_X.isError || Thing.CMS.CMS_ROT_MDW.ROTATION_Y.isError || Thing.CMS.CMS_ROT_MDW.ROTATION_Z.isError"
                [RotationErrorLevel]="Thing.CMS.CMS_ROT_MDW.ROTATION_X.status"
                [RotationValue]="[Thing.CMS.CMS_ROT_MDW.ROTATION_X.TimeseriesData,
                                 Thing.CMS.CMS_ROT_MDW.ROTATION_Y.TimeseriesData,
                                 Thing.CMS.CMS_ROT_MDW.ROTATION_Z.TimeseriesData]"
                [AngularAccelerationError]="Thing.CMS.CMS_ANGACL_MDW.ACCELERATION_X.isError || Thing.CMS.CMS_ANGACL_MDW.ACCELERATION_Y.isError || Thing.CMS.CMS_ANGACL_MDW.ACCELERATION_Z.isError"
                [AngularAccelerationErrorLevel]="Thing.CMS.CMS_ANGACL_MDW.ACCELERATION_X.status"
                [AngularAccelerationValue]="[Thing.CMS.CMS_ANGACL_MDW.ACCELERATION_X.TimeseriesData,
                                 Thing.CMS.CMS_ANGACL_MDW.ACCELERATION_Y.TimeseriesData,
                                 Thing.CMS.CMS_ANGACL_MDW.ACCELERATION_Z.TimeseriesData]"
                [AccelerationError]="Thing.CMS.CMS_ACL_MDW.ACCELERATION_X.isError || Thing.CMS.CMS_ACL_MDW.ACCELERATION_Y.isError || Thing.CMS.CMS_ACL_MDW.ACCELERATION_Z.isError"
                [AccelerationErrorLevel]="Thing.CMS.CMS_ACL_MDW.ACCELERATION_X.status"
                [AccelerationValue]="[Thing.CMS.CMS_ACL_MDW.ACCELERATION_X.TimeseriesData,
                                 Thing.CMS.CMS_ACL_MDW.ACCELERATION_Y.TimeseriesData,
                                 Thing.CMS.CMS_ACL_MDW.ACCELERATION_Z.TimeseriesData]">
</app-cm-sensors>
