<app-group-header [Heading]="'Fingerzeit' | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>

<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Zeit um auszufahren' | translate"
                      [Error]="TimeExtend11?.isError"
                      [ErrorLevel]="TimeExtend11?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[TimeExtend11?.TimeseriesData, TimeExtend12?.TimeseriesData, TimeExtend21?.TimeseriesData, TimeExtend22?.TimeseriesData, TimeExtend31?.TimeseriesData, TimeExtend32?.TimeseriesData]"
                      [Unit]="'ms'"
                      [Legend]="['Finger 1.1' | translate,'Finger 1.2' | translate,'Finger 2.1' | translate,'Finger 2.2' | translate,'Finger 3.1' | translate,'Finger 3.2' | translate]"
                      [Info]="'Die benötigte Zeit der Finger, um auszufahren.'"
                      [resizeEvent]="childResizeEvent">
    </app-default-card>


    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Zeit um einzufahren' | translate"
                      [Error]="TimeRetract11?.isError"
                      [ErrorLevel]="TimeRetract11?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[TimeRetract11?.TimeseriesData, TimeRetract12?.TimeseriesData, TimeRetract21?.TimeseriesData, TimeRetract22?.TimeseriesData, TimeRetract31?.TimeseriesData, TimeRetract32?.TimeseriesData]"
                      [Unit]="'ms'"
                      [Legend]="['Finger 1.1' | translate,'Finger 1.2' | translate,'Finger 2.1' | translate,'Finger 2.2' | translate,'Finger 3.1' | translate,'Finger 3.2' | translate]"
                      [Info]="'Die benötigte Zeit der Finger, um einzufahren.'"
                      [resizeEvent]="childResizeEvent">
    </app-default-card>

</div>
