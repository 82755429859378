<app-help-page-banner [items]="" [returnAddress]="'/HelpPage'"></app-help-page-banner>

<div class="container">
    <div>
        <h2>One Level Shuttle E</h2>
        <div>
            <h3 class="subheading" id="Temperatur">{{'Temperatur' | translate}}</h3>
            <img id="" src="{{environment.storageUrl}}/models3d/general/helpPage/equipment/OLS/temperature_{{urlLanguage}}.png"
                 width="100%">
        </div>
        <div style="margin-top: 30px;">
            <h3 class="subheading" id="SummeDerLeistungsdaten">{{'Fehlermeldungen' | translate}}</h3>
            <img id="" src="{{environment.storageUrl}}/models3d/general/helpPage/equipment/OLS/error_{{urlLanguage}}.png"
                 width="100%">
            <ul class="list-group" style="margin-top: 10px;">
                <li class="list-group-item">
                    <strong>{{'ID: '| translate}}</strong>{{'Nummer der Fehlermeldung.'| translate}}</li>
                <li class="list-group-item">
                    <strong>{{'Text: '| translate}}</strong>{{'Beschreibung der Fehlermeldung.' | translate}}</li>
                <li class="list-group-item">
                    <strong>{{'Motor 1 / Motor 2: ' | translate}}</strong>{{'Fehlernummer am Motor.' | translate}}</li>
                <li class="list-group-item">
                    <strong>{{'Zeit: ' | translate}}</strong>{{'Zeitpunkt, zu dem der Fehler aufgetreten ist.' | translate}}
                </li>
            </ul>
        </div>
        <div style="margin-top: 30px;">
            <h3 class="subheading" id="Bewegungen">{{'Bewegungen' | translate}}</h3>
            <img id="" src="{{environment.storageUrl}}/models3d/general/helpPage/equipment/OLS/movements_{{urlLanguage}}.png"
                 width="100%">
            <ul class="list-group" style="margin-top: 10px;">
                <li class="list-group-item">
                    <strong>{{'Aufgenommene Behälter: '| translate}}</strong>{{'Aufgenommene Behälter durch Teleskop seit Inbetriebnahme/ Wartung.'| translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'Abgesetzte Behälter: '| translate}}</strong>{{'Abgesetzte Behälter durch Teleskop seit Inbetriebnahme/ Wartung.' | translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'Teleskopbewegungen: ' | translate}}</strong>{{'Häufigkeit der Teleskopbewegungen seit Inbetriebnahme/ Wartung.' | translate}}
                </li>
            </ul>
        </div>
        <div style="margin-top: 30px;">
            <h3 class="subheading" id="Aufträge">{{'Aufträge' | translate}}</h3>
            <img id="" src="{{environment.storageUrl}}/models3d/general/helpPage/equipment/OLS/orders_{{urlLanguage}}.png"
                 width="100%">
            <ul class="list-group" style="margin-top: 10px;">
                <li class="list-group-item">
                    <strong>{{'Aufträge pro Stunde: '| translate}}</strong>{{'Anzahl der Ein-/Aus-/Umlagerungsaufträge pro Stunde.'| translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'Aufträge gesamt: '| translate}}</strong>{{'Gesamtanzahl der Ein-/Aus-/Umlagerungsaufträge.' | translate}}
                </li>
            </ul>
        </div>
        <div style="margin-top: 30px;">
            <h3 class="subheading" id="Zeit">{{'Zeit' | translate}}</h3>
            <img id="" src="{{environment.storageUrl}}/models3d/general/helpPage/equipment/OLS/time_{{urlLanguage}}.png"
                 width="100%">
            <ul class="list-group" style="margin-top: 10px;">
                <li class="list-group-item">
                    <strong>{{'Betriebsstunden: ' | translate}}</strong>{{'Wie lange ist das One-Level-Shuttle schon in Stunden seit Inbetriebnahme/ Wartung in Betrieb?' | translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'Fahrzeit: ' | translate}}</strong>{{'Wie lange ist das One-Level-Shuttle seit Inbetriebnahme/ Wartung in Stunden gefahren?' | translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'Wartezeit: ' | translate}}</strong>{{'Wie lange steht das One-Level-Shuttle in Stunden seit Inbetriebnahme/ Wartung?' | translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'Zeit ausgeschaltet: ' | translate}}</strong>{{'Wie lange war das das One-Level-Shuttle seit Inbetriebnahme/ Wartung in Stunden ausgeschalten?' | translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'Zeit Transfer von Regal auf Heber: ' | translate}}</strong>{{'Wie viele Millisekunden braucht das One-Level-Shuttle durchschnittlich, um vom Regal auf den Heber zu fahren?' | translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'Zeit Transfer von Heber in Regal: ' | translate}}</strong>{{'Wie viele Millisekunden braucht das One-Level-Shuttle durchschnittlich, um vom Heber auf das Regal zu fahren?' | translate}}
                </li>
            </ul>
        </div>
        <div style="margin-top: 30px;">
            <h3 class="subheading" id="Positionierung">{{'Positionierung' | translate}}</h3>
            <img id="" src="{{environment.storageUrl}}/models3d/general/helpPage/equipment/OLS/positioning_{{urlLanguage}}.png"
                 width="100%">
            <ul class="list-group" style="margin-top: 10px;">
                <li class="list-group-item">
                    <strong>{{'X-Position: ' | translate}}</strong>{{'In welchem Fach befindet sich das One-Level-Shuttle?' | translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'Y-Position: ' | translate}}</strong>{{'In welcher Ebene befindet sich das One-Level-Shuttle?' | translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'Letzte Motorposition: ' | translate}}</strong>{{'Gibt die reelle Position des One-Level-Shuttles an.' | translate}}
                </li>
            </ul>
        </div>
        <div style="margin-top: 30px;">
            <h3 class="subheading" id="Feinpositionierung">{{'Feinpositionierung' | translate}}</h3>
            <img id="" src="{{environment.storageUrl}}/models3d/general/helpPage/equipment/OLS/finepositioning_{{urlLanguage}}.png"
                 width="100%">
            <ul class="list-group" style="margin-top: 10px;">
                <li class="list-group-item">
                    <strong>{{'Feinpositionierung: ' | translate}}</strong>{{'Zeit die benötigt wird um sich vor einem Lagerfach zu positionieren.' | translate}}
                </li>
            </ul>
        </div>
        <div style="margin-top: 30px;">
            <h3 class="subheading" id="Fingerbewegungen">{{'Fingerbewegungen' | translate}}</h3>
            <img id="" src="{{environment.storageUrl}}/models3d/general/helpPage/equipment/OLS/fingermovements_{{urlLanguage}}.png"
                 width="100%">
            <ul class="list-group" style="margin-top: 10px;">
                <li class="list-group-item">
                    <strong>{{'Fingerbewegungen: ' | translate}}</strong>{{'Zwei Finger bilden immer ein Paar. Die Grafik zeigt die Anzahl der Fingerbewegungen an.' | translate}}
                </li>
            </ul>
        </div>
        <div style="margin-top: 30px;">
            <h3 class="subheading" id="Fingerzeit">{{'Fingerzeit' | translate}}</h3>
            <img id="" src="{{environment.storageUrl}}/models3d/general/helpPage/equipment/OLS/fingertime_{{urlLanguage}}.png"
                 width="100%">
            <ul class="list-group" style="margin-top: 10px;">
                <li class="list-group-item">
                    <strong>{{'Zeit zum Ausfahren: ' | translate}}</strong>{{'Die benötigte Zeit der Finger, um auszufahren.' | translate}}
                </li>
                <li class="list-group-item">
                    <strong>{{'Zeit zum Einfahren: ' | translate}}</strong>{{'Die benötigte Zeit der Finger, um einzufahren.' | translate}}
                </li>
            </ul>
        </div>
        <div style="margin-top: 30px; margin-bottom: 100px;">
            <h3 class="subheading" id="CmSensorDaten">{{'CM Sensor Daten' | translate}}</h3>
            <img id="" src="{{environment.storageUrl}}/models3d/general/helpPage/equipment/OLS/cmsensors_{{urlLanguage}}.png"
                 width="100%">
            <ul class="list-group" style="margin-top: 10px; margin-bottom: 100px;">
                <li class="list-group-item">
                    <strong>{{'CM Sensor: ' | translate}}</strong>{{'Daten zu dem Condition Monitoring Sensor.' | translate}}
                </li>
            </ul>
        </div>
    </div>
</div>


