<app-group-header [Heading]="'Strom' | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>

<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Teleskopmotor' | translate"
                      [Error]="TelescopeEngineError"
                      [ErrorLevel]="TelescopeEngineErrorLevel"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[TelescopeEngineValue]"
                      [Unit]="'A'"
                      [Legend]="['Teleskopmotor' | translate]"
                      [Info]="'Aktuelle Stromstärke am Teleskopmotor.'"
                      [resizeEvent]="childResizeEvent">
    </app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Fahrmotor' | translate"
                      [Error]="DriveEngineError"
                      [ErrorLevel]="DriveEngineErrorLevel"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[DriveEngineValue]"
                      [Unit]="'A'"
                      [Legend]="['Fahrmotor' | translate]"
                      [Info]="'Aktuelle Stromstärke am Fahrmotor.'"
                      [resizeEvent]="childResizeEvent">
    </app-default-card>

</div>
