
<div class="showError" *ngIf="!errorBool && this.error === undefined; else errorTemplate">
</div>

<div class="totalContainer" *ngIf="!loadingBool && this.newEntityStructure !== undefined ; else loading">
    <div class="componentContainer" *ngIf="!errorBool && this.newEntityStructure[0].children.length !== 0; else errorTemplate">
        <br>
        <div class="container">
            <div class="row">
                <div class="col-3">
                    <mat-slide-toggle *ngIf="auth.HealthReset" [checked]="editMode" (change)="enableEditMode($event)">
                        {{'Schwellenwert ändern' | translate}}
                    </mat-slide-toggle>
                </div>
                <div class="col-3">
                    <mat-checkbox *ngIf="auth.HealthReset" [disabled]="!editMode" [checked]="globalEditMode" [(ngModel)]="globalEditMode">
                        {{'Global anwenden' | translate}}</mat-checkbox>
                </div>
                <div class="col-5"></div>
                <div class="col-1">
                    <button mat-flat-button color="primary" *ngIf="auth.HealthReset && editMode" type="button" (click)="saveThresholds()">
                        <mat-icon>save</mat-icon>
                        Save
                    </button>
                </div>
            </div>
        </div>
        <br>

        <button *ngIf="false" type="button" (click)="expandLevelOne()">
            View Hierarchy
        </button>


        <!--TABLE OPERATIONS-->
        <table
            mat-table matSort
            (matSortChange)="announceSortChange($event)"
            class="mat-elevation-z8"
            [dataSource]="dataSource"
            width="100%"
        >
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>
                    <span [style.paddingLeft.px]="40"> Name </span>
                </th>
                <td mat-cell *matCellDef="let data">
                    <button mat-icon-button
                            [style.visibility]="!data.expandable ? 'hidden' : ''"
                            [style.marginLeft.px]="data.level * 32"
                            (click)="treeControl.toggle(data)">
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{treeControl.isExpanded(data) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                    </button>

                    <app-status-button
                    [node]="data"
                    [treeControl]="treeControl"
                    [dialog]="this.dialog"
                    [option]="'onlyName'"
                    (nodeSelection)="subscribeNodeSelection($event)"
                    ></app-status-button>
                </td>
            </ng-container>

            <ng-container matColumnDef="materialnummer">
                <th mat-header-cell *matHeaderCellDef
                >
                    {{'Materialnummer' | translate}}
                </th>
                <td
                    mat-cell *matCellDef="let element"
                >
                    {{element.materialnummer}}
                </td>
            </ng-container>

            <ng-container matColumnDef="avg_health">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                >
                    {{'Average Health(in %)' | translate}}
                </th>
                <td
                    mat-cell *matCellDef="let element"
                    [style.font-weight]="element.entity_type === 'bauteil' || element.entity_type === 'thing' ? 'bold' : 'normal'"
                >
                    {{element.avg_health.toFixed(2)}}
                </td>
            </ng-container>

            <ng-container matColumnDef="reset_counter">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                >
                    {{'Austausch' | translate}}
                </th>
                <td
                    mat-cell *matCellDef="let element"
                    [style.font-weight]="element.entity_type === 'bauteil' || element.entity_type === 'thing' ? 'bold' : 'normal'"

                >
                    {{element.reset_counter}}
                </td>
            </ng-container>

            <ng-container matColumnDef="worst_health_value">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                >
                    {{'Schlechteste Komponent' | translate}}
                </th>
                <td
                    mat-cell *matCellDef="let element"
                    [style.font-weight]="element.entity_type === 'bauteil' || element.entity_type === 'thing' ? 'bold' : 'normal'"
                >
                    {{element.worst_health_value}}
                </td>
            </ng-container>

            <ng-container matColumnDef="extend_counter">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                >
                    {{'Verschobene Wartung' | translate}}
                </th>
                <td
                    mat-cell *matCellDef="let element"
                    [style.font-weight]="element.entity_type === 'bauteil' || element.entity_type === 'thing' ? 'bold' : 'normal'"
                >
                    {{element.extend_counter}}
                </td>
            </ng-container>

            <ng-container matColumnDef="view_komponente">
                <th mat-header-cell *matHeaderCellDef
                ></th>
                <td
                    mat-cell *matCellDef="let element"
                >
                    <div *ngIf="element.data.entity_type === 'komponenten'">
                        <app-komponenten-view
                            [node]="element"
                            [dialog]="this.dialog"
                        ></app-komponenten-view>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="warning_button">
                <th mat-header-cell *matHeaderCellDef>
                </th>
                <td mat-cell *matCellDef="let data">

                    <app-status-button
                        [node]="data"
                        [treeControl]="treeControl"
                        [dialog]="this.dialog"
                        [option]="'onlyButton'"
                        (nodeSelection)="subscribeNodeSelection($event)"
                    ></app-status-button>
                </td>
            </ng-container>

            <!--EDIT MODE COLUMNS-->
            <ng-container matColumnDef="strecke">
                <th mat-header-cell *matHeaderCellDef
                >
                    {{'Strecke' | translate}}
                </th>
                <td
                    mat-cell *matCellDef="let element"
                >
                    <div *ngIf="element.messgroessen !== undefined && element.messgroessen['strecke'] !== undefined">
                        <mat-form-field >
                            <input
                                matInput type="number"
                                [(ngModel)]="element.messgroessen['strecke'].momentaner_grenzwert"
                                (change)="changeInWert('strecke', element)"
                            >
                        </mat-form-field>
                        <span>
                            {{element.messgroessen['strecke']['einheit'] | translate}}
                        </span>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="bewegungen">
                <th mat-header-cell *matHeaderCellDef
                >
                    {{'Bewegungen' | translate}}
                </th>
                <td
                    mat-cell *matCellDef="let element"
                >
                    <div *ngIf="element.messgroessen !== undefined && element.messgroessen['bewegungen'] !== undefined">
                        <mat-form-field >
                            <input
                                matInput
                                type="number"
                                [(ngModel)]="element.messgroessen['bewegungen'].momentaner_grenzwert"
                                (change)="changeInWert('bewegungen', element)"
                            >
                        </mat-form-field>
                        <span>
                            {{element.messgroessen['bewegungen']['einheit'] | translate}}
                        </span>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="intervall">
                <th mat-header-cell *matHeaderCellDef
                >
                    {{'Intervall' | translate}}
                </th>
                <td
                    mat-cell *matCellDef="let element"
                >
                    <div *ngIf="element.messgroessen !== undefined && element.messgroessen['intervall'] !== undefined">
                        <mat-form-field >
                            <input
                                matInput
                                type="number"
                                [(ngModel)]="element.messgroessen['intervall'].momentaner_grenzwert"
                                (change)="changeInWert('intervall', element)"
                            >
                        </mat-form-field>
                        <span>
                            {{element.messgroessen['intervall']['einheit'] | translate}}
                        </span>
                    </div>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayColumns"
                [ngClass]="{ 'highlighted': row === selectedNode }"
            ></tr>
        </table>


        <!--TREE OPERATIONS-->
        <!--<div *ngIf="this.newTableData !== undefined; else loading">-->
            <!--<mat-tree [dataSource]="dataSource" [treeControl]="treeControl"-->
                      <!--class="row-bg-gray">-->
                <!--&lt;!&ndash; This is the tree node template for leaf nodes &ndash;&gt;-->

                <!--<mat-tree-node *matTreeNodeDef="let node"-->
                               <!--matTreeNodePadding-->
                               <!--(click)="handleNodeClick(node)"-->
                <!--&gt;-->
                    <!--&lt;!&ndash; use a disabled button to provide padding for tree leaf &ndash;&gt;-->
                    <!--<app-tree-node-->
                        <!--[isLeaf]="true"-->
                        <!--[node]="node"-->
                        <!--[dialog]="this.dialog"-->
                        <!--style="width: 100%"-->
                        <!--[treeControl]="treeControl"-->
                        <!--[defaultViewMode]="editMode?'nodeLevelEdit':'default'"-->
                        <!--(nodeSelection)="subscribeNodeSelection($event)"-->
                    <!--&gt;</app-tree-node>-->

                <!--</mat-tree-node>-->
                <!--&lt;!&ndash; This is the tree node template for expandable nodes &ndash;&gt;-->
                <!--<mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>-->
                    <!--<app-tree-node-->
                        <!--[isLeaf]="false"-->
                        <!--[node]="node"-->
                        <!--[dialog]="this.dialog"-->
                        <!--[treeControl]="treeControl"-->
                        <!--[defaultViewMode]="editMode?'nodeLevelEdit':'default'"-->
                        <!--style="width: 100%"-->
                        <!--(nodeSelection)="subscribeNodeSelection($event)"-->
                    <!--&gt;</app-tree-node>-->
                <!--</mat-tree-node>-->
            <!--</mat-tree>-->
        <!--</div>-->
</div>
</div>
<br>

<ng-template #loading>
    <div *ngIf="!errorBool" class="row">
        <div class="col-lg-12 text-center">
            <div class="spinner-grow text-danger" role="status" style="height: 100px; width: 100px;">
                <span class="visually-hidden">Loading...</span>
            </div>
            <div style="font-size: 30px">
                {{'Daten werden abgerufen' | translate}}
            </div>
        </div>
    </div>
</ng-template>

<ng-template #errorTemplate>
    <div class="row">
        <div class="col-lg-12 text-center">
            <div style="font-size: 30px">
                {{'Thing-Health Daten konnten nicht abgerufen werden' | translate}}
            </div>
        </div>
    </div>
</ng-template>

<ng-template #warning>
    <div class="row">
        <div class="col-lg-12 text-center">
            <div style="font-size: 30px">
                {{'Thing selbst gilt nicht für die Wiederherstellung der Health' | translate}}
            </div>
        </div>
    </div>
</ng-template>

