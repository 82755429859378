<app-group-header [Heading]="'Status' | translate"
                  [chart]="chart"
                  [info]="info"
                  [noChart]="true"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>

<div class="card-group">
    <!--
            <div #cardWidthFull class="card rounded-0"
                 style="height: calc(100% - 50px)"
                 [class.border-danger]="OperatingModeError && OperatingModeErrorLevel === monitoringLevel.Error"
                 [class.border-warning]="OperatingModeError && OperatingModeErrorLevel === monitoringLevel.Warning"
                 [class.border-info]="OperatingModeError && OperatingModeErrorLevel === monitoringLevel.Info">
                <div class="card-header">
                    Betriebsart
                </div>
                <div class="card-body" [style.fontSize]="fontsizeFull" style="height: 100%; padding:5px; display: flex; justify-content: center; align-items: center">
                    {{OperatingModeValue | operatingMode: operationModes}}
                </div>
            </div>
    -->
    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Betriebsart' | translate"
                      [Error]="OperatingMode?.isError"
                      [ErrorLevel]="OperatingMode?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[OperatingMode?.TimeseriesData]"
                      [customTemplate]="custom"
                      [customNoData]="OperatingMode == null"
                      [Legend]="['Betriebsart' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <ng-template #custom>
        {{OperatingMode?.TimeseriesData.currentDatapoint.value | operatingMode: operationModes}}
    </ng-template>


    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Erste Fehlernummer' | translate"
                      [Error]="FirstErrorNumber?.isError"
                      [ErrorLevel]="FirstErrorNumber?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[FirstErrorNumber?.TimeseriesData]"
                      [Legend]="['Erste Fehlernummer' | translate]"
                      [Info]="'Erste Fehlernummer die zu weiteren Folgefehlern geführt hat.'"
                      [resizeEvent]="childResizeEvent"></app-default-card>
    <!--
    <div class="card rounded-0"
         style="height: calc(100% - 50px)"
         [class.border-danger]="FirstErrorNumberError && FirstErrorNumberErrorLevel === monitoringLevel.Error"
         [class.border-warning]="FirstErrorNumberError && FirstErrorNumberErrorLevel === monitoringLevel.Warning"
         [class.border-info]="FirstErrorNumberError && FirstErrorNumberErrorLevel === monitoringLevel.Info">

        <div class="card-header" style="padding:5px;">
            Erste Fehlernummer
        </div>
        <div class="card-body" [style.fontSize]="fontsizeFull" style="height: 100%; padding:5px; display: flex; justify-content: center; align-items: center">
            {{FirstErrorNumberValue}}
        </div>
    </div>
    -->

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Betriebsstunden' | translate"
                      [Error]="OperatingHours?.isError"
                      [ErrorLevel]="OperatingHours?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[OperatingHours?.TimeseriesData]"
                      [Unit]="'h'"
                      [MaintenanceValue]="OperatingHours?.ValueLastService"
                      [Legend]="['Auslagerung' | translate, 'Stand Wartung' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

</div>
