<div class="row">

    <div class="col-lg-12 subheading">
        <div class="row">
            <div class="col-12">
                <h3 id="All" style="float: left">{{'Cache Manager'}}</h3>
            </div>
        </div>
    </div>

    <div class="row">
        <button (click)="updateThingMonitor()" class="btn gapBot"
                style="margin-left: 20px">{{'Update Thingmonitor every Customer'}}</button>
        <button (click)="clearAllMenu()" class="btn gapBot">{{'Clear ALL Menu Cache'}}</button>
    </div>

    <div class="row" *ngIf="dataProvider.customer">
        <mat-form-field appearance="fill" class="col-lg-3; col-3">
            <mat-label>Customer</mat-label>
            <mat-select [(ngModel)]="selectedCustomer" value="{{selectedCustomer}}" #newActive>
                <mat-option *ngFor="let customer of dataProvider.customer"
                            [value]="customer.name">{{customer.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <button (click)="updateSingleThingMonitor(selectedCustomer)"
                class="btn gapBot">{{'Update Thingmonitor ' + selectedCustomer}}</button>
    </div>

    <div class="row">
        <div class="col-12">
            <app-custom-tile [heading]="'Cache list'">
                <table class="table table-striped">
                    <tr class="table-secondary">
                        <th>
                            ID
                        </th>
                        <th>
                            User Name
                        </th>
                        <th>
                            Fistname
                        </th>
                        <th>
                            Lastname
                        </th>
                        <th>

                        </th>
                    </tr>
                    <tr *ngFor="let cache of cachedList">
                        <th>
                            {{cache.Id}}
                        </th>
                        <th>
                            {{cache.UserName}}
                        </th>
                        <th>
                            {{cache.GivenName}}
                        </th>
                        <th>
                            {{cache.FamilyName}}
                        </th>
                        <th>
                            <button (click)="clearSingleMenu(cache)" class="btn">{{'Delete cache'}}</button>
                        </th>
                    </tr>
                </table>
            </app-custom-tile>
        </div>
    </div>

</div>
