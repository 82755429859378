<app-group-header [Heading]="'Spannsystem' | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>
<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Spanndruck Soll / Ist' | translate"
                      [Error]="ClampingPressure?.isError || ClampingPressureTarget?.isError"
                      [ErrorLevel]="ClampingPressure?.status"
                      [showChart]="true"
                      [showInfo]="info"
                      [Values]="[ClampingPressureTarget?.TimeseriesData, ClampingPressure?.TimeseriesData]"
                      [ChartType]="'gauge'"
                      [Unit]="'bar'"
                      [min]="0"
                      [max]="8"
                      [Legend]="['Soll' | translate, 'Ist' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>
    <!--
        <ng-template #custom>
            <ng-container *ngIf="ClampingPressureValue[0] != null && ClampingPressureValue[1] != null">
                {{ClampingPressureValue[0].currentDatapoint.value}} / {{ClampingPressureValue[1].currentDatapoint.value}}
            </ng-container>
        </ng-template>
    -->
    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Kolben Position Änderung' | translate"
                      [Error]="PistonPosChange?.isError"
                      [ErrorLevel]="PistonPosChange?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[PistonPosChange?.TimeseriesData]"
                      [Legend]="['Kolben Position Änderung' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>


    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Kolben Position Referenz' | translate"
                      [Error]="PistonPosRef?.isError"
                      [ErrorLevel]="PistonPosRef?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[PistonPosRef?.TimeseriesData]"
                      [Legend]="['Kolben Position Referenz' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>


    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Darstellung Kettenspanneinheit' | translate"
                      [Error]="null"
                      [ErrorLevel]="null"
                      [showChart]="chart"
                      [showInfo]="info"
                      [customTemplate]="customIMG"
                      [Legend]="['Kolben Position Referenz' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <ng-template #customIMG>
        <img [style.width]="width" [style.height]="height" class="card-img img-fluid" [src]="img"
             [alt]="'Darstellung Kettenspanneinheit'"/>
    </ng-template>


</div>
