import {Component, Input} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Tile} from '../tile';

@Component({
    selector: 'app-custom-tile',
    templateUrl: './custom-tile.component.html',
    styleUrls: ['./custom-tile.component.scss',
        '../tile.scss']
})
/**
 * Wird vorgesehen um beliebigen HTML Code in einer Kachel darzustellen
 * ZUR ZEIT NICHT FUNKTIONSFÄHIG
 */
export class CustomTileComponent extends Tile
{
    /**
     * Wert
     */
    @Input() value: any;

    /**
     *
     * @param sanitizer
     */
    constructor(public sanitizer: DomSanitizer)
    {
        super();
    }
}
