<app-group-header [Heading]="'Bewegungen' | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>

<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Aufgenommene Behälter' | translate"
                      [Error]="TotalPicks?.isError"
                      [ErrorLevel]="TotalPicks?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[TotalPicks?.TimeseriesData]"
                      [Legend]="['Aufgenommene Behälter' | translate]"
                      [Info]="'aufgenommene Behälter durch Teleskop seit Inbetriebnahme/Wartung'"
                      [resizeEvent]="childResizeEvent">
    </app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Abgesetzte Behälter' | translate"
                      [Error]="TotalDrops?.isError"
                      [ErrorLevel]="TotalDrops?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[TotalDrops?.TimeseriesData]"
                      [Legend]="['Abgesetzte Behälter' | translate]"
                      [Info]="'abgesetzte Behälter durch Teleskop seit Inbetriebnahme/Wartung'"
                      [resizeEvent]="childResizeEvent">
    </app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Teleskopbewegungen' | translate"
                      [Error]="TelescopeMovements?.isError"
                      [ErrorLevel]="TelescopeMovements?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[TelescopeMovements?.TimeseriesData]"
                      [Legend]="['Teleskopbewegungen' | translate]"
                      [Info]="'Häufigkeit der Teleskopbewegungen seit Inbetriebnahme/Wartung'"
                      [resizeEvent]="childResizeEvent">
    </app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Fahraufträge' | translate"
                      [Error]="XAxisMovements?.isError"
                      [ErrorLevel]="XAxisMovements?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[XAxisMovements?.TimeseriesData]"
                      [Legend]="['Fahraufträge' | translate]"
                      [Info]="'Anzahl der erhaltenen Fahraufträge seit Inbetriebnahme/Wartung'"
                      [resizeEvent]="childResizeEvent">
    </app-default-card>

</div>
