<div style="height: calc(100% - {{groupHeaderHeight}});"
     #card
     class="card rounded-0"
     [class.border-danger]="Error && ErrorLevel === monitoringLevel.Error"
     [class.border-warning]="Error && ErrorLevel === monitoringLevel.Warning"
     [class.border-info]="Error && ErrorLevel === monitoringLevel.Info">

    <div *ngIf="Heading != null" class="card-header">
        {{Heading}}
    </div>
    <div #cardbody class="card-body">

        <ng-container *ngIf="!showChart && !showInfo && customTemplate == null">

            <ng-container *ngIf="Values != null && !noValues; else noData">
                <ng-container *ngIf="MaintenanceValue == null;">
                    <div [style.fontSize]="fontsize" class="textParent">

                        <ng-container *ngIf="Values.length === 1 && Values[0] != null;">
                            <ng-container *ngIf="Values[0].currentDatapoint.value != null;">
                                <ng-container *ngIf="Type === 'number'">
                                    <div
                                            style="flex-grow: 1; text-align: center">{{Values[0].currentDatapoint.value | number:'1.0-2'}} {{Unit}}
                                    </div>
                                    <ng-container *ngIf="ShowDate">
                                        <div
                                                style="flex-grow: 1; text-align: center">{{Values[0].currentDatapoint.timestamp | date}}
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="Date">
                                        <div
                                                style="flex-grow: 1; text-align: center">{{Date.values[0]._value | date: 'dd.MM.yyyy'}}
                                        </div>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="Type === 'boolean'">
                                    <div
                                            style="flex-grow: 1; text-align: center">{{Values[0].currentDatapoint.value | boolean: PipeOptions}}
                                    </div>
                                    <ng-container *ngIf="ShowDate">
                                        <div
                                                style="flex-grow: 1; text-align: center">{{Values[0].currentDatapoint.timestamp | date}}
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="Date">
                                        <div
                                                style="flex-grow: 1; text-align: center">{{Date.values[0]._value | date: 'dd.MM.yyyy'}}
                                        </div>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="Type === 'text'">
                                    <div
                                            style="flex-grow: 1; text-align: center">{{Values[0].currentDatapoint.value}} {{Unit}}</div>
                                    <ng-container *ngIf="ShowDate">
                                        <div
                                                style="flex-grow: 1; text-align: center">{{Values[0].currentDatapoint.timestamp | date}}
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="Date">
                                        <div
                                                style="flex-grow: 1; text-align: center">{{Date.values[0]._value | date: 'dd.MM.yyyy'}}
                                        </div>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="Type === 'date'">
                                    <div
                                            style="flex-grow: 1; text-align: center">{{Values[0].currentDatapoint.value | date: 'HH:mm:ss dd.MM.yyyy'}}
                                    </div>
                                </ng-container>
                            </ng-container>

                        </ng-container>

                        <ng-container *ngIf="Values.length > 1">
                            <ng-container *ngIf="Values[0].currentDatapoint.value != null; else noData;">
                                <ul>
                                    <li *ngFor="let value of Values; let i = index;">
                                        <b>{{Legend[i]}}:</b> {{value.currentDatapoint.value | number:'1.2-2'}} {{Unit}}
                                    </li>
                                </ul>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngIf="MaintenanceValue != null">
                    <ng-container *ngIf="Values[1] == null">
                        <div class="card-group">
                            <div #triplecard class="card rounded-0"
                                 style="height: 100%; border-left:0;border-bottom:0;border-top:0;">

                                <div class="card-header" style="padding:5px; background: none; text-align:center">
                                    Insgesamt
                                </div>
                                <div class="card-body" [style.fontSize]="fontsizetriple"
                                     style="height: 100%; padding:5px; display: flex; justify-content: center; align-items: center">
                                    {{Values[0].currentDatapoint.value | number: '1.0-2'}} {{Unit}}
                                </div>
                            </div>
                            <div class="card rounded-0" style="height: 100%; border-top:0;border-bottom:0;">

                                <div class="card-header" style="padding:5px;background: none;text-align:center">
                                    Seit Wartung
                                </div>
                                <div class="card-body" [style.fontSize]="fontsizetriple"
                                     style="height: 100%; padding:5px;display: flex; justify-content: center; align-items: center">
                                    {{(Values[0].currentDatapoint.value | calcDifference: MaintenanceValue.value) | number:'1.0-2'}}
                                    {{Unit}}
                                </div>
                            </div>
                            <div class="card rounded-0" style="height: 100%; border: 0;">

                                <div class="card-header" style="padding:5px; background: none; text-align:center">
                                    Stand Wartung
                                </div>
                                <div class="card-body" [style.fontSize]="fontsizetriple"
                                     style="height: 100%; padding:5px;display: flex; justify-content: center; align-items: center">
                                    {{MaintenanceValue.value | number:'1.0-2'}} {{Unit}}
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="Values[0] != null && Values[1] != null">
                        <div class="card-group">
                            <div #triplecard class="card rounded-0"
                                 style="height: 100%; border-left:0;border-bottom:0;border-top:0;">

                                <div class="card-header" style="padding:5px; background: none; text-align:center">
                                    Insgesamt
                                </div>
                                <div class="card-body" [style.fontSize]="fontsizetriple"
                                     style="height: 100%; padding:5px; display: flex; justify-content: center; align-items: center">
                                    {{[Values[0].currentDatapoint, Values[1].currentDatapoint] | calcDistance: '1.2-2'}} {{Unit}}
                                </div>
                            </div>
                            <div class="card rounded-0" style="height: 100%; border-top:0;border-bottom:0;">

                                <div class="card-header" style="padding:5px;background: none;text-align:center">
                                    Seit Wartung
                                </div>
                                <div class="card-body" [style.fontSize]="fontsizetriple"
                                     style="height: 100%; padding:5px;display: flex; justify-content: center; align-items: center">
                                    {{([Values[0].currentDatapoint, Values[1].currentDatapoint] | calcDistance: '1.2-2' | calcDifference: MaintenanceValue.value) | number:'1.2-2'}}
                                    {{Unit}}
                                </div>
                            </div>
                            <div class="card rounded-0" style="height: 100%; border: 0;">

                                <div class="card-header" style="padding:5px; background: none; text-align:center">
                                    Stand Wartung
                                </div>
                                <div class="card-body" [style.fontSize]="fontsizetriple"
                                     style="height: 100%; padding:5px;display: flex; justify-content: center; align-items: center">
                                    {{MaintenanceValue.value | number:'1.2-2'}} {{Unit}}
                                </div>
                            </div>
                        </div>
                    </ng-container>


                </ng-container>
            </ng-container>


        </ng-container>


        <ng-container *ngIf="showChart && !showInfo">

            <ng-container *ngIf="chartData[0] != null && cardbody.offsetHeight > 0; else noData">
                <ng-container *ngIf="chartData[0][0] != null; else noData">
                    <ng-container *ngIf="ChartType == 'line'">
                        <app-linechart [legend]="Legend"
                                       [unit]="Unit"
                                       [value]="chartData"
                                       [resizeEvent]="childResizeEvent"
                                       [setOptions]="ChartOption">
                        </app-linechart>
                    </ng-container>

                    <ng-container *ngIf="ChartType == 'gauge'">
                        <app-gaugechart [legend]="Legend"
                                        [value]="textData"
                                        [name]="Heading"
                                        [unit]="Unit"
                                        [min]="min"
                                        [max]="max"
                                        [backgroundColor]="backgroundColor"
                                        [resizeEvent]="childResizeEvent"
                                        [setOptions]="ChartOption">
                        </app-gaugechart>
                    </ng-container>

                    <ng-container *ngIf="ChartType == 'bar'">
                        <app-barchart [legend]="Legend"
                                      [value]="chartData"
                                      [name]="Heading"
                                      [setOptions]="ChartOption">
                        </app-barchart>
                    </ng-container>

                    <ng-container *ngIf="ChartType == 'pie'">
                        <app-piechart [value]="chartData"
                                      [setOptions]="ChartOption">
                        </app-piechart>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="showInfo">
            {{Info}}
        </ng-container>

        <div *ngIf="customTemplate != null && ChartType != 'table' && !showInfo" [style.fontSize]="fontsize"
             class="textParent">
            <ng-container *ngIf="!customNoData; else noData"
                          [ngTemplateOutlet]="customTemplate">
            </ng-container>
        </div>

        <div style="overflow-y: auto" *ngIf="customTemplate != null && ChartType == 'table' && !showInfo"
             [style.width]="width" [style.height]="height">
            <ng-container *ngIf="!customNoData; else noData"
                          [ngTemplateOutlet]="customTemplate">
            </ng-container>
        </div>

    </div>

</div>

<ng-template #noData style="height: 100%;">
    <div class="text-center noData" [style.width]="width" [style.height]="height">
        <img title="Keine aktuellen Daten vorhanden!" [style.width]="width" [style.height]="height"
             [src]="environment.storageUrl + '/icons/cloud_off_black_18dp.png'"/>
    </div>
</ng-template>

<!--
     <div #heading class="col-lg-12 subheading" style="height:15%;">
        <h3 id="CurrentVoltage">{{'Spannung und Stromauswertung' | translate}}</h3>
    </div>

              <div class="card rounded-0">
            <div class="card-body">
                <div class="row" style="height: 100%;">
                    <app-linechart class="col-lg-6" style="height:90%;" [legend]="['Spannung Phase 1' | translate, 'Spannung Phase 2' | translate, 'Spannung Phase 3' | translate]"
                                   [value]="VoltageValue"
                                   [resizeEvent]="childResizeEvent">
                    </app-linechart>
                    <app-linechart class="col-lg-6" style="height:90%;" [legend]="['Spannung Phase 1' | translate, 'Spannung Phase 2' | translate, 'Spannung Phase 3' | translate]"
                                   [value]="CurrentValue"
                                   [resizeEvent]="childResizeEvent">
                    </app-linechart>
                </div>
            </div>
        </div>

    <app-line-tile class="col-lg-6" [heading]="'Spannung' | translate" [unit]="'(V)' | translate"
                   [error]="VoltageError"
                   [value]="VoltageValue"
                   [legend]="['Spannung Phase 1' | translate, 'Spannung Phase 2' | translate, 'Spannung Phase 3' | translate]"
                   [resizeEvent]="childResizeEvent">
    </app-line-tile>

    <app-line-tile class="col-lg-6" [heading]="'Strom' | translate" [unit]="'(A)' | translate"
                   [error]="CurrentError"
                   [value]="CurrentValue"
                   [legend]="['Stromstärke Phase 1' | translate, 'Stromstärke Phase 2' | translate, 'Stromstärke Phase 3' | translate]"
                   [resizeEvent]="childResizeEvent">
    </app-line-tile>
       <app-linechart class="col-lg-6" style="height:90%;" [legend]="['Spannung Phase 1' | translate, 'Spannung Phase 2' | translate, 'Spannung Phase 3' | translate]"
               [value]="CurrentValue"
               [resizeEvent]="childResizeEvent">
</app-linechart>
</div>
    -->
