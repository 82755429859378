import {Component, OnChanges, SimpleChanges} from '@angular/core';
import {BaseSubcomp} from '../../../base-subcomp';
import {PropertySet} from '../../../../core/model/helper-models/property-set.model';

@Component({
    selector: 'app-rbg-subcomp-parent',
    templateUrl: './rbg-subcomp-parent.component.html',
    styleUrls: ['./rbg-subcomp-parent.component.scss']
})
export class RbgSubcompParentComponent extends BaseSubcomp implements OnChanges
{
    Currentmeter: PropertySet;

    ngOnChanges(changes: SimpleChanges): void
    {

    }

}
