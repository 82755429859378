import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {MonitoringLevel} from '../../../core/model/MonitoringLevels.enum';
import {LimitSizeArrayModel} from '../../../core/model/helper-models/limit-size-array.model';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import { Property } from '../../../core/model/helper-models/property.model';
import {DataPoint} from '../../../core/model/helper-models/datapoint.model';

@Component({
    selector: 'app-ssdtm-clamping-chain',
    templateUrl: './ssdtm-clamping-chain.component.html',
    styleUrls: ['./ssdtm-clamping-chain.component.scss', './../card.scss']
})
export class SsdtmClampingChainComponent extends BaseViewElement implements OnChanges
{

    @Input() Spannsystem: PropertySet;

    ChainLength: Property<any>;

    stretchingOffsetPercent = new LimitSizeArrayModel<DataPoint<number>>(1000);
    stretchingWarningPercent = new LimitSizeArrayModel<DataPoint<number>>(1000);
    stretchingOffsetMillimeter = new LimitSizeArrayModel<DataPoint<number>>(1000);
    stretchingWarningMillimeter = new LimitSizeArrayModel<DataPoint<number>>(1000);

    constructor()
    {
        super('ssdtm-clamping-chain');
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit(result);
        this.chart = true;
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.Spannsystem == null) return;
        this.ChainLength = this.Spannsystem.properties.get('KETTENLAENGUNG_TRANSPONDER');

        if (this.stretchingOffsetPercent.values.length === 0)
        {
            for (const data of this.ChainLength.TimeseriesData.values)
            {
                const value = this.calcDehnung(+data.value);
                this.stretchingOffsetPercent.pushDataPoint(new DataPoint(Math.round(value.percent * 100) / 100, data.timestamp));
                // this.stretchingWarningPercent.pushDataPoint(new DataPoint<number>(1.5, data.timestamp));
                this.stretchingOffsetMillimeter.pushDataPoint(new DataPoint(Math.round(value.millimeter * 100) / 100, data.timestamp));
                // this.stretchingWarningMillimeter.pushDataPoint(new DataPoint<number>(1761, data.timestamp));
            }
        }

        this.ChainLength.TimeseriesData.valuePushed.subscribe(x =>
        {
            if (x == null) return;
            const value = this.calcDehnung(+x.value);


            if (value != null)
            {
                this.stretchingOffsetPercent.pushDataPoint(new DataPoint(Math.round(value.percent * 100) / 100,
                    x.timestamp));
                // this.stretchingWarningPercent.pushDataPoint(new DataPoint<number>(1.5, x.timestamp));
                this.stretchingOffsetMillimeter.pushDataPoint(new DataPoint(Math.round(value.millimeter * 100) / 100,
                    x.timestamp));
                // this.stretchingWarningMillimeter.pushDataPoint(new DataPoint<number>(1761, x.timestamp));
            }
        });

    }

    calcDehnung(phDelta): any
    {
        if (phDelta == null) return null;
        // Referenzhöhe
        const h0 = 114.977;
        // Hub Zylinder
        const hDelta = phDelta;
        // eff. Durchmesser Kettenrad 1
        const dk1 = 242.350;
        // eff. Durchmesser Kettenrad 2
        const dk2 = 242.350;
        // eff. Durchmesser Spannrad
        const dsp = 96.486;
        // Achsabstand Kettenräder
        const a = 470;
        // Verschiebung Kettenrad 2
        const xDelta = 13.25;
        // Referenzlänge Kette
        const l0 = 1735;

        // Achsabstand Kettenrad 1 zu Spannrad mm
        const ak1 = Math.sqrt(Math.pow((a / 2), 2) + Math.pow((h0 + hDelta), 2));
        // Achsabstand Kettenrad 2 zu Spannrad mm
        const ak2 = Math.sqrt(Math.pow((a / 2 + xDelta), 2) + Math.pow((h0 + hDelta), 2));
        // Winkel Tau 1 °
        const t1 = Math.asin((dk1 - dsp) / (2 * ak1)) * 180 / Math.PI;
        // Winkel Tau 2 °
        const t2 = Math.asin((dk2 - dsp) / (2 * ak2)) * 180 / Math.PI;
        // Umschlingung Kettenrad 1 °
        const phiK1 = 180 + (Math.asin((dk1 - dsp) / (2 * ak1)) * 180 / Math.PI) - (Math.atan((2 * (h0 + hDelta)) / a) * 180 / Math.PI);
        // Umschlingung Kettenrad 2 °
        const phiK2 = 180 + (Math.asin((dk1 - dsp) / (2 * ak2)) * 180 / Math.PI) - (Math.atan((2 * (h0 + hDelta)) / (a + 2 * xDelta)) * 180 / Math.PI);
        // Umschlingung Spannrad °
        const phiSp = 360 - phiK1 - phiK2;
        // Umschlungene Länge Kettenrad 1 mm
        const lU1 = (Math.PI * dk1 * phiK1) / 360;
        // Umschlungene Länge Kettenrad 2 mm
        const lU2 = (Math.PI * dk2 * phiK2) / 360;
        // Umschlungene Länge Kettenrad 3 mm
        const lU3 = (Math.PI * dsp * phiSp) / 360;
        // Schenkellänge 1 mm
        const ls1 = Math.cos(t1 * Math.PI / 180) * ak1;
        // Schenkellänge 2 mm
        const ls2 = Math.cos(t2 * Math.PI / 180) * ak2;
        // Kettenlänge Betrieb mm
        const l1 = a + xDelta + ls1 + ls2 + lU1 + lU2 + lU3;
        // Prozentuale Dehnung Kette %
        const epsilon = (1 - (l0 / l1)) * 100;

        return {
            millimeter: l1,
            percent: epsilon
        };
    }


}
