<app-group-header [Heading]="'Statistik Carrier' | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>
<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Freie Carrier' | translate"
                      [Error]="CarrierFree?.isError"
                      [ErrorLevel]="CarrierFree?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[CarrierFree?.TimeseriesData]"
                      [Legend]="['Freie Carrier' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Belegte Carrier' | translate"
                      [Error]="CarrierInUse?.isError"
                      [ErrorLevel]="CarrierInUse?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[CarrierInUse?.TimeseriesData]"
                      [Legend]="['Belegte Carrier' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Blockierte Carrier' | translate"
                      [Error]="CarrierBlocked?.isError"
                      [ErrorLevel]="CarrierBlocked?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[CarrierBlocked?.TimeseriesData]"
                      [Legend]="['Blockierte Carrier' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Gestörte Carrier' | translate"
                      [Error]="CarrierDisturbed?.isError"
                      [ErrorLevel]="CarrierDisturbed?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[CarrierDisturbed?.TimeseriesData]"
                      [Legend]="['Gestörte Carrier' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>
</div>
