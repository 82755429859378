import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NavigationService} from '../../../core/controller/services/navigation.service';
import {DataProviderService} from '../../../core/controller/services/data-provider.service';
import {DateService} from '../../../core/controller/services/date.service';
import {TranslateService} from '@ngx-translate/core';
import {LayoutService} from '../../../core/controller/services/layout.service';
import {BaseThing} from '../../base-thing';
import {UserSettingsService} from '../../../core/controller/services/user-settings.service';

@Component({
    selector: 'app-ssif',
    templateUrl: './ssif.component.html',
    styleUrls: ['./ssif.component.scss',
        '../../../../assets/css/template.scss']
})
export class SsifComponent extends BaseThing implements OnInit, OnDestroy
{
    /**
     * Konstruktor
     * @param route
     * @param _router
     * @param nav
     * @param data
     * @param date
     * @param _translate
     * @param _layout
     */
    constructor(public route: ActivatedRoute,
                private _router: Router,
                public nav: NavigationService,
                public data: DataProviderService,
                public date: DateService,
                private _translate: TranslateService,
                private _layout: LayoutService,
                private _user: UserSettingsService)
    {
        super(route, _router, nav, data, date, _translate, _layout, _user);
    }

    ngOnInit()
    {
        super.ngOnInit();
    }

    ngOnDestroy()
    {
        super.ngOnDestroy();
    }

}
