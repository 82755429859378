import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
/**
 * Service für historische Daten
 */
export class DateService
{
    /*
     *  Gibt an ob live Daten oder historisch Daten geholt werden
     */
    private _auto = true;
    get auto(): boolean
    {
        return this._auto;
    }

    set auto(pValue: boolean)
    {
        this._auto = pValue;
    }

    /*
     *  Startdatum für historische Daten
     */
    private _startDate: Date;
    get startDate(): Date
    {
        return this._startDate;
    }

    set startDate(pValue: Date)
    {
        this._startDate = pValue;
    }

    /*
     *  Enddatum für historische Daten
     */
    private _endDate: Date;
    get endDate(): Date
    {
        return this._endDate;
    }

    set endDate(pValue: Date)
    {
        this._endDate = pValue;
    }
}
