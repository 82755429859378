import {Component, Input} from '@angular/core';
import {DataProviderService} from '../../controller/services/data-provider.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '../../controller/services/authentication.service';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
/**
 * Komponente des Headers
 */
export class HeaderComponent
{
    @Input() selectedLanguage: string;

    dropdownLang: string[];

    /**
     * Konstruktor
     * @param data
     */
    constructor(public data: DataProviderService,
                private auth: AuthenticationService,
                public translate: TranslateService)
    {
        this.dropdownLang = translate.getLangs();
        console.log(this.dropdownLang);
    }

    /**
     * Logged den aktuellen User aus
     */
    public logout()
    {
        this.auth.logout(false);
        // window.location.href = '/do/logout';
    }

    /**
     * Swtiching the language safely
     * */
    public switchLanguage(){
        this.translate.use(this.selectedLanguage);
    }

    protected readonly environment = environment;
}
