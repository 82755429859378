import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {MonitoringLevel} from '../../../core/model/MonitoringLevels.enum';
import {LimitSizeArrayModel} from '../../../core/model/helper-models/limit-size-array.model';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
    selector: 'app-sscrr-statistic',
    templateUrl: './sscrr-statistic.component.html',
    styleUrls: ['./sscrr-statistic.component.scss', './../card.scss']
})
export class SscrrStatisticComponent extends BaseViewElement implements OnInit, OnChanges
{

    @Input() Statistic: PropertySet;

    AmountFlapMovements: Property<any>;
    CarrierInUse: Property<any>;


    constructor()
    {
        super('sscrr-statistic');
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit(result);
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.Statistic == null) return;
        this.AmountFlapMovements = this.Statistic.properties.get('ANZAHL_DER_KLAPPENBEWEGUNGEN');
        this.CarrierInUse = this.Statistic.properties.get('CARRIER_BELEGT');
    }
}
