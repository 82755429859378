import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import * as $ from 'jquery';
import {Configurations} from '../../../configurations';
import {Company} from '../../model/company.model';
import {TabItem} from '../../model/helper-models/tab-item.model';
import {CLocation} from '../../model/location.model';
import {CSystem} from '../../model/system.model';
import {Thing} from '../../model/thing.model';
import {DataProviderService} from './data-provider.service';
import {filter} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {CustomerDetail} from '../../model/customer-detail.model';
import {environment} from '../../../../environments/environment';

@Injectable()
/*
 * Service der die Navigation regelt
 */
export class NavigationService
{
    public get ShowHeader(): boolean
    {
        return this._ShowHeader;
    }

    public set ShowHeader(value: boolean)
    {
        this._ShowHeader = value;
    }

    public get TabsOneRow(): boolean
    {
        return this._TabsOneRow;
    }

    public set TabsOneRow(value: boolean)
    {
        this._TabsOneRow = value;
    }

    public get NavOpen()
    {
        return this._NavOpen;
    }

    public set NavOpen(value)
    {
        this._NavOpen = value;
    }

    public get MonitoringNavigation()
    {
        return this._MonitoringNavigation;
    }

    public set MonitoringNavigation(value)
    {
        this._MonitoringNavigation = value;
    }

    constructor(private _data: DataProviderService,
                private _router: Router,
                private _route: ActivatedRoute,
                private _translate: TranslateService)
    {
        this.TabsOneRow = true;

        this._router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((nav: NavigationEnd) =>
            {
                const url = nav.url.split('/');
                const thing: Thing = this._data.getThing(url[url.length - 1]);

                if (thing == null || url.includes('Health')) return;

                this.createTab(thing.displayName, this.buildThingUrl(thing), true, false);
            });
    }

    /**
     * Blendet den Header ein und aus.
     */
    private _ShowHeader = true;

    private myFunc: () => void;

    /**
     * Gibt an ob Tabs in eine Reihe passen oder ob sie zu Dropdown umgestellt werden müssen
     */
    private _TabsOneRow: boolean;

    /**
     * Navigation offen oder geschlossen
     */
    private _NavOpen = 'hidden';

    /**
     * Gibt, an ob das Monitoring angezeigt wird, um Tab in Tableiste zu färben
     */
    private _MonitoringNavigation = false;

    public possibleOneRow;

    onSomethingHappended(fn: () => void)
    {
        this.myFunc = fn;
        // from now on, call myFunc wherever you want inside this service
    }

    public buildCustomUrl(pCustom: CustomerDetail): string
    {
        return '/' + CustomerDetail.name;
    }

    /**
     * Baut den URL String für eine Company
     * @param pCompany
     */
    public buildCompanyUrl(pCompany: Company): string
    {
        return '/' + pCompany.name;
    }

    /**
     * Baut den URL String für eine location
     * @param pLocation
     */
    public buildLocationUrl(pLocation: CLocation): string
    {
        return '/' + pLocation.parentCompany.name + '/' + pLocation.name;
    }

    /**
     * Baut den URL String für eine system
     * @param pSystem
     */
    public buildSystemUrl(pSystem: CSystem): string
    {
        return '/' + pSystem.parentLocation.parentCompany.name + '/' + pSystem.parentLocation.name + '/' + pSystem.name;
    }

    /**
     * Baut den URL String für ein thing
     * @param pThing
     */
    public buildThingUrl(pThing: Thing): string
    {
        if (pThing.name === 'Dashboard' || pThing.name === 'Testfahrt')
        {
            return '/' + pThing.parentSystem.parentLocation.parentCompany.name + '/' + pThing.parentSystem.parentLocation.name + '/'
                + pThing.parentSystem.name + '/' + pThing.id;
        }

        return '/' + pThing.parentSystem.parentLocation.parentCompany.name + '/' + pThing.parentSystem.parentLocation.name + '/'
            + pThing.parentSystem.name + '/' + pThing.type + '/' + pThing.id;
    }

    /**
     * Baut die URL eines things, navigiert zu diesem und erstellt ein neues Tab
     * @param pThing
     */
    public navigateToThing(pThing: Thing): void
    {
        // Quickfix. muss eigentlich bei addDashboardTabToFirstIndex gesetzt werden
        if (this._data.activeCompany == null)
        {
            this._data.activeCompany = pThing.parentSystem.parentLocation.parentCompany;
        }
        if (this._data.activeLocation == null)
        {
            this._data.activeLocation = pThing.parentSystem.parentLocation;
        }
        if (this._data.activeSystem == null)
        {
            this._data.activeSystem = pThing.parentSystem;
        }

        const url = this.buildThingUrl(pThing);

        this.createTab(pThing.displayName, url);
    }

    /**
     * Baut die Url je nach übergabe entweder customer, location oder system
     * @param pActive
     */
    public buildCustomerDetailURLs(pActive: any): string
    {
        let url: string;

        switch (pActive.type)
        {
            case 'COMPANY':
                url = this.buildCompanyUrl(pActive);
                break;
            case 'LOCATION':
                url = this.buildLocationUrl(pActive);
                break;
            case 'SYSTEM':
                url = this.buildSystemUrl(pActive);
                break;
            // ToDo Abklären ob so ok
            case 'CUSTOM':
                url = this.buildCustomUrl(pActive);
                break;
            default:
                url = '/';
                break;
        }

        return url;
    }

    /**
     * Setzt aktiven customer, location und Anlage anhand der aktuellen URL im Browser.
     */
    public setActiveCustomerDetails(): void
    {
        const url = decodeURI(this._router.url).split('/');


        if (url.length > 1)
        {
            this._data.activeCompany = this._data.customer.find(cus => cus.name === url[1]);

            if (this._data.activeCompany == null) return;
        }
        else
        {
            this._data.activeCompany = null;
        }

        if (url.length > 2)
        {
            this._data.activeLocation = Array.from(this._data.activeCompany.locations.values()).find(cus => cus.name === url[2]);
        }
        else
        {
            this._data.activeLocation = null;
        }

        if (url.length > 3)
        {
            this._data.activeSystem = Array.from(this._data.activeLocation.systems.values()).find(cus => cus.name === url[3]);
        }
        else
        {
            this._data.activeSystem = null;
        }
    }

    /**
     * Navigiert anhand der URL zur dazugehörigen monitoring Komponente.
     */
    public navigateToCard(): void
    {
        if (this._data.activeSystem != null)
        {
            this._router.navigateByUrl(this.buildCustomerDetailURLs(this._data.activeSystem));
        }
        else if (this._data.activeLocation != null)
        {
            this._router.navigateByUrl(this.buildCustomerDetailURLs(this._data.activeLocation));
        }
        else if (this._data.activeCompany != null)
        {
            this._router.navigateByUrl(this.buildCustomerDetailURLs(this._data.activeCompany));
        }
        else
        {
            this._router.navigateByUrl('/');
        }
    }

    /**
     * Setzt die aktiven CustomerDetails zurück
     * @param pNavElement
     */
    public navigateBack(pNavElement: string): void
    {
        switch (pNavElement)
        {
            case 'Company':
                this._data.activeCompany = null;
                this._data.activeLocation = null;
                this._data.activeSystem = null;
                break;
            case 'Location':
                this._data.activeLocation = null;
                this._data.activeSystem = null;
                break;
            case 'System':
                this._data.activeSystem = null;
                break;
        }
    }

    /**
     * Erzeugt ein neues Tab, wenn active system gesetzt ist
     * @param pName : Tab label
     * @param pPath : component name bzw. pfad Menu item oder thingtype (MLS, RGB usw.)
     * @param pWithoutNavigate : Gibt an ob direkt zum erstellten Tab navigiert werdne soll.
     **/
    public createTab(pName: string, pPath: string, pWithoutNavigate = false, pToggle = true): void
    {
        this.addDashboardTabToFirstIndex();

        this._translate.get(pName).subscribe(Name =>
        {
            const navLink = new TabItem(Name, pPath, '', '');
            const tabExists = this._data.activeSystem.currentTabs.findIndex(link => navLink.path === link.path) > -1;
            if (!tabExists)
            {
                this._data.activeSystem.currentTabs.push(navLink);
                this.storeTabs();
            }

            if (!pWithoutNavigate)
            {
                this._router.navigate([pPath]);
            }

            if (pToggle)
            {
                this.toggleNav();
            }

            this.tabCalc();
        });
    }

    /**
     * Prüft ob active system gesetzt ist, wenn ja dann wird dashboard-Tab an der erste Stelle hinzugefügt
     */
    public addDashboardTabToFirstIndex(): void
    {
        const dashboardTab = new TabItem('Dashboard', this.buildThingUrl(this._data.activeSystem.dashboard), '', '');
        const tabExists = this._data.activeSystem.currentTabs.findIndex(link => dashboardTab.path === link.path) > -1;
        if (tabExists) return;

        this._data.activeSystem.currentTabs.unshift(dashboardTab);
        this.storeTabs();
    }

    /**
     * Speichert alle Tabs in den Local Storage.
     */
    public storeTabs(): void
    {
        const systems = [];

        this._data.customer.forEach(customer =>
        {
            customer.locations.forEach(location =>
            {
                location.systems.forEach(system  =>
                {
                    const systemTabs = {
                        Customer: customer.name,
                        Location: location.name,
                        System: system.name,
                        Tabs: system.currentTabs
                    };

                    systems.push(systemTabs);
                });
            });
        });

        localStorage.setItem(Configurations.LocStoTabs, JSON.stringify(systems));
    }

    /**
     * Holt Tabs aus dem Local Storage und befüllt Tableisten.
     */
    public retrieveTabs(): void
    {
        const systems = JSON.parse(localStorage.getItem(Configurations.LocStoTabs));

        if (systems == null) return;

        for (const sys of systems)
        {
            const foundCustomer = this._data.customer.find(x => x.name === sys.Customer);

            if (foundCustomer == null) continue;

            const foundLocation = Array.from(foundCustomer.locations.values()).find(x => x.name === sys.Location);

            if (foundLocation == null) continue;

            const foundSystem = Array.from(foundLocation.systems.values()).find(x => x.name === sys.System);

            if (foundSystem == null) continue;

            foundSystem.currentTabs = sys.Tabs;
        }
    }

    /**
     * Öffnet und schließt Navigation links
     **/
    public toggleNav(): void
    {
        this.NavOpen = this.NavOpen === 'hidden' ? 'visible' : 'hidden';
        if (this.NavOpen === 'hidden')
        {
            document.getElementById('main').style.marginLeft = '-13px';
            // $('#main').attr('margin-left', '-13px');
            $('#menu-button').attr('src', environment.storageUrl + '/icons/menu.png');
            document.getElementById('nav-opener').style.width = '50px';
        }
        else
        {
            document.getElementById('main').style.marginLeft = '185px';
            $('#menu-button').attr('src', environment.storageUrl + '/icons/close.png');
            document.getElementById('nav-opener').style.width = '200px';
            this.myFunc();
        }
    }

    /**
     * Berechnet auf Basis der Fenster- und Tabbreite ob die Tabs in einer Reihe
     * oder in einem Menü dargestellt werden.
     **/
    public tabCalc(): void
    {
        const windowSize = window.innerWidth;
        const sumWidth = 56 * 3;

        this.possibleOneRow = Math.round((windowSize - sumWidth) / 190 - 1);

        if (this._data.activeSystem == null) return;

        this.TabsOneRow = this._data.activeSystem.currentTabs.length < this.possibleOneRow;
    }

    /**
     * Baut den URL String für die ThingHealthPage für die Navigation aus dem Datepicker
     * @param pThing
     */
    public buildThingHealthpageUrl(pThing: Thing): string
    {
        return '/' + pThing.parentSystem.parentLocation.parentCompany.name + '/' + pThing.parentSystem.parentLocation.name + '/'
            + pThing.parentSystem.name + '/' + pThing.thingType + '/' + pThing.id + '/Health';
    }

    /**
     * Baut die URL eines things, navigiert zu dessen HealthThing und erstellt ein neues Tab
     * für die Navigation aus dem Datepicker
     * @param pThing
     */
    public navigateToThingHealthpage(pThing: Thing): void
    {
        const url = this.buildThingHealthpageUrl(pThing);
        // TODO clean delete similar tab
        this._data.activeSystem.currentTabs = this._data.activeSystem.currentTabs.filter(tab => tab.path != this.buildThingHealthpageUrlFromOverview(pThing));

        // TODO gescheiter tabname
        this.createTab(pThing.displayName + ' HP', url);
    }

    /**
     * Baut den URL String für die ThingHealthPage für die Navigation aus dem health-overview Component
     * @param pThing
     */
    public buildThingHealthpageUrlFromOverview(pThing: Thing): string
    {
        return '/' + pThing.parentSystem.parentLocation.parentCompany.name + '/' + pThing.parentSystem.parentLocation.name + '/'
            + pThing.parentSystem.name + '/' + 'Health/' + pThing.thingType + '/' + pThing.id;
    }

    /**
     * Baut die URL eines things, navigiert zu dessen HealthThing und erstellt ein neues Tab
     * Für die Navigation aus dem health-overview Component
     * @param pThing
     */
    public navigateToThingHealthpageFromOverview(pThing: Thing): void
    {
        const url = this.buildThingHealthpageUrlFromOverview(pThing);
        // TODO clean delete similar tab
        this._data.activeSystem.currentTabs = this._data.activeSystem.currentTabs.filter(tab => tab.path != this.buildThingHealthpageUrl(pThing));

        // TODO gescheiter tabname
        this.createTab(pThing.displayName + ' HP', url);
    }

    public navigateToTestdrive()
    {
        let front_url = this.buildSystemUrl(this._data.activeSystem);
        front_url += '/insight';
        this.createTab('Testfahrt', front_url, true);
        this._router.navigate([front_url], {relativeTo: this._route});
    }

    public navigateToThingLayout(thing: Thing): void {
        this._router.navigateByUrl('/layout', { state: { id: thing.id } });
    }
}
