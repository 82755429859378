<div class="card rounded-0">

    <div class="card-header">
        <ul class="nav nav-pills card-header-pills">
            <li class="nav-item" style="padding-left:10px;">
                {{heading}}
            </li>
            <li *ngIf="ain != null" class="nav-item ms-auto" style="padding-right:10px;">
                <a [href]="ain" target="_blank">{{'Kundenportal' | translate }}</a>
            </li>
        </ul>
    </div>
    <div #imgContainer class="card-body" style="display:flex; align-items:center; justify-content:center;">
        <img [style.width]="width" [style.height]="height" class="card-img img-fluid" [src]="src" [alt]="alt"/>
    </div>
</div>
