import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
    selector: 'app-bot-status',
    templateUrl: './bot-status.component.html',
    styleUrls: ['./bot-status.component.scss',
        './../card.scss']
})
export class BotStatusComponent extends BaseViewElement implements OnInit, OnDestroy, OnChanges
{

    @Input() ActualData: PropertySet;

    LADEZYKLEN: Property<any>;
    BETRIEBSDAUER: Property<any>;
    KAPAZITAET: Property<any>;
    EINSCHALTDAUER: Property<any>;

    constructor()
    {
        super('bot-status');
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.ActualData != null)
        {
            this.LADEZYKLEN = this.ActualData.properties.get('LADEZYKLEN');
            this.BETRIEBSDAUER = this.ActualData.properties.get('BETRIEBSDAUER');
            this.KAPAZITAET = this.ActualData.properties.get('KAPAZITÄT');
            this.EINSCHALTDAUER = this.ActualData.properties.get('EINSCHALTDAUER');
        }

    }


    onResize(result: any)
    {
        this.childResizeEvent.emit(result);
    }

}
