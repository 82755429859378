<app-image *ngIf="widget === 'image'"
           [resizeEvent]="resizeEvent"
           [heading]="'Darstellung BOT' | translate"
           [src]="Thing.model3D"
           [equipmentNumber]="Thing.equipmentNumber"
           [alt]="'Darstellung BOT' | translate"
           [ain]="Thing.ainUrl"
           [width]="'auto'">
</app-image>

<app-bot-volcurtemp *ngIf="widget === 'bot-volcurtemp'"
                    [resizeEvent]="resizeEvent"
                    [ActualData]="Thing.propertySets.get('ACTUALDATA')">
</app-bot-volcurtemp>

<app-bot-status *ngIf="widget === 'bot-status'"
                [resizeEvent]="resizeEvent"
                [ActualData]="Thing.propertySets.get('ACTUALDATA')">
</app-bot-status>
