import { Component, OnInit } from '@angular/core';
import {BaseSubcomp} from '../../../base-subcomp';

@Component({
  selector: 'app-seq-subcomp-parent',
  templateUrl: './seq-subcomp-parent.component.html',
  styleUrls: ['./seq-subcomp-parent.component.scss']
})
export class SeqSubcompParentComponent extends BaseSubcomp implements OnInit
{
  CmError = false;

  ngOnInit(): void
  {
    this.Thing.outdatedProperties.subscribe((x: any) =>
    {
      if (x == null) return;
      this.CmError = x.some((str) => str.includes('DS_ROT'))
          || x.some((str) => str.includes('DS_ANGACL'))
          || x.some((str) => str.includes('DS_ACL'))
          || x.some((str) => str.includes('DS_ENV'));
      console.log(this.CmError + ':  cmerroror');
    });

  }

}