import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
    selector: 'app-status',
    templateUrl: './status.component.html',
    styleUrls: ['./status.component.scss',
        './../card.scss']
})
export class StatusComponent extends BaseViewElement implements OnChanges
{

    operationModes = new Map<number, string>([[0, 'keine'], [1, 'Hand'], [2, 'Halbautomatik'], [3, 'Automatik'], [4, 'Messfahrt']]);

    @Input() Status: PropertySet;

    OperatingMode: Property<any>;
    OperatingHours: Property<any>;
    FirstErrorNumber: Property<any>;

    constructor()
    {
        super('status');
    }


    onResize(result: any)
    {
        this.childResizeEvent.emit();
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.Status == null) return;
        this.OperatingMode = this.Status.properties.get('OPERATING_MODE');
        this.OperatingHours = this.Status.properties.get('OPERATING_HOURS');
        this.FirstErrorNumber = this.Status.properties.get('FIRST_ERROR_NUMBER');
    }

}
