import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {SelectionModel} from '@angular/cdk/collections';
import {MatSort, Sort} from '@angular/material/sort';
import {DataProviderService} from '../../../core/controller/services/data-provider.service';
import {Thing} from '../../../core/model/thing.model';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {MatTableDataSource} from '@angular/material/table';
import {Subscription} from 'rxjs';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {AuthenticationService} from '../../../core/controller/services/authentication.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-thing-health',
    templateUrl: './thing-health.component.html',
    styleUrls: ['./thing-health.component.scss',
        '../../../core/view/tile/tile.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed, void', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
            transition('void <=> expanded', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})

export class ThingHealthComponent implements OnInit, OnDestroy
{
    public thing: Thing;

    public dataSource = new MatTableDataSource<any>();
    public displayedColumns: string[] = ['select', 'bauteil', 'komponente', 'materialnummer', 'messgroesse', 'liveValue', 'thresholdValue', 'einheit', 'percentage'];
    public selection = new SelectionModel<any>(true, []);
    public loadingBool = true;
    public errorBool = false;
    public editThresholdsDataSource = new MatTableDataSource<any>();
    public globalChange = false;
    public AeSnapshot = new Array<any>();
    public pickedDate = 0;

    private routerSubscription: Subscription;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    public hasMaintenanceInstructions: boolean;

    public editingModeBool: boolean;
    public globalChangeBool: boolean;
    public editThresholdsArray: any[];

    public newTableData: any;
    columnsToDisplay = ['bauteil_name_de', 'komponenten_name_de', 'materialnummer', 'extendCounter', 'resetCounter', 'worstHealthValue', 'averageHealthValue'];
    columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
    expandedElement: any;
    isMaintenanceUserBool = false;

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private _toastr: ToastrService,
                private http: HttpClient,
                public _data: DataProviderService,
                private _translate: TranslateService,
                private picker: MatDatepickerModule,
                public auth: AuthenticationService)
    {
        this.routerSubscription = router.events.subscribe((val) =>
        {
            if (this.thing == null) return;

            if (val instanceof NavigationEnd)
            {
                this.dataSource = new MatTableDataSource<any>();
                this.selection = new SelectionModel<any>(true, []);
                this.loadingBool = true;
                this.errorBool = false;
                this.editThresholdsDataSource = new MatTableDataSource<any>();
                this.globalChange = false;
                this.AeSnapshot = new Array<any>();
                this.editingModeBool = false;
                this.globalChangeBool = false;
                this.ngOnInit();
            }
        });
    }


    ngOnInit()
    {
        this.thing = this._data.activeSystem.getAllThings().find(thing => thing.id === this.activatedRoute.snapshot.paramMap.get('id'));
        this.getNewHealthTableData();
        this.isMaintenanceUserBool = this.isMaintenanceUser();

    }

    public saveThresholdData()
    {
        this.loadingBool = true;
        if (this.globalChangeBool)
        {
            this.updateThresholdsForThingType();
        }
        else
        {
            this.updateThresholdsForThing();
        }
    }

    public async updateThresholdsForThingType()
    {
        const headers = await this.auth.getDefaultHttpHeaders();

        const url = environment.apiUrl + environment.apiVersion + '/health/updateThresholdForThingType';
        const requestJson = {
            'SystemId': this._data.activeSystem.id,
            'ThingType': this.thing.type,
            'Thresholds': this.editThresholdsArray
        };
        this.http.post(url, requestJson, {headers: headers}).subscribe((res: any) =>
        {
            this.getNewHealthTableData();
        });
    }

    public async updateThresholdsForThing()
    {
        const headers = await this.auth.getDefaultHttpHeaders();

        const url = environment.apiUrl + environment.apiVersion + '/health/updateThresholdForThing';
        const requestJson = {
            'SystemId': this._data.activeSystem.id,
            'ThingId': this.thing.id,
            'Thresholds': this.editThresholdsArray
        };
        this.http.post(url, requestJson, {headers: headers}).subscribe((res: any) =>
        {
            this.getNewHealthTableData();
        });
    }

    public formatEditThresholdsArray()
    {
        this.editThresholdsArray = [];
        this.newTableData.forEach((row) =>
        {
            row.messgroessen.forEach((messgroesse) =>
            {
                const rowJson = {
                    'bauteil': row.bauteil_name_de,
                    'komponente': row.komponenten_name_de,
                    'messgroesse': messgroesse.messgroessen_name,
                    'grenzwert': messgroesse.momentaner_grenzwert,
                    'einheit': messgroesse.einheit
                };
                this.editThresholdsArray.push(rowJson);
            });
        });
    }

    // TODO pls fix auth
    public isMaintenanceUser()
    {
        return true;
        let bool = false;
        const auth = JSON.parse(localStorage.getItem('auth'));
        auth.scopes.forEach(scope =>
        {
            if (scope.includes('.GalileoIoT_HealthReset'))
            {
                bool = true;
            }
        });
        return bool;
    }

    expandColumn(element)
    {
        if (this.expandedElement === element)
        {
            this.expandedElement = null;
        }
        else
        {
            this.expandedElement = element;
        }
    }

    sortNewData(sort: Sort)
    {
        this.expandedElement = null;
        console.log(this.expandedElement);
        let data = this.newTableData.slice();
        if (sort.active || !(sort.direction === ''))
        {
            data = data.sort((a, b) =>
            {
                const isAsc = sort.direction === 'asc';
                switch (sort.active)
                {
                    case 'bauteil_name_de':
                        return compare(a.bauteil_name_de, b.bauteil_name_de, isAsc);
                    case 'komponenten_name_de':
                        return compare(a.komponenten_name_de, b.komponenten_name_de, isAsc);
                    case 'materialnummer':
                        return compare(a.materialnummer, b.materialnummer, isAsc);
                    case 'worstHealthValue':
                        return compare(a.worstHealthValue, b.worstHealthValue, isAsc);
                    case 'averageHealthValue':
                        return compare(a.averageHealthValue, b.averageHealthValue, isAsc);
                    case 'extendCounter':
                        return compare(a.extendCounter, b.extendCounter, isAsc);
                    case 'resetCounter':
                        return compare(a.resetCounter, b.resetCounter, isAsc);
                    default:
                        return 0;
                }
            });
        }
        this.newTableData = data;
        return;
    }

    private async updateComponent(materialnummer)
    {
        const headers = await this.auth.getDefaultHttpHeaders();

        const url = environment.apiUrl + environment.apiVersion + '/health/getThingHealth' +
            '?CustomerId=' + this._data.activeCompany.id +
            '&LocationId=' + this._data.activeLocation.id +
            '&SystemId=' + this._data.activeSystem.id +
            '&ThingId=' + this.thing.id;

        this.http.get(url, {headers: headers}).subscribe((res: any) =>
        {
            let i = 0;
            let tableDataIndex = 0;
            // find index in tableData
            this.newTableData.forEach(tableDataRow =>
            {
                if (tableDataRow.materialnummer === materialnummer)
                {
                    tableDataIndex = i;
                }
                i++;
            });
            res.forEach(resRow =>
            {
                if (resRow.materialnummer === materialnummer)
                {
                    this.newTableData[tableDataIndex].averageHealthValue = resRow.averageHealthValue;
                    this.newTableData[tableDataIndex].extendCounter = resRow.extendCounter;
                    this.newTableData[tableDataIndex].healthMaintenance = resRow.healthMaintenance;
                    this.newTableData[tableDataIndex].messgroessen = resRow.messgroessen;
                    this.newTableData[tableDataIndex].resetCounter = resRow.resetCounter;
                    this.newTableData[tableDataIndex].worstHealthValue = resRow.worstHealthValue;
                }
            });
        });
    }

    private async extendMaintenanceDate(bauteil, komponente, materialnummer)
    {
        const headers = await this.auth.getDefaultHttpHeaders();

        const requestJson = {
            'Username': this.auth.ActiveUser.name,
            'SystemId': this._data.activeSystem.id,
            'ThingId': this.thing.id,
            'ThingType': this.thing.type,
            'Bauteil': bauteil,
            'Komponente': komponente,
            'Materialnummer': materialnummer,
            'Month': 12
        };
        if (confirm('Bitte bestätigen Sie die Verschiebung der Wartung des Bauteils ' + bauteil + ' ' + komponente))
        {
            this.http.post(environment.apiUrl + environment.apiVersion + '/health/extendMaintenance', requestJson, {headers: headers}).subscribe(res =>
            {
                // this.loadingBool = true;
                // this.getNewHealthTableData();
                this.updateComponent(materialnummer);
                console.log(this.newTableData);

            });
        }
    }

    private async shortenMaintenanceDate(bauteil, komponente, materialnummer)
    {
        const headers = await this.auth.getDefaultHttpHeaders();

        const requestJson = {
            'Username': this.auth.ActiveUser.name,
            'SystemId': this._data.activeSystem.id,
            'ThingId': this.thing.id,
            'ThingType': this.thing.type,
            'Bauteil': bauteil,
            'Komponente': komponente,
            'Materialnummer': materialnummer,
            'Month': -12
        };
        if (confirm('Bitte bestätigen Sie die Verschiebung der Wartung des Bauteils ' + bauteil + ' ' + komponente))
        {
            this.http.post(environment.apiUrl + environment.apiVersion + '/health/extendMaintenance', requestJson, {headers: headers}).subscribe(res =>
            {
                // this.loadingBool = true;
                // this.getNewHealthTableData();
                this.updateComponent(materialnummer);
                console.log(this.newTableData);

            });
        }
    }

    private async resetComponent(bauteil, komponente, materialnummer)
    {
        const headers = await this.auth.getDefaultHttpHeaders();

        const requestJson = {
            'Username': this.auth.ActiveUser.name,
            'SystemId': this._data.activeSystem.id,
            'ThingId': this.thing.id,
            'ThingType': this.thing.thingType,
            'Bauteil': bauteil,
            'Komponente': komponente,
            'Materialnummer': materialnummer,
            'isFrontendRequest': true
        };
        if (confirm('Bitte bestätigen Sie, dass das Bauteil ' + bauteil + ' ' + komponente + ' Ausgetauscht wurde'))
        {
            this.http.post(environment.apiUrl + environment.apiVersion + '/health/resetComponent', requestJson, {headers: headers}).subscribe(res =>
            {
                // this.loadingBool = true;
                // this.getNewHealthTableData();
                this.updateComponent(materialnummer);
            });
        }
    }

    private async getNewHealthTableData()
    {
        const headers = await this.auth.getDefaultHttpHeaders();

        const url = environment.apiUrl + environment.apiVersion + '/health/getThingHealth' +
            '?CustomerId=' + this._data.activeCompany.id +
            '&LocationId=' + this._data.activeLocation.id +
            '&SystemId=' + this._data.activeSystem.id +
            '&ThingId=' + this.thing.id;

        this.http.get(url, {headers: headers}).subscribe(res =>
        {
            this.newTableData = res;
            this.formatEditThresholdsArray();
            this.loadingBool = false;
        });
    }

    ngOnDestroy()
    {
        this.routerSubscription.unsubscribe();
    }
}

function compare(a: number | string, b: number | string, isAsc: boolean)
{
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
