import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataProviderService} from '../../controller/services/data-provider.service';
import {DateService} from '../../controller/services/date.service';
import {GetThingDataService} from '../../controller/services/get-thing-data.service';
import {Thing} from '../../model/thing.model';
import {NavigationService} from '../../controller/services/navigation.service';
import {ActivatedRoute} from '@angular/router';
import {LayoutService} from '../../controller/services/layout.service';
import {Configurations} from '../../../configurations';
import {UserSettingsService} from '../../controller/services/user-settings.service';
import { AuthenticationService } from '@core-services//authentication.service';


@Component({
    selector: 'app-datepicker-popup',
    templateUrl: './datepicker-popup.component.html',
    styleUrls: ['./datepicker-popup.component.scss']
})

export class DatepickerPopupComponent implements OnInit
{
    // TODO Workaround, auslagerung später in einen Service
    @Input() thing: Thing;

    @Output() layoutReset = new EventEmitter<string>();

    // Gibt an ob HealthButton mitangezeigt wird oder nicht
    public healthButton = false;
    public layoutBtn = false;

    /**
     * Frühster Zeitpunkt um historische Daten zu holen
     * Im Moment vor 3 Monaten da Daten dann in den ColdStore geschoben werden
     */
    public get min(): Date
    {
        return new Date();
    }

    public get max(): Date
    {
        return new Date();
    }

    /**
     * Start und Endzeit für Historische Daten
     * Beim setzen der Zeit wird die Applikation auf historisch gesetellt
     */
    private time: Array<Date>;

    public get Time(): any
    {
        return this.time;
    }

    /*
     * Berechnet Zeitspanne in der Daten geholt werden sollen
     */
    public set Time(pValue: any)
    {
        this._dateService.startDate = pValue[0];
        this._dateService.endDate = pValue[1];

        this._dateService.startDate.setSeconds(0);
        this._dateService.endDate.setSeconds(0);

        this.time = pValue;

        if (this._dateService.auto)
        {
            this._dateService.auto = false;
            // Alle Historic
            this.thing.setHistoric();

            this._data.things.forEach(thing =>
            {
                thing.setHistoric();
            });

            this._getData.callOnce();

            Configurations.PullType.next('historic');
        }
        else
        {
            // Alle clearen
            this._data.things.forEach(thing =>
            {
                thing.clearSets();
            });

            // Aktuelles thing Pullen
            this._getData.callOnce();

            Configurations.PullType.next('historic');
        }
    }

    constructor(private _dateService: DateService,
                private _getData: GetThingDataService,
                private _data: DataProviderService,
                public _nav: NavigationService,
                private _user: UserSettingsService,
                private activatedRoute: ActivatedRoute,
                private auth: AuthenticationService,
                public layout: LayoutService)
    {
        this.checkThingtype();
    }

    ngOnInit()
    {
        this.layoutBtn = this.auth.isAdmin();
    }


    public resetLayout()
    {
        this.layout.deleteLayout(this._user.User.azureId, this.thing.id).subscribe();
        this.layoutReset.emit('reset');
    }

    /**
     * Setzt die Applikation auf Auto
     */
    public setAuto()
    {
        this._dateService.auto = true;

        // Alle Live setzen
        this._data.things.forEach(thing =>
        {
            //thing.clearSets();
            thing.setLive();

        });

        // timer für aktuelles neu Starten
        this._getData.restartTimer();

        Configurations.PullType.next('live');
    }

    /**
     * Prüft ob Thinghealth angezeigt werden soll
     */
    checkThingtype()
    {
        const id = this.activatedRoute.snapshot.paramMap.get('id');

        if (id == null) return;

        // extra lokale variable, weil chrome sich komisch verhalten hat
        let healthButton = true;
        const activeCompany = this._data.activeCompany.name;
        this._data.things.forEach(thing =>
        {
            if (thing.id === id && ((thing.thingType === 'MLS') || (thing.thingType === 'OLS') || (thing.thingType === 'OLSE')) &&
                (activeCompany === 'Häcker Küchen GmbH & Co. KG' || activeCompany === 'Gebhardt Fördertechnik GmbH' || activeCompany === 'KW automotive GmbH'))
            {
                healthButton = true;
            }
            // special case VCV
            if (thing.id === id && (thing.thingType === 'VCV') && (activeCompany === 'Häcker Küchen GmbH & Co. KG'))
            {
                healthButton = true;
            }
            // special cases für Kunden mit vereinzelten Thingtypes mit HP Anbindung
            if ((thing.id === id && (thing.thingType === 'OLSE') && activeCompany === 'Leuze electronic GmbH & Co. KG') ||
                (thing.id === id && (thing.thingType === 'MLS') && activeCompany === 'wenglor sensoric GmbH'))
            {
                healthButton = true;
            }
        });
        this.healthButton = healthButton;
    }
}
