import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'calcDifference'
})
export class CalcDifferencePipe implements PipeTransform
{
    /**
     * Nimmt zwei formatierte Strings und wandelt diese zurück in Zahlen
     * Zieht anschließend die Zahlen voneiander ab. (Seit letzter Wartung)
     * @param pCurrent
     * @param pMaintenance
     *//*
    transform(pCurrent: string, pMaintenance: string): any
    {
        const numCurrent = this.parseDecimalPipe(pCurrent);
        const numMain = this.parseDecimalPipe(pMaintenance);

        if (isNaN(numCurrent) || isNaN(numMain)) return null;

        return numCurrent - numMain;
    }*/

    transform(pCurrent: number, pMaintenance: number): number
    {
        const parsedCurrent = +String(pCurrent).replace(',', '.');

        if (isNaN(parsedCurrent) || isNaN(+pMaintenance)) return null;

        return parsedCurrent - pMaintenance;
    }

    /**
     * Konvertiert einen NummernString (deutsches Format) zurück in eine Zahl
     * @param pNumberString
     */
    public parseDecimalPipe(pNumberString: string): number
    {
        if (pNumberString == null) return NaN;

        let result = 0;

        if (pNumberString.indexOf('.') < 0)
        {
            return parseFloat(pNumberString.replace(',', '.'));
        }
        else
        {
            const splitted = pNumberString.split('.');

            for (let i = 0; i < splitted.length - 1; i++)
            {
                result += parseInt(splitted[i]) * Math.pow(1000, splitted.length - i - 1);
            }

            result += parseFloat(splitted[splitted.length - 1].replace(',', '.'));
        }

        return result;
    }
}
