import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ViewportScroller} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-thing-banner',
    templateUrl: './thing-banner.component.html',
    styleUrls: ['./thing-banner.component.scss']
})
/**
 * Komponente in der der Banner für die Thingunterseiten generiert wird
 */
export class ThingBannerComponent implements OnInit, AfterViewInit
{
    public isOneLine = true;

    @Input() items: Array<[string, string]>;
    itemsWithSeperators: Array<[string, string]>;

    @ViewChild('banner') banner: ElementRef;

    constructor(private _translate: TranslateService, private _viewportScroller: ViewportScroller)
    {
    }

    ngOnInit(): void
    {
        if (this.items == null) return;
        this.itemsWithSeperators = [];

        for (let i = 0; i < this.items.length; i++)
        {
            this._translate.get(this.items[i][0]).subscribe(x => this.items[i][0] = x);

            this.itemsWithSeperators.push(this.items[i]);
            if (i < this.items.length - 1) this.itemsWithSeperators.push(null);
        }
    }

    ngAfterViewInit(): void
    {
        // Checkt ob die Bannerelemente in eine Reihe passen
        this.isOneLine = this.banner.nativeElement.offsetHeight <= 40;
    }

    scrollToAnchor(elementId): void
    {
        const content = document.getElementById('content');
        const element = document.getElementById(elementId);
        const headerOffset = 25;
        // Abstand zum Seitenanfang
        const topOffset = element.parentElement.parentElement.offsetTop - headerOffset;

        content.scrollTo({
            top: topOffset,
            behavior: 'smooth'
        });
    }
}
