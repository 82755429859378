import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NavigationService} from '../../../core/controller/services/navigation.service';
import {DataProviderService} from '../../../core/controller/services/data-provider.service';
import {DateService} from '../../../core/controller/services/date.service';
import {TranslateService} from '@ngx-translate/core';
import {LayoutService} from '../../../core/controller/services/layout.service';
import {UserSettingsService} from '../../../core/controller/services/user-settings.service';
import {BaseThing} from '../../base-thing';

@Component({
  selector: 'app-seq',
  templateUrl: './seq.component.html',
  styleUrls: ['./seq.component.scss',
    '../../../../assets/css/template.scss']
})
export class SeqComponent extends BaseThing implements OnInit, OnDestroy
{
  /**
   * Zustände
   */
  operationModes = new Map<number, string>([[0, 'keine'], [1, 'Hand'], [2, 'Halbautomatik'], [3, 'Automatik'], [4, 'Messfahrt']]);

  constructor(public route: ActivatedRoute,
              private _router: Router,
              public nav: NavigationService,
              public data: DataProviderService,
              public date: DateService,
              private _translate: TranslateService,
              private _layout: LayoutService,
              private _user: UserSettingsService)
  {
    super(route, _router, nav, data, date, _translate, _layout, _user);
  }


  ngOnInit()
  {
    super.ngOnInit();

    if (this.thing == null) return;

  }

  ngOnDestroy()
  {
    super.ngOnDestroy();
  }
}
