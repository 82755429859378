import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Configurations} from '../../../../configurations';
import {DataProviderService} from '../../../controller/services/data-provider.service';
import {NavigationService} from '../../../controller/services/navigation.service';
import {Company} from '../../../model/company.model';
import {CLocation} from '../../../model/location.model';
import {environment} from '../../../../../environments/environment';

@Component({
    selector: 'app-monitoring-location',
    templateUrl: './monitoring-location.component.html',
    styleUrls: ['./monitoring-location.component.scss',
        '../monitoring.scss']
})
/**
 * Komponente in der die Standort Cards angezeigt werden
 */
export class MonitoringLocationComponent implements OnInit, OnDestroy, AfterViewInit
{
    /**
     * Kunde dessen Standorte angezegit werden
     */
    public company: Company;

    public isOneLine = true;

    @ViewChild('banner') banner: ElementRef;

    public locations: Array<CLocation>;

    /**
     * Konstruktor
     * @param data
     * @param nav
     * @param _route
     */
    constructor(public data: DataProviderService,
                public nav: NavigationService,
                private _route: ActivatedRoute)
    {
    }

    /**
     *  Setzt die aktiven Customer Details und holt den Kunden
     */
    ngOnInit()
    {
        Configurations.InitaliseComplete.subscribe(x =>
        {
            if (x)
            {
                this._route.paramMap.subscribe(params =>
                {
                    this.nav.setActiveCustomerDetails();

                    this.company = this.data.customer.find(cus => cus.name === params.get('company'));
                    this.locations = Array.from(this.company.locations.values());
                });

                this.data.activeSystem = null;

                this.nav.MonitoringNavigation = true;
            }
        });
    }

    ngAfterViewInit(): void
    {
        if (this.banner == null) return;
        this.isOneLine = this.banner.nativeElement.offsetHeight <= 40;
    }

    /**
     * Setzt Monitoring Navigation auf false
     */
    ngOnDestroy(): void
    {
        this.nav.MonitoringNavigation = false;
    }

    protected readonly Array = Array;
    protected readonly environment = environment;
}
