<app-group-header [Heading]="'Allgemein' | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>

<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Anzahl Not Stopps' | translate"
                      [Error]="ANZAHL_NOT_STOPPS?.isError"
                      [ErrorLevel]="ANZAHL_NOT_STOPPS?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[ANZAHL_NOT_STOPPS?.TimeseriesData]"
                      [Legend]="['Anzahl Not Stopps' | translate]"
                      [Unit]="'Anzahl'"
                      [Info]="''"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Gleichstrom Lasttrennschalter Schaltspiel' | translate"
                      [Error]="DC_LASTTRENNSCHALTER_SCHALTSPIELE?.isError"
                      [ErrorLevel]="DC_LASTTRENNSCHALTER_SCHALTSPIELE?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[DC_LASTTRENNSCHALTER_SCHALTSPIELE?.TimeseriesData]"
                      [Legend]="['Gleichstrom Lasttrennschalter Schaltspiel' | translate]"
                      [Unit]="'?'"
                      [Info]="''"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Leistungsverstärker Drehzahl' | translate"
                      [Error]="LEISTUNGSVERSTAERKER_DREHZAHL?.isError"
                      [ErrorLevel]="LEISTUNGSVERSTAERKER_DREHZAHL?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[LEISTUNGSVERSTAERKER_DREHZAHL?.TimeseriesData]"
                      [Legend]="['Leistungsverstärker Drehzahl' | translate]"
                      [Unit]="'rpm'"
                      [Info]="''"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Geschwindigkeit' | translate"
                      [Error]="GESCHWINDIGKEIT?.isError"
                      [ErrorLevel]="GESCHWINDIGKEIT?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[GESCHWINDIGKEIT?.TimeseriesData]"
                      [Legend]="['Geschwindigkeit' | translate]"
                      [Unit]="'m/s'"
                      [Info]="''"
                      [resizeEvent]="childResizeEvent"></app-default-card>
</div>
