<app-help-page-banner [items]="" [returnAddress]="'/HelpPage'"></app-help-page-banner>
<div class="container">

    <h2>{{'Testfahrt' | translate}}</h2>
    <h3 class="subheading">{{'Übersicht' | translate}}</h3>
    <p>{{'Die Übersichts-Seite der Testfahrt zeigt neben der Testauswertung auch Daten wie die Anzahl Umrichterdaten, Anzahl der Testfahrten uvm...' | translate}}</p>
    <p>{{'Es ist möglich, nach verschiedenen Geräten zu filtern, um genauere Daten zu erhalten.' | translate}}</p>
    <img id="testDriveOverview"
         src="{{environment.storageUrl}}/models3d/general/helpPage/equipmentGeneral/testDrive/testDriveOverview2_de.png" class="w-100">
     
     <p>{{'1. Menü Struktur' | translate}}</p>
     <p>{{'2. Lager/Lagerbereichsauswahl (falls vorhanden)' | translate}}</p>
     <p>{{'3. Gerätetyp Auswahl' | translate}}</p>
     <p>{{'4. Geräte/Gerät Auswahl' | translate}}</p>
     <p>{{'5. Statistikwert gesamt' | translate}}</p> 
     <p>{{'6. Healthbewertung (Datum)' | translate}}</p>
     <p>{{'7. Healthbewertung (Zeitverlauf)' | translate}}</p>

    <h3 class="subheading">{{'Umrichterdaten (SEW)' | translate}}</h3>
    <p>{{'Diese Informationsseite gibt Aufschluss über verschiedene Motordaten des Geräts, wie z.B. Momentan- und Ausgangsstrom, Vergleich zwischen Soll- und Ist-Drehzahl und Zwischenkreisspannung.' | translate}}</p>
    <p>{{'Eine Zeitleiste ermöglicht es, einen Zeitraum der letzten Testfahrten anzuzeigen.' | translate}}</p>
    <p>{{'In den Grafiken kann dann per Click auf das gewünschte Datum gefiltert werden.' | translate}}</p>
    <img id="testDriveConverterData"
         src="{{environment.storageUrl}}/models3d/general/helpPage/equipmentGeneral/testDrive/testDriveConverterData_de.png" class="w-100">

    <h3 class="subheading">{{'CMS-Daten' | translate}}</h3>
    <p>{{'Die CMS-Übersicht zeigt Informationen über Schwingungen/Beschleunigung und Winkelgeschwindigkeiten des Geräts an.' | translate}}</p>
    <p>{{'Eine Zeitleiste ermöglicht es, einen Zeitraum der letzten Testfahrten anzuzeigen.' | translate}}</p>
    <p>{{'In den Grafiken kann dann per Click auf das gewünschte Datum gefiltert werden.' | translate}}</p>
    <img id="testDriveCmsData"
         src="{{environment.storageUrl}}/models3d/general/helpPage/equipmentGeneral/testDrive/testDriveCmsData_de.png" class="w-100">

    <h3 class="subheading">{{'SPS-Daten' | translate}}</h3>
    <p>{{'Über die SPS-Übersicht lassen Informationen über Momentanstrom, Geschwindigkeit, Drehzahl und Position des Geräts anzeigen.' | translate}}</p>
    <p>{{'Eine Zeitleiste ermöglicht es, einen Zeitraum der letzten Testfahrten anzuzeigen.' | translate}}</p>
    <p>{{'In den Grafiken kann dann per Click auf das gewünschte Datum gefiltert werden.' | translate}}</p>
    <img id="testDriveSpsData"
         src="{{environment.storageUrl}}/models3d/general/helpPage/equipmentGeneral/testDrive/testDriveSpsData_de.png" class="w-100">

    <h3 class="subheading">{{'Threshold Evaluation' | translate}}</h3>
    <p>{{'Mithilfe der Schwellschwertauswertung kann gesehen werden, an welchen Tagen, welche Testfahrt Probleme verursachte. Durch das Filtern des Geräts und nach Eingabe des gewünschten Zeitraums aktualisieren sich die Grafiken automatisch.' | translate}}</p>
    <p>{{'Die Grafiken zeigen verschiedene Abweichungen von Soll- und Ist-Werten an, können aber auch bspw. Informationen über den Stromverbrauch liefern.' | translate}}</p>
    <p>{{'Eine Gesundheits-Ampel zeigt an, wie viele Testfahrten in dem ausgewählten Zeitraum gut, auffällig oder schlecht liefen.' | translate}}</p>
    <img id="testDriveThresholdEvaluation"
         src="{{environment.storageUrl}}/models3d/general/helpPage/equipmentGeneral/testDrive/testDriveThresholdEvaluation_de.png" class="w-100">


</div>
