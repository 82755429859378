<app-lsc-scan-information *ngIf="widget === 'lsc-scan-information'"
                          [resizeEvent]="resizeEvent"
                          [AverageReadSinceDeliveryError]="Thing.LEUZE_GENERAL_INFORMATION.AVERAGE_SINCE_DELIVERY.isError"
                          [AverageReadSinceDeliveryErrorLevel]="Thing.LEUZE_GENERAL_INFORMATION.AVERAGE_SINCE_DELIVERY.status"
                          [AverageReadSinceDeliveryValue]="Thing.LEUZE_GENERAL_INFORMATION.AVERAGE_SINCE_DELIVERY.TimeseriesData"
                          [AverageReadSinceResetError]="Thing.LEUZE_GENERAL_INFORMATION.AVERAGE_SINCE_RESET.isError"
                          [AverageReadSinceResetErrorLevel]="Thing.LEUZE_GENERAL_INFORMATION.AVERAGE_SINCE_RESET.status"
                          [AverageReadSinceResetValue]="Thing.LEUZE_GENERAL_INFORMATION.AVERAGE_SINCE_RESET.TimeseriesData"
                          [StatusError]="Thing.LEUZE_GENERAL_INFORMATION.DEVICE_STATUS.isError"
                          [StatusErrorLevel]="Thing.LEUZE_GENERAL_INFORMATION.DEVICE_STATUS.status"
                          [StatusValue]="Thing.LEUZE_GENERAL_INFORMATION.DEVICE_STATUS.TimeseriesData"
                          [SerialnumberError]="Thing.LEUZE_GENERAL_INFORMATION.SERIAL_NUMBER.isError"
                          [SerialnumberErrorLevel]="Thing.LEUZE_GENERAL_INFORMATION.SERIAL_NUMBER.status"
                          [SerialnumberValue]="Thing.LEUZE_GENERAL_INFORMATION.SERIAL_NUMBER.TimeseriesData">
</app-lsc-scan-information>

<app-good-bad-reads *ngIf="widget === 'good-bad-reads'"
                    [resizeEvent]="resizeEvent"
                    [GoodReadsError]="Thing.LEUZE_GENERAL_INFORMATION.OK_READING_COUNT.isError"
                    [GoodReadsErrorLevel]="Thing.LEUZE_GENERAL_INFORMATION.OK_READING_COUNT.status"
                    [GoodReadsValue]="Thing.LEUZE_GENERAL_INFORMATION.OK_READING_COUNT.TimeseriesData"
                    [BadReadsError]="Thing.LEUZE_GENERAL_INFORMATION.BAD_READING_COUNT.isError"
                    [BadReadsErrorLevel]="Thing.LEUZE_GENERAL_INFORMATION.BAD_READING_COUNT.status"
                    [BadReadsValue]="Thing.LEUZE_GENERAL_INFORMATION.BAD_READING_COUNT.TimeseriesData">
</app-good-bad-reads>

<app-scan-quality *ngIf="widget === 'scan-quality'"
                  [resizeEvent]="resizeEvent"
                  [ScanQualityError]="Thing.LEUZE_GENERAL_INFORMATION.LAST_SCAN_BARCODE_QUALITY.isError"
                  [ScanQualityErrorLevel]="Thing.LEUZE_GENERAL_INFORMATION.LAST_SCAN_BARCODE_QUALITY.status"
                  [ScanQualityValue]="Thing.LEUZE_GENERAL_INFORMATION.LAST_SCAN_BARCODE_QUALITY.TimeseriesData">
</app-scan-quality>

<app-last-scan *ngIf="widget === 'last-scan'"
               [resizeEvent]="resizeEvent"
               [StartPositionError]="Thing.LEUZE_GENERAL_INFORMATION.LAST_SCAN_START_POSITION_DEGREE.isError"
               [StartPositionErrorLevel]="Thing.LEUZE_GENERAL_INFORMATION.LAST_SCAN_START_POSITION_DEGREE.status"
               [StartPositionValue]="Thing.LEUZE_GENERAL_INFORMATION.LAST_SCAN_START_POSITION_DEGREE.TimeseriesData"
               [EndPositionError]="Thing.LEUZE_GENERAL_INFORMATION.LAST_SCAN_STOP_POSITION_DEGREE.isError"
               [EndPositionErrorLevel]="Thing.LEUZE_GENERAL_INFORMATION.LAST_SCAN_STOP_POSITION_DEGREE.status"
               [EndPositionValue]="Thing.LEUZE_GENERAL_INFORMATION.LAST_SCAN_STOP_POSITION_DEGREE.TimeseriesData"
               [ScanQualityError]="Thing.LEUZE_GENERAL_INFORMATION.LAST_SCAN_BARCODE_QUALITY.isError"
               [ScanQualityErrorLevel]="Thing.LEUZE_GENERAL_INFORMATION.LAST_SCAN_BARCODE_QUALITY.status"
               [ScanQualityValue]="Thing.LEUZE_GENERAL_INFORMATION.LAST_SCAN_BARCODE_QUALITY.TimeseriesData">
</app-last-scan>
