import {TranslateService} from '@ngx-translate/core';
import {Inject, Injectable} from '@angular/core';
import {OwlDateTimeIntl} from '@danielmoncada/angular-datetime-picker';

// here is the default text string
@Injectable()
export class DefaultIntl extends OwlDateTimeIntl
{
    /** A label for the up second button (used by screen readers).  */
    upSecondLabel = 'Plus eine Sekunde';

    /** A label for the down second button (used by screen readers).  */
    downSecondLabel = 'Minus eine Sekunde';

    /** A label for the up minute button (used by screen readers).  */
    upMinuteLabel = 'Plus eine Minute';

    /** A label for the down minute button (used by screen readers).  */
    downMinuteLabel = 'Minus eine Minute';

    /** A label for the up hour button (used by screen readers).  */
    upHourLabel = 'Plus eine Stunde';

    /** A label for the down hour button (used by screen readers).  */
    downHourLabel = 'Minus eine Stunde';

    /** A label for the previous month button (used by screen readers). */
    prevMonthLabel = 'vorheriger Monat';

    /** A label for the next month button (used by screen readers). */
    nextMonthLabel = 'nächster Monat';

    /** A label for the previous year button (used by screen readers). */
    prevYearLabel = 'vorheriges Jahr';

    /** A label for the next year button (used by screen readers). */
    nextYearLabel = 'nächstes Jahr';

    /** A label for the previous multi-year button (used by screen readers). */
    prevMultiYearLabel = 'vorherige 21 Jahre';

    /** A label for the next multi-year button (used by screen readers). */
    nextMultiYearLabel = 'nächste 21 Jahre';

    /** A label for the 'switch to month view' button (used by screen readers). */
    switchToMonthViewLabel = 'Zur Monatsansicht wechseln';

    /** A label for the 'switch to year view' button (used by screen readers). */
    switchToMultiYearViewLabel = 'Monat und Jahr auswählen';

    /** A label for the cancel button */
    cancelBtnLabel = 'Abbrechen';

    /** A label for the set button */
    setBtnLabel = 'Bestätigen';

    /** A label for the range 'from' in picker info */
    rangeFromLabel = 'Start';

    /** A label for the range 'to' in picker info */
    rangeToLabel = 'Ende';

    /** A label for the hour12 button (AM) */
    hour12AMLabel = 'AM';

    /** A label for the hour12 button (PM) */
    hour12PMLabel = 'PM';

    constructor(@Inject(TranslateService) private _translate)
    {
        super();

        this._translate.get(this.upSecondLabel).subscribe(x => this.upSecondLabel = x);
        this._translate.get(this.downSecondLabel).subscribe(x => this.downSecondLabel = x);
        this._translate.get(this.upMinuteLabel).subscribe(x => this.upMinuteLabel = x);
        this._translate.get(this.downMinuteLabel).subscribe(x => this.downMinuteLabel = x);
        this._translate.get(this.upHourLabel).subscribe(x => this.upHourLabel = x);
        this._translate.get(this.downHourLabel).subscribe(x => this.downHourLabel = x);
        this._translate.get(this.prevMonthLabel).subscribe(x => this.prevMonthLabel = x);
        this._translate.get(this.nextMonthLabel).subscribe(x => this.nextMonthLabel = x);
        this._translate.get(this.prevYearLabel).subscribe(x => this.prevYearLabel = x);
        this._translate.get(this.nextYearLabel).subscribe(x => this.nextYearLabel = x);
        this._translate.get(this.switchToMonthViewLabel).subscribe(x => this.switchToMonthViewLabel = x);
        this._translate.get(this.switchToMultiYearViewLabel).subscribe(x => this.switchToMultiYearViewLabel = x);
        this._translate.get(this.cancelBtnLabel).subscribe(x => this.cancelBtnLabel = x);
        this._translate.get(this.setBtnLabel).subscribe(x => this.setBtnLabel = x);
        this._translate.get(this.rangeFromLabel).subscribe(x => this.rangeFromLabel = x);
        this._translate.get(this.rangeToLabel).subscribe(x => this.rangeToLabel = x);
        this._translate.get(this.hour12AMLabel).subscribe(x => this.hour12AMLabel = x);
        this._translate.get(this.hour12PMLabel).subscribe(x => this.hour12PMLabel = x);
    }
}
