import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss']
})
/**
 * Komponente der ProgressBar
 */
export class ProgressBarComponent
{
    /**
     * Höhe: Standard 20px
     */
    @Input() height = 20;

    /**
     * Aktueller Wert
     */
    private _value: number;

    /**
     * Maximal Wert
     */
    private _max: number;

    /**
     * Prozentangabe
     * Wird berechnet kann aber auch direkt gesetzt werden
     */
    private _percentValue: number;

    /**
     * Farbe in der die ProgressBar dargestellt werden soll.
     * Wird je nach Prozent Wert gesetzt.
     */
    private _color: string;

    /**
     * Prozent Wert getter
     */
    get percentValue(): number
    {
        return this._percentValue;
    }

    /**
     * Prozent Wert setter
     * setzt Farbe je nach Wert
     */
    @Input('percentValue')
    set percentValue(pPercentValue: number)
    {
        if (pPercentValue < 0)
        {
            this._percentValue = 0;
        }
        else if (pPercentValue > 100)
        {
            this._percentValue = 100;
        }
        else
        {
            this._percentValue = pPercentValue;
        }

        if (this._percentValue >= 40)
        {
            this._color = 'green';
        }
        else if (this._percentValue > 10 && this._percentValue < 40)
        {
            this._color = 'darkorange';
        }
        else
        {
            this._color = 'red';
        }
    }

    /**
     * Max Value getter
     */
    get max(): number
    {
        return this._max;
    }

    /**
     * Max Value Setter
     */
    @Input('max')
    set max(pMax: number)
    {
        this._max = pMax;
    }

    /**
     * Value getter
     */
    get value(): number
    {
        return this._value;
    }

    /**
     * Value setter
     * Beim setzen wird der Prozentwert berechnet
     */
    @Input('value')
    set value(pValue: number)
    {
        this._value = pValue;

        this.calculatePercentage();
    }

    /**
     * Color getter
     */
    get color(): string
    {
        return this._color;
    }

    /**
     * Berechnet den Pronzentwert
     */
    calculatePercentage()
    {
        if (this.max != null && this.value != null)
        {
            this.percentValue = 100 - Math.round(this.value / this.max * 100);
        }
    }
}
