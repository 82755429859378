import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {UserSettings} from '../../model/helper-models/user-settings.model';
import {environment} from '../../../../environments/environment';
import {AuthenticationService} from './authentication.service';
import {firstValueFrom} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserSettingsService
{
    public User: UserSettings;
    private url = environment.apiUrl + environment.apiVersion + '/user';

    constructor(private _http: HttpClient,
                private _auth: AuthenticationService,
                private _translate: TranslateService)
    {
    }

    /**
     * Initialisiert Sprachen
     */
    public async initUser()
    {
        this._translate.addLangs(['de', 'en']);

        // this language will be used as a fallback when a translation isn't found in the current language
        this._translate.setDefaultLang('de');

        await this.getUserSettings();
    }

    /**
     * Holt geicherte Daten (im Moment aus localStorage) oder setzt diese falls nicht vorhanden
     */
    public async getUserSettings()
    {
        const headers = await this._auth.getDefaultHttpHeaders();

        const query = '?azureId=' + this._auth.UserId;

        try
        {
            this.User = await firstValueFrom(this._http.get<UserSettings>(this.url + query, {headers: headers}));

            if (this.User == null)
            {
                console.log('User not found in DB');
                this.addUser();
                return;
            }

            if (this.User.language == null)
            {
                this.initLanguage();
            }
            else
            {
                this._translate.use(this.User.language);

                this.languageChange();
            }
        } catch (e)
        {
            console.log('Get failed: ', e);
            this.initLanguage();
        }
    }

    private async addUser()
    {
        const headers = await this._auth.getDefaultHttpHeaders();

        const newUser = {
            azureId: this._auth.ActiveUser.localAccountId,
            username: this._auth.ActiveUser.username,
            familyName: this._auth.ActiveUser.name.split(',')[0],
            givenName: this._auth.ActiveUser.name.split(',')[1],
            email: '',
            favorites: []
        };

        //todo return new user on insert
        this._http.post(this.url, newUser, {headers: headers}).subscribe((x: any) =>
        {
            this._auth.UserId = newUser.azureId;
            this.User = x.user;
        });

    }

    /**
     * Setzt Sprache anhand der im Browser eingestellten Sprache
     */
    private initLanguage(): void
    {
        this.languageChange();

        const browserLang = this._translate.getBrowserLang();
        this._translate.use(browserLang.match(/de|en/) ? browserLang : 'de');
    }

    /**
     * Wird ausgelöst wenn sich die eingestelle Sprache ändert
     */
    private languageChange(): void
    {
        this._translate.onLangChange.subscribe(x =>
        {
            this.setUserSettings(x.lang);
        });
    }

    /**
     * Speichert Sprache (im Moment lokal)
     * @param pSettings
     */
    public async setUserSettings(pLang: string)
    {
        const accessToken = await this._auth.getToken();
        const headers = new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Authorization': 'Bearer ' + accessToken.accessToken
        });

        const reqBody = {
            UserId: this.User.azureId,
            Language: pLang
        };

        this._http.post(this.url + '/setLanguage', reqBody, {headers: headers}).subscribe();
        localStorage.setItem('lang', pLang);
    }
}
