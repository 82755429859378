import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {S7Info} from '../../../../core/model/gateway_extended_status.model';
import { GatewayTestdriveInfo } from '../../../../core/model/gateway_status.v2.model';

@Component({
    selector: 'app-s7-change-box',
    templateUrl: './s7-change-box.component.html',
    styleUrls: ['./s7-change-box.component.scss']
})
export class S7ChangeBoxComponent implements OnInit
{

    constructor(public dialogRef: MatDialogRef<S7ChangeBoxComponent>, @Inject(MAT_DIALOG_DATA) public s7Data: GatewayTestdriveInfo)
    {
    }

    changedModus: number = this.s7Data.RecordingMode;
    changedTime: string = this.s7Data.StartTimeTestrun.toString();
    changedActive: boolean = Boolean(this.s7Data.StartTimeTestrunActive).valueOf();

    ngOnInit(): void
    {
    }

    // ToDo Better binding
    public changeTime(event)
    {
        this.changedTime = event['srcElement']['value'];
    }

    public changeModus(event)
    {
        this.changedModus = event['srcElement']['value'];
    }

    onNoClick(): void
    {
        this.dialogRef.close();
    }

    onSaveClick(): void
    {
        // ToDo Replace textbox so this part wouldn't be necessary
        // converts string into time in milliseconds
        if (isNaN(Number(this.changedTime)))
        {
            const tmpDate = new Date('1-1-1970 ' + this.changedTime);
            const utc_timestamp = Date.UTC(tmpDate.getUTCFullYear(), tmpDate.getUTCMonth(), tmpDate.getUTCDate() ,
                tmpDate.getHours(), tmpDate.getUTCMinutes(), tmpDate.getUTCSeconds(), tmpDate.getUTCMilliseconds());
            this.s7Data.StartTimeTestrun = utc_timestamp;
        }
        else
        {
            this.s7Data.StartTimeTestrun = Number(this.changedTime);
        }
        this.s7Data.StartTimeTestrunActive = this.changedActive;
        this.s7Data.RecordingMode = this.changedModus;
        this.dialogRef.close(this.s7Data);
    }

    onResetClick(): void
    {
        this.s7Data.Reset = true;
        this.dialogRef.close(this.s7Data);
    }

}
