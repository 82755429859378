import {Component} from '@angular/core';
import {BaseSubcomp} from '../../../base-subcomp';

@Component({
    selector: 'app-sscrr-subcomp-parent',
    templateUrl: './sscrr-subcomp-parent.component.html',
    styleUrls: ['./sscrr-subcomp-parent.component.scss']
})
export class SscrrSubcompParentComponent extends BaseSubcomp
{

}

