<!-- Button trigger modal -->
<!--
<button (click)="resetLayout()" class="btn" type="button">
    {{'Layout zurücksetzen' | translate}}
</button>
-->
<div *ngIf="layoutBtn">
    <button (click)="_nav.navigateToThingLayout(thing)" class="btn" type="button" i18n>
        {{'edit_layout' | translate}}
    </button>
</div>

<div *ngIf="healthButton">
    <button (click)="_nav.navigateToThingHealthpage(thing)" class="btn" type="button" i18n>
        {{'Health' | translate}}
    </button>
</div>

<button (click)="setAuto()" class="btn" type="button">
    {{'Livedaten' | translate}}
</button>

<button type="button" class="btn trigger fas fa-calendar-alt" [owlDateTimeTrigger]="dt">
    {{'Historische Daten' | translate}}
    <owl-date-time #dt firstDayOfWeek="1"></owl-date-time>
</button>

<label>
    <input style="display: none" [max]="max" [owlDateTimeTrigger]="dt" [owlDateTime]="dt"
           [selectMode]="'range'" [(ngModel)]="Time">
</label>

<!-- Modal
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                Zeitspanne auswählen
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </button>
            </div>
            <div class="modal-body">
                <ngb-datepicker #dp (select)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t">
                </ngb-datepicker>

                <ng-template #t let-date="date" let-focused="focused">
          <span class="custom-day"
                [class.focused]="focused"
                [class.range]="isRange(date)"
                [class.faded]="isHovered(date) || isInside(date)"
                (mouseenter)="_hoveredDate = date"
                (mouseleave)="_hoveredDate = null">
                {{ date.day }}
          </span>
                </ng-template>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn" data-dismiss="modal" (click)="save()">Speichern</button>
            </div>
        </div>
    </div>
</div>
-->
