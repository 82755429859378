<button
    mat-flat-button
    (mouseenter)="isHealthDialogCardHidden = !isHealthDialogCardHidden"
    (mouseleave)="isHealthDialogCardHidden = !isHealthDialogCardHidden"
    class="d-inline-block ms-5 bg-gray"
    (click)="showComponentView()"
>
    <mat-icon
        class="vertical-center-icon"
    >visibility</mat-icon>
</button>

<!--CARD Information-->
<app-health-details
    *ngIf="!isHealthDialogCardHidden"
></app-health-details>
