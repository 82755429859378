import {Component} from '@angular/core';
import {BaseSubcomp} from '../../../base-subcomp';
import {environment} from '../../../../../environments/environment';

@Component({
    selector: 'app-ssif-subcomp-parent',
    templateUrl: './ssif-subcomp-parent.component.html',
    styleUrls: ['./ssif-subcomp-parent.component.scss']
})
export class SsifSubcompParentComponent extends BaseSubcomp
{

    protected readonly environment = environment;
}
