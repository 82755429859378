import {Component} from '@angular/core';

@Component({
    selector: 'app-placehold-piechart',
    templateUrl: './placehold-piechart.component.html',
    styleUrls: ['./placehold-piechart.component.scss']
})
/**
 * Platzhalter wenn in einer Piechart keine Daten angekommen sind
 */
export class PlaceholdPiechartComponent
{
}
