<div *ngIf="data.activeSystem != null" id="primaryWindow">

    <app-thing-banner *ngIf="data.activeLocation.name === 'Sinsheim'"
                      [items]="[]"></app-thing-banner>

    <!--Überschrift-->
    <div class="row">
        <div class="col-lg-12">
            <div class="">
                <h2>{{ data.activeLocation.name }} - {{ data.activeSystem.name }}</h2>
            </div>
        </div>
    </div>

    <!--3D Darstellung-->
    <div class="row">

        <div class="col-lg-12 subheading">
            <h3>{{'3D Layout' | translate}}</h3>
        </div>

        <div class="col-lg-1"></div>

        <div class="col-lg-10">
            <div class="tile">
                <div class="tileBody videoContainer">
                    <iframe id="3dframe" allowfullscreen=true webkitallowfullscreen=true mozallowfullscreen=true
                            #unityIframe
                            [src]="frameSrc | safe" class="video"></iframe>
                </div>
            </div>
        </div>

        <div class="col-lg-1"></div>
    </div>


    <div class="gap"></div>
</div>
