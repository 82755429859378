import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'errorColor'
})
export class ErrorColorPipe implements PipeTransform
{
    /**
     * Nimmt einen Wert und vergleicht ihn mit 4 Schwellwerten.
     * Gibt je nach Ergebnis den Namen einer Klasse wieder
     * (Calculated TestdriveEvent)
     * @param value
     * @param uuT
     * @param uT
     * @param lT
     * @param llT
     */
    transform(value: number, uuT: number, uT: number, lT: number, llT: number): string
    {
        if ((value === 0 || value === null) || uuT == null || uT == null || lT == null || llT == null)
        {
            return '';
        }
        else if (value > uuT)
        {
            return 'make-red';
        }
        else if (value > uT)
        {
            return 'make-yellow';
        }
        else if (value < llT)
        {
            return 'make-red';
        }
        else if (value < lT) return 'make-yellow';

        return '';
    }
}
