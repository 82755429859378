import {Component} from '@angular/core';
import {BaseSubcomp} from '../../../base-subcomp';

@Component({
    selector: 'app-olsx-subcomp-parent',
    templateUrl: './olsx-subcomp-parent.component.html',
    styleUrls: ['./olsx-subcomp-parent.component.scss']
})
export class OlsxSubcompParentComponent extends BaseSubcomp
{

}
