import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {MonitoringLevel} from '../../../core/model/MonitoringLevels.enum';
import {LimitSizeArrayModel} from '../../../core/model/helper-models/limit-size-array.model';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';
import {DataPoint} from '../../../core/model/helper-models/datapoint.model';

@Component({
    selector: 'app-ssagm-carrier-statistic',
    templateUrl: './ssagm-carrier-statistic.component.html',
    styleUrls: ['./ssagm-carrier-statistic.component.scss', './../card.scss']
})
export class SsagmCarrierStatisticComponent extends BaseViewElement implements OnInit, OnDestroy, OnChanges
{

    @Input() Allgemein: PropertySet;

    CarrierFree: Property<any>;
    CarrierInUse: Property<any>;
    CarrierBlocked: Property<any>;
    CarrierDisturbed: Property<any>;

    constructor()
    {
        super('ssagm-carrier-statistic');
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit(result);
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.Allgemein == null) return;
        this.CarrierFree = this.Allgemein.properties.get('ANZ_FREIE_CARRIER');
        this.CarrierInUse = this.Allgemein.properties.get('ANZ_BEL_CARRIER');
        this.CarrierBlocked = this.Allgemein.properties.get('ANZ_BLOCKIERTE_CARRIER');
        this.CarrierDisturbed = this.Allgemein.properties.get('ANZ_GESTOERTE_CARRIER');
    }
}
