import {Injectable} from '@angular/core';
import {CanActivate, CanLoad} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class AdminGuard implements CanActivate, CanLoad
{

    constructor(private authenticationService: AuthenticationService)
    {

    }

    /**
     * Gibt einen bool zurück der angibt ob der User das Element aktivieren darf
     */
    canActivate(): boolean
    {
        return this.authenticationService.isAdmin();
    }

    /**
     * Gibt einen bool zurück der angibt ob der User das Element laden darf
     */
    canLoad(): boolean
    {
        return this.authenticationService.isAdmin();
    }

}
