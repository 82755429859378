/**
 * Gang --> für die Navigation
 */
export class AisleModel
{
    private _name: string;
    public get name(): string
    {
        return this._name;
    }

    public set name(value: string)
    {
        this._name = value;
    }

    private _things = [];
    public get things()
    {
        return this._things;
    }

    public set things(value)
    {
        this._things = value;
    }

    constructor(pName: string)
    {
        this.name = pName;
    }
}
