<app-group-header [Heading]="'Schmiersystem Kartusche' | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>
<div class="card-group">
<!--
    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Anzahl Kartuschenwechsel' | translate"
                      [Error]="AmountCartridgeChange?.isError"
                      [ErrorLevel]="AmountCartridgeChange?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[AmountCartridgeChange?.TimeseriesData]"
                      [Date]="DateCartridgeChange?.TimeseriesData"
                      [Legend]="['Anzahl Kartuschenwechsel' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>
-->

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Leermeldungen' | translate"
                      [Error]="EmptyMessage?.isError"
                      [ErrorLevel]="EmptyMessage?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[EmptyMessage?.TimeseriesData]"
                      [Type]="'boolean'"
                      [ShowDate]="true"
                      [PipeOptions]="'number'"
                      [Legend]="['Leermeldungen' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Ölstand' | translate"
                      [Error]="OilLevel?.isError"
                      [ErrorLevel]="OilLevel?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[OilLevel?.TimeseriesData]"
                      [Unit]="'%'"
                      [Legend]="['Ölstand' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>


    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Aktuelle Einstellung Kartusche fast leer bei' | translate"
                      [Error]="WarningNearlyEmpty?.isError"
                      [ErrorLevel]="WarningNearlyEmpty?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[WarningNearlyEmpty?.TimeseriesData]"
                      [Unit]="'%'"
                      [Legend]="['Warnung Fast Leer' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>


</div>
