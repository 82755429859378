import {Component, OnInit} from '@angular/core';
import {HelpPageComponent} from '../../help-page.component';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';

@Component({
    selector: 'app-help-page-frequent-questions',
    templateUrl: './help-page-frequent-questions.component.html',
    styleUrls: ['./help-page-frequent-questions.component.scss', '../../helpPageModule.scss']
})
export class HelpPageFrequentQuestionsComponent extends HelpPageComponent implements OnInit
{
    constructor(trans: TranslateService, private http: HttpClient)
    {
        super(trans);
    }

    ngOnInit(): void
    {
    }
}
