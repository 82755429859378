import {Component, Input, OnInit} from '@angular/core';
import {HelpPageComponent} from '../help-page.component';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-help-page-banner',
    templateUrl: './help-page-banner.component.html',
    styleUrls: ['./help-page-banner.component.scss', '../helpPageModule.scss']
})
export class HelpPageBannerComponent extends HelpPageComponent implements OnInit
{
    @Input() items: Array<[string, string, string]>; // German ; Englisch; IDName
    itemLanguagID: Array<[string, string]>;
    @Input() returnAddress: string;

    constructor(tranlate: TranslateService, private router: Router)
    {
        super(tranlate);
    }

    ngOnInit(): void
    {
        this.itemLanguagID = new Array<[string, string]>();

        let x = 0;
        if (this.curentLang === 'en')
        {
            x = 1;
        }

        if (this.items == null) return;

        for (let i = 0; i < this.items.length; i++)
        {
            this.itemLanguagID.push([this.items[i][x], this.items[i][2]]);
        }
    }

    goBack(path: string)
    {
        this.router.navigateByUrl(path);
    }

    scroll(id)
    {
        // problem scroll zu weit Methide unten scrollToAnchor ist von Julian aus thing-banner geht jedoch nicht -->problem parenet.Element
        const el = document.getElementById(id);
        el.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    }

    // Methide von Julian aus thing-banner geht jedoch nicht -->problem parenet.Element
    scrollToAnchor(elementId): void
    {
        const content = document.getElementById('content');
        const element = document.getElementById(elementId);
        const headerOffset = 25;
        //Abstand zum Seitenanfang
        const topOffset = element.parentElement.parentElement.offsetTop - headerOffset;

        content.scrollTo({
            top: topOffset,
            behavior: 'smooth'
        });
    }
}
