<div class="tile" [ngClass]="{'errorTile' : error}">

    <div class="tileHead">
        {{heading}}
        <div class="unit">
            {{unit}}
        </div>
    </div>

    <div class="tileBody">
        <ng-content></ng-content>
    </div>
</div>
