import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {Configurations} from '../../../configurations';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
    selector: 'app-error-messages',
    templateUrl: './error-messages.component.html',
    styleUrls: ['./error-messages.component.scss',
        './../card.scss']
})
export class ErrorMessagesComponent extends BaseViewElement implements OnInit, OnChanges
{
    @Input() Status: PropertySet;

    StatusCode: Property<any>;
    StatusText: Property<any>;
    DM1Code: Property<any>;
    DM3Code: Property<any>;

    pullType = Configurations.PullType.value;

    constructor()
    {
        super('error-messages');
        Configurations.PullType.subscribe((x) => this.pullType = x);
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit();
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.Status != null)
        {
            this.StatusCode = this.Status.properties.get('STATUS_CODE');
            this.StatusText = this.Status.properties.get('STATUS_TEST');
            this.DM1Code = this.Status.properties.get('DM1_CODE');
            this.DM3Code = this.Status.properties.get('DM3_CODE');
        }
    }
}
