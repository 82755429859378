import {DragDropModule} from '@angular/cdk/drag-drop';
import {registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import {Injectable, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {MatButtonModule} from '@angular/material/button';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
// Open Street Maps
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
// Banner Scroll
import {ToastrModule} from 'ngx-toastr';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ChartsModule} from './core/controller/modules/charts.module';
import {DatepickerModule} from './core/controller/modules/datepicker.module';
import {GuardModule} from './core/controller/modules/guard.module';
import {MonitoringModule} from './core/controller/modules/monitoring.module';
import {PipesModule} from './core/controller/modules/pipes.module';
import {TileModule} from './core/controller/modules/tile.module';
import {DataProviderService} from './core/controller/services/data-provider.service';
import {DateService} from './core/controller/services/date.service';
import {GetThingDataService} from './core/controller/services/get-thing-data.service';
import {InitialisePageService} from './core/controller/services/initialise-page.service';
import {NavigationService} from './core/controller/services/navigation.service';
import {UserSettingsService} from './core/controller/services/user-settings.service';
import {WebsocketService} from './core/controller/services/websocket.service';
import {FooterComponent} from './core/view/footer/footer.component';
import {HeaderComponent} from './core/view/header/header.component';
import {NavigationLeftComponent} from './core/view/navigation/navigation-left.component';
import {PowerbiComponent} from './core/view/powerbi/powerbi.component';
import {BronzeModule} from './packages/bronze/bronze.module';
import {GoldModule} from './packages/gold/gold.module';
import {PlatinumModule} from './packages/platinum/platinum.module';
import {SilverModule} from './packages/silver/silver.module';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AdminModule} from './packages/admin/admin.module';
import {ProgressBarModule} from './core/controller/modules/progress-bar.module';
import {PowerBIEmbedModule} from 'powerbi-client-angular';
import {MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent} from '@azure/msal-angular';
import {InteractionType, PublicClientApplication} from '@azure/msal-browser';
import {environment} from '../environments/environment';
import {SubComponentModule} from './core/controller/modules/sub-component.module';
import {HttpCacheInterceptorService} from './core/controller/services/http-cache-interceptor.service';
import { TableTranslationPipe } from './core/controller/pipes/table-translation.pipe';
import {NgxQRCodeModule} from '@techiediaries/ngx-qrcode';

declare var process: any;


registerLocaleData(localeDe, 'de');

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient)
{
    return new TranslateHttpLoader(http);
}

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        NavigationLeftComponent,
        PowerbiComponent,
        TableTranslationPipe
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        MsalModule.forRoot(new PublicClientApplication({
                auth: {
                    clientId: environment.clientId,
                    authority: environment.authority,
                    redirectUri: environment.redirectUri
                },
                cache: {
                    cacheLocation: 'localStorage',
                    storeAuthStateInCookie: isIE
                }
            }),
            {
                interactionType: InteractionType.Redirect,
                authRequest: {
                    scopes: ['api://97b96046-8098-48e4-8943-bf80d8391aa5/.default'],
                },
            }, {
                interactionType: InteractionType.Redirect,
                protectedResourceMap: new Map([
                    ['https://graph.microsoft.com/v1.0/me', ['api://97b96046-8098-48e4-8943-bf80d8391aa5/.default']],
                ])
            }),
        HttpClientModule,
        FormsModule,
        NgSelectModule,
        LeafletModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            }
        }),
        ChartsModule,
        PipesModule,
        DatepickerModule,
        GuardModule,
        BronzeModule,
        SilverModule,
        GoldModule,
        PlatinumModule,
        AdminModule,
        MatInputModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        TileModule,
        DragDropModule,
        BrowserAnimationsModule,
        MatCheckboxModule,
        ToastrModule.forRoot({preventDuplicates: true}),
        PowerBIEmbedModule,
        MonitoringModule,
        MatButtonModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        MatNativeDateModule,
        ProgressBarModule,
        SubComponentModule,
        NgxQRCodeModule
    ],
    providers: [
        {provide: LOCALE_ID, useValue: 'de'},
        InitialisePageService,
        DataProviderService,
        GetThingDataService,
        NavigationService,
        DateService,
        Title,
        UserSettingsService,
        WebsocketService,
        // {provide: HTTP_INTERCEPTORS, useClass: HttpCacheInterceptorService, multi: true},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        MsalGuard
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
@Injectable()
export class AppModule
{
}
