<app-group-header [Heading]="('Schaltschrank' | translate)"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>

<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="('Temperatur' | translate)"
                      [Error]="TemperatureError"
                      [ErrorLevel]="TemperatureErrorLevel"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="TemperatureValue"
                      [Unit]="'°C'"
                      [Legend]="['Temperatur' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>
</div>
