import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BronzeGuard} from '../../core/controller/guards/bronze.guard';
import {DashboardComponent} from './dashboard/dashboard.component';
import {LocationsComponent} from './locations/locations.component';
import {StorewareComponent} from './storeware/storeware.component';

const routes: Routes = [
    {path: 'dashboard', component: DashboardComponent, canActivate: [BronzeGuard]},
    {path: 'storeware/:id', component: StorewareComponent, canActivate: [BronzeGuard]},
    {path: 'location', component: LocationsComponent, canActivate: [BronzeGuard]},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class BronzeRoutingModule
{
}
