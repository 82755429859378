import {BaseOptions} from './base-options';

/**
 * Optionen für Piecharts
 */
export class PiechartOptions extends BaseOptions
{
    /**
     * Konstruktor
     * @param pName
     * @param pTitle
     * @param pUnit
     * @param pLegend
     */
    constructor(pName: string, pTitle: string, pUnit: string, pLegend: Array<string>)
    {
        super(pName, pTitle, pUnit);
        this.legend = pLegend;
    }
}
