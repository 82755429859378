import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {LinechartOptions} from '../../../core/view/charts/options/linechart-options';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
    selector: 'app-scan-quality',
    templateUrl: './scan-quality.component.html',
    styleUrls: ['./scan-quality.component.scss',
        './../card.scss']
})
export class ScanQualityComponent extends BaseViewElement implements OnInit, OnChanges
{
    @Input() GeneralInformation: PropertySet;

    ScanQuality: Property<any>;

    public lineOptions = new LinechartOptions(null, ['Code Qualität'], null, null, 95, null, 0, 100);

    constructor()
    {
        super('scan-quality');
        this.chart = true;
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit();
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.GeneralInformation != null)
        {
            this.ScanQuality = this.GeneralInformation.properties.get('LAST_SCAN_BARCODE_QUALITY');
        }
    }
}
