<app-group-header [Heading]="'Scan Qualität' | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>

<div class="card-group">

    <app-default-card class="card"
                      [Error]="ScanQuality.isError"
                      [ErrorLevel]="ScanQuality.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[ScanQuality.TimeseriesData]"
                      [Legend]="['Code Qualität' | translate]"
                      [Info]="'Die Scan Qualität sollte im Optimalfall über 95% liegen. In dem Diagramm kann man die Scanqualität zu den ausgewählten Daten (Livedaten oder historische Daten) beobachten.'"
                      [resizeEvent]="childResizeEvent"
                      [ChartOption]="lineOptions"
                      [ChartType]="'line'">
    </app-default-card>

</div>
