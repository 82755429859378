import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ProgressBarComponent} from '../../view/progress-bar/progress-bar.component';
import {TranslateModule} from '@ngx-translate/core';
import {LoadingComponent} from '../../view/loading/loading.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
    ],
    declarations: [
        ProgressBarComponent,
        LoadingComponent
    ],
    exports: [
        ProgressBarComponent,
        LoadingComponent

    ]
})
export class ProgressBarModule
{
}
