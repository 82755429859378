<app-group-header [Heading]="'Letzter Scan' | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>

<div class="card-group">

    <app-default-card class="card"
                      [Heading]="'Start Position' | translate"
                      [Error]="StartPosition.isError"
                      [ErrorLevel]="StartPosition.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[StartPosition.TimeseriesData]"
                      [Unit]="'°'"
                      [Legend]="['Start Position' | translate]"
                      [Info]="'In welchem Winkel der Scanner anfängt zu Scannen.'"
                      [resizeEvent]="childResizeEvent">
    </app-default-card>

    <app-default-card class="card"
                      [Heading]="'End Position'"
                      [Error]="EndPosition.isError"
                      [ErrorLevel]="EndPosition.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[EndPosition.TimeseriesData]"
                      [Unit]="'°'"
                      [Legend]="['End Position' | translate]"
                      [Info]="'In welchem Winkel der Scanner aufhört zu Scannen.'"
                      [resizeEvent]="childResizeEvent">
    </app-default-card>


    <app-default-card class="card"
                      [Heading]="'Scanqualität' | translate"
                      [Error]="ScanQuality.isError"
                      [ErrorLevel]="ScanQuality.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[ScanQuality.TimeseriesData]"
                      [Unit]="'%'"
                      [Legend]="['Scanqualität' | translate]"
                      [Info]="'Die durchschnittliche Scanqualität des Leuze Scanners wird festgehalten.'"
                      [resizeEvent]="childResizeEvent">
    </app-default-card>

</div>
