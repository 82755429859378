import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Configurations} from '../../../../../configurations';
import {DateService} from '../../../../controller/services/date.service';
import {DataPoint} from '../../../../model/helper-models/datapoint.model';
import {PiechartOptions} from '../../options/piechart-options';

@Component({
    selector: 'app-piechart',
    template:
        `
            <div echarts style="height: 100%" [options]="options" [merge]="updateOptions"></div>
          `
})
/**
 * Kuchendiagramm
 */
export class PiechartComponent implements OnInit, OnDestroy
{
    /**
     * Array mit den einzelnen Werten
     * Prozentwert und Verhältnis wird automatisch berechnet
     */
    @Input() value: Array<Array<DataPoint<number>>>;
    /**
     * Zusätzliche Optionen
     */
    @Input() setOptions: PiechartOptions;

    /**
     * Optionen die der Chart über Binding übergeben Wert
     */
    options: any;

    /**
     * Optionen die bei jedem Intervall übergeben werden um Chart Daten zu aktualisieren
     */
    updateOptions: any;

    /**
     * Standard Optionen wenn nichts übergeben wurde
     */
    standardOptions = {
        series: [{
            name: '',
            type: 'pie',
            data: null
        }]
    };

    /**
     * Standardfarben
     */
    public color_default = ['#20818C', '#1BA68C', '#18855A', '#A4D97E', '#6EA61B', '#599225', '#9C9828', '#857129', '#9C6E28', '#924922'];

    /**
     * Neues Farbschema
     */
    public color_new = ['#c23531', '#2f4554', '#61a0a8', '#d48265', '#91c7ae', '#749f83', '#ca8622', '#bda29a', '#6e7074', '#546570', '#c4ccd3'];

    /**
     * Intervall zum Chart Updaten
     */
    private timer: any;

    /**
     * Konstruktor
     * @param Date
     */
    constructor(private Date: DateService)
    {
    }

    /**
     * Setzt Optionen und erstellt Chart
     */
    ngOnInit()
    {
        if (this.setOptions == null && this.value == null)
        {
            this.options = this.standardOptions;
            return;
        }

        // Setzt Übergebene Optionen (setOptions)
        this.options = {
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b}: {c} ({d}' + this.setOptions.unit + ')'
            },
            legend: {
                // right: 10,
                // top: 20,
                // bottom: 20,
                left: 'left',
                // data: properties
                // orient: 'vertical',

                data: this.setOptions.legend // data.legendData
            },
            calculable: true,
            series: [{
                name: this.setOptions.name,
                type: 'pie',
                color: this.color_new, // color,
                radius: '65%',
                center: ['50%', '60%'],
                avoidLabelOverlap: false,

                data: this.parseData(this.value)
            }]
        };

        // Startet timer der im Interval aufgerufen wird
        this.timer = setInterval(() =>
        {
            if (this.Date.auto)
            {
                // Updatet Piechart Datenpunkte
                this.updateOptions =
                    {
                        animation: false,
                        series: [{
                            data: this.parseData(this.value)
                        }]
                    };
            }
            else
            {
                clearInterval(this.timer);
            }
        }, Configurations.chartUpdateTime);
    }

    /**
     * Stoppt das Updaten der Chart
     */
    ngOnDestroy()
    {
        clearInterval(this.timer);
    }

    /**
     * Wandelt einen DataPoint in ein Objekt um das von der PieChart angenommen wird.
     */
    private parseData<T>(pDataArray: Array<Array<DataPoint<T>>>)
    {
        const datapoints = [];

        for (let i = 0; i < pDataArray.length; i++)
        {
            datapoints.push({value: pDataArray[i][pDataArray[i].length - 1].value, name: this.setOptions.legend[i]});
        }

        return datapoints;
    }
}
