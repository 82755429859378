export class DataexplorerNode
{
    id: string;
    name: string;
    controlCabinet: string;
    count?: number;
    timestamp?: Date;
    loading: boolean;
    childsLoaded: boolean;
    children?: DataexplorerNode[];

    constructor(id: string, name: string, controlCabinet?: string)
    {
        this.id = id;
        this.name = name;
        this.controlCabinet = controlCabinet;
        this.children = [];
        this.loading = true;
    }

    public addChild(child: DataexplorerNode)
    {
        this.children.push(child);
    }

    public getChild(id: string)
    {
        return this.children.find(child => child.id == id);
    }

}

export interface DataexplorerFlatNode
{
    expandable: boolean;
    id?: string;
    name: string;
    count?: number;
    timestamp?: Date;
    loading: boolean;
    childsLoaded: boolean;
    children?: DataexplorerNode[];
    level: number;
}
