<app-help-page-banner [items]="[['Zoomen','Zooming','Zoom'], ['Kategorien ausblenden','Hide categories','HideC']]"
                      [returnAddress]="'/HelpPage'"></app-help-page-banner>
<div class="container">
    <!-- StartPage -->
    <h2>{{'Grafiken' | translate}} </h2>
    <h3 class="subheading" id="Zoom">{{'Zoomen' | translate}}</h3>
    <p>{{'Um das Zeitfenster in einer Grafik zu verändern, muss man mit der Maus in die Grafik gehen und rein oder raus scrollen.' | translate}}</p>
    <img id="imagezoom1" src="{{environment.storageUrl}}/models3d/general/helpPage/introduction/Graphics/Zoom1_{{urlLanguage}}.png"
         class="embed-responsive mx-auto d-block" style="width: 40%;">
    <img id="imagezoom2" src="{{environment.storageUrl}}/models3d/general/helpPage/introduction/Graphics/Zoom2_{{urlLanguage}}.png"
         class="embed-responsive mx-auto d-block" style="width: 40%;">

    <h3 class="subheading" id="HideC">{{'Kategorien ausblenden' | translate}}</h3>
    <p>{{'Um Daten in einer Grafik auszublenden, muss man auf die auszublendende Kategorie klicken:' | translate}}</p>
    <img id="imageHideCat" src="{{environment.storageUrl}}/models3d/general/helpPage/introduction/Graphics/HideCategorie_{{urlLanguage}}.png"
         class="embed-responsive mx-auto d-block" style="margin-bottom: 100px;">
</div>
