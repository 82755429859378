import {Component, Input} from '@angular/core';
import {DataPoint} from '../../../model/helper-models/datapoint.model';
import {BarchartOptions} from '../../charts/options/barchart-options';
import {Tile} from '../tile';

@Component({
    selector: 'app-bar-tile',
    templateUrl: './bar-tile.component.html',
    styleUrls: ['./bar-tile.component.scss',
        '../tile.scss']
})
/**
 * Balkendiagramm Kachel
 */
export class BarTileComponent extends Tile
{
    /**
     * Werte
     */
    @Input() value: Array<DataPoint<number>> = [];

    /**
     * Optionen
     */
    @Input() options: BarchartOptions;

    /**
     *
     */
    constructor()
    {
        super();
    }
}
