import {Component} from '@angular/core';
import {BaseSubcomp} from '../../../base-subcomp';

@Component({
    selector: 'app-fts-subcomp-parent',
    templateUrl: './fts-subcomp-parent.component.html',
    styleUrls: ['./fts-subcomp-parent.component.scss']
})
export class FtsSubcompParentComponent extends BaseSubcomp
{
}
