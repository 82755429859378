import {Component, Input, OnInit} from '@angular/core';
import {MaterialComponentViewDialogComponent} from '../../../../../dialog/material-component-view-dialog/material-component-view-dialog.component';
import {ThingHealthV2Component} from '../../../../../../thing-health-v2/thing-health-v2.component';
import {Thing} from '../../../../../../../../core/model/thing.model';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-komponenten-view',
  templateUrl: './komponenten-view.component.html',
  styleUrls: ['./komponenten-view.component.scss']
})
export class KomponentenViewComponent implements OnInit {

    isHealthDialogCardHidden = true;
    @Input() node: any;
    @Input() dialog: MatDialog;

    public thingInfo: Thing;

  constructor(
      public _parent: ThingHealthV2Component
  ) { }

  ngOnInit(): void {
      this.thingInfo = this._parent.thing;
  }

    public showComponentView(): void{
        const node = this.node;
        const dialogRef = this.dialog.open(MaterialComponentViewDialogComponent,
            {
                data: {
                    name: `${node.name}`,
                    node: node,
                    data: node.data,
                    thingInfo: this.thingInfo
                },
                height: '90%',
                width: '95%'
            }
        );

        /**
         * Dialog Reference update the parent table information for the tree based view
         * */
        dialogRef.componentInstance.onUpdate.subscribe(() => {
            this._parent.getNewHealthTableData();
        });

        /**
         * Dialog Reference for after closed operations
         * */

    }

}
