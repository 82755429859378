/**
 * Enum mit Zuständen fürs Monitoring.
 * Gibt an wie schwerwiegend es ist wenn keine Daten ankommen.
 */
export enum MonitoringLevel
{
    None,
    Info,
    Warning,
    Error
}
