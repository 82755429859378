import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {MonitoringLevel} from '../../../core/model/MonitoringLevels.enum';
import {LimitSizeArrayModel} from '../../../core/model/helper-models/limit-size-array.model';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';
import {DataPoint} from '../../../core/model/helper-models/datapoint.model';


@Component({
    selector: 'app-ssagm-statistic',
    templateUrl: './ssagm-statistic.component.html',
    styleUrls: ['./ssagm-statistic.component.scss', './../card.scss']
})
export class SsagmStatisticComponent extends BaseViewElement implements OnInit, OnDestroy, OnChanges
{

    @Input() Motor: PropertySet;
    @Input() Allgemein: PropertySet;

    OperatingHours: Property<any>;
    DistanceTotal: Property<any>;
    SorterSpeed: Property<any>;
    Input: Property<any>;
    Output: Property<any>;
    Crash: Property<any>;


    sorterSpeed = new LimitSizeArrayModel<DataPoint<number>>(1000);

    constructor()
    {
        super('ssagm-statistic');
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit(result);
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.Motor == null) return;
        this.OperatingHours = this.Motor.properties.get('BETRIEBSSTUNDEN');


        if (this.Allgemein == null) return;
        this.DistanceTotal = this.Allgemein.properties.get('STRECKE');
        this.SorterSpeed = this.Allgemein.properties.get('SORTERGESCHWINDIGKEIT');
        this.SorterSpeed.TimeseriesData.valuePushed.subscribe(x =>
        {
            if (x != null)
            {
                this.sorterSpeed.pushDataPoint(new DataPoint(x.value / 1000, x.timestamp));
            }
        });

        this.Input = this.Allgemein.properties.get('ANZ_EINSCHLEUSUNGEN');
        this.Output = this.Allgemein.properties.get('ANZ_AUSSCHLEUSUNGEN');
        this.Crash = this.Allgemein.properties.get('CRASH_ANZ');
    }

}
