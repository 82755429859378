import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DataProviderService} from '../../../core/controller/services/data-provider.service';
import {DateService} from '../../../core/controller/services/date.service';
import {NavigationService} from '../../../core/controller/services/navigation.service';
import {LinechartOptions} from '../../../core/view/charts/options/linechart-options';
import {PiechartOptions} from '../../../core/view/charts/options/piechart-options';
import {BaseThing} from '../../base-thing';
import {TranslateService} from '@ngx-translate/core';
import {LayoutService} from '../../../core/controller/services/layout.service';
import {UserSettingsService} from '../../../core/controller/services/user-settings.service';

@Component({
    selector: 'app-lsc',
    templateUrl: './lsc.component.html',
    styleUrls: ['./lsc.component.scss',
        '../../../core/view/tile/tile.scss',
        '../../../../assets/css/template.scss']
})
// LSC Seite
export class LscComponent extends BaseThing implements OnInit, OnDestroy
{
    /**
     * Zustände
     */
    operationModes = new Map<number, string>([[0, 'Untätig'], [1, 'Fehler'], [2, 'Scan'], [3, 'Beschäftigt']]);

    public loadOptionsPie =
        new PiechartOptions('Gut-/Fehl-Lesungen', '', '%',
            ['Gut-Lesungen', 'Fehl-Lesungen']);

    public lineOptions = new LinechartOptions(null, ['Code Qualität'], null, null, 95, null, 0, 100);

    constructor(public route: ActivatedRoute,
                private _router: Router,
                public nav: NavigationService,
                public data: DataProviderService,
                public date: DateService,
                private _translate: TranslateService,
                private _layout: LayoutService,
                private _user: UserSettingsService)
    {
        super(route, _router, nav, data, date, _translate, _layout, _user);

        this.setTranslation();

        this._translate.onTranslationChange.subscribe(x =>
        {
            this.setTranslation();
        });
    }

    private setTranslation()
    {
        let piechartheading;
        let piechartlegend1;
        let piechartlegend2;
        let linechartlegend;
        this._translate.get('Gut-/Fehl-Lesungen').subscribe(x => piechartheading = x);
        this._translate.get('Gut-Lesungen').subscribe(x => piechartlegend1 = x);
        this._translate.get('Fehl-Lesungen').subscribe(x => piechartlegend2 = x);
        this._translate.get('Code Qualität').subscribe(x => linechartlegend = x);

        this.loadOptionsPie = new PiechartOptions(piechartheading, '', '%',
            [piechartlegend1, piechartlegend2]);

        this.lineOptions = new LinechartOptions(null, [linechartlegend], null, null, 95, null, 0, 100);

        for (let i = 0; i < this.operationModes.size; i++)
        {
            this._translate.get(this.operationModes.get(i)).subscribe(x => this.operationModes.set(i, x));
        }
    }

    ngOnInit()
    {
        super.ngOnInit();
    }

    ngOnDestroy()
    {
        super.ngOnDestroy();
    }
}
