/**
 * Basis Klasse für Chart Optionen
 */
export class BaseOptions
{
    /**
     * Name der Chart
     */
    name = '';

    /**
     * Titel der Chart
     */
    title = '';

    /**
     * Einheit
     * Standard %
     */
    unit = '%';

    /**
     * Legende
     */
    legend = [];

    /**
     * Konstruktor
     * @param pName
     * @param pTitle
     * @param pUnit
     */
    constructor(pName: string, pTitle: string, pUnit: string)
    {
        this.name = pName;
        this.title = pTitle;
        this.unit = pUnit;
    }
}
