import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Configurations} from '../../../../../configurations';
import {DateService} from '../../../../controller/services/date.service';
import {GaugechartOptions} from '../../options/gaugechart-options';
import {NavigationStart, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {DataPoint} from '../../../../model/helper-models/datapoint.model';
import {WarningGaugechartOptions} from '../../options/warning-gaugechart-options';
import {HEALTH_DEGRADATION_ALERT_PERCENTAGE, HEALTH_DEGRADATION_WARNING_PRECENTAGE} from '../../../../STATICS';

@Component({
    selector: 'app-gaugechart',
    template:
        `
                    <div #chart style="height: 100%" echarts [options]="options" [merge]="updateOptions"></div>
                `
})
/**
 * Messanzeige (Tacho)
 */
export class GaugechartComponent implements OnInit, OnDestroy
{
    @Input() name = '';

    @Input() unit = '';

    @Input() title = '';

    @Input() legend = [];

    @Input() warning = false;

    @Input() min = 0;
    @Input() max = 100;

    @Input() backgroundColor: string = null;

    /**
     * Wert der angezeigt werden soll
     */
    @Input() value: DataPoint<any>[];

    /**
     * Optionen für die Chart
     */
    @Input() setOptions: GaugechartOptions | WarningGaugechartOptions;

    /**
     * Optionen die an die Chart gebindet werden
     */
    options: any;

    /**
     * Optionen die in jedem Intervall übergeben werden um die Chart upzudaten
     */
    updateOptions: any;

    @Input() resizeEvent: EventEmitter<any>;

    /**
     * Standard Optionen falls kein setOptions übergeben wurde
     */
    standardOptions = {
        title: {
            text: ''
        },
        tooltip: {
            formatter: ''
        },
        series: [
            {
                type: 'gauge',
                data: [{value: 0, name: ''}],
                radius: '90%'
            }
        ]
    };

    /**
     * Intervall zum Chart Updaten
     */
    private timer: any;

    @ViewChild('chart', {read: ElementRef}) chart: ElementRef;
    labelFontSize = 15;

    /**
     * Konstruktor
     */
    constructor(private Date: DateService, private router: Router)
    {
        this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() =>
            {
                this.options.series = null;
            });
    }

    /**
     * Setzt Optionen und Initalisiert Chart
     */
    ngOnInit()
    {
        if (this.resizeEvent)
        {
            this.resizeEvent.subscribe((x) =>
            {
                if (this.value.length > 1 && this.chart.nativeElement.offsetWidth > 500)
                {
                    this.labelFontSize = this.chart.nativeElement.offsetWidth * 0.02;
                }
                else
                {
                    this.labelFontSize = this.chart.nativeElement.offsetWidth * 0.04;
                }

                this.updateOptions = {
                    series: [{
                        detail: {
                            fontSize: this.labelFontSize,
                        }
                    }]
                };
            });
        }

        if (this.setOptions == null && this.value == null)
        {
            this.options = this.standardOptions;
            return;
        }

        // Setzt übergebene Optionen (setOptions)
        if (this.setOptions != null)
        {
            this.options =
                {
                    title: {
                        text: this.setOptions.title
                    },
                    tooltip: {
                        formatter: '{c}' + this.setOptions.unit
                    },
                    series: [
                        {
                            min: this.setOptions.min,
                            max: this.setOptions.max,
                            type: 'gauge',
                            detail: {
                                fontSize: this.labelFontSize,
                                formatter: '{value} ' + this.setOptions.unit,
                                color: 'auto'
                            },
                            data: [{value: this.value[0].value, name: this.setOptions.name}],
                            radius: '90%',
                        }
                    ]
                };
            if (this.setOptions instanceof WarningGaugechartOptions){
                this.options.series[0].axisLine = (this.setOptions as WarningGaugechartOptions).axisLine;
            }
            console.log(this.options);
        }
        else
        {
            this.options =
                {

                    title: {
                        text: this.title
                    },
                    tooltip: {
                        formatter: '{c}' + this.unit
                    },
                    backgroundColor: this.backgroundColor,
                    series: [
                        {
                            splitNumber: 10,
                            colorBy: 'seriesBy',
                            min: this.min,
                            max: this.max,
                            type: 'gauge',
                            axisLine: {
                                lineStyle: {
                                    color: this.warning ?
                                        [
                                            [HEALTH_DEGRADATION_ALERT_PERCENTAGE, '#fd666d'],
                                            [HEALTH_DEGRADATION_WARNING_PRECENTAGE, '#37a2da'],
                                            [1, '#56b464']
                                        ]
                                        :
                                        [
                                            [0.2, '#67e0e3'],
                                            [0.8, '#37a2da'],
                                            [1, '#fd666d']
                                        ]
                                }
                            },
                            pointer: {
                                itemStyle: {
                                    color: 'auto'
                                }
                            },
                            detail: {
                                fontSize: this.labelFontSize,
                                formatter: '{value} ' + this.unit,
                                color: 'auto'
                            },
                            axisLabel: {
                                formatter: function (value, index)
                                {
                                    return value;
                                }
                            },
                            data: this.setData(),
                            radius: '90%'
                        }
                    ]
                };
        }
        /*
                this.labelFontSize = this.chart.nativeElement.offsetWidth * 0.04;

                this.updateOptions = {
                    series: [{
                        detail: {
                            fontSize: this.labelFontSize,
                        }
                    }]
                };
        */
        // Startet timer der im Interval aufgerufen wird
        this.timer = setInterval(() =>
        {
            if (this.Date.auto)
            {

                if (this.value != null)
                {
                    if (this.setOptions != null)
                    {
                        // Updatet Gaugechart Datenpunkte
                        this.updateOptions = {
                            series: [{
                                data: [{value: this.value, name: this.setOptions.name}]
                            }]
                        };
                    }
                    else
                    {
                        // Updatet Gaugechart Datenpunkte
                        this.updateOptions = {
                            series: [{
                                data: this.setData()
                            }]
                        };
                    }
                }
            }
            else
            {
                clearInterval(this.timer);
            }
        }, Configurations.chartUpdateTime);
    }

    setData()
    {
        const data = [];
        if (this.value.length > 1)
        {
            data.push({
                value: +this.value[0].value,
                name: this.legend[0],
                pointer: {
                    itemStyle: {
                        color: 'auto'
                    }
                },
                title: {
                    offsetCenter: ['-35%', '80%']
                },
                detail: {
                    offsetCenter: ['-35%', '95%']
                }
            });
            data.push({
                value: +this.value[1].value,
                name: this.legend[1],
                pointer: {
                    itemStyle: {
                        color: 'auto'
                    }
                },
                title: {
                    offsetCenter: ['35%', '80%']
                },
                detail: {
                    offsetCenter: ['35%', '95%']
                }
            });
        }
        else
        {
            data.push({
                value: +this.value[0].value
            });
        }

        return data;
    }

    /**
     * Stoppt Timer
     */
    ngOnDestroy()
    {
        clearInterval(this.timer);
    }
}
