import {Component, Input, OnInit} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {MonitoringLevel} from '../../../core/model/MonitoringLevels.enum';
import {LimitSizeArrayModel} from '../../../core/model/helper-models/limit-size-array.model';

@Component({
    selector: 'app-electricity',
    templateUrl: './electricity.component.html',
    styleUrls: ['./electricity.component.scss',
        './../card.scss']
})
export class ElectricityComponent extends BaseViewElement implements OnInit
{
    @Input() TelescopeEngineError: boolean;
    @Input() TelescopeEngineErrorLevel: MonitoringLevel;
    @Input() TelescopeEngineValue: LimitSizeArrayModel<any>;

    @Input() DriveEngineError: boolean;
    @Input() DriveEngineErrorLevel: MonitoringLevel;
    @Input() DriveEngineValue: LimitSizeArrayModel<any>;

    constructor()
    {
        super('electricity');
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit();
    }

}
