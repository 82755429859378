<app-group-header [Heading]="'Distanz' | translate"
                  [chart]="chart"
                  [info]="info"
                  [noChart]="true"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>

<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Horizontale Strecke' | translate"
                      [Error]="HorizontalKilometer?.isError || HorizontalMeter?.isError"
                      [ErrorLevel]="HorizontalKilometer?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[HorizontalKilometer?.TimeseriesData]"
                      [MaintenanceValue]="HorizontalKilometer?.ValueLastService"
                      [Unit]="'km'"
                      [Legend]="['Distanz' | translate, 'Distanz stand Wartung' | translate]"
                      [Info]="'Zurückgelegte horizontale Strecke'"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Vertikale Strecke' | translate"
                      [Error]="VerticalKilometer?.isError || VerticalMeter?.isError"
                      [ErrorLevel]="VerticalKilometer?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[VerticalKilometer?.TimeseriesData]"
                      [MaintenanceValue]="VerticalKilometer?.ValueLastService"
                      [Unit]="'km'"
                      [Legend]="['Distanz' | translate, 'Distanz stand Wartung' | translate]"
                      [Info]="'Zurückgelegte vertikale Strecke'"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Teleskop Strecke' | translate"
                      [Error]="TelescopeKilometer?.isError || TelescopeMeter?.isError"
                      [ErrorLevel]="TelescopeKilometer?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[TelescopeKilometer?.TimeseriesData]"
                      [MaintenanceValue]="TelescopeKilometer?.ValueLastService"
                      [Unit]="'km'"
                      [Legend]="['Distanz' | translate, 'Distanz stand Wartung' | translate]"
                      [Info]="'Zurückgelegte Strecke der Teleskoparme'"
                      [resizeEvent]="childResizeEvent"></app-default-card>

</div>
