<div style="height: calc(100% - {{groupHeaderHeight}});"
     class="card rounded-0"
     #cardfull
     [class.border-danger]="Error && ErrorLevel === monitoringLevel.Error"
     [class.border-warning]="Error && ErrorLevel === monitoringLevel.Warning"
     [class.border-info]="Error && ErrorLevel === monitoringLevel.Info">

    <div *ngIf="Heading != null" class="card-header">
        {{Heading}}
    </div>
    <div class="card-body" *ngIf="Value != null">
        <ng-container *ngIf="Value[0].currentDatapoint.value != null; else noData">
            <ng-container *ngIf="!showChart && !showInfo">
                <div [style.fontSize]="fontsize" class="textParent">

                    <ng-container *ngIf="MaintenanceValue == null">
                        <div [style.fontSize]="fontsizefull">
                            <ng-container *ngIf="Value[1] == null">
                                {{Value[0].currentDatapoint.value | number: '1.0-0'}} {{Unit}}
                            </ng-container>
                            <ng-container *ngIf="Value[1] != null">
                                {{[Value[0].currentDatapoint.value, Value[1].currentDatapoint.value] | calcDistance: '1.2-2'}} {{Unit}}
                            </ng-container>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="Value[1] == null && MaintenanceValue != null">
                        <div class="card-group">
                            <div #card class="card rounded-0"
                                 style="height: 100%; border-left:0;border-bottom:0;border-top:0;">

                                <div class="card-header" style="padding:5px; background: none; text-align:center">
                                    Insgesamt
                                </div>
                                <div class="card-body" [style.fontSize]="fontsize"
                                     style="height: 100%; padding:5px; display: flex; justify-content: center; align-items: center">
                                    {{Value[0].currentDatapoint.value | number: '1.0-0'}} {{Unit}}
                                </div>
                            </div>
                            <div class="card rounded-0" style="height: 100%; border-top:0;border-bottom:0;">

                                <div class="card-header" style="padding:5px;background: none;text-align:center">
                                    Seit Wartung
                                </div>
                                <div class="card-body" [style.fontSize]="fontsize"
                                     style="height: 100%; padding:5px;display: flex; justify-content: center; align-items: center">
                                    {{Value[0].currentDatapoint.value | number: '1.0-0' | calcDifference: MaintenanceValue | number:'1.0-0'}}
                                    {{Unit}}
                                </div>
                            </div>
                            <div class="card rounded-0" style="height: 100%; border: 0;">

                                <div class="card-header" style="padding:5px; background: none; text-align:center">
                                    Stand Wartung
                                </div>
                                <div class="card-body" [style.fontSize]="fontsize"
                                     style="height: 100%; padding:5px;display: flex; justify-content: center; align-items: center">
                                    {{MaintenanceValue}} {{Unit}}
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="Value[0] != null && Value[1] != null && MaintenanceValue != null">
                        <div class="card-group">
                            <div #card class="card rounded-0"
                                 style="height: 100%; border-left:0;border-bottom:0;border-top:0;">

                                <div class="card-header" style="padding:5px; background: none; text-align:center">
                                    Insgesamt
                                </div>
                                <div class="card-body" [style.fontSize]="fontsize"
                                     style="height: 100%; padding:5px; display: flex; justify-content: center; align-items: center">
                                    {{[Value[0].currentDatapoint.value, Value[1].currentDatapoint.value] | calcDistance: '1.2-2'}} {{Unit}}
                                </div>
                            </div>
                            <div class="card rounded-0" style="height: 100%; border-top:0;border-bottom:0;">

                                <div class="card-header" style="padding:5px;background: none;text-align:center">
                                    Seit Wartung
                                </div>
                                <div class="card-body" [style.fontSize]="fontsize"
                                     style="height: 100%; padding:5px;display: flex; justify-content: center; align-items: center">
                                    {{[Value[0].currentDatapoint.value, Value[1].currentDatapoint.value] | calcDistance: '1.2-2' | calcDifference: MaintenanceValue | number:'1.2-2'}}
                                    {{Unit}}
                                </div>
                            </div>
                            <div class="card rounded-0" style="height: 100%; border: 0;">

                                <div class="card-header" style="padding:5px; background: none; text-align:center">
                                    Stand Wartung
                                </div>
                                <div class="card-body" [style.fontSize]="fontsize"
                                     style="height: 100%; padding:5px;display: flex; justify-content: center; align-items: center">
                                    {{MaintenanceValue}} {{Unit}}
                                </div>
                            </div>
                        </div>
                    </ng-container>

                </div>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="showChart && !showInfo">
            <ng-container *ngIf="MaintenanceValue != null; else noData">
                <ng-container *ngIf="Value.values.length > 0; else noData">
                    <app-linechart [legend]="Legend"
                                   [value]="[Value[0].values, Value[1].values]"
                                   [resizeEvent]="childResizeEvent">
                    </app-linechart>
                </ng-container>
            </ng-container>
        </ng-container>


        <ng-container *ngIf="showInfo">
            {{Info}}
        </ng-container>

        <ng-container *ngIf="customTemplate != null && !showInfo" [ngTemplateOutlet]="customTemplate"></ng-container>

    </div>


</div>


<ng-template #noData>
    <div class="text-center" [style.width]="width" [style.height]="height">
        <img title="Keine aktuellen Daten vorhanden!" [style.width]="width" [style.height]="height"
             [src]="environment.storageUrl + '/icons/cloud_off_black_18dp.png'"/>
    </div>
</ng-template>
