import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'reorganisationStatus'
})
export class ReorganisationStatusPipe implements PipeTransform
{
    transform(count: number): string
    {
        if (count <= 0)
        {
            return 'OK';
        }
        else
        {
            return 'Fehler';
        }
    }
}
