import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import { Property } from '../../../core/model/helper-models/property.model';


@Component({
    selector: 'app-temperature-current',
    templateUrl: './temperature-current.component.html',
    styleUrls: ['./temperature-current.component.scss', './../card.scss']
})
export class TemperatureCurrentComponent extends BaseViewElement implements OnChanges
{
    @Input() Motor: PropertySet;

    Temperature: Property<any>;
    Current: Property<any>;

    constructor()
    {
        super('temperature-current');
        this.chart = true;
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit(result);
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.Motor == null) return;
        this.Temperature = this.Motor.properties.get('TEMPERATUR');
        this.Current = this.Motor.properties.get('STROM');
    }

}
