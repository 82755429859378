export class PowerbiConfigModel
{

    public Health: {
        GroupId: string,
        ReportId: string,
        ReportIdEn: string
    };

    public Insight: {
        GroupId: string,
        ReportId: string
    };

    public Testdrive: {
        GroupId: string,
        ReportId: string
    };

    constructor(pHealth: Array<any>, pInsight: Array<any>, pTestdrive: Array<any>)
    {

        if (pHealth.length > 0)
        {
            this.Health = {
                GroupId: pHealth[0].groupId,
                ReportId: pHealth[0].reportId,
                ReportIdEn: pHealth[0].reportIdEn
            };
        }

        if (pInsight.length > 0)
        {
            this.Insight = {
                GroupId: pInsight[0].groupId,
                ReportId: pInsight[0].reportId
            };
        }

        if (pTestdrive.length > 0)
        {
            this.Testdrive = {
                GroupId: pTestdrive[0].groupId,
                ReportId: pTestdrive[0].reportId
            };
        }

    }

}
