<app-group-header [Heading]="'Information' | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>
<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Wirkarbeit' | translate"
                      [Error]="RealWork?.isError"
                      [ErrorLevel]="RealWork?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[RealWork?.TimeseriesData]"
                      [Unit]="'Wh'"
                      [Legend]="['Wirkarbeit' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'LED Panel' | translate"
                      [Error]="LEDWork?.isError"
                      [ErrorLevel]="LEDWork?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [customTemplate]="custom"
                      [customNoData]="LEDWork?.TimeseriesData.values.length < 1"
                      [Legend]="['LED  Panel' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <ng-template #custom>
        <div class="card" style="width: 100%;">
            <ul class="list-group list-group-flush"
                style="height: 100%; border-radius: 0; font-size: 12px; color: white; text-align: center">
                <li [class.disabled]="LEDWork?.TimeseriesData.currentDatapoint.value !== 1" class="list-group-item"
                    style="background: green; flex: 1; padding: 0; align-items: center; display: flex; justify-content: center;">
                    <div>OK</div>
                </li>
                <li [class.disabled]="LEDWork?.TimeseriesData.currentDatapoint.value !== 2" class="list-group-item"
                    style="background: blue; flex: 1; padding: 0; align-items: center; display: flex; justify-content: center;">
                    <div>Warnung</div>
                </li>
                <li [class.disabled]="LEDWork?.TimeseriesData.currentDatapoint.value !== 3" class="list-group-item"
                    style="background: red; flex: 1; padding: 0; align-items: center; display: flex; justify-content: center;">
                    <div>Fehler</div>
                </li>
            </ul>
        </div>
    </ng-template>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Betriebsstunden' | translate"
                      [Error]="OperatingHours?.isError"
                      [ErrorLevel]="OperatingHours?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[OperatingHours?.TimeseriesData]"
                      [Unit]="'h'"
                      [Legend]="['Betriebsstunden' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>


</div>
