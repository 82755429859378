<ng-container
>
<h2>

    <span *ngIf="this.data.data.entity_type !== 'komponenten'">
        {{ this.data.node.name | translate}}
    </span>

    <span *ngIf="this.data.data.entity_type === 'komponenten'">
        {{'Komponente' | translate}}: {{ this.data.node.name | translate}}
    </span>

    <span style="float: right">
        <button mat-button (click)="closeModal()">
            <mat-icon>close</mat-icon>
        </button>
    </span>
</h2>

<!-- Display the thing details -->
<div
    class="dialog-container"
    *ngIf="this.data.data.entity_type === 'thing'"
>

    <div
        class="dialog-container"
        *ngIf="'live_aggregation' in this.data.data">

        <div class="gauge-chart-container">
            <app-gaugechart
                [name]="'Health'"
                [unit]="'%'"
                [title]="'Durchschnittlich gesund' | translate"
                [legend]=""
                [value]="[this.data.iData.avg_health]"
                [warning]="true"
            >
            </app-gaugechart>

<!--            {{this.data.iData | json}}-->

            <!--<app-gaugechart-->
                <!--[setOptions]="gaugeOptions"-->
                <!--[legend]=""-->
                <!--[value]="[this.data.iData.avg_health]"-->
            <!--&gt;-->
            <!--</app-gaugechart>-->
        </div>
        <div class="info-container">
            <br>
            <table class="table caption-top">
                <caption>
                    {{'Alert Zusammenfassung' | translate}}
                </caption>
                <tbody>
                <tr>
                    <td>
                        <button
                            mat-flat-button
                            color="{{this.data.iData.warning.length > 0? 'warning' : 'allcool'}}"
                            (click)="showAlert(this.data.iData.warning, 'warning')"
                            disabled="{{!(this.data.iData.warning.length > 0)}}"
                        >
                            {{this.data.iData.warning.length | translate}} {{'WARNUNGEN' | translate}}
                        </button>
                    </td>
                    <td>
                        <button
                            mat-flat-button
                            color="{{this.data.iData.alerting.length > 0? 'error' : 'allcool'}}"
                            (click)="showAlert(this.data.iData.alerting, 'alerting')"
                            disabled="{{!(this.data.iData.alerting.length > 0)}}"
                        >
                            {{this.data.iData.alerting.length | translate}} {{'ALERTS' | translate}}
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<!-- Display Bauteil details -->
<div
    class="dialog-container"
    *ngIf="this.data.data.entity_type === 'bauteil'"
>

    <div
        class="dialog-container"
        *ngIf="'live_aggregation' in this.data.data">
        <div class="gauge-chart-container">
            <app-gaugechart
                [name]="'Health'"
                [unit]="'%'"
                [title]="'Durchschnittlich gesund' | translate"
                [legend]=""
                [value]="[this.data.iData.avg_health]"
                [warning]="true"
            >
            </app-gaugechart>
        </div>
        <div class="info-container">
            <br>
            <table class="table caption-top">
                <caption>
                    {{'Alert Zusammenfassung' | translate}}
                </caption>
                <tbody>
                <tr>
                    <td>
                        <button
                            mat-flat-button
                            color="{{this.data.iData.warning.length > 0? 'warning' : 'allcool'}}"
                            (click)="showAlert(this.data.iData.warning, 'warning')"
                            disabled="{{!(this.data.iData.warning.length > 0)}}"
                        >
                            {{this.data.iData.warning.length | translate}} {{'WARNUNGEN' | translate}}
                        </button>
                    </td>
                    <td>
                        <button
                            mat-flat-button
                            color="{{this.data.iData.alerting.length > 0? 'error' : 'allcool'}}"
                            (click)="showAlert(this.data.iData.alerting, 'alerting')"
                            disabled="{{!(this.data.iData.alerting.length > 0)}}"
                        >
                            {{this.data.iData.alerting.length | translate}} {{'ALERTS' | translate}}
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>


<!-- Display Komponente details -->
<div
    *ngIf="this.data.data.entity_type === 'komponenten'"
    class="dialog-container"
>
    <!--<div>-->
        <!--Komponente Name: {{ this.data.data.komponenten_name_de }}-->
    <!--</div>-->
    <div class="gauge-chart-container">
        <app-ringchart [(data)]="injInfoPoints"></app-ringchart>
<!--        {{this.data.data.messgroessen | json}}-->
    </div>

    <div class="info-container">
        <div class="container">
            <table class="table caption-top">
                <caption>
                    {{'Berechnung' | translate}}: {{'Health' | translate}}
                </caption>
                <thead>
                    <tr scope="col">
                        <th>{{'Messgroesse' | translate}}</th>
                        <th>{{'Grenzwert' | translate}}</th>
                        <th>{{'Live Value' | translate}}</th>
                        <!--<th>Kalkulation</th>-->
                        <th>Health</th>
                    </tr>
                    <tr scope="col">
                        <th>{{'Zeile' | translate}}</th>
                        <th>GW</th>
                        <th>V</th>
                        <!--<th>(1 - V/GW)*100%</th>-->
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let tableRow of this.data.data.messgroessen">
                        <th scope="row">{{tableRow.messgroessen_name | translate}}</th>
                        <td>{{tableRow.momentaner_grenzwert}}</td>
                        <td>{{tableRow.live_value}}</td>
                        <!--<td><em>(1 - {{tableRow.live_value}}/{{tableRow.momentaner_grenzwert}}) * 100</em></td>-->
                        <td>{{tableRow.health_percentage}} %</td>
                    </tr>
                </tbody>
            </table>
            <br>

            <table class="table caption-top">
                <caption>
                    {{'Entity Information' | translate}}
                </caption>
                <thead>
                <tr>
                    <th>{{'Entity' | translate}}</th>
                    <th>{{'Entity Typ' | translate}}</th>
                    <th>{{'Entity Beschreibung' | translate}}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        {{this.data.node.name | translate}}
                    </td>
                    <td>
                        {{'Komponente' | translate}}
                    </td>
                    <td>
                        {{this.data.data.komponenten_instance_description | translate}}
                    </td>
                </tr>
                </tbody>
            </table>

            <!--<table class="table caption-top" *ngIf="allAlerts.length > 0">-->
                <!--<caption>-->
                    <!--Alert Summary-->
                <!--</caption>-->
                <!--<thead>-->
                    <!--<tr>-->
                        <!--<th>-->

                        <!--</th>-->
                        <!--<th>Messgroesse</th>-->
                        <!--<th>Live Value</th>-->
                        <!--<th>Grenzwert</th>-->
                        <!--<th>Health Nachricht</th>-->
                    <!--</tr>-->
                <!--</thead>-->
                <!--<tbody >-->
                    <!--<tr-->
                        <!--*ngFor="let warning of this.data.iData.warning"-->
                    <!--&gt;-->
                        <!--<td>WARNING</td>-->
                        <!--<td>{{warning.messgroessen_name}}</td>-->
                        <!--<td>{{warning.live_value}}</td>-->
                        <!--<td>{{warning.momentaner_grenzwert}}</td>-->
                        <!--<td>-->
                            <!--{{warning.health_percentage}}% : Unterhalb der Warnstufe-->
                        <!--</td>-->
                    <!--</tr>-->


                    <!--<tr-->
                        <!--*ngFor="let alerting of this.data.iData.alerting"-->
                    <!--&gt;-->
                        <!--<td>ALERT</td>-->
                        <!--<td>{{alerting.messgroessen_name}}</td>-->
                        <!--<td>{{alerting.live_value}}</td>-->
                        <!--<td>{{alerting.momentaner_grenzwert}}</td>-->
                        <!--<td>-->
                            <!--{{alerting.health_percentage}}% : Unterhalb der Stufe CRITICAL-->
                        <!--</td>-->
                    <!--</tr>-->

                <!--</tbody>-->
            <!--</table>-->
        </div>
    </div>
</div>
</ng-container>
