<mat-card appearance="outlined" class="card_block">
    <h2>

    <span *ngIf="inData.data.entity_type !== 'komponenten'">
        {{ nodeTitle | translate}}
    </span>
        <span *ngIf="inData.data.entity_type === 'komponenten'">
        {{'Komponente' | translate}}: {{
            translate.defaultLang === 'de' ? inData.data.komponenten_name_de :
                inData.data.komponenten_name_en
                | translate
            }}
    </span>

    </h2>

    <!-- Display the thing details -->
    <div
        class="dialog-container"
        *ngIf="inData.data.entity_type === 'thing'"
    >

        <div
            class="dialog-container"
            *ngIf="'live_aggregation' in inData.data">

            <div class="gauge-chart-container-card">
                <app-gaugechart
                    [name]="'Health'"
                    [unit]="'%'"
                    [title]="'Durchschnittlich gesund' | translate"
                    [legend]=""
                    [value]="[inData.iData.avg_health]"
                >
                </app-gaugechart>
            </div>
            <div class="info-container">
                <br>
                <table class="table caption-top">
                    <caption>
                        {{'Alert Zusammenfassung' | translate}}
                    </caption>
                    <tbody>
                    <tr>
                        <td>
                                {{inData.iData.warning.length}} {{'WARNUNGEN' | translate}}
                        </td>
                        <td>
                                {{inData.iData.alerting.length}} {{'ALERTS' | translate}}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <!-- Display Bauteil details -->
    <div
        class="dialog-container"
        *ngIf="inData.data.entity_type === 'bauteil'"
    >

        <div
            class="dialog-container"
            *ngIf="'live_aggregation' in inData.data">
            <div class="gauge-chart-container-card">
                <app-gaugechart
                    [name]="'Health'"
                    [unit]="'%'"
                    [title]="'Durchschnittlich gesund' | translate"
                    [legend]=""
                    [value]="[inData.iData.avg_health]"
                >
                </app-gaugechart>
            </div>
            <div class="info-container">
                <br>
                <table class="table caption-top">
                    <caption>
                        {{'Alert Zusammenfassung' | translate}}
                    </caption>
                    <tbody>
                    <tr>
                        <td>
                                {{inData.iData.warning.length}} {{'WARNUNGEN' | translate}}
                        </td>
                        <td>
                                {{inData.iData.alerting.length}} {{'ALERTS' | translate}}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>


    <!-- Display Komponente details -->
    <div
        *ngIf="inData.data.entity_type === 'komponenten'"
        class="dialog-container"
    >
        <!--<div>-->
        <!--Komponente Name: {{ inData.data.komponenten_name_de }}-->
        <!--</div>-->
        <div class="gauge-chart-container-card">
            <app-ringchart [(data)]="injInfoPoints"></app-ringchart>
        </div>

        <div class="info-container">
            <div class="container">
                <table class="table caption-top">
                    <caption>
                        {{'Berechnung: Health' | translate}}
                    </caption>
                    <thead>
                    <tr scope="col">
                        <th>{{'Messgroesse' | translate}}</th>
                        <th>{{'Grenzwert' | translate}}</th>
                        <th>{{'Live Value' | translate}}</th>
                        <!--<th>Kalkulation</th>-->
                        <th>{{'Health' | translate}}</th>
                    </tr>
                    <tr scope="col">
                        <th>{{'Zeile' | translate}}</th>
                        <th>GW</th>
                        <th>V</th>
                        <!--<th>(1 - V/GW)*100%</th>-->
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let tableRow of inData.data.messgroessen">
                        <th scope="row">{{tableRow.messgroessen_name | translate}}</th>
                        <td>{{tableRow.momentaner_grenzwert}}</td>
                        <td>{{tableRow.live_value}}</td>
                        <!--<td><em>(1 - {{tableRow.live_value}}/{{tableRow.momentaner_grenzwert}}) * 100</em></td>-->
                        <td>{{tableRow.health_percentage}} %</td>
                    </tr>
                    </tbody>
                </table>
                <br>

                <table class="table caption-top">
                    <caption>
                        {{'Entity Information' | translate}}
                    </caption>
                    <thead>
                    <tr>
                        <th>{{'Entity' | translate}}</th>
                        <th>{{'Entity Typ' | translate}}</th>
                        <th>{{'Entity Beschribung' | translate}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            {{inData.data.komponenten_name_de | translate}}
                        </td>
                        <td>
                            Komponente
                        </td>
                        <td>
                            {{inData.data.komponenten_instance_description | translate}}
                        </td>
                    </tr>
                    </tbody>
                </table>

                <!--<table class="table caption-top" *ngIf="allAlerts.length > 0">-->
                <!--<caption>-->
                <!--Alert Summary-->
                <!--</caption>-->
                <!--<thead>-->
                <!--<tr>-->
                <!--<th>-->

                <!--</th>-->
                <!--<th>Messgroesse</th>-->
                <!--<th>Live Value</th>-->
                <!--<th>Grenzwert</th>-->
                <!--<th>Health Nachricht</th>-->
                <!--</tr>-->
                <!--</thead>-->
                <!--<tbody >-->
                <!--<tr-->
                <!--*ngFor="let warning of inData.iData.warning"-->
                <!--&gt;-->
                <!--<td>WARNING</td>-->
                <!--<td>{{warning.messgroessen_name}}</td>-->
                <!--<td>{{warning.live_value}}</td>-->
                <!--<td>{{warning.momentaner_grenzwert}}</td>-->
                <!--<td>-->
                <!--{{warning.health_percentage}}% : Unterhalb der Warnstufe-->
                <!--</td>-->
                <!--</tr>-->


                <!--<tr-->
                <!--*ngFor="let alerting of inData.iData.alerting"-->
                <!--&gt;-->
                <!--<td>ALERT</td>-->
                <!--<td>{{alerting.messgroessen_name}}</td>-->
                <!--<td>{{alerting.live_value}}</td>-->
                <!--<td>{{alerting.momentaner_grenzwert}}</td>-->
                <!--<td>-->
                <!--{{alerting.health_percentage}}% : Unterhalb der Stufe CRITICAL-->
                <!--</td>-->
                <!--</tr>-->

                <!--</tbody>-->
                <!--</table>-->
            </div>
        </div>
    </div>

</mat-card>
