import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BronzeGuard} from '../guards/bronze.guard';
import {GoldGuard} from '../guards/gold.guard';
import {PlatinumGuard} from '../guards/platinum.guard';
import {SilverGuard} from '../guards/silver.guard';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [],
    providers: [
        BronzeGuard,
        SilverGuard,
        GoldGuard,
        PlatinumGuard
    ]
})
export class GuardModule
{
}
