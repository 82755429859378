<p>monitoring-timeseries works!</p>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>
            <span [style.paddingLeft.px]="40"> Name </span>
        </th>
        <td mat-cell *matCellDef="let data">
            <button mat-icon-button
                    [style.visibility]="!data.expandable || data.loading ? 'hidden' : ''"
                    [style.marginLeft.px]="data.level * 32"
                    (click)="ExpandKeepOpen(data)">
                <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(data)  ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
            </button>

            {{data.name}}
        </td>
    </ng-container>

    <ng-container matColumnDef="things">
        <th mat-header-cell *matHeaderCellDef> Things > 12 Stunden </th>
        <td mat-cell *matCellDef="let data"> {{data.count}} </td>
    </ng-container>

    <ng-container matColumnDef="timestamp">
        <th mat-header-cell *matHeaderCellDef> Timestamp </th>
        <td mat-cell *matCellDef="let data"> {{data.timestamp | date:"dd.MM.yyyy HH:mm:ss z"}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>



<!--<button (click)="AddOne()"-->
<!--     class="monitoring-text-card-body">-->
<!--</button>-->
