<app-group-header [Heading]="'Energieverbrauch' | translate"
                  [chart]="chart"
                  [info]="info"
                  [noChart]="true"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>
<div class="card-group">

    <div #cardWidth
         class="card rounded-0"
         [class.border-danger]="Energy?.properties.get('ACTIVE_ENERGY_PRICE')?.isError && Energy?.properties.get('ACTIVE_ENERGY_PRICE')?.status === monitoringLevel.Error"
         [class.border-warning]="Energy?.properties.get('ACTIVE_ENERGY_PRICE')?.isError && Energy?.properties.get('ACTIVE_ENERGY_PRICE')?.status === monitoringLevel.Warning"
         [class.border-info]="Energy?.properties.get('ACTIVE_ENERGY_PRICE')?.isError && Energy?.properties.get('ACTIVE_ENERGY_PRICE')?.status === monitoringLevel.Info">

        <div class="card-header">
            {{'Energiekosten' | translate}}
        </div>

        <ng-container *ngIf="!chart && !info">
            <div class="card-body" style="padding: 0;" [style.fontSize]="fontsize">
                <ng-container *ngIf="Energy?.properties.get('ACTIVE_ENERGY_PRICE')?.TimeseriesData.currentDatapoint.value != null; else noData;">
                    {{Energy?.properties.get('ACTIVE_ENERGY_PRICE')?.TimeseriesData.currentDatapoint.value | number:'1.2-2'}} €
                </ng-container>
            </div>
        </ng-container>

        <ng-container *ngIf="chart && !info">
            <ng-container *ngIf="Energy?.properties.get('ACTIVE_ENERGY_PRICE')?.TimeseriesData.values != null; else placeholdLine">
                <ng-container *ngIf="Energy?.properties.get('ACTIVE_ENERGY_PRICE')?.TimeseriesData.values[0] != null; else placeholdLine">
                    <app-linechart [legend]="['Energiekosten']"
                                   [value]="[Energy?.properties.get('ACTIVE_ENERGY_PRICE')?.TimeseriesData.values]"
                                   [resizeEvent]="childResizeEvent">
                    </app-linechart>
                </ng-container>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="info">
            <div class="card-body">
                {{'Preis pro Kilowattstunde' | translate}}
            </div>
        </ng-container>

    </div>

    <div class="card rounded-0"
         [class.border-danger]="Energy?.properties.get('ACTIVE_ENERGY_HOUR')?.isError && Energy?.properties.get('ACTIVE_ENERGY_HOUR')?.status === monitoringLevel.Error"
         [class.border-warning]="Energy?.properties.get('ACTIVE_ENERGY_HOUR')?.isError && Energy?.properties.get('ACTIVE_ENERGY_HOUR')?.status === monitoringLevel.Warning"
         [class.border-info]="Energy?.properties.get('ACTIVE_ENERGY_HOUR')?.isError && Energy?.properties.get('ACTIVE_ENERGY_HOUR')?.status === monitoringLevel.Info">

        <div class="card-header">
            {{'Stunde' | translate}}
        </div>

        <ng-container *ngIf="!chart && !info">
            <ng-container *ngIf="Energy?.properties.get('ACTIVE_ENERGY_HOUR')?.TimeseriesData.currentDatapoint.value != null; else noData;">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item" [style.fontSize]="fontsize">
                        {{Energy?.properties.get('ACTIVE_ENERGY_HOUR')?.TimeseriesData.currentDatapoint.value | number:'1.2-2'}} kWh
                    </li>
                    <li class="list-group-item" [style.fontSize]="fontsize">
                        {{Energy?.properties.get('ACTIVE_ENERGY_HOUR')?.TimeseriesData.currentDatapoint.value | calcPrice: Energy?.properties.get('ACTIVE_ENERGY_PRICE')?.TimeseriesData.currentDatapoint.value: false}}
                    </li>
                </ul>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="chart && !info">
            <ng-container *ngIf="Energy?.properties.get('ACTIVE_ENERGY_HOUR')?.TimeseriesData.values != null">
                <ng-container *ngIf="Energy?.properties.get('ACTIVE_ENERGY_HOUR')?.TimeseriesData.values[0] != null">
                    <app-linechart [legend]="['Energieverbrauch Stunde']"
                                   [value]="[Energy?.properties.get('ACTIVE_ENERGY_HOUR')?.TimeseriesData.values]"
                                   [resizeEvent]="childResizeEvent">
                    </app-linechart>
                </ng-container>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="info">
            <div class="card-body">
                {{'Energieverbrauch in einer Stunde' | translate}}
            </div>
        </ng-container>

    </div>

    <div class="card rounded-0"
         [class.border-danger]="Energy?.properties.get('ACTIVE_ENERGY_WEEK')?.isError && Energy?.properties.get('ACTIVE_ENERGY_WEEK')?.status === monitoringLevel.Error"
         [class.border-warning]="Energy?.properties.get('ACTIVE_ENERGY_WEEK')?.isError && Energy?.properties.get('ACTIVE_ENERGY_WEEK')?.status === monitoringLevel.Warning"
         [class.border-info]="Energy?.properties.get('ACTIVE_ENERGY_WEEK')?.isError && Energy?.properties.get('ACTIVE_ENERGY_WEEK')?.status === monitoringLevel.Info">

        <div class="card-header">
            {{'Woche' | translate}}
        </div>

        <ng-container *ngIf="!chart && !info">
            <ng-container *ngIf="Energy?.properties.get('ACTIVE_ENERGY_WEEK')?.TimeseriesData.currentDatapoint.value != null; else noData;">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item" [style.fontSize]="fontsize">
                        {{Energy?.properties.get('ACTIVE_ENERGY_WEEK')?.TimeseriesData.currentDatapoint.value | number:'1.2-2'}} kWh
                    </li>
                    <li class="list-group-item" [style.fontSize]="fontsize">
                        {{Energy?.properties.get('ACTIVE_ENERGY_WEEK')?.TimeseriesData.currentDatapoint.value | calcPrice: Energy?.properties.get('ACTIVE_ENERGY_PRICE')?.TimeseriesData.currentDatapoint.value: false}}
                    </li>
                </ul>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="chart && !info">
            <ng-container *ngIf="Energy?.properties.get('ACTIVE_ENERGY_WEEK')?.TimeseriesData.values != null;">
                <ng-container *ngIf="Energy?.properties.get('ACTIVE_ENERGY_WEEK')?.TimeseriesData.values[0] != null;">
                    <app-linechart [legend]="['Energieverbrauch Woche']"
                                   [value]="[Energy?.properties.get('ACTIVE_ENERGY_WEEK')?.TimeseriesData.values]"
                                   [resizeEvent]="childResizeEvent">
                    </app-linechart>
                </ng-container>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="info">
            <div class="card-body">
                {{'Energieverbrauch in einer Woche' | translate}}
            </div>
        </ng-container>

    </div>

    <div class="card rounded-0"
         [class.border-danger]="Energy?.properties.get('ACTIVE_ENERGY_MONTH')?.isError && Energy?.properties.get('ACTIVE_ENERGY_MONTH')?.status === monitoringLevel.Error"
         [class.border-warning]="Energy?.properties.get('ACTIVE_ENERGY_MONTH')?.isError && Energy?.properties.get('ACTIVE_ENERGY_MONTH')?.status === monitoringLevel.Warning"
         [class.border-info]="Energy?.properties.get('ACTIVE_ENERGY_MONTH')?.isError && Energy?.properties.get('ACTIVE_ENERGY_MONTH')?.status === monitoringLevel.Info">

        <div class="card-header">
            {{'Monat' | translate}}
        </div>

        <ng-container *ngIf="!chart && !info">
            <ng-container *ngIf="Energy?.properties.get('ACTIVE_ENERGY_MONTH')?.TimeseriesData.currentDatapoint.value != null; else noData;">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item" [style.fontSize]="fontsize">
                        {{Energy?.properties.get('ACTIVE_ENERGY_MONTH')?.TimeseriesData.currentDatapoint.value | number:'1.2-2'}} kWh
                    </li>
                    <li class="list-group-item" [style.fontSize]="fontsize">
                        {{Energy?.properties.get('ACTIVE_ENERGY_MONTH')?.TimeseriesData.currentDatapoint.value | calcPrice: Energy?.properties.get('ACTIVE_ENERGY_PRICE')?.TimeseriesData.currentDatapoint.value: false}}
                    </li>
                </ul>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="chart && !info">
            <ng-container *ngIf="Energy?.properties.get('ACTIVE_ENERGY_MONTH')?.TimeseriesData.values != null;">
                <ng-container *ngIf="Energy?.properties.get('ACTIVE_ENERGY_MONTH')?.TimeseriesData.values[0] != null;">
                    <app-linechart [legend]="['Energieverbrauch Monat']"
                                   [value]="[Energy?.properties.get('ACTIVE_ENERGY_MONTH')?.TimeseriesData.values]"
                                   [resizeEvent]="childResizeEvent">
                    </app-linechart>
                </ng-container>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="info">
            <div class="card-body">
                {{'Energieverbrauch in einem Monat' | translate}}
            </div>
        </ng-container>

    </div>

    <div class="card rounded-0"
         [class.border-danger]="Energy?.properties.get('ACTIVE_ENERGY_YEAR')?.isError && Energy?.properties.get('ACTIVE_ENERGY_YEAR')?.status === monitoringLevel.Error"
         [class.border-warning]="Energy?.properties.get('ACTIVE_ENERGY_YEAR')?.isError && Energy?.properties.get('ACTIVE_ENERGY_YEAR')?.status === monitoringLevel.Warning"
         [class.border-info]="Energy?.properties.get('ACTIVE_ENERGY_YEAR')?.isError && Energy?.properties.get('ACTIVE_ENERGY_YEAR')?.status === monitoringLevel.Info">

        <div class="card-header">
            {{'Jahr' | translate}}
        </div>

        <ng-container *ngIf="!chart && !info">
            <ng-container *ngIf="Energy?.properties.get('ACTIVE_ENERGY_YEAR')?.TimeseriesData.currentDatapoint.value != null; else noData;">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item" [style.fontSize]="fontsize">
                        {{Energy?.properties.get('ACTIVE_ENERGY_YEAR')?.TimeseriesData.currentDatapoint.value | number:'1.2-2'}} kWh
                    </li>
                    <li class="list-group-item" [style.fontSize]="fontsize">
                        {{Energy?.properties.get('ACTIVE_ENERGY_YEAR')?.TimeseriesData.currentDatapoint.value | calcPrice: Energy?.properties.get('ACTIVE_ENERGY_PRICE')?.TimeseriesData.currentDatapoint.value: false}}
                    </li>
                </ul>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="chart && !info">
            <ng-container *ngIf="Energy?.properties.get('ACTIVE_ENERGY_YEAR')?.TimeseriesData.values != null;">
                <ng-container *ngIf="Energy?.properties.get('ACTIVE_ENERGY_YEAR')?.TimeseriesData.values[0] != null;">
                    <app-linechart [legend]="['Energieverbrauch Jahr' | translate]"
                                   [value]="[Energy?.properties.get('ACTIVE_ENERGY_YEAR')?.TimeseriesData.values]"
                                   [resizeEvent]="childResizeEvent">
                    </app-linechart>
                </ng-container>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="info">
            <div class="card-body">
                {{'Energieverbrauch in einem Jahr' | translate}}
            </div>
        </ng-container>

    </div>

    <div class="card rounded-0"
         [class.border-danger]="Energy?.properties.get('ACTIVE_ENERGY_ALL')?.isError && Energy?.properties.get('ACTIVE_ENERGY_ALL')?.status === monitoringLevel.Error"
         [class.border-warning]="Energy?.properties.get('ACTIVE_ENERGY_ALL')?.isError && Energy?.properties.get('ACTIVE_ENERGY_ALL')?.status === monitoringLevel.Warning"
         [class.border-info]="Energy?.properties.get('ACTIVE_ENERGY_ALL')?.isError && Energy?.properties.get('ACTIVE_ENERGY_ALL')?.status === monitoringLevel.Info">

        <div class="card-header">
            {{'Gesamt' | translate}}
        </div>

        <ng-container *ngIf="!chart && !info">
            <ng-container *ngIf="Energy?.properties.get('ACTIVE_ENERGY_ALL')?.TimeseriesData.currentDatapoint.value != null; else noData;">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item" [style.fontSize]="fontsize">
                        {{Energy?.properties.get('ACTIVE_ENERGY_ALL')?.TimeseriesData.currentDatapoint.value | number:'1.2-2'}} kWh
                    </li>
                    <li class="list-group-item" [style.fontSize]="fontsize">
                        {{Energy?.properties.get('ACTIVE_ENERGY_ALL')?.TimeseriesData.currentDatapoint.value | calcPrice: Energy?.properties.get('ACTIVE_ENERGY_PRICE')?.TimeseriesData.currentDatapoint.value: false}}
                    </li>
                </ul>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="chart && !info">
            <ng-container *ngIf="Energy?.properties.get('ACTIVE_ENERGY_ALL')?.TimeseriesData.values != null;">
                <ng-container *ngIf="Energy?.properties.get('ACTIVE_ENERGY_ALL')?.TimeseriesData.values[0] != null;">
                    <app-linechart [legend]="['Energieverbrauch Gesamt' | translate]"
                                   [value]="[Energy?.properties.get('ACTIVE_ENERGY_ALL')?.TimeseriesData.values]"
                                   [resizeEvent]="childResizeEvent">
                    </app-linechart>
                </ng-container>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="info">
            <div class="card-body">
                {{'Energieverbrauch Gesamt' | translate}}
            </div>
        </ng-container>

    </div>

</div>

<ng-template #noData>
    <app-placeholder-card class="card" style="border: 0;"></app-placeholder-card>
</ng-template>

<ng-template #placeholdLine>
    <app-placehold-linechart></app-placehold-linechart>
</ng-template>
<!--

<span [style.fontSize]="fontsize">{{ReactivePowerValue | number:'1.2-2'}} VAR</span>
   <span [style.fontSize]="fontsize">{{ApparentPowerValue | number:'1.2-2'}} VA</span>
<app-text-tile class="col-lg-2 gap"
[heading]="'Energiekosten' | translate"
[unit]="'(€)' | translate"
[error]="thing.RBG_ENERGY.ACTIVE_ENERGY_PRICE.isError"
[directInput]="'&#8192;'"
[directInputRowTwo]="thing.RBG_ENERGY.ACTIVE_ENERGY_PRICE.TimeseriesData.currentDatapoint?.value | number:'1.2-2'"
[historicValues]="[thing.RBG_ENERGY.ACTIVE_ENERGY.TimeseriesData.values]"
[historicLegend]="['Energiekosten' | translate]">
</app-text-tile>

<app-text-tile class="col-lg-2 gap"
[heading]="'Stunde' | translate"
[unit]="'(kWh)' | translate"
[error]="thing.RBG_ENERGY.ACTIVE_ENERGY_HOUR.isError"
[directInput]="(thing.RBG_ENERGY.ACTIVE_ENERGY_HOUR.TimeseriesData.currentDatapoint?.value | number:'1.2-2')"
[directInputRowTwo]="(thing.RBG_ENERGY.ACTIVE_ENERGY_HOUR.TimeseriesData.currentDatapoint?.value | calcPrice: thing.RBG_ENERGY.ACTIVE_ENERGY_PRICE.TimeseriesData.currentDatapoint?.value: false)"
[historicValues]="[thing.RBG_ENERGY.ACTIVE_ENERGY.TimeseriesData.values]"
[historicLegend]="['Energieverbrauch Stunde' | translate]">
</app-text-tile>

<app-text-tile class="col-lg-2 gap"
[heading]="'Woche' | translate"
[unit]="'(kWh)' | translate"
[error]="thing.RBG_ENERGY.ACTIVE_ENERGY_WEEK.isError"
[directInput]="(thing.RBG_ENERGY.ACTIVE_ENERGY_WEEK.TimeseriesData.currentDatapoint?.value | number:'1.2-2')"
[directInputRowTwo]="(thing.RBG_ENERGY.ACTIVE_ENERGY_WEEK.TimeseriesData.currentDatapoint?.value | calcPrice: thing.RBG_ENERGY.ACTIVE_ENERGY_PRICE.TimeseriesData.currentDatapoint?.value: false)"
[historicValues]="[thing.RBG_ENERGY.APPARENT_ENERGY.TimeseriesData.values]"
[historicLegend]="['Energieverbrauch Woche' | translate]">
</app-text-tile>

<app-text-tile class="col-lg-2 gap"
[heading]="'Monat' | translate"
[unit]="'(kWh)' | translate"
[error]="thing.RBG_ENERGY.ACTIVE_ENERGY_MONTH.isError"
[directInput]="(thing.RBG_ENERGY.ACTIVE_ENERGY_MONTH.TimeseriesData.currentDatapoint?.value | number:'1.2-2')"
[directInputRowTwo]="(thing.RBG_ENERGY.ACTIVE_ENERGY_MONTH.TimeseriesData.currentDatapoint?.value | calcPrice: thing.RBG_ENERGY.ACTIVE_ENERGY_PRICE.TimeseriesData.currentDatapoint?.value: false)"
[historicValues]="[thing.RBG_ENERGY.APPARENT_ENERGY.TimeseriesData.values]"
[historicLegend]="['Energieverbrauch Monat' | translate]">
</app-text-tile>

<app-text-tile class="col-lg-2 gap"
[heading]="'Jahr' | translate"
[unit]="'(kWh)' | translate"
[error]="thing.RBG_ENERGY.ACTIVE_ENERGY_YEAR.isError"
[directInput]="(thing.RBG_ENERGY.ACTIVE_ENERGY_YEAR.TimeseriesData.currentDatapoint?.value | number:'1.2-2')"
[directInputRowTwo]="(thing.RBG_ENERGY.ACTIVE_ENERGY_YEAR.TimeseriesData.currentDatapoint?.value | calcPrice: thing.RBG_ENERGY.ACTIVE_ENERGY_PRICE.TimeseriesData.currentDatapoint?.value: false)"
[historicValues]="[thing.RBG_ENERGY.APPARENT_ENERGY.TimeseriesData.values]"
[historicLegend]="['Energieverbrauch Jahr' | translate]">
</app-text-tile>

<app-text-tile class="col-lg-2 gap"
[heading]="'Gesamt' | translate"
[unit]="'(kWh)' | translate"
[error]="thing.RBG_ENERGY.ACTIVE_ENERGY_ALL.isError"
[directInput]="(thing.RBG_ENERGY.ACTIVE_ENERGY_ALL.TimeseriesData.currentDatapoint?.value | number:'1.2-2')"
[directInputRowTwo]="(thing.RBG_ENERGY.ACTIVE_ENERGY_ALL.TimeseriesData.currentDatapoint?.value | calcPrice: thing.RBG_ENERGY.ACTIVE_ENERGY_PRICE.TimeseriesData.currentDatapoint?.value: false)"
[historicValues]="[thing.RBG_ENERGY.ACTIVE_ENERGY_ALL.TimeseriesData.values]"
[historicLegend]="['Energieverbrauch Gesamt' | translate]">
</app-text-tile>

-->
