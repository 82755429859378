<div class="row">

    <div class="col-lg-12 subheading">
        <h3>{{'Favoriten' | translate}}</h3>
    </div>

    <div *ngFor="let fav of favorite.Favorites" class="col-lg-2 monitoring-card">

        <app-card-component *ngIf="fav.type === 'COMPANY'" [customerDetail]="fav"></app-card-component>

        <app-card-component *ngIf="fav.type === 'LOCATION'" [customerDetail]="fav"></app-card-component>

        <app-card-component *ngIf="fav.type === 'SYSTEM'" [customerDetail]="fav"></app-card-component>

        <app-card-component *ngIf="fav.type !== 'COMPANY' &&
                                    fav.type !== 'LOCATION' &&
                                    fav.type !== 'SYSTEM'"
                            [thing]="fav">
        </app-card-component>
    </div>
</div>

<app-monitoring-console *ngIf="data.showMonitoring"
                        [criticalThingToShowInConsole]="monitoring.monitoredThings"></app-monitoring-console>
