import { Component, OnInit } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { MonitoringCloudService } from '../../../../core/controller/services/monitoring-cloud.service';
import { DataexplorerFlatNode, DataexplorerNode } from '../../../../core/model/dataexplorer-node.model';

@Component({
  selector: 'app-monitoring-timeseries',
  templateUrl: './monitoring-timeseries.component.html',
  styleUrls: ['./monitoring-timeseries.component.scss']
})
export class MonitoringTimeseriesComponent implements OnInit {

    displayedColumns: string[] = ['name', 'things', 'timestamp'];

    treeControl = new FlatTreeControl<DataexplorerFlatNode>(
        node => node.level, node => node.expandable);
    treeFlattener: MatTreeFlattener<DataexplorerNode, DataexplorerFlatNode>;
    dataSource: MatTreeFlatDataSource<DataexplorerNode, DataexplorerFlatNode>;

    constructor(private _monitoringService: MonitoringCloudService)
    {

    }

    async ngOnInit(): Promise<void>
    {
        this.treeFlattener = new MatTreeFlattener(
            this.transformer,
            node => node.level,
            node => node.expandable,
            node => node.children
        );
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
        this.dataSource.data = await this._monitoringService.getCloudData();
    }



    hasChild = (_: number, node: DataexplorerFlatNode) => node.expandable;

    private transformer = (node: DataexplorerNode, level: number) => {
        return {
            expandable: !!node.children && node.children.length > 0,
            id: node.id,
            name: node.name,
            count: node.count,
            timestamp: node.timestamp,
            loading: node.loading,
            childsLoaded: node.childsLoaded,
            children: node.children,
            level: level,
        };
    }

    public async ExpandKeepOpen(data: DataexplorerFlatNode)
    {
        this.treeControl.toggle(data);
        // remember last states
        const prevData = this.GetOnlyExpanded();

        // If the node was not loaded, check which level it is an
        // which data should be loaded
        if (!data.childsLoaded)
        {
            const level = this.treeControl.getLevel(data);
            const reqIds = data.children.map(child => child.id);

            switch (level)
            {
                case 0:
                {
                    await this._monitoringService.updateLocation('locations', reqIds);
                    break;
                }
                case 1:
                {
                    await this._monitoringService.updateSystem('systems', reqIds);
                    break;
                }
                case 2:
                {
                    await this._monitoringService.updateThing('things', data.id);
                    break;
                }
            }


            this.dataSource.data = await this._monitoringService.getCloudData();

            // Apply prev states
            this.treeControl.dataNodes.forEach(node =>
            {
                prevData.forEach(prevNode =>
                {
                    if (node.id === prevNode.id)
                    {
                        this.treeControl.expand(node);
                    }
                });
            });
        }
    }

    private GetOnlyExpanded(): DataexplorerFlatNode[]
    {
        let result: DataexplorerFlatNode[] = [];
        this.treeControl.dataNodes.forEach(node => {
            if (this.treeControl.isExpanded(node))
            {
                result.push(node);
            }
        });
        return result;
    }
}
