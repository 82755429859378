import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
    selector: 'app-cm-sensors-two',
    templateUrl: './cm-sensors-two.component.html',
    styleUrls: ['./cm-sensors-two.component.scss',
        './../card.scss']
})
export class CmSensorsTwoComponent extends BaseViewElement implements OnInit, OnChanges
{
    @Input() Type: string;
    @Input() Environment: PropertySet;
    @Input() Sound: PropertySet;
    @Input() AngularVelocity: PropertySet;
    @Input() Acceleration: PropertySet;
    @Input() Error = false;

    Temperature = new Property<any>(0);
    Humidity: Property<any>;
    SoundPressureLevel: Property<any>;
    DecibleFullScale: Property<any>;
    AngularVelocityX: Property<any>;
    AngularVelocityY: Property<any>;
    AngularVelocityZ: Property<any>;
    AccelerationX: Property<any>;
    AccelerationY: Property<any>;
    AccelerationZ: Property<any>;


    constructor()
    {
        super('cm-sensors');
        this.chart = true;
    }

    onResize(item: any)
    {
        this.childResizeEvent.emit();
    }

    ngOnChanges(changes: SimpleChanges): void
    {

        if (this.Environment != null)
        {
            this.Temperature = this.Environment?.properties.get('TEMPERATURE');
            this.Humidity = this.Environment?.properties.get('HUMIDITY');
        }

        if (this.Sound != null)
        {
            this.SoundPressureLevel = this.Sound?.properties.get('SOUND_PRESSURE_LEVEL');
            this.DecibleFullScale = this.Sound?.properties.get('DECIBEL_FULL_SCALE');
        }

        if (this.AngularVelocity != null)
        {
            this.AngularVelocityX = this.AngularVelocity?.properties.get('ANGULAR_VELOCITY_X');
            this.AngularVelocityY = this.AngularVelocity?.properties.get('ANGULAR_VELOCITY_Y');
            this.AngularVelocityZ = this.AngularVelocity?.properties.get('ANGULAR_VELOCITY_Z');
        }

        if (this.Acceleration != null)
        {
            this.AccelerationX = this.Acceleration.properties?.get('ACCELERATION_X');
            this.AccelerationY = this.Acceleration.properties?.get('ACCELERATION_Y');
            this.AccelerationZ = this.Acceleration.properties?.get('ACCELERATION_Z');
        }

    }
}
