import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {S7Info} from '../../../../core/model/gateway_extended_status.model';

@Component({
    selector: 'app-s7-custom-change-box',
    templateUrl: './s7-custom-change-box.component.html',
    styleUrls: ['./s7-custom-change-box.component.scss']
})
export class S7CustomChangeBoxComponent implements OnInit
{

    constructor(public dialogRef: MatDialogRef<S7CustomChangeBoxComponent>, @Inject(MAT_DIALOG_DATA) public s7Data: S7Info)
    {
    }

    changedBeginTime: string = this.s7Data.S7TestdriveData.LetzterAnfang;
    changedEndTime: string = this.s7Data.S7TestdriveData.LetztesEnde;
    changedActive: string = this.s7Data.S7TestdriveData.StartzeitMessfahrtenAktive;

    ngOnInit(): void
    {
    }

    // ToDo Better binding
    public changeBeginTime(event)
    {
        this.changedBeginTime = event['srcElement']['value'];
    }

    public changeEndTime(event)
    {
        this.changedEndTime = event['srcElement']['value'];
    }

    public changeActive(event)
    {
        this.changedActive = event['value'];
    }

    onNoClick(): void
    {
        this.dialogRef.close();
    }

    onSaveClick(): void
    {
        this.s7Data.S7TestdriveData.LetzterAnfang = this.changedBeginTime;
        this.s7Data.S7TestdriveData.LetztesEnde = this.changedEndTime;
        this.s7Data.S7TestdriveData.StartzeitMessfahrtenAktive = this.changedActive;
        this.dialogRef.close(this.s7Data);
    }

}
