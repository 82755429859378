<app-group-header [Heading]="'Statistik' | translate"
                  [chart]="chart"
                  [info]="info"
                  [noChart]="true"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>

<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Betriebsstunden' | translate"
                      [Error]="OperatingHoursError"
                      [ErrorLevel]="OperatingHoursErrorLevel"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Unit]="'h'"
                      [Values]="OperatingHoursValue"
                      [Legend]="['Betriebsstunden' | translate]"
                      [Info]="'Zeit in der der Aufzug gefahren ist.'"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Fahrten 1->2' | translate"
                      [Error]="JourneysSpecificError"
                      [ErrorLevel]="JourneysSpecificErrorLevel"
                      [showChart]="chart"
                      [showInfo]="info"
                      [customTemplate]="journey12"
                      [customNoData]="journeysSpecific == null"
                      [Legend]="['Fahrten 1->2' | translate]"
                      [Info]="'Fahrten von der Etage a zur Etage b'"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <ng-template #journey12>
        {{journeysSpecific[0][1]}}
    </ng-template>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Fahrten 2->1' | translate"
                      [Error]="JourneysSpecificError"
                      [ErrorLevel]="JourneysSpecificErrorLevel"
                      [showChart]="chart"
                      [showInfo]="info"
                      [customTemplate]="journey21"
                      [customNoData]="journeysSpecific == null"
                      [Legend]="['Fahrten 2->1' | translate]"
                      [Info]="'Fahrten von der Etage b zur Etage a'"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <ng-template #journey21>
        {{journeysSpecific[1][0]}}
    </ng-template>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Fahrten Gesamt' | translate"
                      [Error]="JourneysAllError"
                      [ErrorLevel]="JourneysAllErrorLevel"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="JourneysAllValue"
                      [Legend]="['Fahrten Gesamt' | translate]"
                      [Info]="'Fahrten, die insgesamt von dem Güteraufzug seit Inbetriebnahme/ Wartung durchgeführt wurden.'"
                      [resizeEvent]="childResizeEvent"></app-default-card>

</div>
