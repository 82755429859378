<app-group-header [Heading]="'Zeit' | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>

<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Gefahrene Strecke' | translate"
                      [Error]="KmCounterX?.isError"
                      [ErrorLevel]="KmCounterX?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[KmCounterX?.TimeseriesData]"
                      [Unit]="'km'"
                      [Legend]="['Gefahrene Strecke' | translate]"
                      [resizeEvent]="childResizeEvent">
    </app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Gefahrene Strecke Teleskop' | translate"
                      [Error]="TelescopeDistance?.isError"
                      [ErrorLevel]="TelescopeDistance?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[TelescopeDistance?.TimeseriesData]"
                      [Unit]="'km'"
                      [Legend]="['Gefahrene Strecke Teleskop' | translate]"
                      [resizeEvent]="childResizeEvent">
    </app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Betriebsstunden' | translate"
                      [Error]="OperationTime?.isError"
                      [ErrorLevel]="OperationTime?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[OperationTime?.TimeseriesData]"
                      [Unit]="'h'"
                      [Legend]="['Betriebsstunden' | translate]"
                      [resizeEvent]="childResizeEvent">
    </app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Fahrzeit' | translate"
                      [Error]="DrivingTime?.isError"
                      [ErrorLevel]="DrivingTime?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[DrivingTime?.TimeseriesData]"
                      [Unit]="'h'"
                      [Legend]="['Fahrzeit' | translate]"
                      [resizeEvent]="childResizeEvent">
    </app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Wartezeit' | translate"
                      [Error]="WaitTime?.isError"
                      [ErrorLevel]="WaitTime?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[WaitTime?.TimeseriesData]"
                      [Unit]="'h'"
                      [Legend]="['Wartezeit' | translate]"
                      [resizeEvent]="childResizeEvent">
    </app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Zeit ausgeschaltet' | translate"
                      [Error]="OffTime?.isError"
                      [ErrorLevel]="OffTime?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[OffTime?.TimeseriesData]"
                      [Unit]="'h'"
                      [Legend]="['Zeit ausgeschaltet' | translate]"
                      [resizeEvent]="childResizeEvent">
    </app-default-card>
<!--
    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Zeit zwischen Fehlern' | translate"
                      [Error]="TimeBetweenErrors?.isError"
                      [ErrorLevel]="TimeBetweenErrors?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[TimeBetweenErrors?.TimeseriesData]"
                      [Unit]="'h'"
                      [Legend]="['Zeit zwischen Fehlern' | translate]"
                      [resizeEvent]="childResizeEvent">
    </app-default-card>
-->
    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Zeit Transfer von Regal auf Heber' | translate"
                      [Error]="TimeIntoLift?.isError"
                      [ErrorLevel]="TimeIntoLift?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[TimeIntoLift?.TimeseriesData]"
                      [Unit]="'ms'"
                      [Legend]="['Zeit Transfer von Regal auf Heber' | translate]"
                      [Info]="'Zeit die benötigt wird um vom Lager auf einen Vertikalheber zu fahren.'"
                      [resizeEvent]="childResizeEvent">
    </app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Zeit Transfer von Heber in Regal' | translate"
                      [Error]="TimeIntoRack?.isError"
                      [ErrorLevel]="TimeIntoRack?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[TimeIntoRack?.TimeseriesData]"
                      [Unit]="'ms'"
                      [Legend]="['Zeit Transfer von Heber in Regal' | translate]"
                      [Info]="'Zeit die benötigt wird um vom Vertikalheber ins Lager zu fahren.'"
                      [resizeEvent]="childResizeEvent">
    </app-default-card>

</div>
