<div class="componentContainer">
    <div class="tile firstDiv">
        <mat-slide-toggle *ngIf="auth.HealthReset" [(ngModel)]="editingModeBool"> Edit Thresholds
        </mat-slide-toggle>
        <mat-slide-toggle *ngIf="auth.HealthReset" [(ngModel)]="globalChangeBool"> Global Change</mat-slide-toggle>
        <button *ngIf="auth.HealthReset && editingModeBool" type="button" class="btn" (click)="saveThresholdData()">
            Save
        </button>
        <div class="tileHead">
            <div>
                {{this.thing.displayName}} - Health
            </div>
        </div>
        <div *ngIf="!this.loadingBool; else loading">
            <table *ngIf="!editingModeBool" mat-table
                   [dataSource]="newTableData" multiTemplateDataRows
                   class="mat-elevation-z8" matSort (matSortChange)="sortNewData($event)">
                <ng-container matColumnDef="bauteil_name_de">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="bauteil_name_de"
                        [@detailExpand]="'collapsed'"> Bauteil
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element["bauteil_name_de"]}} </td>
                </ng-container>
                <ng-container matColumnDef="komponenten_name_de">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="komponenten_name_de"
                        [@detailExpand]="'collapsed'"> Komponente
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element["komponenten_name_de"]}} </td>
                </ng-container>
                <ng-container matColumnDef="materialnummer">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="materialnummer"
                        [@detailExpand]="'collapsed'"> Materialnummer
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element["materialnummer"]}} </td>
                </ng-container>
                <ng-container matColumnDef="resetCounter">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="resetCounter" [@detailExpand]="'collapsed'">
                        Austausch
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element["resetCounter"]}} </td>
                </ng-container>
                <ng-container matColumnDef="extendCounter">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="extendCounter" [@detailExpand]="'collapsed'">
                        Verschobene Wartungen
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element["extendCounter"]}} </td>
                </ng-container>
                <ng-container matColumnDef="worstHealthValue">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="worstHealthValue"
                        [@detailExpand]="'collapsed'"> Schlechteste Komponente
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element["worstHealthValue"]}} %</td>
                </ng-container>
                <ng-container matColumnDef="averageHealthValue">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="averageHealthValue"
                        [@detailExpand]="'collapsed'"> Durchschnitt
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element["averageHealthValue"]}} %</td>
                </ng-container>

                <ng-container matColumnDef="expand">
                    <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button aria-label="expand row"
                                (click)="expandColumn(element);$event.stopPropagation()">
                            <mat-icon *ngIf="expandedElement !== element">+</mat-icon>
                            <mat-icon *ngIf="expandedElement === element">-</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
                        <div class="example-element-detail"
                             [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                            <div class="container">
                                <div class="row">
                                    <div class="col-4">
                                        <div class="row">
                                            <figure class="figure">
                                                <img src="https://galileoiotfilestorage.blob.core.windows.net/models3d/general/healthPage/{{element.materialnummer}}.png"
                                                     class="figure-img img-fluid rounded"
                                                     style="width: auto; height: 250px;">
                                                <figcaption *ngIf="element.komponenten_name_de != ''"
                                                            class="figure-caption">{{element.bauteil_name_de}}
                                                    - {{element.komponenten_name_de}}</figcaption>
                                                <figcaption *ngIf="element.komponenten_name_de == ''"
                                                            class="figure-caption">{{element.bauteil_name_de}}</figcaption>
                                            </figure>
                                        </div>
                                        <div *ngIf="auth.HealthReset" class="row">
                                            <div class="col-3">
                                                <button type="button" class="btn btn-primary btn-block"
                                                        (click)="shortenMaintenanceDate(element.bauteil_name_de,element.komponenten_name_de,element.materialnummer)">
                                                    Austausch vorziehen
                                                </button>
                                            </div>
                                            <div class="col-1"></div>
                                            <div class="col-3">
                                                <button type="button" class="btn btn-primary btn-block"
                                                        (click)="extendMaintenanceDate(element.bauteil_name_de,element.komponenten_name_de,element.materialnummer)">
                                                    Austausch verschieben
                                                </button>
                                            </div>
                                            <div class="col-1"></div>
                                            <div class="col-4">
                                                <button type="button" class="btn btn-primary btn-block"
                                                        (click)="resetComponent(element.bauteil_name_de,element.komponenten_name_de,element.materialnummer)">
                                                    Komponente ausgetauscht
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-1"></div>
                                    <div class="col-7">
                                        <div class="row">
                                            <table class="table caption-top">
                                                <caption *ngIf="element.maintenanceCriteria[0]">Wartungsinformationen -
                                                    Prüfkriterien: {{element.maintenanceCriteria[0]["Criteria"]}}</caption>
                                                <caption *ngIf="!element.maintenanceCriteria[0]">Wartungsinformationen
                                                </caption>
                                                <thead>
                                                <tr>
                                                    <th scope="col">Aktionsnummer</th>
                                                    <th scope="col">Aktion</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr *ngFor="let tableRow of element.maintenanceInformation">
                                                    <th scope="row">{{tableRow.Aktionsnummer}}</th>
                                                    <td>{{tableRow.Aktion}}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="row">
                                            <table class="table caption-top">
                                                <caption>Messgroessen Details</caption>
                                                <thead>
                                                <tr>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Wert</th>
                                                    <th scope="col">Grenzwert</th>
                                                    <th scope="col">Einheit</th>
                                                    <th scope="col">Health</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr *ngFor="let tableRow of element.messgroessen">
                                                    <td>{{tableRow.messgroessen_name}}</td>
                                                    <td>{{tableRow.live_value}}</td>
                                                    <td>{{tableRow.momentaner_grenzwert}}</td>
                                                    <td>{{tableRow.einheit}}</td>
                                                    <td>{{tableRow.health_percentage}} %</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div *ngIf="element.healthMaintenance.length>0" class="row">
                                            <table class="table caption-top">
                                                <caption>Wartungshistorie</caption>
                                                <thead>
                                                <tr>
                                                    <th scope="col">User</th>
                                                    <th scope="col">Typ</th>
                                                    <th scope="col">Beschreibung</th>
                                                    <th scope="col">Zeitpunkt</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr *ngFor="let tableRow of element.healthMaintenance">
                                                    <td>{{tableRow.iot_user}}</td>
                                                    <td>{{tableRow.type}}</td>
                                                    <td>{{tableRow.description}}</td>
                                                    <td>{{tableRow.parsedTimestamp}}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
                    class="example-element-row"
                    [class.example-expanded-row]="expandedElement == element"
                    (click)="expandColumn(element)">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
            </table>
            <table *ngIf="editingModeBool">
                <caption>Edit Thresholds</caption>
                <thead>
                <tr>
                    <th scope="col">Bauteil</th>
                    <th scope="col">Komponente</th>
                    <th scope="col">Messgroesse</th>
                    <th scope="col">Wert</th>
                    <th scope="col">Einheit</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let messgroessenRow of editThresholdsArray">
                    <td>{{messgroessenRow.bauteil}}</td>
                    <td>{{messgroessenRow.komponente}}</td>
                    <td>{{messgroessenRow.messgroesse}}</td>
                    <td><input type="text" [(ngModel)]="messgroessenRow.grenzwert"></td>
                    <td>{{messgroessenRow.einheit}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="row">
        <div class="col-lg-12 text-center">
            <div class="spinner-grow text-danger" role="status" style="height: 100px; width: 100px;">
                <span class="visually-hidden">Loading...</span>
            </div>
            <div style="font-size: 30px">
                {{'Daten werden abgerufen' | translate}}
            </div>
        </div>
    </div>
</ng-template>

<ng-template #error>
    <div class="row">
        <div class="col-lg-12 text-center">
            <div style="font-size: 30px">
                {{'Thing-Health Daten konnten nicht abgerufen werden' | translate}}
            </div>
        </div>
    </div>
</ng-template>
