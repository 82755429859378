import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {DataPoint} from '../../model/helper-models/datapoint.model';
import {Thing} from '../../model/thing.model';

@Injectable({
    providedIn: 'root'
})
export class ThresholdsService
{
    constructor(private _http: HttpClient)
    {
    }

    /**
     * Setzt die thresholds für einem thing.
     * @param _thing
     */
    public setThresholds(_thing: Thing)
    {
        //const thingId = this.activatedRoute.snapshot.firstChild.params['id'];
        const thing = _thing as any;

        const httpOptions = {
            params: {thingId: thing.id}
        };

        this._http.get<any>('/backend/api/threshold', httpOptions).pipe(map(response =>
        {
            const tmp = response as any;

            this.mapThresholdOnThing(thing, tmp);
        })).subscribe();
    }

    public mapThresholdOnThing(thing: any, tmp: any)
    {
        if (tmp.things == null) return;

        tmp.things.forEach(jsonThing =>
        {
            if (thing == null) return;

            if (thing.hasOwnProperty('TESTDRIVE_EVALUATION_HORIZONTAL'))
            {
                const hKeys = Object.keys(jsonThing.H);

                hKeys.forEach(key =>
                {
                    thing.TESTDRIVE_EVALUATION_HORIZONTAL.TESTDRIVE_EVALUATION_H[key].TimeseriesData.pushDataPoint(new DataPoint(jsonThing.H[key].value, 0));
                    thing.TESTDRIVE_EVALUATION_HORIZONTAL.TESTDRIVE_EVALUATION_H[key].type = jsonThing.H[key].typ;
                    thing.TESTDRIVE_EVALUATION_HORIZONTAL.TESTDRIVE_EVALUATION_H[key].upperThreshold = jsonThing.H[key].UpperThreshold;
                    thing.TESTDRIVE_EVALUATION_HORIZONTAL.TESTDRIVE_EVALUATION_H[key].upperupperThreshold = jsonThing.H[key].UpperUpperThreshold;
                    thing.TESTDRIVE_EVALUATION_HORIZONTAL.TESTDRIVE_EVALUATION_H[key].lowerThreshold = jsonThing.H[key].LowerThreshold;
                    thing.TESTDRIVE_EVALUATION_HORIZONTAL.TESTDRIVE_EVALUATION_H[key].lowerlowerThreshold = jsonThing.H[key].LowLowerThreshold;
                });
            }

            if (thing.hasOwnProperty('TESTDRIVE_EVALUATION_VERTICAL'))
            {
                const vKeys = Object.keys(jsonThing.V);

                vKeys.forEach(key =>
                {
                    thing.TESTDRIVE_EVALUATION_VERTICAL.TESTDRIVE_EVALUATION_V[key].TimeseriesData.pushDataPoint(new DataPoint(jsonThing.V[key].value, 0));
                    thing.TESTDRIVE_EVALUATION_VERTICAL.TESTDRIVE_EVALUATION_V[key].type = jsonThing.V[key].typ;
                    thing.TESTDRIVE_EVALUATION_VERTICAL.TESTDRIVE_EVALUATION_V[key].upperThreshold = jsonThing.V[key].UpperThreshold;
                    thing.TESTDRIVE_EVALUATION_VERTICAL.TESTDRIVE_EVALUATION_V[key].upperupperThreshold = jsonThing.V[key].UpperUpperThreshold;
                    thing.TESTDRIVE_EVALUATION_VERTICAL.TESTDRIVE_EVALUATION_V[key].lowerThreshold = jsonThing.V[key].LowerThreshold;
                    thing.TESTDRIVE_EVALUATION_VERTICAL.TESTDRIVE_EVALUATION_V[key].lowerlowerThreshold = jsonThing.V[key].LowLowerThreshold;
                });
            }
        });

        /*
        *   Falls ein Rückgabewert erwartet wird, wird hier das thing noch einmal zurück gegeben.
        *   Wird für testdrive benötigt.
        *   Bei den Livedaten aber nicht.
        * */
        return thing;
    }
}
