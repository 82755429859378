import {Component} from '@angular/core';
import {BaseSubcomp} from '../../../base-subcomp';

@Component({
    selector: 'app-sses-subcomp-parent',
    templateUrl: './sses-subcomp-parent.component.html',
    styleUrls: ['./sses-subcomp-parent.component.scss']
})
export class SsesSubcompParentComponent extends BaseSubcomp
{

}
