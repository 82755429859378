import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {MonitoringLevel} from '../../../core/model/MonitoringLevels.enum';
import {LimitSizeArrayModel} from '../../../core/model/helper-models/limit-size-array.model';
import {DataPoint} from '../../../core/model/helper-models/datapoint.model';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
    selector: 'app-ssagm-statistic-std',
    templateUrl: './ssagm-statistic-std.component.html',
    styleUrls: ['./ssagm-statistic-std.component.scss', './../card.scss']
})
export class SsagmStatisticStdComponent extends BaseViewElement implements OnDestroy, OnChanges
{

    @Input() Allgemein: PropertySet;
    @Input() Wartungseinheit: PropertySet;

    InputHour: Property<any>;
    OutputHour: Property<any>;
    DistanceHour: Property<any>;
    CompressionOn: Property<any>;
    Pressure: Property<any>;

    tmpPressure = [];


    inputHour = new LimitSizeArrayModel<DataPoint<number>>(1000);
    outputHour = new LimitSizeArrayModel<DataPoint<number>>(1000);
    distanceHour = new LimitSizeArrayModel<DataPoint<number>>(1000);


    constructor()
    {
        super('ssagm-statistic-std');
        this.chart = true;
        this.tmpPressure.push(new LimitSizeArrayModel(1));
        this.tmpPressure[0].pushDataPoint(new DataPoint(6, Date.now()));
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit(result);
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.Allgemein == null) return;
        this.InputHour = this.Allgemein.properties.get('ANZ_EINSCHLEUSUNGEN');
        this.InputHour.TimeseriesData.valuePushed.subscribe(x =>
        {
            const value = this.calcHourlyRate(this.InputHour.TimeseriesData);

            if (value != null)
            {
                this.inputHour.pushDataPoint(new DataPoint(value, x.timestamp));
            }
        });


        this.OutputHour = this.Allgemein.properties.get('ANZ_AUSSCHLEUSUNGEN');
        this.OutputHour.TimeseriesData.valuePushed.subscribe(x =>
        {
            const value = this.calcHourlyRate(this.OutputHour.TimeseriesData);

            if (value != null)
            {
                this.outputHour.pushDataPoint(new DataPoint(value, x.timestamp));
            }
        });


        this.DistanceHour = this.Allgemein.properties.get('STRECKE');
        this.DistanceHour.TimeseriesData.valuePushed.subscribe(x =>
        {
            const value = this.calcHourlyRate(this.DistanceHour.TimeseriesData);

            if (value != null)
            {
                this.distanceHour.pushDataPoint(new DataPoint(value, x.timestamp));
            }
        });


        if (this.Wartungseinheit == null) return;
        this.CompressionOn = this.Wartungseinheit.properties.get('LUFTDRUCK_DRUCK_WERT');
        this.Pressure = this.Wartungseinheit.properties.get('LUFTDRUCK_DRUCK');
    }

    calcHourlyRate(values: LimitSizeArrayModel<DataPoint<any>>)
    {
        if (values == null || values.values.length === 0) return null;

        const timestampNow = new Date(values.currentDatapoint.timestamp).getTime();
        const timestampOneHourAgo = timestampNow - (3600 * 1000);

        const minTime = timestampOneHourAgo - (60 * 1000);
        const maxTime = timestampOneHourAgo + (60 * 1000);

        const possibleValues = values.values.filter(x =>
        {
            const datapointTimestamp = new Date(x.timestamp).getTime();
            if ((datapointTimestamp > minTime) && (datapointTimestamp < maxTime))
            {
                return x;
            }
            // return ((datapointTimestamp > minTime) && (datapointTimestamp < maxTime));
        });

        if (possibleValues.length === 0) return null;

        return Math.abs(values.currentDatapoint.value - possibleValues[possibleValues.length - 1].value);
    }

}
