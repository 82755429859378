<div class="row">
    <div class="col-lg-12 subheading">
        <div class="row">
            <h3 id="TimeMoni">Timeseries Monitor</h3>
        </div>
    </div>

    <app-monitoring-timeseries></app-monitoring-timeseries>
</div>


<div class="row">
    <div class="col-lg-12 subheading">
        <div class="row">
            <h3 id="DbMoni">Datenbanken Monitor</h3>
        </div>
    </div>

    <div>Placeholder</div>
</div>
