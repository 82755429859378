import {Component, OnInit} from '@angular/core';
import {HelpPageComponent} from '../../help-page.component';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../../../environments/environment';

@Component({
    selector: 'app-help-page-dashboard',
    templateUrl: './help-page-dashboard.component.html',
    styleUrls: ['./help-page-dashboard.component.css', '../../helpPageModule.scss']
})
export class HelpPageDashboardComponent extends HelpPageComponent implements OnInit
{
    constructor(trans: TranslateService)
    {
        super(trans);
    }

    ngOnInit(): void
    {
    }
    public readonly environment = environment;
}
