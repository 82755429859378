import {Component} from '@angular/core';

@Component({
    selector: 'app-placehold-gaugechart',
    templateUrl: './placehold-gaugechart.component.html',
    styleUrls: ['./placehold-gaugechart.component.scss']
})
/**
 * Platzhalter wenn in einer Gaugechart keine Daten angekommen sind
 */
export class PlaceholdGaugechartComponent
{
}
