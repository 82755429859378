<div class="tile" [ngClass]="{'errorTile' : error}">

    <div class="tileHead">
        {{heading}}
        <div class="unit">
            {{unit}}
        </div>
    </div>
    <div *ngIf="date.auto === true; else historic">
        <div *ngIf="lastMaintenanceValue == null; else Maintenance">
            <div *ngIf="directInput == null; else Direct">
                <div class="tileBody innerText">
                    <div *ngIf="datapoint != null && datapoint.value != null; else placeholdText">
                        <div *ngIf="datapoint.value !== ''; else placeholdEmpty">
                            {{datapoint.value}}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ng-template #Direct>
            <div class="tileBody innerText">
                <div *ngIf="directInput != null; else placeholdText">
                    {{directInput}}
                </div>
                <div *ngIf="directInputRowTwo;" style="border-top: 1px solid gray;">
                    {{directInputRowTwo}}
                </div>
            </div>
        </ng-template>

        <ng-template #Maintenance>
            <div *ngIf="directInput != null; else placeholdText">
                <div class="tileBody maintenance">
                    <div class="row maintenanceRowBorder" style="margin: 0;">
                        <div class="col-lg-4 maintenanceTableElement">{{'Insgesamt' | translate}}</div>
                        <div
                                class="col-lg-4 maintenanceTableElement maintenanceTableBorder">{{'Wartung' | translate}}</div>
                        <div class="col-lg-4 maintenanceTableElement">{{'Stand Wartung' | translate}}</div>
                    </div>
                    <div class="row" style="margin: 0;">
                        <div class="col-lg-4 maintenanceTableElement">{{directInput}}</div>
                        <div
                                class="col-lg-4 maintenanceTableElement maintenanceTableBorder">{{lastMaintenanceValue}}</div>
                        <div
                                class="col-lg-4 maintenanceTableElement">{{directInput | calcDifference: lastMaintenanceValue | number:'1.0-2'}}</div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>

    <ng-template #historic>
        <div *ngIf="historicValues != null" class="tileBody">
            <div *ngIf="historicValues[0] != null; else placeholdLine">
                <div *ngIf="historicValues[0][0] != null; else placeholdLine">
                    <app-linechart [legend]="historicLegend"
                                   [value]="historicValues">
                    </app-linechart>
                </div>
            </div>
        </div>
    </ng-template>
</div>

<ng-template #placeholdText>
    <span class="tileBody innerText">
        {{'Keine aktuellen Daten vorhanden' | translate}}
    </span>
</ng-template>

<ng-template #placeholdLine>
    <app-placehold-linechart></app-placehold-linechart>
</ng-template>

<ng-template #placeholdEmpty>
    -
</ng-template>
