import {Component, OnDestroy, OnInit} from '@angular/core';
import {Gateway} from '../../../core/model/gateway.model';
import {HttpClient} from '@angular/common/http';
import {MonitoringGatewayService} from '../../../core/controller/services/monitoring-gateway.service';
import {NavigationService} from '../../../core/controller/services/navigation.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {MonitoringGatewayV2Service} from '../../../core/controller/services/monitoring-gateway.v2.service';
import {GatewayV2, IServicesStatus} from '../../../core/model/gateway.v2.model';
import {AuthenticationService} from '../../../core/controller/services/authentication.service';
import {MatSnackBar} from '@angular/material/snack-bar';

interface ISummary{
    Name: string;
    Message: string;
}

@Component({
    selector: 'app-monitoring-gateway',
    templateUrl: './monitoring-gateway.component.html',
    styleUrls: ['./monitoring-gateway.component.scss', '../../../core/view/monitoring/monitoring.scss']
})
export class MonitoringGatewayComponent implements OnInit, OnDestroy
{
    public dataLoaded: boolean = false;
    public dataLoadError: boolean = false;
    public error : any;

    public showDetails : boolean = false;
    public holdMode: boolean = false;

    public currentDetailingGateway: GatewayV2 = null;
    public displayedColumns: string[] = ['Name', 'Status'];
    public summary_displayedColumns: string[] = ['Name', 'Message'];
    public servicesTableData: IServicesStatus[]= [];
    public summaryInfo: ISummary[] = [];

    constructor(
        private _http: HttpClient,
        private auth: AuthenticationService,
        public gatewayServiceV2: MonitoringGatewayV2Service,
        public nav: NavigationService,
        public toastr: ToastrService,
        private router: Router,
        private _snackBar: MatSnackBar
    )
    {
    }

    async ngOnInit(): Promise<void>
    {
        try
        {
            // await this.auth.checkAndSetActiveAccount();
            const auth_headers = await this.auth.getDefaultHttpHeaders();
            await this.gatewayServiceV2.initialize(auth_headers);

            console.log('Open Gateways');
            if (this.gatewayServiceV2.UpdateGatewayList == null) return;
            await this.gatewayServiceV2.UpdateGatewayList();
            console.log(this.gatewayServiceV2.gateways);
            // this.gatewayServiceV2.startPullTimer();

            this.dataLoaded = true;
        }
        catch (e)
        {
            this.dataLoadError = true;
        }
    }

    ngOnDestroy(): void
    {
        this.gatewayServiceV2.gateways = [];
        // this.gatewayServiceV2.stopPullTimer();
    }

    gotoGateway(gateway: GatewayV2)
    {
        console.log('DEBUG', gateway);
        this.router.navigate(['/gateway/' + gateway.device._id]);
    }

    me_displayDetails(gateway: GatewayV2){
        this.showDetails = this.holdMode ? true: !this.showDetails;
        this.currentDetailingGateway = gateway;
        this.onSetExpandedCurrentGatewayDetails(gateway);
    }

    ml_displayDetails(gateway: GatewayV2){
        this.showDetails = this.holdMode;
        if(this.showDetails) return;
        this.currentDetailingGateway = null;
        this.servicesTableData = [];
        this.summaryInfo = [];
    }

    onSetExpandedCurrentGatewayDetails(gateway: GatewayV2){
        this.setSummaryTable(gateway);
        if(gateway.connectionStatus.EventType === 'Device.CombinedStatus.CONNECTED'
            ||
            'Device.CombinedStatus.EdgeCore.CONNECTED'){
            this.setServicesTable(gateway);
        }
    }

    holdInfo(event: any){
        event.preventDefault();
        this.holdMode = !this.holdMode;
        const message = this.holdMode ? 'Hold Mode turned On!': 'Hold Mode turned Off!';
        this._snackBar.open(message, null, {duration: 1000});
        if(!this.holdMode) this.showDetails = false;
    }

    setServicesTable(gateway: GatewayV2){
        this.servicesTableData = gateway.windowsServiceStatus;
    }

    setSummaryTable(gateway: GatewayV2){
        switch(gateway.connectionStatus.EventType)
        {
            case 'Device.CombinedStatus.CONNECTED':
                this.summaryInfo = [
                    {Name: 'Galileo EdgeCore', Message: 'Gateway connected to the Cloud'},
                    {Name: 'Crosser', Message: 'Crosser working'}
                ];
                return;
            case 'Device.CombinedStatus.EdgeCore.CONNECTED':
                this.summaryInfo = [
                    {Name: 'Galileo EdgeCore', Message: 'Gateway connected to the Cloud'},
                    {Name: 'Crosser', Message: 'Crosser NOT working'}
                ];
                return;
            case 'Device.CombinedStatus.DISCONNECTED':
                this.summaryInfo = [
                    {Name: 'Galileo EdgeCore', Message: 'Gateway disconnected from the Cloud'},
                    {Name: 'Crosser', Message: 'Crosser NOT working'}
                ];
                return;
            default:
                this.summaryInfo = [
                    {Name: 'Galileo EdgeCore', Message: 'Gateway disconnected from the Cloud'},
                    {Name: 'Crosser', Message: 'Crosser NOT working'}
                ];
                return;
        }

    }

    // getGateways(): void
    // {
    //     let that = this;
    //     this._http.get<Array<Gateway>>('/backend/api/gateway').subscribe(
    //         gateways =>
    //         {
    //             // that.gateways = gateways;
    //             gateways.forEach(gateway =>
    //             {
    //                 that.gateways.push(gateway);
    //             });
    //         });
    // }
}
