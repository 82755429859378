import {DataPoint} from './datapoint.model';

interface baseInfoPoint
{
    name: string;
    dataPoint: DataPoint<any>;
}

export class InfoPoint<T>
{
    private __datapoint: DataPoint<T>;
    private __name: string;
    private __data: any;

    constructor(
        pName: string,
        pDatapoint: DataPoint<T>
    ){
        this.__name = pName;
        this.__datapoint = pDatapoint;
    }

    public get name(): string{
        return this.__name;
    }

    public set name(pname:string) {
        this.__name = pname;
    }

    public get datapoint(): DataPoint<T>{
        return this.__datapoint;
    }

    public set datapoint(pdatapoint) {
        this.__datapoint = pdatapoint;
    }

    public get value(): T{
        return this.__datapoint.value
    }

    public get timestamp(): Date {
        return this.__datapoint.timestamp;
    }

    public get data(): any {
        return this.__data;
    }

    public set value(pval: T) {
        this.__datapoint.value = pval;
    }

    public set timestamp(ptimestamp: Date) {
        this.__datapoint.timestamp = ptimestamp;
    }

    public set data(pdata: any) {
        this.__data = pdata;
    }
}
