import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {LimitSizeArrayModel} from '../../../core/model/helper-models/limit-size-array.model';
import {MonitoringLevel} from '../../../core/model/MonitoringLevels.enum';
import {BaseViewElement} from '../base-view-element';

@Component({
    selector: 'app-testrun-status',
    templateUrl: './testrun-status.component.html',
    styleUrls: ['./testrun-status.component.scss',
        './../card.scss']
})
export class TestrunStatusComponent extends BaseViewElement implements OnInit
{
    @Input() AutoStartError: boolean;
    @Input() AutoStartErrorLevel: MonitoringLevel;
    @Input() AutoStartValue: LimitSizeArrayModel<any>;

    @Input() ManualStartError: boolean;
    @Input() ManualStartErrorLevel: MonitoringLevel;
    @Input() ManualStartValue: LimitSizeArrayModel<any>;

    @Input() ActiveError: boolean;
    @Input() ActiveErrorLevel: MonitoringLevel;
    @Input() ActiveValue: LimitSizeArrayModel<any>;

    @Input() ResetError: boolean;
    @Input() ResetErrorLevel: MonitoringLevel;
    @Input() ResetValue: LimitSizeArrayModel<any>;

    @ViewChild('cardWidth', {read: ElementRef}) cardWidth: ElementRef;

    constructor()
    {

        super('testrun-status');
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit();
    }

}
