import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'boolean'
})
/**
 * Klasse der BooleanPipe
 */
export class BooleanPipe implements PipeTransform
{
    constructor(private _translate: TranslateService)
    {
    }

    /**
     * Pipe zum Umwandeln der Werte von true auf ja und false auf nein
     */
    transform(pValue: boolean, pType = 'string'): string
    {
        if (pValue == null) return null;

        if (pType === 'string' || pType == null)
        {
            if (pValue)
            {
                let answer = 'Ja';
                this._translate.get(answer).subscribe(x => answer = x);
                return answer;
            }
            else if (!pValue)
            {
                let answer = 'Nein';
                this._translate.get(answer).subscribe(x => answer = x);
                return answer;
            }
            else
            {
                return 'Fehler bei der Boolumwandlung: Target String';
            }
        }
        else if (pType === 'number')
        {
            if (pValue)
            {
                return '1';
            }
            else if (!pValue)
            {
                return '0';
            }
            else
            {
                return 'Fehler bei der Boolumwandlung: Target Number';
            }
        }
        else
        {
            return 'Fehler bei der Boolumwandlung: Target Unknown';
        }

    }
}
