import {Pipe, PipeTransform} from '@angular/core';
import {GatewayStatus} from '../../model/gateway_status.model';

@Pipe({
    name: 'gatewayStatus'
})
export class GatewayStatusPipe implements PipeTransform
{

    transform(gatewayStatus: GatewayStatus[]): string
    {
        // ToDo Prüfen wieso gatewayStatus manchmal NULL ist
        if (gatewayStatus && gatewayStatus.length > 0)
        {
            switch (gatewayStatus[0].status)
            {
                case 'Started':
                    return 'make-green';
                case 'Buffering':
                    return 'make-orange';
                case 'Stopped':
                    return 'make-red';
                case 'Offline':
                    return 'make-gray';
                default:
                    return 'make-red';
            }
        }
        else
        {
            return 'make-gray';
        }
    }

}
