import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MonitoringGatewayService} from '../../../core/controller/services/monitoring-gateway.service';
import {Drives, InfoRange, S7Info} from '../../../core/model/gateway_extended_status.model';
import {GaugechartOptions} from '../../../core/view/charts/options/gaugechart-options';
import {DataPoint} from '../../../core/model/helper-models/datapoint.model';
import {MatDialog} from '@angular/material/dialog';
import {S7ChangeBoxComponent} from './s7-change-box/s7-change-box.component';
import {faCheckCircle, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import {S7CustomChangeBoxComponent} from './s7-custom-change-box/s7-custom-change-box.component';
import {MonitoringGatewayV2Service} from '../../../core/controller/services/monitoring-gateway.v2.service';
import {Configurations} from '../../../configurations';
import { GatewayTestdriveInfo } from '../../../core/model/gateway_status.v2.model';
import {MatSnackBar} from '@angular/material/snack-bar';

class CPU {
    public min = 0;
    public max = 100;
    public load: number = null;
}

class MEM {
    public min = 0;
    public max = 100;
    public load: number = null;
}

interface Drive
{
    device: string;
    mountpoint: string;
    total_space: number;
    free_space: number;
    used_space: number;
    usable_space: number
}

@Component({
    selector: 'app-monitoring-gateway-page',
    templateUrl: './monitoring-gateway-page.component.html',
    styleUrls: ['./monitoring-gateway-page.component.css', '../../../core/view/monitoring/monitoring.scss']
})
export class MonitoringGatewayPageComponent implements OnInit, OnDestroy
{

    private updateTimer: any = false;
    private defaultDecimalPlaces: number = 2;


    gatewayId: string;

    icoOk = faCheckCircle;
    icoError = faExclamationTriangle;

    public cpu = new CPU();
    public cpuOption: GaugechartOptions;
    public cpuValue: DataPoint<any> = new DataPoint<[number]>([0], new Date(Date.now()));

    public ramOption: GaugechartOptions;
    public ramValue: DataPoint<any> = new DataPoint<[number]>([0], new Date(Date.now()));

    public driveOption: GaugechartOptions[] = [];
    public driveValue: DataPoint<number>[] = [];

    constructor(
        private route: ActivatedRoute,
        public monGateService: MonitoringGatewayV2Service,
        public dialog: MatDialog,
        private _snackBar: MatSnackBar
    )
    {
    }

    ngOnInit(): void
    {
        this.gatewayId = this.route.snapshot.paramMap.get('gateway');
        console.log(this.route);
        this.monGateService.pullGatewayStatus(this.gatewayId);
        this.updateUi();
        this.monGateService.startExtendedPullTimer(this.gatewayId);
        this.startUpdateTimer();
    }

    ngOnDestroy(): void
    {
        this.stopUpdateTimer();
        this.monGateService.stopExtendedPullTimer();

        // Wenn vorher ein anderes Gateway angeschaut wurde, sollen die alten Daten weg
        this.monGateService.activeMachineInfo = null;
        this.monGateService.activeFlowInfo = null;
        this.cpuValue = new DataPoint<[number]>([0], new Date(Date.now()));
        this.ramValue = new DataPoint<[number]>([0], new Date(Date.now()));

        this.monGateService.activeTestdrive = null;
    }

    /**
     * Startet Timer um alle Status der Gateways zu pullen
     */
    public startUpdateTimer(): void
    {
        this.updateUi();

        this.updateTimer = setInterval(() =>
        {
            this.updateUi();
        }, Configurations.uiUpdateTimer);
    }

    public showUnhealthyReason(message: string){
        this._snackBar.open(message, null, {duration: 5000});
    }

    /**
     *  ToDo Kommentieren
     **/
    public stopUpdateTimer(): void
    {
        clearInterval(this.updateTimer);
        // Wird false gesetzt um zu wissen ob Timer aktiv oder nicht
        this.updateTimer = false;
    }

    /*
    *   Aktualisiert die UI, mit den Daten aus dem Service
    * */
    public updateUi()
    {
        if (this.monGateService.activeMachineInfo)
        {
            if (this.monGateService.activeMachineInfo.CPULoad)
            {
                this.cpu.load = this.monGateService.activeMachineInfo.CPULoad;
                this.cpuOption = new GaugechartOptions('CPU', 'CPU', '%', this.cpu.min, this.cpu.max);
                this.cpuValue = new DataPoint<any>(Math.round(this.cpu.load), new Date(Date.now()));
            }
            if (
                this.monGateService.activeMachineInfo.MemRamPercent !== undefined
                &&
                this.monGateService.activeMachineInfo.MemRamGb !== undefined
            )
            {
                const mem = new MEM();
                // Max Mem in GB
                mem.max = Math.round(100 * this.monGateService.activeMachineInfo.MemRamGb / this.monGateService.activeMachineInfo.MemRamPercent);
                mem.load = Math.ceil(
                    this.monGateService.activeMachineInfo.MemRamGb * Math.pow(10, this.defaultDecimalPlaces)
                ) / Math.pow(10, this.defaultDecimalPlaces);
                this.ramOption = new GaugechartOptions('RAM', 'RAM', 'GB', mem.min, mem.max);
                // In GB
                this.ramValue = new DataPoint<any>(mem.load, new Date(Date.now()));
            }

            if (this.driveOption.length <= 0)
            {
                this.initDrives();
            }
            else
            {
                this.updateDrives();
            }
        }
    }

    initDrives()
    {
        for (let i = 0; i < this.monGateService.activeMachineInfo.Disk.length; i++){
            const drive = this.monGateService.activeMachineInfo.Disk[i] as Drive;
            const option = new GaugechartOptions(drive.device, drive.mountpoint, 'GB', 0, +this.sizeInGb(drive.total_space).toFixed(0));
            const value = new DataPoint<number>(+this.sizeInGb(drive.used_space).toFixed(2), Date.now());
            // Flackert wenn liste genullt wird
            // Deswegen wird sie hier erweitert oder geupdated

            this.driveOption.push(option);
            this.driveValue.push(value);
        }
    }
    //     for (let i = 0; i < this.monGateService.activeExtendedStatus.Drives.length; i++)
    //     {
    //         const drive = this.monGateService.activeExtendedStatus.Drives[i];
    //
    //         const option = new GaugechartOptions(drive.Name, drive.Label, 'GB', 0, +this.sizeInGb(drive).toFixed(0));
    //         const value = new DataPoint<number>(+this.usedInGb(drive).toFixed(2), Date.now());
    //
    //         // Flackert wenn liste genullt wird
    //         // Deswegen wird sie hier erweitert oder geupdated
    //
    //         this.driveOption.push(option);
    //         this.driveValue.push(value);
    //
    //     }
    // }
    //
    updateDrives()
    {
        this.monGateService.activeMachineInfo.Disk.forEach(async (value, i) =>
        {
            const drive = this.monGateService.activeMachineInfo.Disk[i] as Drive;

            const dataPointvalue = new DataPoint<number>(+this.sizeInGb(drive.used_space).toFixed(2), Date.now());

            // Flackert wenn liste genullt wird
            // Deswegen wird sie hier erweitert oder geupdated

            this.driveValue[i] = dataPointvalue;

        });
    }
    //
    // //
    // // s7Data = [{
    // //     Ip: '192.168.10.7',
    // //     Name: 'MLS_0101_40529189_MLS_SPS',
    // //     CpuType: 40,
    // //     Port: '102',
    // //     Rack: 0,
    // //     Slot: 0,
    // //     S7TestdriveData: {
    // //         Aktive: 'False',
    // //         LetzterAnfang: 'False',
    // //         LetztesEnde: '21.10.2021 00:14:33',
    // //         Reset: 'False',
    // //         Start: 'False',
    // //         StartzeitMessfahrten: '00:05:00',
    // //         StartzeitMessfahrtenAktive: 'True'
    // //     }
    // // }];
    //
    //
    setS7Data(s7Data: GatewayTestdriveInfo)
    {
        const dialogRef = this.dialog.open(S7ChangeBoxComponent, {
            width: '1000px',
            data: s7Data
        });

        dialogRef.afterClosed().subscribe(result =>
        {
            this.monGateService.setTestdriveStatus(this.gatewayId, s7Data);
        });
    }
    //
    // setOtherS7Data(s7Data: S7Info)
    // {
    //     const dialogRef = this.dialog.open(S7CustomChangeBoxComponent, {
    //         width: '1000px',
    //         data: s7Data
    //     });
    //
    //     dialogRef.afterClosed().subscribe(result =>
    //     {
    //         this.monGateService.setS7Data(this.gatewayId, s7Data);
    //     });
    // }
    //
    // /**
    //  * Methoden zum umwandeln von Daten
    //  * **/
    toGb(value: number, divi: number, fraction: number, pow: number = 1): number
    {
        // Nur 2 Kommastellen
        // ToDo Schauen wieso andere Rundungen nicht funktioniert haben.
        // Bei anderen war es immer eine Ganzzahl
        const num = value / Math.pow(divi, pow);
        return +num.toFixed(fraction);
    }
    //
    sizeInGb(num: number): number
    {
        return this.toGb(num, 1024, 3, 3);
    }
    //
    // freeInGb(drive: Drives): number
    // {
    //     return this.toGb(drive.Free, 1024, 3, 3);
    // }
    //
    // usedInGb(drive: Drives): number
    // {
    //     return this.toGb(drive.Size - drive.Free, 1024, 3, 3);
    // }
    //
    // usedInPercent(drive: Drives)
    // {
    //     return this.inPercent(drive.Size, drive.Size - drive.Free);
    // }
    //
    // inPercent(max: number, value: number): number
    // {
    //     return 100 * value / max;
    // }

    GetFullName()
    {
        if (this.monGateService.activeGateway)
        {
            return this.monGateService.activeGateway.device.deviceId;
        }
        else
        {
            return '';
        }
    }

    IsSpezialThing(s7Info: S7Info): boolean
    {
        return s7Info.Name.endsWith('_Dauer');
    }

    public GetOptions(range: InfoRange): GaugechartOptions
    {
        if (range)
        {
            const result = new GaugechartOptions(range.Value.toString(), range.Value.toString(), '%', range.Min, range.Max);
            return result;
        }
        return new GaugechartOptions('', '', '', 0, 0);
    }

}
