import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Thing} from '../../model/thing.model';
import {DataProviderService} from './data-provider.service';
import {MonitoringLevel} from '../../model/MonitoringLevels.enum';
import {AuthenticationService} from './authentication.service';
import {environment} from '../../../../environments/environment';
import {lastValueFrom} from 'rxjs/internal/lastValueFrom';

@Injectable({
    providedIn: 'root'
})
/**
 * Service der die Thing Activity abruft und überwacht
 */
export class MonitoringService
{
    /**
     * Liste aller things die über die monitoring Konsole gemonitored werden
     */
    public monitoredThings = new Array<Thing>();

    public get MonitoredThings()
    {
        return this.monitoredThings;
    }

    public set MonitoredThings(value)
    {
        this.monitoredThings = value;
    }

    constructor(public data: DataProviderService,
                private _http: HttpClient,
                private _auth: AuthenticationService)
    {
    }

    public async getCurrentProperties(systemId: string)
    {
        const url = environment.apiUrl + environment.apiVersion;
        const header = await this._auth.getDefaultHttpHeaders();

        const result = await lastValueFrom(this._http.get(url + '/getCurrentPropertiesSystem?SystemId=' + systemId, {headers: header})) as any;

        return JSON.parse(result);
    }

    /**
     * Holt thing Monitor Daten aus dem Backend und schreibt diese in die jeweiligen things.
     * Wird verwendet um zu sehen wann das letzte Mal Daten für ein Property ins Backend geschickt wurden.
     */
    public async updateThingActivity()
    {
        // Bricht ab wenn User kein Monitoring sehen soll
        if (!this._auth.Monitoring) {
            return;
        }

        const url = environment.apiUrl + environment.apiVersion;
        const header = await this._auth.getDefaultHttpHeaders();

        for (const thing of this.data.things)
        {
            const thingId = thing.id;

            if (thingId === '0') continue;

            const result = await lastValueFrom(this._http.get(url + '/thing/snapshot?ThingId=' + thingId, {headers: header})) as any;

            const test = {};

            if (thing.monitoredPropertieSets == null) continue;

            for (const propSet of thing.monitoredPropertieSets)
            {
                if (propSet in result.sensor)
                {
                    test[propSet] = result.sensor[propSet];
                }
                else
                {
                    test[propSet] = null;
                }
            }

            thing.monitoredPropertieSets = test;

            this.getActivityMonitiorinDataAndState(thing);
            console.log(thing.displayName + ' Errors:' + thing.errorCount);

        }
    }

    /**
     * Berechnet ob monitoring Daten noch im aktuellen Zeitfenster liegen oder ob sie alt sind.
     * Wenn Daten alt sind werden diese auf eine Liste für alte Werte geschrieben.
     * @param pThing
     */
    private getActivityMonitiorinDataAndState(pThing: Thing): void
    {
        const outdatedProperties = [];

        Object.keys(pThing.monitoredPropertieSets).forEach(key =>
        {
            let keyErrorCount = 0;

            const activities = pThing.monitoredPropertieSets[key];

            if (activities == null)
            {
                keyErrorCount++;
                const dataWithExpiredTime = key + ': Keine Daten verfügbar';

                outdatedProperties.push(dataWithExpiredTime);
                pThing.errorCount++;
            }
            else
            {
                Object.keys(activities).forEach(aKey =>
                {
                    // Property von Testfahrten wird ignoriert
                    if (aKey === 'LAG_ERROR')
                    {
                        return;
                    }

                    if (aKey.includes('MAX') || aKey.includes('MIN'))
                    {
                        return;
                    }

                    if (activities[aKey] === '' || activities[aKey] == null)
                    {
                        keyErrorCount++;
                        const dataWithExpiredTime = key + ' > ' + aKey + ' Keine Daten verfügbar';

                        outdatedProperties.push(dataWithExpiredTime);

                        if (this.getPropertyError(pThing, key, aKey))
                        {
                            return;
                        }

                        this.setPropertyError(pThing, key, aKey, true);
                    }
                    else
                    {
                        let lastUpdatedDate = null; // new Date(activities[aKey][activities[aKey].length - 1].timestamp);
                        for (const datapoint of activities[aKey])
                        {
                            const datestamp = new Date(datapoint.timestamp);
                            if (datestamp > lastUpdatedDate)
                            {
                                lastUpdatedDate = datestamp;
                            }
                        }

                        const dateNow = new Date(Date.now());
                        const diffTime = dateNow.getTime() - lastUpdatedDate.getTime();

                        const diffDay = diffTime / (1000 * 60 * 60 * 24);
                        // Gibt den Zeitraum an in dem Daten für das Property Set geholt werden.
                        // Wenn diffDay >= initTime sind keine Daten im Dashboard zu sehen,
                        // da der letzte Datenpunkt nicht in der initTime liegt

                        let initDays = 1;

                        if (true)// this.getSeverity(pThing, key, aKey) === MonitoringLevel.Error)
                        {
                            if (this._auth.isAdmin())
                            {
                                initDays = 0.04166666666666666666666666666667;
                            }
                            else
                            {
                                if (pThing.propertySets.has(key))
                                {
                                    // Berechnet Zeit in Tagen in denen Daten beim initalisieren geholt werden
                                    // initDays = pThing.propertySets[key].initPullTimerange / 86400;
                                }
                            }
                        }
                        else
                        {
                            if (pThing.propertySets.has(key))
                            {
                                // Berechnet Zeit in Tagen in denen Daten beim initalisieren geholt werden
                                // initDays = pThing.propertySets[key].initPullTimerange / 86400;
                            }

                        }


                        // Mit dieser Abfrage kann man angeben ab wievielen Tagen ein Wert zu alt ist.
                        if (diffDay >= initDays)
                        {
                            keyErrorCount++;

                            const hours = Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                            const minutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));
                            const seconds = Math.floor((diffTime % (1000 * 60)) / (1000));

                            const dataWithExpiredTime = key + ' > ' + aKey + '; ist seit ' + Math.floor(diffDay) + ' T '
                                + hours + ' h '
                                + minutes + ' min '
                                + seconds + ' sec. nicht aktualisiert';

                            outdatedProperties.push(dataWithExpiredTime);


                            if (this.getPropertyError(pThing, key, aKey))
                            {
                                return;
                            }

                            this.setPropertyError(pThing, key, aKey, true);
                        }
                        else
                        {
                            if (!this.getPropertyError(pThing, key, aKey))
                            {
                                return;
                            }

                            this.setPropertyError(pThing, key, aKey, false);
                        }
                    }
                });


                if (keyErrorCount > 0)
                {
                    pThing.errorCount++;
                    this.setPropertySetError(pThing, key, true);
                }
                else
                {
                    this.setPropertySetError(pThing, key, false);
                }
            }
        });

        pThing.outdatedProperties.next(outdatedProperties);
    }

    /**
     * Setzt den Status des übergebenen PropertySets auf aktiv
     * @param pThing
     * @param pKey
     * @param pValue
     */
    private setPropertySetError(pThing: Thing, pKey: string, pValue: boolean): void
    {
        if (pKey in pThing)
        {
            pThing[pKey].statusActive = pValue;
            pThing[pKey].isError = pValue;
        }
        else
        {
            const prop = pThing.propertySets[pKey];
            if (prop == null) return;

            prop.statusActive = pValue;
            prop.isError = pValue;
        }
    }

    /**
     * Gibt an ob Status des PropertySets aktiv ist
     * @param pThing
     * @param pKey
     */
    private getPropertySetError(pThing: Thing, pKey: string): boolean
    {
        if (pKey in pThing)
        {
            return pThing[pKey].statusActive;
        }
        else
        {
            const prop = pThing.propertySets[pKey];
            if (prop == null) return;

            return prop.statusActive;
        }
    }

    /**
     * Setzt den Status des übergebenen PropertySets auf aktiv
     * @param pThing
     * @param pKey
     * @param pPropKey
     * @param pValue
     */
    private setPropertyError(pThing: Thing, pKey: string, pPropKey: string, pValue: boolean): void
    {
        if (pKey in pThing)
        {
            if (pPropKey in pThing[pKey])
            {
                switch (pThing[pKey][pPropKey].status)
                {
                    case MonitoringLevel.None:
                        return;
                    case MonitoringLevel.Info:
                    {
                        if (pValue)
                        {
                            pThing.infoCount++;
                        }
                        else
                        {
                            pThing.infoCount--;
                        }
                        break;
                    }
                    case MonitoringLevel.Warning:
                    {
                        if (pValue)
                        {
                            pThing.warningCount++;
                        }
                        else
                        {
                            pThing.warningCount--;
                        }
                        break;
                    }
                    case MonitoringLevel.Error:
                    {
                        if (pValue)
                        {
                            pThing.errorCount++;
                        }
                        else
                        {
                            pThing.errorCount--;
                        }
                        break;
                    }
                }

                pThing[pKey][pPropKey].statusActive = pValue;
                pThing[pKey][pPropKey].isError = false;

                if (pValue)
                {
                    if (pThing[pKey][pPropKey].status === MonitoringLevel.Error)
                    {
                        pThing[pKey][pPropKey].isError = true;
                    }
                }
            }
        }
        else
        {
            const prop = pThing.propertySets[pKey];
            if (prop == null) return;

            if (pPropKey in prop)
            {
                switch (prop[pPropKey].status)
                {
                    case MonitoringLevel.None:
                        return;
                    case MonitoringLevel.Info:
                    {
                        if (pValue)
                        {
                            pThing.infoCount++;
                        }
                        else
                        {
                            pThing.infoCount--;
                        }
                        break;
                    }
                    case MonitoringLevel.Warning:
                    {
                        if (pValue)
                        {
                            pThing.warningCount++;
                        }
                        else
                        {
                            pThing.warningCount--;
                        }
                        break;
                    }
                    case MonitoringLevel.Error:
                    {
                        if (pValue)
                        {
                            pThing.errorCount++;
                        }
                        else
                        {
                            pThing.errorCount--;
                        }
                        break;
                    }
                }

                prop[pPropKey].statusActive = pValue;
                prop[pPropKey].isError = false;

                if (pValue)
                {
                    if (prop[pPropKey].status === MonitoringLevel.Error)
                    {
                        prop[pPropKey].isError = true;
                    }
                }
            }
        }
    }

    /**
     * Gibt an ob Status des PropertySets aktiv ist
     * @param pThing
     * @param pKey
     * @param pPropKey
     */
    private getPropertyError(pThing: Thing, pKey: string, pPropKey: string): boolean
    {
        const prop = pThing.propertySets[pKey];

        if (prop == null) return false;

        if (pPropKey in prop)
        {
            return prop[pPropKey].statusActive;
        }


        return false;
    }

    /**
     * Gibt das Monitoring Level des Things zurück
     * @param pThing
     * @param pKey
     * @param pPropKey
     */
    private getSeverity(pThing: Thing, pKey: string, pPropKey: string): MonitoringLevel
    {
        if (pKey in pThing.propertySets)
        {
            if (pPropKey in pThing.propertySets[pKey])
            {
                return pThing[pKey][pPropKey].status;
            }
        }
        else
        {
            const prop = pThing.propertySets[pKey];
            if (prop == null) return;

            if (pPropKey in prop)
            {
                return prop[pPropKey].status;
            }
        }
    }

}
