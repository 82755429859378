<app-current-voltage *ngIf="widget === 'current-voltage'"
                     [resizeEvent]="resizeEvent"
                     [VoltageErrorLevel]="Thing.CTH_CURRENTMETER.VOLTAGE_UL1_N.status"
                     [VoltageError]="Thing.CTH_CURRENTMETER.VOLTAGE_UL1_N.isError || Thing.CTH_CURRENTMETER.VOLTAGE_UL2_N.isError  || Thing.CTH_CURRENTMETER.VOLTAGE_UL3_N.isError"
                     [VoltageValue]="[Thing.CTH_CURRENTMETER.VOLTAGE_UL1_N.TimeseriesData,
                                      Thing.CTH_CURRENTMETER.VOLTAGE_UL2_N.TimeseriesData,
                                      Thing.CTH_CURRENTMETER.VOLTAGE_UL3_N.TimeseriesData]"
                     [CurrentErrorLevel]="Thing.CTH_CURRENTMETER.AMPERAGE_L1.status"
                     [CurrentError]="Thing.CTH_CURRENTMETER.AMPERAGE_L1.isError || Thing.CTH_CURRENTMETER.AMPERAGE_L2.isError  || Thing.CTH_CURRENTMETER.AMPERAGE_L3.isError"
                     [CurrentValue]="[Thing.CTH_CURRENTMETER.AMPERAGE_L1.TimeseriesData,
                                      Thing.CTH_CURRENTMETER.AMPERAGE_L2.TimeseriesData,
                                      Thing.CTH_CURRENTMETER.AMPERAGE_L3.TimeseriesData]">
</app-current-voltage>

<app-sum-of-power *ngIf="widget === 'sum-of-power'"
                  [resizeEvent]="resizeEvent"
                  [ActivePowerError]="Thing.CTH_CURRENTMETER.SUM_ACTIVE_POWER.isError"
                  [ActivePowerErrorLevel]="Thing.CTH_CURRENTMETER.SUM_ACTIVE_POWER.status"
                  [ActivePowerValue]="[Thing.CTH_CURRENTMETER.SUM_ACTIVE_POWER.TimeseriesData]"
                  [ReactivePowerError]="Thing.CTH_CURRENTMETER.SUM_REACTIVE_POWER.isError"
                  [ReactivePowerErrorLevel]="Thing.CTH_CURRENTMETER.SUM_REACTIVE_POWER.status"
                  [ReactivePowerValue]="[Thing.CTH_CURRENTMETER.SUM_REACTIVE_POWER.TimeseriesData]"
                  [ApparentPowerError]="Thing.CTH_CURRENTMETER.SUM_APPARENT_POWER.isError"
                  [ApparentPowerErrorLevel]="Thing.CTH_CURRENTMETER.SUM_APPARENT_POWER.status"
                  [ApparentPowerValue]="[Thing.CTH_CURRENTMETER.SUM_APPARENT_POWER.TimeseriesData]">
</app-sum-of-power>

<app-image *ngIf="widget === 'image'"
           [resizeEvent]="resizeEvent"
           [heading]="'Darstellung CTH' | translate"
           [src]="Thing.model3D"
           [equipmentNumber]="Thing.equipmentNumber"
           [alt]="'Darstellung CTH' | translate"
           [ain]="Thing.ainUrl"
           [width]="'auto'">
</app-image>


<app-energy-consumption *ngIf="widget === 'energy-consumption'"
                        [resizeEvent]="resizeEvent"
                        [EnergyPriceError]="Thing.CTH_ENERGY.ACTIVE_ENERGY_PRICE.isError"
                        [EnergyPriceErrorLevel]="Thing.CTH_ENERGY.ACTIVE_ENERGY_PRICE.status"
                        [EnergyPriceValue]="Thing.CTH_ENERGY.ACTIVE_ENERGY_PRICE.TimeseriesData"
                        [EnergyHourError]="Thing.CTH_ENERGY.ACTIVE_ENERGY_HOUR.isError"
                        [EnergyHourErrorLevel]="Thing.CTH_ENERGY.ACTIVE_ENERGY_HOUR.status"
                        [EnergyHourValue]="Thing.CTH_ENERGY.ACTIVE_ENERGY_HOUR.TimeseriesData"
                        [EnergyWeekError]="Thing.CTH_ENERGY.ACTIVE_ENERGY_WEEK.isError"
                        [EnergyWeekErrorLevel]="Thing.CTH_ENERGY.ACTIVE_ENERGY_WEEK.status"
                        [EnergyWeekValue]="Thing.CTH_ENERGY.ACTIVE_ENERGY_WEEK.TimeseriesData"
                        [EnergyMonthError]="Thing.CTH_ENERGY.ACTIVE_ENERGY_MONTH.isError"
                        [EnergyMonthErrorLevel]="Thing.CTH_ENERGY.ACTIVE_ENERGY_MONTH.status"
                        [EnergyMonthValue]="Thing.CTH_ENERGY.ACTIVE_ENERGY_MONTH.TimeseriesData"
                        [EnergyYearError]="Thing.CTH_ENERGY.ACTIVE_ENERGY_YEAR.isError"
                        [EnergyYearErrorLevel]="Thing.CTH_ENERGY.ACTIVE_ENERGY_YEAR.status"
                        [EnergyYearValue]="Thing.CTH_ENERGY.ACTIVE_ENERGY_YEAR.TimeseriesData"
                        [EnergyAllError]="Thing.CTH_ENERGY.ACTIVE_ENERGY_ALL.isError"
                        [EnergyAllErrorLevel]="Thing.CTH_ENERGY.ACTIVE_ENERGY_ALL.status"
                        [EnergyAllValue]="Thing.CTH_ENERGY.ACTIVE_ENERGY_ALL.TimeseriesData">
</app-energy-consumption>

<app-distance *ngIf="widget === 'distance'"
              [resizeEvent]="resizeEvent"
              [HorizontalDistanceError]="Thing.CTH_SPS_HORIZONTAL_DISTANCE.KILOMETER.isError || Thing.CTH_SPS_HORIZONTAL_DISTANCE.METER.isError"
              [HorizontalDistanceErrorLevel]="Thing.CTH_SPS_HORIZONTAL_DISTANCE.KILOMETER.status"
              [HorizontalDistanceValue]="[Thing.CTH_SPS_HORIZONTAL_DISTANCE.DistanceCalculated.TimeseriesData]"
              [HorizontalDistanceLastMaintenance]="[Thing.CTH_SPS_HORIZONTAL_DISTANCE.KILOMETER.ValueLastService, Thing.CTH_SPS_HORIZONTAL_DISTANCE.METER.ValueLastService] | calcDistance: null"
              [VerticalDistanceError]="Thing.CTH_SPS_VERTICAL_DISTANCE.KILOMETER.isError || Thing.CTH_SPS_VERTICAL_DISTANCE.METER.isError"
              [VerticalDistanceErrorLevel]="Thing.CTH_SPS_VERTICAL_DISTANCE.KILOMETER.status"
              [VerticalDistanceValue]="[Thing.CTH_SPS_VERTICAL_DISTANCE.DistanceCalculated.TimeseriesData]"
              [VerticalDistanceLastMaintenance]="[Thing.CTH_SPS_VERTICAL_DISTANCE.KILOMETER.ValueLastService, Thing.CTH_SPS_VERTICAL_DISTANCE.METER.ValueLastService] | calcDistance: null">
</app-distance>

<app-statistic *ngIf="widget === 'statistic'"
               [resizeEvent]="resizeEvent"
               [RollInError]="Thing.CTH_SPS_STATISTICS.ROLL_IN.isError"
               [RollInErrorLevel]="Thing.CTH_SPS_STATISTICS.ROLL_IN.status"
               [RollInValue]="[Thing.CTH_SPS_STATISTICS.ROLL_IN.TimeseriesData]"
               [RollInLastMaintenance]="Thing.CTH_SPS_STATISTICS.ROLL_IN?.ValueLastService"
               [RollOutError]="Thing.CTH_SPS_STATISTICS.ROLL_OUT.isError"
               [RollOutErrorLevel]="Thing.CTH_SPS_STATISTICS.ROLL_OUT.status"
               [RollOutValue]="[Thing.CTH_SPS_STATISTICS.ROLL_OUT.TimeseriesData]"
               [RollOutLastMaintenance]="Thing.CTH_SPS_STATISTICS.ROLL_OUT?.ValueLastService"
               [RelocationError]="Thing.CTH_SPS_STATISTICS.RELOCATION.isError"
               [RelocationErrorLevel]="Thing.CTH_SPS_STATISTICS.RELOCATION.status"
               [RelocationValue]="[Thing.CTH_SPS_STATISTICS.RELOCATION.TimeseriesData]"
               [RelocationLastMaintenance]="Thing.CTH_SPS_STATISTICS.RELOCATION?.ValueLastService">
</app-statistic>

<app-status *ngIf="widget === 'status'"
            [resizeEvent]="resizeEvent"
            [OperatingModeError]="Thing.CTH_SPS_STATUS.OPERATING_MODE.isError"
            [OperatingModeErrorLevel]="Thing.CTH_SPS_STATUS.OPERATING_MODE.status"
            [OperatingModeValue]="Thing.CTH_SPS_STATUS.OPERATING_MODE.TimeseriesData.currentDatapoint?.value"
            [FirstErrorNumberError]="Thing.CTH_SPS_STATUS.FIRST_ERROR_NUMBER.isError"
            [FirstErrorNumberErrorLevel]="Thing.CTH_SPS_STATUS.FIRST_ERROR_NUMBER.status"
            [FirstErrorNumberValue]="[Thing.CTH_SPS_STATUS.FIRST_ERROR_NUMBER.TimeseriesData]"
            [OperatingHoursError]="Thing.CTH_SPS_STATUS.OPERATING_HOURS.isError"
            [OperatingHoursErrorLevel]="Thing.CTH_SPS_STATUS.OPERATING_HOURS.status"
            [OperatingHoursValue]="[Thing.CTH_SPS_STATUS.OPERATING_HOURS.TimeseriesData]"
            [OperatingHoursLastMaintenance]="Thing.CTH_SPS_STATUS.OPERATING_HOURS?.ValueLastService">
</app-status>


<app-cm-sensors *ngIf="widget === 'cm-mdw'"
                [resizeEvent]="resizeEvent"
                [Type]="'Mast Unten'"
                [TemperatureError]="Thing.CMS.CMS_ENV_MDW.TEMPERATURE.isError"
                [TemperatureErrorLevel]="Thing.CMS.CMS_ENV_MDW.TEMPERATURE.status"
                [TemperatureValue]="[Thing.CMS.CMS_ENV_MDW.TEMPERATURE.TimeseriesData]"
                [RotationError]="Thing.CMS.CMS_ROT_MDW.ROTATION_X.isError || Thing.CMS.CMS_ROT_MDW.ROTATION_Y.isError || Thing.CMS.CMS_ROT_MDW.ROTATION_Z.isError"
                [RotationErrorLevel]="Thing.CMS.CMS_ROT_MDW.ROTATION_X.status"
                [RotationValue]="[Thing.CMS.CMS_ROT_MDW.ROTATION_X.TimeseriesData,
                                 Thing.CMS.CMS_ROT_MDW.ROTATION_Y.TimeseriesData,
                                 Thing.CMS.CMS_ROT_MDW.ROTATION_Z.TimeseriesData]"
                [AngularAccelerationError]="Thing.CMS.CMS_ANGACL_MDW.ACCELERATION_X.isError || Thing.CMS.CMS_ANGACL_MDW.ACCELERATION_Y.isError || Thing.CMS.CMS_ANGACL_MDW.ACCELERATION_Z.isError"
                [AngularAccelerationErrorLevel]="Thing.CMS.CMS_ANGACL_MDW.ACCELERATION_X.status"
                [AngularAccelerationValue]="[Thing.CMS.CMS_ANGACL_MDW.ACCELERATION_X.TimeseriesData,
                                 Thing.CMS.CMS_ANGACL_MDW.ACCELERATION_Y.TimeseriesData,
                                 Thing.CMS.CMS_ANGACL_MDW.ACCELERATION_Z.TimeseriesData]"
                [AccelerationError]="Thing.CMS.CMS_ACL_MDW.ACCELERATION_X.isError || Thing.CMS.CMS_ACL_MDW.ACCELERATION_Y.isError || Thing.CMS.CMS_ACL_MDW.ACCELERATION_Z.isError"
                [AccelerationErrorLevel]="Thing.CMS.CMS_ACL_MDW.ACCELERATION_X.status"
                [AccelerationValue]="[Thing.CMS.CMS_ACL_MDW.ACCELERATION_X.TimeseriesData,
                                 Thing.CMS.CMS_ACL_MDW.ACCELERATION_Y.TimeseriesData,
                                 Thing.CMS.CMS_ACL_MDW.ACCELERATION_Z.TimeseriesData]">
</app-cm-sensors>

<app-cm-sensors *ngIf="widget === 'cm-mtop'"
                [resizeEvent]="resizeEvent"
                [Type]="'Mast Oben'"
                [TemperatureError]="Thing.CMS.CMS_ENV_MTOP.TEMPERATURE.isError"
                [TemperatureErrorLevel]="Thing.CMS.CMS_ENV_MTOP.TEMPERATURE.status"
                [TemperatureValue]="[Thing.CMS.CMS_ENV_MTOP.TEMPERATURE.TimeseriesData]"
                [RotationError]="Thing.CMS.CMS_ROT_MTOP.ROTATION_X.isError || Thing.CMS.CMS_ROT_MTOP.ROTATION_Y.isError || Thing.CMS.CMS_ROT_MTOP.ROTATION_Z.isError"
                [RotationErrorLevel]="Thing.CMS.CMS_ROT_MTOP.ROTATION_X.status"
                [RotationValue]="[Thing.CMS.CMS_ROT_MTOP.ROTATION_X.TimeseriesData,
                                 Thing.CMS.CMS_ROT_MTOP.ROTATION_Y.TimeseriesData,
                                 Thing.CMS.CMS_ROT_MTOP.ROTATION_Z.TimeseriesData]"
                [AngularAccelerationError]="Thing.CMS.CMS_ANGACL_MTOP.ACCELERATION_X.isError || Thing.CMS.CMS_ANGACL_MTOP.ACCELERATION_Y.isError || Thing.CMS.CMS_ANGACL_MTOP.ACCELERATION_Z.isError"
                [AngularAccelerationErrorLevel]="Thing.CMS.CMS_ANGACL_MTOP.ACCELERATION_X.status"
                [AngularAccelerationValue]="[Thing.CMS.CMS_ANGACL_MTOP.ACCELERATION_X.TimeseriesData,
                                 Thing.CMS.CMS_ANGACL_MTOP.ACCELERATION_Y.TimeseriesData,
                                 Thing.CMS.CMS_ANGACL_MTOP.ACCELERATION_Z.TimeseriesData]"
                [AccelerationError]="Thing.CMS.CMS_ACL_MTOP.ACCELERATION_X.isError || Thing.CMS.CMS_ACL_MTOP.ACCELERATION_Y.isError || Thing.CMS.CMS_ACL_MTOP.ACCELERATION_Z.isError"
                [AccelerationErrorLevel]="Thing.CMS.CMS_ACL_MTOP.ACCELERATION_X.status"
                [AccelerationValue]="[Thing.CMS.CMS_ACL_MTOP.ACCELERATION_X.TimeseriesData,
                                 Thing.CMS.CMS_ACL_MTOP.ACCELERATION_Y.TimeseriesData,
                                 Thing.CMS.CMS_ACL_MTOP.ACCELERATION_Z.TimeseriesData]">
</app-cm-sensors>

<app-cm-sensors *ngIf="widget === 'cth-cm-mbl'"
                [resizeEvent]="resizeEvent"
                [Type]="'Zwischenebene'"
                [TemperatureError]="Thing.CMS.CMS_ENV_MBL.TEMPERATURE.isError"
                [TemperatureErrorLevel]="Thing.CMS.CMS_ENV_MBL.TEMPERATURE.status"
                [TemperatureValue]="[Thing.CMS.CMS_ENV_MBL.TEMPERATURE.TimeseriesData]"
                [RotationError]="Thing.CMS.CMS_ROT_MBL.ROTATION_X.isError || Thing.CMS.CMS_ROT_MBL.ROTATION_Y.isError || Thing.CMS.CMS_ROT_MBL.ROTATION_Z.isError"
                [RotationErrorLevel]="Thing.CMS.CMS_ROT_MBL.ROTATION_X.status"
                [RotationValue]="[Thing.CMS.CMS_ROT_MBL.ROTATION_X.TimeseriesData,
                                 Thing.CMS.CMS_ROT_MBL.ROTATION_Y.TimeseriesData,
                                 Thing.CMS.CMS_ROT_MBL.ROTATION_Z.TimeseriesData]"
                [AngularAccelerationError]="Thing.CMS.CMS_ANGACL_MBL.ACCELERATION_X.isError || Thing.CMS.CMS_ANGACL_MBL.ACCELERATION_Y.isError || Thing.CMS.CMS_ANGACL_MBL.ACCELERATION_Z.isError"
                [AngularAccelerationErrorLevel]="Thing.CMS.CMS_ANGACL_MBL.ACCELERATION_X.status"
                [AngularAccelerationValue]="[Thing.CMS.CMS_ANGACL_MBL.ACCELERATION_X.TimeseriesData,
                                 Thing.CMS.CMS_ANGACL_MBL.ACCELERATION_Y.TimeseriesData,
                                 Thing.CMS.CMS_ANGACL_MBL.ACCELERATION_Z.TimeseriesData]"
                [AccelerationError]="Thing.CMS.CMS_ACL_MBL.ACCELERATION_X.isError || Thing.CMS.CMS_ACL_MBL.ACCELERATION_Y.isError || Thing.CMS.CMS_ACL_MBL.ACCELERATION_Z.isError"
                [AccelerationErrorLevel]="Thing.CMS.CMS_ACL_MBL.ACCELERATION_X.status"
                [AccelerationValue]="[Thing.CMS.CMS_ACL_MBL.ACCELERATION_X.TimeseriesData,
                                 Thing.CMS.CMS_ACL_MBL.ACCELERATION_Y.TimeseriesData,
                                 Thing.CMS.CMS_ACL_MBL.ACCELERATION_Z.TimeseriesData]">
</app-cm-sensors>

<app-cm-sensors *ngIf="widget === 'cth-cm-smd2'"
                [resizeEvent]="resizeEvent"
                [Type]="'Fahrmotor 2'"
                [TemperatureError]="Thing.CMS.CMS_ENV_SMD2.TEMPERATURE.isError"
                [TemperatureErrorLevel]="Thing.CMS.CMS_ENV_SMD2.TEMPERATURE.status"
                [TemperatureValue]="[Thing.CMS.CMS_ENV_SMD2.TEMPERATURE.TimeseriesData]"
                [RotationError]="Thing.CMS.CMS_ROT_SMD2.ROTATION_X.isError || Thing.CMS.CMS_ROT_SMD2.ROTATION_Y.isError || Thing.CMS.CMS_ROT_SMD2.ROTATION_Z.isError"
                [RotationErrorLevel]="Thing.CMS.CMS_ROT_SMD2.ROTATION_X.status"
                [RotationValue]="[Thing.CMS.CMS_ROT_SMD2.ROTATION_X.TimeseriesData,
                                 Thing.CMS.CMS_ROT_SMD2.ROTATION_Y.TimeseriesData,
                                 Thing.CMS.CMS_ROT_SMD2.ROTATION_Z.TimeseriesData]"
                [AngularAccelerationError]="Thing.CMS.CMS_ANGACL_SMD2.ACCELERATION_X.isError || Thing.CMS.CMS_ANGACL_SMD2.ACCELERATION_Y.isError || Thing.CMS.CMS_ANGACL_SMD2.ACCELERATION_Z.isError"
                [AngularAccelerationErrorLevel]="Thing.CMS.CMS_ANGACL_SMD2.ACCELERATION_X.status"
                [AngularAccelerationValue]="[Thing.CMS.CMS_ANGACL_SMD2.ACCELERATION_X.TimeseriesData,
                                 Thing.CMS.CMS_ANGACL_SMD2.ACCELERATION_Y.TimeseriesData,
                                 Thing.CMS.CMS_ANGACL_SMD2.ACCELERATION_Z.TimeseriesData]"
                [AccelerationError]="Thing.CMS.CMS_ACL_SMD2.ACCELERATION_X.isError || Thing.CMS.CMS_ACL_SMD2.ACCELERATION_Y.isError || Thing.CMS.CMS_ACL_SMD2.ACCELERATION_Z.isError"
                [AccelerationErrorLevel]="Thing.CMS.CMS_ACL_SMD2.ACCELERATION_X.status"
                [AccelerationValue]="[Thing.CMS.CMS_ACL_SMD2.ACCELERATION_X.TimeseriesData,
                                 Thing.CMS.CMS_ACL_SMD2.ACCELERATION_Y.TimeseriesData,
                                 Thing.CMS.CMS_ACL_SMD2.ACCELERATION_Z.TimeseriesData]">
</app-cm-sensors>

<app-cm-sensors *ngIf="widget === 'cth-cm-smd3'"
                [resizeEvent]="resizeEvent"
                [Type]="'Fahrmotor 3'"
                [TemperatureError]="Thing.CMS.CMS_ENV_SMD3.TEMPERATURE.isError"
                [TemperatureErrorLevel]="Thing.CMS.CMS_ENV_SMD3.TEMPERATURE.status"
                [TemperatureValue]="[Thing.CMS.CMS_ENV_SMD3.TEMPERATURE.TimeseriesData]"
                [RotationError]="Thing.CMS.CMS_ROT_SMD3.ROTATION_X.isError || Thing.CMS.CMS_ROT_SMD3.ROTATION_Y.isError || Thing.CMS.CMS_ROT_SMD3.ROTATION_Z.isError"
                [RotationErrorLevel]="Thing.CMS.CMS_ROT_SMD3.ROTATION_X.status"
                [RotationValue]="[Thing.CMS.CMS_ROT_SMD3.ROTATION_X.TimeseriesData,
                                 Thing.CMS.CMS_ROT_SMD3.ROTATION_Y.TimeseriesData,
                                 Thing.CMS.CMS_ROT_SMD3.ROTATION_Z.TimeseriesData]"
                [AngularAccelerationError]="Thing.CMS.CMS_ANGACL_SMD3.ACCELERATION_X.isError || Thing.CMS.CMS_ANGACL_SMD3.ACCELERATION_Y.isError || Thing.CMS.CMS_ANGACL_SMD3.ACCELERATION_Z.isError"
                [AngularAccelerationErrorLevel]="Thing.CMS.CMS_ANGACL_SMD3.ACCELERATION_X.status"
                [AngularAccelerationValue]="[Thing.CMS.CMS_ANGACL_SMD3.ACCELERATION_X.TimeseriesData,
                                 Thing.CMS.CMS_ANGACL_SMD3.ACCELERATION_Y.TimeseriesData,
                                 Thing.CMS.CMS_ANGACL_SMD3.ACCELERATION_Z.TimeseriesData]"
                [AccelerationError]="Thing.CMS.CMS_ACL_SMD3.ACCELERATION_X.isError || Thing.CMS.CMS_ACL_SMD3.ACCELERATION_Y.isError || Thing.CMS.CMS_ACL_SMD3.ACCELERATION_Z.isError"
                [AccelerationErrorLevel]="Thing.CMS.CMS_ACL_SMD3.ACCELERATION_X.status"
                [AccelerationValue]="[Thing.CMS.CMS_ACL_SMD3.ACCELERATION_X.TimeseriesData,
                                 Thing.CMS.CMS_ACL_SMD3.ACCELERATION_Y.TimeseriesData,
                                 Thing.CMS.CMS_ACL_SMD3.ACCELERATION_Z.TimeseriesData]">
</app-cm-sensors>


<app-cm-sensors *ngIf="widget === 'cth-cm-ccm'"
                [resizeEvent]="resizeEvent"
                [Type]="'Schaltschrank'"
                [TemperatureError]="Thing.CMS.CMS_ENV_CCM.TEMPERATURE.isError"
                [TemperatureErrorLevel]="Thing.CMS.CMS_ENV_CCM.TEMPERATURE.status"
                [TemperatureValue]="[Thing.CMS.CMS_ENV_CCM.TEMPERATURE.TimeseriesData]"
                [RotationError]="Thing.CMS.CMS_ROT_CCM.ROTATION_X.isError || Thing.CMS.CMS_ROT_CCM.ROTATION_Y.isError || Thing.CMS.CMS_ROT_CCM.ROTATION_Z.isError"
                [RotationErrorLevel]="Thing.CMS.CMS_ROT_CCM.ROTATION_X.status"
                [RotationValue]="[Thing.CMS.CMS_ROT_CCM.ROTATION_X.TimeseriesData,
                                 Thing.CMS.CMS_ROT_CCM.ROTATION_Y.TimeseriesData,
                                 Thing.CMS.CMS_ROT_CCM.ROTATION_Z.TimeseriesData]"
                [AngularAccelerationError]="Thing.CMS.CMS_ANGACL_CCM.ACCELERATION_X.isError || Thing.CMS.CMS_ANGACL_CCM.ACCELERATION_Y.isError || Thing.CMS.CMS_ANGACL_CCM.ACCELERATION_Z.isError"
                [AngularAccelerationErrorLevel]="Thing.CMS.CMS_ANGACL_CCM.ACCELERATION_X.status"
                [AngularAccelerationValue]="[Thing.CMS.CMS_ANGACL_CCM.ACCELERATION_X.TimeseriesData,
                                 Thing.CMS.CMS_ANGACL_CCM.ACCELERATION_Y.TimeseriesData,
                                 Thing.CMS.CMS_ANGACL_CCM.ACCELERATION_Z.TimeseriesData]"
                [AccelerationError]="Thing.CMS.CMS_ACL_CCM.ACCELERATION_X.isError || Thing.CMS.CMS_ACL_CCM.ACCELERATION_Y.isError || Thing.CMS.CMS_ACL_CCM.ACCELERATION_Z.isError"
                [AccelerationErrorLevel]="Thing.CMS.CMS_ACL_CCM.ACCELERATION_X.status"
                [AccelerationValue]="[Thing.CMS.CMS_ACL_CCM.ACCELERATION_X.TimeseriesData,
                                 Thing.CMS.CMS_ACL_CCM.ACCELERATION_Y.TimeseriesData,
                                 Thing.CMS.CMS_ACL_CCM.ACCELERATION_Z.TimeseriesData]">
</app-cm-sensors>

<app-cm-sensors *ngIf="widget === 'cth-cm-lftop'"
                [resizeEvent]="resizeEvent"
                [Type]="'Hubkorb oben'"
                [TemperatureError]="Thing.CMS.CMS_ENV_LFTOP.TEMPERATURE.isError"
                [TemperatureErrorLevel]="Thing.CMS.CMS_ENV_LFTOP.TEMPERATURE.status"
                [TemperatureValue]="[Thing.CMS.CMS_ENV_LFTOP.TEMPERATURE.TimeseriesData]"
                [RotationError]="Thing.CMS.CMS_ROT_LFTOP.ROTATION_X.isError || Thing.CMS.CMS_ROT_LFTOP.ROTATION_Y.isError || Thing.CMS.CMS_ROT_LFTOP.ROTATION_Z.isError"
                [RotationErrorLevel]="Thing.CMS.CMS_ROT_LFTOP.ROTATION_X.status"
                [RotationValue]="[Thing.CMS.CMS_ROT_LFTOP.ROTATION_X.TimeseriesData,
                                 Thing.CMS.CMS_ROT_LFTOP.ROTATION_Y.TimeseriesData,
                                 Thing.CMS.CMS_ROT_LFTOP.ROTATION_Z.TimeseriesData]"
                [AngularAccelerationError]="Thing.CMS.CMS_ANGACL_LFTOP.ACCELERATION_X.isError || Thing.CMS.CMS_ANGACL_LFTOP.ACCELERATION_Y.isError || Thing.CMS.CMS_ANGACL_LFTOP.ACCELERATION_Z.isError"
                [AngularAccelerationErrorLevel]="Thing.CMS.CMS_ANGACL_LFTOP.ACCELERATION_X.status"
                [AngularAccelerationValue]="[Thing.CMS.CMS_ANGACL_LFTOP.ACCELERATION_X.TimeseriesData,
                                 Thing.CMS.CMS_ANGACL_LFTOP.ACCELERATION_Y.TimeseriesData,
                                 Thing.CMS.CMS_ANGACL_LFTOP.ACCELERATION_Z.TimeseriesData]"
                [AccelerationError]="Thing.CMS.CMS_ACL_LFTOP.ACCELERATION_X.isError || Thing.CMS.CMS_ACL_LFTOP.ACCELERATION_Y.isError || Thing.CMS.CMS_ACL_LFTOP.ACCELERATION_Z.isError"
                [AccelerationErrorLevel]="Thing.CMS.CMS_ACL_LFTOP.ACCELERATION_X.status"
                [AccelerationValue]="[Thing.CMS.CMS_ACL_LFTOP.ACCELERATION_X.TimeseriesData,
                                 Thing.CMS.CMS_ACL_LFTOP.ACCELERATION_Y.TimeseriesData,
                                 Thing.CMS.CMS_ACL_LFTOP.ACCELERATION_Z.TimeseriesData]">
</app-cm-sensors>

<app-cm-sensors *ngIf="widget === 'cth-cm-lfdown'"
                [resizeEvent]="resizeEvent"
                [Type]="'Hubkorb unten'"
                [TemperatureError]="Thing.CMS.CMS_ENV_LFDOWN.TEMPERATURE.isError"
                [TemperatureErrorLevel]="Thing.CMS.CMS_ENV_LFDOWN.TEMPERATURE.status"
                [TemperatureValue]="[Thing.CMS.CMS_ENV_LFDOWN.TEMPERATURE.TimeseriesData]"
                [RotationError]="Thing.CMS.CMS_ROT_LFDOWN.ROTATION_X.isError || Thing.CMS.CMS_ROT_LFDOWN.ROTATION_Y.isError || Thing.CMS.CMS_ROT_LFDOWN.ROTATION_Z.isError"
                [RotationErrorLevel]="Thing.CMS.CMS_ROT_LFDOWN.ROTATION_X.status"
                [RotationValue]="[Thing.CMS.CMS_ROT_LFDOWN.ROTATION_X.TimeseriesData,
                                 Thing.CMS.CMS_ROT_LFDOWN.ROTATION_Y.TimeseriesData,
                                 Thing.CMS.CMS_ROT_LFDOWN.ROTATION_Z.TimeseriesData]"
                [AngularAccelerationError]="Thing.CMS.CMS_ANGACL_LFDOWN.ACCELERATION_X.isError || Thing.CMS.CMS_ANGACL_LFDOWN.ACCELERATION_Y.isError || Thing.CMS.CMS_ANGACL_LFDOWN.ACCELERATION_Z.isError"
                [AngularAccelerationErrorLevel]="Thing.CMS.CMS_ANGACL_LFDOWN.ACCELERATION_X.status"
                [AngularAccelerationValue]="[Thing.CMS.CMS_ANGACL_LFDOWN.ACCELERATION_X.TimeseriesData,
                                 Thing.CMS.CMS_ANGACL_LFDOWN.ACCELERATION_Y.TimeseriesData,
                                 Thing.CMS.CMS_ANGACL_LFDOWN.ACCELERATION_Z.TimeseriesData]"
                [AccelerationError]="Thing.CMS.CMS_ACL_LFDOWN.ACCELERATION_X.isError || Thing.CMS.CMS_ACL_LFDOWN.ACCELERATION_Y.isError || Thing.CMS.CMS_ACL_LFDOWN.ACCELERATION_Z.isError"
                [AccelerationErrorLevel]="Thing.CMS.CMS_ACL_LFDOWN.ACCELERATION_X.status"
                [AccelerationValue]="[Thing.CMS.CMS_ACL_LFDOWN.ACCELERATION_X.TimeseriesData,
                                 Thing.CMS.CMS_ACL_LFDOWN.ACCELERATION_Y.TimeseriesData,
                                 Thing.CMS.CMS_ACL_LFDOWN.ACCELERATION_Z.TimeseriesData]">
</app-cm-sensors>


