import {Component} from '@angular/core';
import {BaseSubcomp} from '../../../base-subcomp';

@Component({
    selector: 'app-ols-subcomp-parent',
    templateUrl: './ols-subcomp-parent.component.html',
    styleUrls: ['./ols-subcomp-parent.component.scss']
})
export class OlsSubcompParentComponent extends BaseSubcomp
{

}
