<app-help-page-banner [items]="[['Livedaten', 'Live Data', 'LifeDa'],
['Historiche Daten', 'Historic Data', 'HistoData']]"
                      [returnAddress]="'/HelpPage'"></app-help-page-banner>
<div class="container">
    <!-- StartPage -->
    <h2>{{'Datum einstellen' | translate}} </h2>
    <p> {{'Das Datum der anzuzeigenden Daten eines Equipments kann, in der Equipmentansicht oben rechts geändert werden.' | translate}} </p>
    <h3 class="subheading" id="LifeDa">{{'Livedaten' | translate}}</h3>
    <img id="imageLiveData" src="{{environment.storageUrl}}/models3d/general/helpPage/introduction/SetDate/LiveData_{{urlLanguage}}.png">
    <div>
        {{'Um die aktuellen Daten aufzurufen muss man den Button „Livedaten“ klicken.' | translate}}
    </div>
    <h3 class="subheading" id="HistoData">{{'Historiche Daten' | translate}}</h3>
    <img id="imageHistData" src="{{environment.storageUrl}}/models3d/general/helpPage/introduction/SetDate/HistoricData_{{urlLanguage}}.png">
    <div>
        {{'Um auf frühere Daten zugreifen zu können, müssen sie auf den Button „Historische Daten“ klicken, über welchen Daten zu einem gewissen Zeitpunkt aufgerufen werden können.' | translate}}
    </div>

    <h3 class="subheading" id="HistoData">{{'Zeitraum eingrenzen' | translate}}</h3>
    <div>
        {{'Um den Zeitraum einzugrenzen können sie folgendermaßen das Datum sowie die Uhrzeit festlegen und anschließend bestätigen.' | translate}}
    
    <img id="selectDate" src="{{environment.storageUrl}}/models3d/general/helpPage/introduction/SetDate/selectDate_de.png" 
        class="embed-responsive mx-auto d-block" style="margin-bottom: 100px;">

    </div>
</div>
