import {Injectable} from '@angular/core';
import {DataPoint} from '../../model/helper-models/datapoint.model';
import {LimitSizeArrayModel} from '../../model/helper-models/limit-size-array.model';

@Injectable({
    providedIn: 'root'
})
/*
 * Klasse für Hilfsmethoden
 * Darf nicht statisch sein da man im HTMl Markup nicht auf statische Methoden zugreifen kann
 */
export class HelperService
{
    /**
     * Wandelt die timestamp aus dem AE (UTC) in unsere Zeit (GMT) um.
     * @param pTime
     */
    public static parseUTCToGMT(pTime: string): string
    {
        if (Date.parse(pTime) < 0 || pTime == null) return;

        const timestamp = new Date(pTime);

        if (isNaN(timestamp.getDate())) return;

        return [timestamp.getFullYear(), String(timestamp.getMonth() + 1).padStart(2, '0'),
                String(timestamp.getDate()).padStart(2, '0')].join('-')
            + 'T' + String(timestamp.getHours()).padStart(2, '0')
            + ':' + String(timestamp.getMinutes()).padStart(2, '0')
            + ':' + String(timestamp.getSeconds()).padStart(2, '0')
            + '.' + String(timestamp.getMilliseconds()).padStart(3, '0');
    }

    /**
     * Rundet Zahl mit angegebener Genauigkeit
     * @param pValue
     * @param pPrecision
     */
    public round(pValue, pPrecision): number
    {
        const multiplier = Math.pow(10, pPrecision || 0);
        return Math.round(pValue * multiplier) / multiplier;
    }

    /**
     * Rundet eine Datenpunkt ab.
     * @param pDatapoint
     * @param pPercision
     * @constructor
     */
    public roundDatapoint(pDatapoint: DataPoint<number>, pPercision: number): DataPoint<number>
    {
        if (pDatapoint == null) return null;

        return new DataPoint<number>(this.round(pDatapoint.value, pPercision), pDatapoint.timestamp);
    }

    /**
     * Zahl positiv |pNumber|
     * es kann nicht direkt Math.abs verwendet werden da HTML keine statischen Methoden erkennt
     * @param pNumber
     */
    public abs(pNumber: number): number
    {
        return Math.abs(pNumber);
    }

    /**
     * Gibt eine zufällige Zahl im angegebenen Bereich zurück
     * @param pMin
     * @param pMax
     */
    public getRandomInt(pMin, pMax): number
    {
        if (pMin > pMax) return 0;
        return Math.floor(Math.random() * (pMax - pMin + 1)) + pMin;
    }

    /**
     * Rundet eine Liste
     * @param pList
     */
    public roundList(pList: LimitSizeArrayModel<DataPoint<number>>): Array<DataPoint<number>>
    {
        if (pList == null)
        {
            return [];
        }

        const roundedValues = new Array<DataPoint<number>>();

        pList.values.forEach(x =>
        {
            roundedValues.push(new DataPoint(this.round(x.value, 2), x.timestamp));
        });

        return roundedValues;
    }

    /**
     * Generiert ein Array von Fakedaten in einem bestimmten Bereich
     * @param pMin
     * @param pMax
     * @param pLength
     * @param pDate
     */
    public generateFakeData(pMin: number, pMax: number, pLength: number, pDate: Date): Array<DataPoint<any>>
    {
        const values = [];
        const dateloc = new Date(pDate);

        for (let i = 0; i < pLength; i++)
        {
            dateloc.setUTCSeconds(dateloc.getUTCSeconds() + 1);
            values.push(new DataPoint(this.getRandomInt(pMin, pMax), new Date(dateloc)));
        }

        return values;
    }

    /**
     * Wandelt UTC timestamp in Datum um
     * Vielleicht überflüssig??
     * @param pTimestamp
     */
    public utcToDate(pTimestamp: string): Date
    {
        return new Date(pTimestamp);
    }

    /**
     * delay für asynchrone Methoden
     * @param pMs
     */
    public delay(pMs: number): Promise<boolean>
    {
        return new Promise(resolve => setTimeout(resolve, pMs));
    }

    /**
     * Zieht einen festen Wert von jedem Datenpunkt in einer Liste ab.
     * @param pList
     * @param pNumber
     */
    public subtractFromList(pList: LimitSizeArrayModel<DataPoint<number>>, pNumber: number): Array<DataPoint<number>>
    {
        if (pList == null) return [];

        const SubtractedList = Array<DataPoint<number>>();

        pList.values.forEach(x =>
        {
            SubtractedList.push(new DataPoint(x.value - pNumber, x.timestamp));
        });

        return SubtractedList;
    }
}
