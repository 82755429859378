import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MaterialHealthDialogComponent} from '../../../dialog/material-dialog-health/material-dialog.component';
import {DataPoint} from '../../../../../../core/model/helper-models/datapoint.model';
import {FlatTreeControl} from '@angular/cdk/tree';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatTableDataSource} from '@angular/material/table';
import {MaterialComponentViewDialogComponent} from '../../../dialog/material-component-view-dialog/material-component-view-dialog.component';
import {ThingHealthV2Component} from '../../../../thing-health-v2/thing-health-v2.component';
import {Thing} from '../../../../../../core/model/thing.model';

interface L_FlatNode{
    expandable: boolean;
    name: string;
    level: number;
    data: any;
}

interface NodeViewInfo
{
    komponenten_name_de: string;
    komponenten_name_en: string;
    materialnummer: string;
    resetCounter: number;
    extendCounter: number;
    worstHealthValue: number;
    averageHealthValue: number;
}

interface EditableInfo_messgroessen{
    einheit: string;
    messgroessen_name: string;
    momentaner_grenzwert: number;
}

interface L_nodeLevelModes
{
    nodeLevelDefaultMode: string;
    nodeLevelInfoViewMode: string;
    nodeLevelEditMode: string;
    nodeLevelComponentViewMode: string;
}

interface L_nodeLevelDataSource
{
    nodeLevel_chevron: string;
    nodeLevel_name: string;
    nodeLevel_verschobeneWartung: number;
    nodeLevel_austauch: number;
    nodeLevel_worstHealthValue: number;
    nodeLevel_healthPercentage: number;
    nodeLevel_max_austausch: number;
    nodeLevel_min_austausch: number;
    nodeLevel_max_extensions: number;
    nodeLevel_min_extensions: number;
}

interface L_bauteilDataCollection
{
    avg_health: number ;
    worst_health: number ;
    total_austausch: number ;
    max_austausch: number ;
    min_austausch: number ;
    total_extensions: number ;
    max_extensions: number ;
    min_extensions: number ;
}

const NodeLevelModes: L_nodeLevelModes =
{
    nodeLevelDefaultMode : 'default',
    nodeLevelInfoViewMode : 'nodeLevelInfoView',
    nodeLevelEditMode : 'nodeLevelEdit',
    nodeLevelComponentViewMode: 'nodeLevelComponentView'
};



@Component({
  selector: 'app-tree-node',
  templateUrl: './tree-node.component.html',
  styleUrls: ['./tree-node.component.scss']
})
export class TreeNodeComponent implements OnInit {

    isHealthDialogCardHidden: boolean = true;
  constructor(
      public _parent: ThingHealthV2Component
  ) {
  }
    @Input() node: any;
    @Input() dialog: MatDialog;
    @Input() isLeaf: boolean;
    @Input() treeControl: FlatTreeControl<L_FlatNode>;
    @Input() defaultViewMode: string = NodeLevelModes.nodeLevelDefaultMode;
    public thingInfo: Thing;
    public hasChildKomponenten: boolean;
    @Output() nodeSelection = new EventEmitter<L_FlatNode>();


    viewMode: Map<string, boolean> = new Map<string, boolean>([
        ['default', true],
        ['nodeLevelEdit', false],
        ['nodeLevelInfoView', false],
        ['nodeLevelComponentView', false],
    ]);

    dataSource_nodeViewTable: NodeViewInfo[];
    displayColumns_nodeViewTable: string[] = [
        'komponenten_name_de',
        'materialnummer',
        'extendCounter',
        'resetCounter',
        'worstHealthValue',
        'averageHealthValue'
    ];

    dataSource_nodeLevel: L_nodeLevelDataSource[];
    displayColumns_nodeLevel: string[] = [
        'nodeLevel_chevron',
        'nodeLevel_name',
        // 'nodeLevel_verschobeneWartung',
        // 'nodeLevel_austauch',
        // 'nodeLevel_worstHealthValue',
        // 'nodeLevel_healthPercentage',
        // 'nodeLevel_max_austausch',
        // 'nodeLevel_min_austausch',
        // 'nodeLevel_max_extensions',
        // 'nodeLevel_min_extensions',
    ];

    dataSource_editWertTable_messgroessen: EditableInfo_messgroessen[];
    displayColumns_editWertTable_messgroessen: string[] = [
        'messgroessen_name',
        'einheit',
        'momentaner_grenzwert'
    ];

    private switchMode = (mode: string): void => {
        this.viewMode.forEach((val: boolean, key: string) => {
            if (key === mode) {
                this.viewMode.set(key, !this.viewMode.get(key));
            }
            else this.viewMode.set(key, false);
        });
    }

  ngOnInit(): void {
      this.thingInfo = this._parent.thing;

      // Set default view mode from input
      this.switchMode(this.defaultViewMode);

      this.dataSource_nodeViewTable = [
          this.node.data as NodeViewInfo
      ];
      // Derive the node level Data source table
      if (this.node.data.entity_type === 'bauteil'){
          const bauteil_data_collection = this.onCollectData(this.node);
          this.dataSource_nodeLevel = [
              {
                nodeLevel_chevron: this.treeControl.isExpanded(this.node) ? 'expand_more' : 'chevron_right',
                  nodeLevel_name: this.node.name,
                  nodeLevel_austauch: bauteil_data_collection.max_austausch,
                  nodeLevel_healthPercentage: bauteil_data_collection.avg_health,
                  nodeLevel_verschobeneWartung: bauteil_data_collection.max_extensions,
                  nodeLevel_worstHealthValue: bauteil_data_collection.worst_health,
                  nodeLevel_max_austausch: bauteil_data_collection.max_austausch,
                  nodeLevel_min_austausch: bauteil_data_collection.min_austausch,
                  nodeLevel_max_extensions: bauteil_data_collection.max_extensions,
                  nodeLevel_min_extensions: bauteil_data_collection.min_extensions
              }
          ];
      }


      this.dataSource_editWertTable_messgroessen = this.node.data.messgroessen as EditableInfo_messgroessen[];
  }

  private onCollectData(node: L_FlatNode): L_bauteilDataCollection{
        if (node.data['entity_type'] === 'bauteil'){
            const children: any[] = node.data['children'];

            const avg_health = node.data['live_aggregation']['avg_health'];
            let gesamteAustausch = 0;
            let maximaleAustausch = 0;
            let minimaleAustausch = 999999999999999;
            let worstHealth = 100;

            let gesamteExtensions = 0;
            let minExtensions = 999999999999999;
            let maxExtension = 0;

            children.forEach(child => {
                gesamteAustausch += child.data['resetCounter'];
                if (maximaleAustausch < child.data['resetCounter']) {
                    maximaleAustausch = child.data['resetCounter'];
                }

                if (minimaleAustausch > child.data['resetCounter']) {
                    minimaleAustausch = child.data['resetCounter'];
                }

                gesamteExtensions += child.data['extendCounter'];
                if (maxExtension < child.data['extendCounter']) {
                    maxExtension = child.data['extendCounter'];
                }

                if (minExtensions > child.data['extendCounter']) {
                    minExtensions = child.data['extendCounter'];
                }

                if (worstHealth > child.data['worstHealthValue']){
                    worstHealth = child.data['worstHealthValue'];
                }
            });
            return {
                avg_health: avg_health ,
                worst_health: worstHealth ,
                total_austausch: gesamteAustausch,
                max_austausch: maximaleAustausch,
                min_austausch: minimaleAustausch,
                total_extensions: gesamteExtensions,
                max_extensions: maxExtension,
                min_extensions: minExtensions
            };
        }
  }

    changeInWert(elem){
        const rowJson = {
            'bauteil': this.node.data.bauteil_name_de,
            'komponente': this.node.data.komponenten_name_de,
            'messgroesse': elem.messgroessen_name,
            'grenzwert': elem.momentaner_grenzwert,
            'einheit': elem.einheit
        };
        if (this.node.data['editGrenzwert'] === undefined){
            this.node.data['editGrenzwert'] = [rowJson];
        } else {
            this.node.data['editGrenzwert'].push(rowJson);
        }
    }

    openDialog(node: L_FlatNode): void {
        const dialogRef = this.dialog.open(MaterialHealthDialogComponent,
            {
                data: {
                    name: `${node.data.entity_type.toUpperCase()}: ${node.name}`,
                    data: node.data,
                    iData: {
                        avg_health: new DataPoint(
                            node.data.live_aggregation.avg_health.toFixed(2),
                            0
                        ),
                        warning: node.data.live_aggregation.warning,
                        alerting: node.data.live_aggregation.alerting,
                    },
                    treeInfo: {
                        node: node,
                        treeControl: this.treeControl
                    }
                },
                height: '90%',
                width: '95%'
            }
        );
        /**
         * Dialog Reference for after closed operations
         * */
        // dialogRef.afterClosed();
        dialogRef.afterClosed().subscribe(d_results => {
            if(d_results){
                if (d_results.action === 'CLOSE_PARENT'){
                    this.nodeSelection.emit(d_results.result);
                }
            }
        });
    }

    onTreeNodeToggle(node):void {
        this.hasChildKomponenten =
            node.data.children.length > 0 && node.data.children.some(child => child.data.entity_type === 'komponenten');
    }

    setNodeLevelEditMode(): void{
      this.switchMode(NodeLevelModes.nodeLevelEditMode);
    }

    setNodeLevelInfoViewMode(): void {
      this.switchMode(NodeLevelModes.nodeLevelInfoViewMode);
    }

    setNodeLevelComponentViewMode(): void{
      this.switchMode(NodeLevelModes.nodeLevelComponentViewMode);
      this.showComponentView();
    }

    private showComponentView(): void{
      const node = this.node;
        const dialogRef = this.dialog.open(MaterialComponentViewDialogComponent,
            {
                data: {
                    name: `${node.data.entity_type.toUpperCase()}: ${node.name}`,
                    data: node.data,
                    thingInfo: this.thingInfo
                },
                height: '90%',
                width: '95%'
            }
        );

        /**
         * Dialog Reference update the parent table information for the tree based view
         * */
        dialogRef.componentInstance.onUpdate.subscribe(() => {
            this._parent.getNewHealthTableData();
        });

        /**
         * Dialog Reference for after closed operations
         * */
        dialogRef.afterClosed().subscribe(res => {
            this.switchMode(NodeLevelModes.nodeLevelDefaultMode);
        });
    }

    public onGetNodeData(node: L_FlatNode) {
        return {
            name: `${node.data.entity_type.toUpperCase()}: ${node.name}`,
            data: node.data,
            iData: {
                avg_health: new DataPoint(
                    node.data.live_aggregation.avg_health.toFixed(2),
                    0
                ),
                warning: node.data.live_aggregation.warning,
                alerting: node.data.live_aggregation.alerting,
            },
            treeInfo: {
                node: node,
                treeControl: this.treeControl
            }
        };
    }
}
