import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Configurations} from '../../../configurations';
import {Company} from '../../model/company.model';
import {DataPoint} from '../../model/helper-models/datapoint.model';
import {LimitSizeArrayModel} from '../../model/helper-models/limit-size-array.model';
import {CLocation} from '../../model/location.model';
import {CSystem} from '../../model/system.model';
import {Thing} from '../../model/thing.model';
import {DateService} from './date.service';
import {GetThingDataService} from './get-thing-data.service';
import {InitialisePageService} from './initialise-page.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
/**
 * Service der Kunden und thing Objekte enthält und diese
 * in der ganzen Applikation bereitstellt.
 */
export class DataProviderService
{
    /**
     * Komplette Modell Struktur des angemeldeten User (alle Objekte)
     */
    private _customer: Array<Company>;
    get customer(): Array<Company>
    {
        return this._customer;
    }

    set customer(pValue: Array<Company>)
    {
        this._customer = pValue;
    }

    /**
     * Alle Things in einem Array
     */
    private _things: Array<Thing> = [];
    get things(): Array<Thing>
    {
        return this._things;
    }

    set things(pValue: Array<Thing>)
    {
        this._things = pValue;
    }

    /**
     * aktiver Kunde
     */
    private _activeCompany: Company;
    get activeCompany(): Company
    {
        return this._activeCompany;
    }

    set activeCompany(pValue: Company)
    {
        this._activeCompany = pValue;
    }

    /**
     * aktiver Standort
     */
    private _activeLocation: CLocation;
    get activeLocation(): CLocation
    {
        return this._activeLocation;
    }

    set activeLocation(pValue: CLocation)
    {
        this._activeLocation = pValue;
    }

    /**
     * aktive Anlage
     */
    private _activeSystem: CSystem;
    get activeSystem(): CSystem
    {
        return this._activeSystem;
    }

    set activeSystem(pValue: CSystem)
    {
        this._activeSystem = pValue;
    }

    /**
     * gibt an ob Monitoring angezeigt werdn soll oder nicht
     */
    public showMonitoring = true;

    constructor(private _initPage: InitialisePageService,
                private _getData: GetThingDataService,
                private _date: DateService,
                private _toastr: ToastrService,
                private _translate: TranslateService)
    {
    }

    /**
     * Holt alle Daten zum Initialisieren vom InitialisePageService.
     */
    public async getCustomer(): Promise<boolean>
    {
        return new Promise(resolve =>
        {
            this._initPage.getInitialiseData().then(
                data =>
                {
                    this.customer = data;
                    this.things = this.getAllThings(this.customer);
                    resolve(true);
                },
                error =>
                {
                    console.log(error);
                    let toastMsg = 'Fehler beim Abrufen der Daten!';
                    this._translate.get(toastMsg).subscribe(x => toastMsg = x);
                    this._toastr.error(toastMsg, null,
                        {positionClass: 'toast-bottom-right', disableTimeOut: true});
                });
        });
    }

    /**
     * Holt alle things eines Customers
     **/
    public getAllThings(pCustomer: Array<Company>): Thing[]
    {
        let allThings = Array<Thing>();

        if (pCustomer == null) return allThings;

        for (const company of pCustomer)
        {
            for (const location of company.locations.values())
            {
                for (const system of location.systems.values())
                {
                    const things = system.getAllThings();
                    if (things == null) break;
                    allThings = allThings.concat(things);
                }
            }
        }

        return allThings;
    }

    /**
     * Gibt ein thing mit der angegeben ID zurück.
     * @param pId ThingId
     **/
    public getThing(pId: string): Thing
    {
        let thing: Thing;

        thing = this.things.find(element => element.id === pId);

        if (thing == null) return null;

        return thing;
    }

    /**
     * Fängt an Daten von einem thing zu holen.
     * Startet den timer um Daten alle X Sekunden zu bekommen.
     * @param pId ThingId
     */
    public pullThing<T extends Thing>(pId: string): T
    {
        const pThing = this.getThing(pId) as T;
        if (pThing == null)
        {
            console.log('[Warning] no thing found for id ' + pId);
            return null;
        }

        if (pThing.controlCabinet.id != null)
        {
            this._getData.setThings(new Array<Thing>(pThing, pThing.controlCabinet));
        }
        else
        {
            this._getData.setThings(new Array<Thing>(pThing));
        }

        this._getData.callOnce();

        if (this._date.auto)
        {
            this._getData.startPullTimer();
        }

        return pThing;
    }

    /**
     * Gibt das Datum für den letzten timestamp in den Arrays zurück.
     * Wird verwendet um das Datum für Testfahrten anzuzeigen.
     * @param pTestdrive Timeseries Daten einer Testfahrt
     */
    public getTestdriveTimestamp(pTestdrive: LimitSizeArrayModel<DataPoint<any>>): string
    {
        let date: string;

        if (pTestdrive == null || pTestdrive.values.length === 0) return null;

        const datapoint = pTestdrive.currentDatapoint;

        if (datapoint != null)
        {
            date = this._getData.getDate(datapoint);
        }

        return date;
    }

    /**
     * Gibt alle Info IDs zurück.
     */
    public getActiveInfoID(): string
    {
        let info: string = null;
        if (this.activeSystem != null)
        {
            const res = this.activeSystem.allThingTypes[Configurations.InfoString];

            if (res.length > 0) info = res[0].id;
        }
        return info;
    }

    /**
     * Stoppt den Timer für das Abrufen eines Things
     * Löscht die Daten wenn es sich um historische Daten handelt (performance)
     * @param pThing
     */
    public destroyComponent(pThing: Thing): void
    {

        pThing.clearSets();

        this._getData.stopPullTimer();
    }
}
