import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {HelpPageComponent} from '../../../help-page.component';
import {environment} from '../../../../../../../environments/environment';

@Component({
    selector: 'app-help-page-equipment-mls',
    templateUrl: './help-page-equipment-mls.component.html',
    styleUrls: ['./help-page-equipment-mls.component.scss', '../../../helpPageModule.scss']
})
export class HelpPageEquipmentMlsComponent extends HelpPageComponent implements OnInit
{

    urlLanguage: string;

    constructor(public translate: TranslateService)
    {
        super(translate);
    }

    ngOnInit(): void
    {
        this.urlLanguage = this.translate.currentLang;
    }
    public readonly environment = environment;
}
