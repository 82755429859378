import {TranslateService} from '@ngx-translate/core';

export class HelpPageComponent
{
    public curentLang: string;

    constructor(public translate: TranslateService)
    {
        this.curentLang = translate.currentLang;
        translate.onLangChange.subscribe(x =>
        {
            location.reload();
        });
    }
}
