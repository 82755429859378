import {Component} from '@angular/core';

@Component({
    selector: 'app-placehold-linechart',
    templateUrl: './placehold-linechart.component.html',
    styleUrls: ['./placehold-linechart.component.scss']
})
/**
 * Platzhalter wenn in einer Linechart keine Daten angekommen sind
 */
export class PlaceholdLinechartComponent
{
}
