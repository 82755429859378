import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {BaseViewElement} from '../base-view-element';
import {MonitoringLevel} from '../../../core/model/MonitoringLevels.enum';

@Component({
    selector: 'app-testrun-calculated',
    templateUrl: './testrun-calculated.component.html',
    styleUrls: ['./testrun-calculated.component.scss',
        './../card.scss']
})
export class TestrunCalculatedComponent extends BaseViewElement implements OnInit
{
    @ViewChild('card', {read: ElementRef}) card: ElementRef;
    width = 'auto';
    height = '100%';

    dataContext: Array<TableHelper> = new Array<TableHelper>();

    /**
     * Angezeigte Spalten
     */
    displayedColumns: string[] = ['type', 'name', 'value'];

    /**
     * Wird von der Tabelle benötigt um Spalten aufklappen zu können
     */
    expandedElement: TableHelper | null;

    /**
     * Wird zum Berechnen der Thresholds verwendet
     * Ersatzt Number.MAX_VALUE
     */
    numberMax = 1000000000000000;

    /**
     * Wird zum Berechnen der Thresholds verwendet
     * Ersatzt Number.MIN_VALUE
     */
    numberMin = -1000000000000000;
    private testdrives = {};
    chartError;
    public testdrivesXandY = new Array<EvaluationTable>();
    public providedColumns: string;
    noData = true;

    @Input() Error: boolean;
    @Input() ErrorLevel: MonitoringLevel;

    @Input()
    public set propSet(val: PropertySet)
    {
        if (val == null)
        {
            this.chartError = true;
            return;
        }
        else
        {
            this.noData = false;
            this.chartError = false;
        }

        const tmp = val as any;
        const testdriveEvalObj = JSON.parse(tmp);

        const keyList = Object.keys(testdriveEvalObj);

        for (let i = 0; i < keyList.length; i++)
        {
            const key = keyList[i];
            if (key !== 'Prediction' && key !== 'Ratings')
            {
                this.testdrives[key] = testdriveEvalObj[key];
            }
        }
        const testDrivesKeys = Object.keys(this.testdrives);
        this.dataContext = new Array<TableHelper>();
        testDrivesKeys.forEach((value) =>
        {
            const testRunObj = this.testdrives[value];
            const type = testRunObj['description_DE'];
            const direction = testRunObj['direction'];
            const testRunMeasurements = Object.keys(testRunObj.evaluations);
            testRunMeasurements.forEach((measurement) =>
            {
                const measurementObj = testRunObj.evaluations[measurement];
                const tmp = new TableHelper();
                tmp.name = measurementObj.description_DE;
                tmp.direction = direction;
                // tmp.type = value;
                tmp.type = type;
                tmp.value = measurementObj.value;
                tmp.upperThreshold = measurementObj.UpperThreshold;
                tmp.upperupperThreshold = measurementObj.UpperUpperThreshold;
                tmp.lowerThreshold = measurementObj.LowerThreshold;
                tmp.lowerlowerThreshold = measurementObj.LowLowerThreshold;
                this.dataContext.push(tmp);
            });
        });

        const x_direction = new Array<TableHelper>();
        const y_direction = new Array<TableHelper>();

        this.dataContext.forEach((tableHelperObj) =>
        {
            if (tableHelperObj.direction === 'X')
            {
                x_direction.push(tableHelperObj);
            }
            if (tableHelperObj.direction === 'Y')
            {
                y_direction.push(tableHelperObj);
            }
        });
        if (x_direction.length !== 0)
        {
            const finalTable = new EvaluationTable();
            finalTable.content = x_direction;
            finalTable.header = 'Berechnete Werte Fahrantrieb';
            this.testdrivesXandY.push(finalTable);
        }
        if (y_direction.length !== 0)
        {
            const finalTable = new EvaluationTable();
            finalTable.content = y_direction;
            finalTable.header = 'Berechnete Werte Hubantrieb';
            this.testdrivesXandY.push(finalTable);
        }
        if (this.testdrivesXandY.length == 2)
        {
            this.providedColumns = 'col-lg-6';
        }
        else
        {
            this.providedColumns = 'col-lg-6 offset-lg-3';
        }

    }

    openInfo(id)
    {
        const elem = document.getElementById(id);

        if (elem.classList.contains('show'))
        {
            elem.classList.remove('show');
        }
        else
        {
            elem.classList.add('show');
        }
    }

    constructor()
    {
        super('testrun-calculated');
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit();

        if (this.card.nativeElement.offsetWidth > this.card.nativeElement.offsetHeight)
        {
            this.width = 'auto';
            this.height = '100%';
        }
        else
        {
            this.width = '100%';
            this.height = 'auto';
        }
    }
}

/**
 * Klasse für den DataContext der Tabelle
 */
class TableHelper
{
    /**
     * Name
     */
    name: string;

    /**
     * Typ z.B. X, Y
     */
    type: string;

    /**
     * Wert
     */
    value: number;

    /**
     * Oberer Schwellwert
     */
    upperThreshold: number;

    /**
     * Oberer Grenzwert
     */
    upperupperThreshold: number;

    /**
     * unterer Schwellwert
     */
    lowerThreshold: number;

    /**
     * unterer Grenzwert
     */
    lowerlowerThreshold: number;

    direction: string;
}

class EvaluationTable
{
    header: string;
    content: Array<TableHelper>;
}
