import {Component} from '@angular/core';
import {BaseSubcomp} from '../../../base-subcomp';

@Component({
    selector: 'app-cth-custom-subcomp-parent',
    templateUrl: './cth-custom-subcomp-parent.component.html',
    styleUrls: ['./cth-custom-subcomp-parent.component.scss']
})
export class CthCustomSubcompParentComponent extends BaseSubcomp
{

}
