import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {NgxEchartsModule} from 'ngx-echarts';
import {PlaceholdBarchartComponent} from '../../view/charts/placehold-charts/placehold-barchart/placehold-barchart.component';
import {PlaceholdGaugechartComponent} from '../../view/charts/placehold-charts/placehold-gaugechart/placehold-gaugechart.component';
import {PlaceholdLinechartComponent} from '../../view/charts/placehold-charts/placehold-linechart/placehold-linechart.component';
import {PlaceholdPiechartComponent} from '../../view/charts/placehold-charts/placehold-piechart/placehold-piechart.component';
import {BarchartComponent} from '../../view/charts/real-charts/barchart/barchart.component';
import {GaugechartComponent} from '../../view/charts/real-charts/gaugechart/gaugechart.component';
import {LinechartComponent} from '../../view/charts/real-charts/linechart/linechart.component';
import {PiechartComponent} from '../../view/charts/real-charts/piechart/piechart.component';
import {RingchartComponent} from '../../view/charts/real-charts/ringchart/ringchart.component';
import{TreechartComponent} from '../../view/charts/real-charts/treechart/treechart.component';
import {TranslateModule} from '@ngx-translate/core';
import 'echarts';

@NgModule({
    imports: [
        CommonModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'), // Import all modules from echarts:
        }),
        TranslateModule,
    ],
    declarations: [
        LinechartComponent,
        GaugechartComponent,
        BarchartComponent,
        PiechartComponent,
        RingchartComponent,
        TreechartComponent,

        PlaceholdLinechartComponent,
        PlaceholdGaugechartComponent,
        PlaceholdPiechartComponent,
        PlaceholdBarchartComponent,
    ],
    exports: [
        LinechartComponent,
        GaugechartComponent,
        BarchartComponent,
        PiechartComponent,
        RingchartComponent,
        TreechartComponent,

        PlaceholdLinechartComponent,
        PlaceholdGaugechartComponent,
        PlaceholdPiechartComponent,
        PlaceholdBarchartComponent,
    ]
})
export class ChartsModule
{
}
