import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'operatingMode'
})
/**
 * Klasse der OperatingModePipe
 */
export class OperatingModePipe implements PipeTransform
{
    constructor(private _translate: TranslateService)
    {
    }

    /**
     * Übersetzt einen ZahlenCode in einen Zustand
     * @param pValue aktueller Wert
     * @param pMap Key ist die Fehlernummer, Value der dazugehörige Statuswert
     */
    transform(pValue: number, pMap: Map<number, string>)
    {
        let noValue = 'Keine aktuellen Daten vorhanden';
        let noMap = 'Keine Übersetzung vorhanden';

        this._translate.get(noValue).subscribe(x => noValue = x);
        this._translate.get(noMap).subscribe(x => noMap = x);

        if (pValue == null) return noValue;
        if (pMap == null || pMap.size === 0) return noMap;

        // cast um sicherzustellen das der Typ number ist da man sich nicht auf die Parameter nicht verlassen kann
        pValue = +pValue;

        if (pMap.has(pValue))
        {
            return pMap.get(pValue);
        }
        else
        {
            /*
            let trans1 = 'Für den Statuscode ';
            let trans2 = ' ist keine Übersetzung vorhanden';

            this._translate.get(trans1).subscribe(x => trans1 = x);
            this._translate.get(trans2).subscribe(x => trans2 = x);

            return trans1 + pValue + trans2;*/
            return pValue;
        }
    }
}
