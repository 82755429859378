<div class="row">
    <div class="col-lg-12 subheading">
        <h3>{{ 'layout' | translate }}</h3>
    </div>
    <div class="col-lg-12 add-container">
        <form class="add-form">
            <mat-form-field appearance="fill">
                <mat-label> {{ 'add_component' | translate }} </mat-label>
                <input
                    type="text"
                    [placeholder]="'pick_to_add' | translate"
                    [attr.aria-label]="'component' | translate"
                    matInput
                    [formControl]="myControl"
                    [matAutocomplete]="auto"
                />
                <mat-autocomplete
                    #auto="matAutocomplete"
                    [displayWith]="displayFn"
                    (optionSelected)="addItem()"
                >
                    <mat-option
                        *ngFor="let option of filteredOptions | async"
                        [value]="option"
                    >
                        {{ option.name }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </form>
        <div class="code-block">
            <pre>{{ dashboardCopy | json }}</pre>
            <button
                [cdkCopyToClipboard]="dashboardCopy | json"
                [cdkCopyToClipboardAttempts]="5"
                class="btn btn-self-center btn-sm"
            >
                <mat-icon>content_copy</mat-icon>
            </button>
        </div>
    </div>
</div>
<gridster [options]="options">
    <gridster-item
        *ngFor="let item of dashboard"
        [item]="item"
    >
        <div class="button-holder">
            {{ item.name }}
            <button
                mat-mini-fab
                (mousedown)="removeItem($event, item)"
                (touchstart)="removeItem($event, item)"
                class="btn btn-inline btn-sm"
            >
                <mat-icon>delete</mat-icon>
            </button>
        </div>
        <app-thing-subcomp-parent [widget]="item.type"></app-thing-subcomp-parent>
    </gridster-item>
</gridster>
