import {Component, Input} from '@angular/core';
import {DataPoint} from '../../../model/helper-models/datapoint.model';
import {Tile} from '../tile';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {PropertySet} from '../../../model/helper-models/property-set.model';

@Component({
    selector: 'app-calculated-testdrive-tile',
    templateUrl: './calculated-testdrive-tile.component.html',
    styleUrls: ['./calculated-testdrive-tile.component.scss',
        '../tile.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})
/**
 * Kachel zum anzeigen der berechneten Testfahrt Werte
 * Kann eventuell noch erweitert werden um für alle Tabellen zu funktionieren
 */
export class CalculatedTestdriveTileComponent extends Tile
{

    /**
     * DataContext der die Chart gebindet wird
     */
    dataContext: Array<TableHelper> = new Array<TableHelper>();

    /**
     * Angezeigte Spalten
     */
    displayedColumns: string[] = ['type', 'name', 'value'];

    /**
     * Wird von der Tabelle benötigt um Spalten aufklappen zu können
     */
    expandedElement: TableHelper | null;

    /**
     * Wird zum Berechnen der Thresholds verwendet
     * Ersatzt Number.MAX_VALUE
     */
    numberMax = 1000000000000000;

    /**
     * Wird zum Berechnen der Thresholds verwendet
     * Ersatzt Number.MIN_VALUE
     */
    numberMin = -1000000000000000;
    private testdrives = {};
    public actualRating: any;
    public chartError: boolean;
    public predictionChartValues = new Array<Array<DataPoint<number>>>();
    public testdrivesXandY = new Array<EvaluationTable>();
    public providedColumns: string;

    /**
     * Property Set
     * wird beim übergeben in ein Objekt umgewandelt das in der Tabelle angezeigt werden kann
     */
    @Input()
    public set propSet(val: PropertySet)
    {
        console.log(val);
        if (val == null)
        {
            this.chartError = true;
            return;

        }
        else
        {
            this.chartError = false;
        }

        const tmp = val as any;
        // filling up the rating obj for line-chart
        const testdriveEvalObj = JSON.parse(tmp);
        this.actualRating = testdriveEvalObj.Ratings[testdriveEvalObj.Ratings.length - 1].value;
        var ratingDataPoints = new Array<DataPoint<number>>();
        testdriveEvalObj.Ratings.forEach((rating) =>
        {
            ratingDataPoints.push(new DataPoint<number>(rating.value, rating._time));
        });
        // filling up the prediction line which is also part of the line-chart
        this.predictionChartValues.push(ratingDataPoints);
        var predictionDataPoints = new Array<DataPoint<number>>();
        predictionDataPoints.push(new DataPoint<number>(
            testdriveEvalObj.Prediction.First_Value.value, testdriveEvalObj.Prediction.First_Value._time));
        predictionDataPoints.push(new DataPoint<number>(
            testdriveEvalObj.Prediction.Last_Value.value, testdriveEvalObj.Prediction.Last_Value._time));
        this.predictionChartValues.push(predictionDataPoints);


        const keyList = Object.keys(testdriveEvalObj);

        for (let i = 0; i < keyList.length; i++)
        {
            const key = keyList[i];
            if (key !== 'Prediction' && key !== 'Ratings')
            {
                this.testdrives[key] = testdriveEvalObj[key];
            }
        }
        const testDrivesKeys = Object.keys(this.testdrives);
        this.dataContext = new Array<TableHelper>();
        testDrivesKeys.forEach((value) =>
        {
            const testRunObj = this.testdrives[value];
            var type = testRunObj['description_DE'];
            var direction = testRunObj['direction'];
            const testRunMeasurements = Object.keys(testRunObj.evaluations);
            testRunMeasurements.forEach((measurement) =>
            {
                const measurementObj = testRunObj.evaluations[measurement];
                const tmp = new TableHelper();
                tmp.name = measurementObj.description_DE;
                tmp.direction = direction;
                // tmp.type = value;
                tmp.type = type;
                tmp.value = measurementObj.value;
                tmp.upperThreshold = measurementObj.UpperThreshold;
                tmp.upperupperThreshold = measurementObj.UpperUpperThreshold;
                tmp.lowerThreshold = measurementObj.LowerThreshold;
                tmp.lowerlowerThreshold = measurementObj.LowLowerThreshold;
                this.dataContext.push(tmp);
            });
        });

        const x_direction = new Array<TableHelper>();
        const y_direction = new Array<TableHelper>();

        this.dataContext.forEach((tableHelperObj) =>
        {
            if (tableHelperObj.direction === 'X')
            {
                x_direction.push(tableHelperObj);
            }
            if (tableHelperObj.direction === 'Y')
            {
                y_direction.push(tableHelperObj);
            }
        });
        if (x_direction.length !== 0)
        {
            var finalTable = new EvaluationTable();
            finalTable.content = x_direction;
            finalTable.header = 'Berechnete Werte Fahrantrieb';
            this.testdrivesXandY.push(finalTable);
        }
        if (y_direction.length !== 0)
        {
            var finalTable = new EvaluationTable();
            finalTable.content = y_direction;
            finalTable.header = 'Berechnete Werte Hubantrieb';
            this.testdrivesXandY.push(finalTable);
        }
        if (this.testdrivesXandY.length == 2)
        {
            this.providedColumns = 'col-lg-6';
        }
        else
        {
            this.providedColumns = 'col-lg-6 offset-lg-3';
        }
    }

    /**
     *
     */
    constructor()
    {
        super();
    }
}

/**
 * Klasse für den DataContext der Tabelle
 */
class TableHelper
{
    /**
     * Name
     */
    name: string;

    /**
     * Typ z.B. X, Y
     */
    type: string;

    /**
     * Wert
     */
    value: number;

    /**
     * Oberer Schwellwert
     */
    upperThreshold: number;

    /**
     * Oberer Grenzwert
     */
    upperupperThreshold: number;

    /**
     * unterer Schwellwert
     */
    lowerThreshold: number;

    /**
     * unterer Grenzwert
     */
    lowerlowerThreshold: number;

    direction: string;
}

class EvaluationTable
{
    header: string;
    content: Array<TableHelper>;
}
