import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {ThingBannerComponent} from '../../view/banner/thing-banner.component';

@NgModule({
    declarations: [ThingBannerComponent],
    imports: [
        CommonModule,
        TranslateModule
    ],
    exports: [ThingBannerComponent]
})
export class BannerModule
{
}
