import {marker} from '../../packages/bronze/locations/marker';
import {Company} from './company.model';
import {CustomerDetail} from './customer-detail.model';
import {CSystem} from './system.model';
import {Thing} from './thing.model';

export class CLocation extends CustomerDetail
{
    public id: string;
    public mapCoordinates: marker;
    public systems = new Map<string, CSystem>();
    public parentCompany: Company;

    public type = 'LOCATION';

    constructor(pId: string, pName: string, pMapCoordinates: marker)
    {
        super();

        this.id = pId;
        this.name = pName;
        this.mapCoordinates = pMapCoordinates;

        this.getOutdatedProperties();
    }

    /**
     * Gibt alle things der location zurück.
     */
    public getAllThings(): Array<Thing>
    {
        const allThings = [];

        for (const system of this.systems.values())
        {
            allThings.push(...system.getAllThings());
        }

        return allThings;
    }

    public getOutdatedProperties()
    {
        this.getAllThings().forEach(thing =>
        {
            thing.outdatedProperties.subscribe(x =>
            {
                if (x != null)
                {
                    this.errorCount = 0;
                    this.warningCount = 0;
                    this.infoCount = 0;

                    for (let i = 0; i < this.systems.size; i++)
                    {
                        this.errorCount += this.systems[i].errorCount;
                        this.warningCount += this.systems[i].warningCount;
                        this.infoCount += this.systems[i].infoCount;
                    }
                }
            });
        });
    }
}
