import {Component, Input, OnInit} from '@angular/core';
import {EChartsOption} from 'echarts/types/dist/echarts';
import {InfoPoint} from '../../../../model/helper-models/infopoint.model';


/**
 * Configurations
 * */
const title_locations = {
    1:  [['0%', '-5%']],
    2:  [['0%', '-20%'], ['0%', '15%']],
    3:  [
        ['0%', '-40%'],
        ['0%', '-10%'],
        ['0%', '20%']
    ],
};

const details_locations = {
    1:  [['0%', '10%']],
    2:  [['0%', '-5%'], ['0%', '30%']],
    3:  [
        ['0%', '-25%'],
        ['0%', '5%'],
        ['0%', '35%']
    ],
};

@Component({
  selector: 'app-ringchart',
  template: `
    <div echarts [options]="options" style="height: 100%" ></div>
  `,
  styleUrls: ['./ringchart.component.scss']
})
export class RingchartComponent implements OnInit {
    @Input() data: InfoPoint<number>[];

    gaugeData: any[] = [];

    options: EChartsOption = {
        series: [
            {
                type: 'gauge',
                startAngle: 90,
                endAngle: -270,
                pointer: {
                    show: false
                },
                progress: {
                    show: true,
                    overlap: false,
                    roundCap: true,
                    clip: false,
                    itemStyle: {
                        borderWidth: 1,
                        borderColor: '#464646'
                    }
                },
                axisLine: {
                    lineStyle: {
                        width: 40
                    }
                },
                splitLine: {
                    show: false,
                    distance: 0,
                    length: 10
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    show: false,
                    distance: 50
                },
                data: this.gaugeData,
                title: {
                    fontSize: 16,
                    fontWeight: 'bold'
                },
                detail: {
                    width: 50,
                    height: 14,
                    fontSize: 14,
                    color: 'inherit',
                    borderColor: 'inherit',
                    borderRadius: 20,
                    borderWidth: 1,
                    formatter: '{value}%'
                }
            }
        ]
    };
  constructor() { }

  ngOnInit(): void {
    this.pushData(this.data);
  }

    //      {
    //         value: 20,
    //         name: 'Perfect',
    //         title: {
    //             offsetCenter: ['0%', '-30%']
    //         },
    //         detail: {
    //             valueAnimation: true,
    //             offsetCenter: ['0%', '-20%']
    //         }
    //      },

  pushData(pdata: InfoPoint<number>[]){
    const lData = pdata.length;
    const title_loc = title_locations[lData];
    const detail_loc = details_locations[lData];
    pdata.forEach((val, i) => {
        this.gaugeData.push({
            value: val.value.toFixed(),
            name: val.name,
            title: {
                offsetCenter: title_loc[i]
            },
            detail: {
                valueAnimation: true,
                offsetCenter: detail_loc[i]
            }
        });
    });
  }

}
