<app-help-page-banner [items]="" [returnAddress]="'/HelpPage'"></app-help-page-banner>
<div class="container">
    <h2>{{'Activity Thing Monitor' | translate}} </h2>
    <table class="table table-striped table-bordered" style="margin-top: 20px;">
        <thead>
        <tr>
            <th>{{'Schritt' | translate}}</th>
            <th>{{'Beschreibung' | translate}}</th>
            <th>{{'Symbol' | translate}}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td><strong>1</strong></td>
            <td> {{'Um sich den aktuellen Status anzeigen zu lassen, müssen Sie in der Menüleiste das linke Symbol anklicken.' | translate}}</td>
            <td><img id="imageActThiMo1"
                     src="{{environment.storageUrl}}/models3d/models/general/helpPage/introduction/ActivityThingMonitor/ActThiMo1.png"></td>
        </tr>
        <tr>
            <td><strong>2</strong></td>
            <td> {{'Unten links erschein ein graues Kästchen.' | translate}}</td>
            <td><img id="imageActThiMo2"
                     src="{{environment.storageUrl}}/models3d/general/helpPage/introduction/ActivityThingMonitor/ActThiMo2.png"></td>
        </tr>
        <tr>
            <td><strong>3</strong></td>
            <td> {{'Setzen Sie in dem Kästchen den Haken.' | translate}}</td>
            <td><img id="imageActThiMo3"
                     src="{{environment.storageUrl}}/models3d/general/helpPage/introduction/ActivityThingMonitor/ActThiMo3.png"></td>
        </tr>

        <tr>
            <td><strong>4</strong></td>
            <td>
                {{'Daraufhin erscheinen über den Equipments, bei welchen Fehler unterlaufen sind, rote Dreiecke.' | translate}}
                <div>
                    <img id="imageError" src="{{environment.storageUrl}}/models3d/general/helpPage/introduction/ActivityThingMonitor/Error.png">
                    {{'= Fehler aufgetreten' | translate}}
                </div>
                <div>
                    <img id="imageWarning"
                         src="{{environment.storageUrl}}/models3d/general/helpPage/introduction/ActivityThingMonitor/Warning.png">
                    {{'= Fehler Testfahrt aufgetreten' | translate}}
                </div>
            </td>

            <td><img id="imageActThiMo4"
                     src="{{environment.storageUrl}}/models3d/general/helpPage/introduction/ActivityThingMonitor/ActThiMo4.png"></td>
        </tr>
        <tr>
            <td><strong>5</strong></td>
            <td> {{'Um die Informationen einsehen zu können, müssen sie auf das durchgestrichene Auge klicken.' | translate}}</td>
            <td><img id="imageActThiMo5"
                     src="{{environment.storageUrl}}/models3d/general/helpPage/introduction/ActivityThingMonitor/ActThiMo5.png"></td>
        </tr>
        <tr>
            <td><strong>6</strong></td>
            <td> {{'Danach müssen sie die Konsole öffnen, um alle Informationen des Equipments einsehen zu können. Die Konsole befindet sich ganz unten im Bildschirm.' | translate}}
            </td>
            <td><img id="imageActThiMo6"
                     src="{{environment.storageUrl}}/models3d/general/helpPage/introduction/ActivityThingMonitor/ActThiMo6.png"></td>
        </tr>
        </tbody>
    </table>
</div>
