import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {DataProviderService} from './data-provider.service';
import {DataPoint} from '../../model/helper-models/datapoint.model';

@Injectable({
    providedIn: 'root'
})
/**
 * Service der die letzte Wartung abruft und speichert
 */
export class LastMaintenanceService
{
    constructor(private _http: HttpClient, private _data: DataProviderService)
    {
    }

    /**
     * Holt die letzten Wartungsdaten aus einer JSON und schreibt die Werte
     * auf das jeweilige Thing Property
     */
    public getLastMaintenance()
    {
        this._http.get('assets/lastService.json').pipe(map(response =>
        {
            const tmp = response as any;
            tmp.things.forEach(thing =>
            {
                const localThing = this._data.getThing(thing.id);
                if (localThing == null) return;

                localThing.lastMaintenance = thing._time;

                const propsetkeys = localThing.getPropertySetsKeyNames();

                propsetkeys.forEach((propSet: string) =>
                {
                    if (propSet in thing)
                    {
                        const propkeys = Object.keys(localThing[propSet]);

                        propkeys.forEach((prop: string) =>
                        {
                            if (prop in thing[propSet])
                            {
                                localThing[propSet][prop].ValueLastService = new DataPoint(thing[propSet][prop], thing._time);
                            }
                        });
                    }
                });
            });
        })).subscribe();
    }
}
