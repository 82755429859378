<app-conver-status *ngIf="widget === 'conver-status'"
                   [resizeEvent]="resizeEvent"
                   [PositionError]="Thing.CONVER_SLE_STATUS.POSITION.isError"
                   [PositionErrorLevel]="Thing.CONVER_SLE_STATUS.POSITION.status"
                   [PositionValue]="[Thing.CONVER_SLE_STATUS.POSITION.TimeseriesData]"
                   [DestinationError]="Thing.CONVER_SLE_STATUS.DESTINATION.isError"
                   [DestinationErrorLevel]="Thing.CONVER_SLE_STATUS.DESTINATION.status"
                   [DestinationValue]="[Thing.CONVER_SLE_STATUS.DESTINATION.TimeseriesData]"
                   [DriveError]="Thing.CONVER_SLE_STATUS.DRIVE.isError"
                   [DriveErrorLevel]="Thing.CONVER_SLE_STATUS.DRIVE.status"
                   [DriveValue]="[Thing.CONVER_SLE_STATUS.DRIVE.TimeseriesData]"
                   [OperatingConditionError]="Thing.CONVER_SLE_STATUS.OPERATING_CONDITION.isError"
                   [OperatingConditionErrorLevel]="Thing.CONVER_SLE_STATUS.OPERATING_CONDITION.status"
                   [OperatingConditionValue]="[Thing.CONVER_SLE_STATUS.OPERATING_CONDITION.TimeseriesData]">
</app-conver-status>

<app-conver-statistic *ngIf="widget === 'conver-statistic'"
                      [resizeEvent]="resizeEvent"
                      [OperatingHoursError]="Thing.CONVER_SLE_STATISTICS.OPERATING_HOURS.isError"
                      [OperatingHoursErrorLevel]="Thing.CONVER_SLE_STATISTICS.OPERATING_HOURS.status"
                      [OperatingHoursValue]="[Thing.CONVER_SLE_STATISTICS.OPERATING_HOURS.TimeseriesData]"
                      [JourneysSpecificError]="Thing.CONVER_SLE_STATISTICS.JOURNEYS_SPECIFIC.isError"
                      [JourneysSpecificErrorLevel]="Thing.CONVER_SLE_STATISTICS.JOURNEYS_SPECIFIC.status"
                      [JourneysSpecificValue]="[Thing.CONVER_SLE_STATISTICS.JOURNEYS_SPECIFIC.TimeseriesData]"
                      [JourneysAllError]="Thing.CONVER_SLE_STATISTICS.JOURNEYS_ALL.isError"
                      [JourneysAllErrorLevel]="Thing.CONVER_SLE_STATISTICS.JOURNEYS_ALL.status"
                      [JourneysAllValue]="[Thing.CONVER_SLE_STATISTICS.JOURNEYS_ALL.TimeseriesData]">
</app-conver-statistic>

<app-image *ngIf="widget === 'image'"
           [resizeEvent]="resizeEvent"
           [heading]="'Darstellung Conver' | translate"
           [src]="Thing.model3D"
           [equipmentNumber]="Thing.equipmentNumber"
           [alt]="'Darstellung Conver' | translate"
           [ain]="Thing.ainUrl"
           [width]="'auto'">
</app-image>

<app-conver-errors *ngIf="widget === 'conver-errors'"
                   [resizeEvent]="resizeEvent"
                   [CurrentError]="Thing.CONVER_SLE_ERROR.CURRENT.isError"
                   [CurrentErrorLevel]="Thing.CONVER_SLE_ERROR.CURRENT.status"
                   [CurrentValue]="[Thing.CONVER_SLE_ERROR.CURRENT.TimeseriesData]"
                   [CommentError]="Thing.CONVER_SLE_KOMMENTAR.KOMMENTAR.isError"
                   [CommentErrorLevel]="Thing.CONVER_SLE_KOMMENTAR.KOMMENTAR.status"
                   [CommentValue]="[Thing.CONVER_SLE_KOMMENTAR.KOMMENTAR.TimeseriesData]"
                   [HistoryError]="Thing.CONVER_SLE_ERROR.HISTORY.isError"
                   [HistoryErrorLevel]="Thing.CONVER_SLE_ERROR.HISTORY.status"
                   [HistoryValue]="[Thing.CONVER_SLE_ERROR.HISTORY.TimeseriesData]">
</app-conver-errors>

<app-cm-sensors *ngIf="widget === 'cm-mtop'"
                [resizeEvent]="resizeEvent"
                [Type]="'Decke Links'"
                [TemperatureError]="Thing.CMS_CONVER.CMS_ENV_MTOP.TEMPERATURE.isError"
                [TemperatureErrorLevel]="Thing.CMS_CONVER.CMS_ENV_MTOP.TEMPERATURE.status"
                [TemperatureValue]="[Thing.CMS_CONVER.CMS_ENV_MTOP.TEMPERATURE.TimeseriesData]"
                [AirPressureError]="Thing.CMS_CONVER.CMS_ENV_MTOP.AIR_PRESSURE.isError"
                [AirPressureErrorLevel]="Thing.CMS_CONVER.CMS_ENV_MTOP.AIR_PRESSURE.status"
                [AirPressureValue]="[Thing.CMS_CONVER.CMS_ENV_MTOP.AIR_PRESSURE.TimeseriesData]"
                [HumidityError]="Thing.CMS_CONVER.CMS_ENV_MTOP.HUMIDITY.isError"
                [HumidityErrorLevel]="Thing.CMS_CONVER.CMS_ENV_MTOP.HUMIDITY.status"
                [HumidityValue]="[Thing.CMS_CONVER.CMS_ENV_MTOP.HUMIDITY.TimeseriesData]"
                [RotationError]="Thing.CMS_CONVER.CMS_ROT_MTOP.ROTATION_X.isError || Thing.CMS_CONVER.CMS_ROT_MTOP.ROTATION_Y.isError || Thing.CMS_CONVER.CMS_ROT_MTOP.ROTATION_Z.isError"
                [RotationErrorLevel]="Thing.CMS_CONVER.CMS_ROT_MTOP.ROTATION_X.status"
                [RotationValue]="[Thing.CMS_CONVER.CMS_ROT_MTOP.ROTATION_X.TimeseriesData,
                                 Thing.CMS_CONVER.CMS_ROT_MTOP.ROTATION_Y.TimeseriesData,
                                 Thing.CMS_CONVER.CMS_ROT_MTOP.ROTATION_Z.TimeseriesData]"
                [AngularAccelerationError]="Thing.CMS_CONVER.CMS_ANGACL_MTOP.ACCELERATION_X.isError || Thing.CMS_CONVER.CMS_ANGACL_MTOP.ACCELERATION_Y.isError || Thing.CMS_CONVER.CMS_ANGACL_MTOP.ACCELERATION_Z.isError"
                [AngularAccelerationErrorLevel]="Thing.CMS_CONVER.CMS_ANGACL_MTOP.ACCELERATION_X.status"
                [AngularAccelerationValue]="[Thing.CMS_CONVER.CMS_ANGACL_MTOP.ACCELERATION_X.TimeseriesData,
                                 Thing.CMS_CONVER.CMS_ANGACL_MTOP.ACCELERATION_Y.TimeseriesData,
                                 Thing.CMS_CONVER.CMS_ANGACL_MTOP.ACCELERATION_Z.TimeseriesData]"
                [AccelerationError]="Thing.CMS_CONVER.CMS_ACL_MTOP.ACCELERATION_X.isError || Thing.CMS_CONVER.CMS_ACL_MTOP.ACCELERATION_Y.isError || Thing.CMS_CONVER.CMS_ACL_MTOP.ACCELERATION_Z.isError"
                [AccelerationErrorLevel]="Thing.CMS_CONVER.CMS_ACL_MTOP.ACCELERATION_X.status"
                [AccelerationValue]="[Thing.CMS_CONVER.CMS_ACL_MTOP.ACCELERATION_X.TimeseriesData,
                                 Thing.CMS_CONVER.CMS_ACL_MTOP.ACCELERATION_Y.TimeseriesData,
                                 Thing.CMS_CONVER.CMS_ACL_MTOP.ACCELERATION_Z.TimeseriesData]">
</app-cm-sensors>

<app-cm-sensors *ngIf="widget === 'cm-mdw'"
                [resizeEvent]="resizeEvent"
                [Type]="'Decke Rechts'"
                [TemperatureError]="Thing.CMS_CONVER.CMS_ENV_MDW.TEMPERATURE.isError"
                [TemperatureErrorLevel]="Thing.CMS_CONVER.CMS_ENV_MDW.TEMPERATURE.status"
                [TemperatureValue]="[Thing.CMS_CONVER.CMS_ENV_MDW.TEMPERATURE.TimeseriesData]"
                [AirPressureError]="Thing.CMS_CONVER.CMS_ENV_MDW.AIR_PRESSURE.isError"
                [AirPressureErrorLevel]="Thing.CMS_CONVER.CMS_ENV_MDW.AIR_PRESSURE.status"
                [AirPressureValue]="[Thing.CMS_CONVER.CMS_ENV_MDW.AIR_PRESSURE.TimeseriesData]"
                [HumidityError]="Thing.CMS_CONVER.CMS_ENV_MDW.HUMIDITY.isError"
                [HumidityErrorLevel]="Thing.CMS_CONVER.CMS_ENV_MDW.HUMIDITY.status"
                [HumidityValue]="[Thing.CMS_CONVER.CMS_ENV_MDW.HUMIDITY.TimeseriesData]"
                [RotationError]="Thing.CMS_CONVER.CMS_ROT_MDW.ROTATION_X.isError || Thing.CMS_CONVER.CMS_ROT_MDW.ROTATION_Y.isError || Thing.CMS_CONVER.CMS_ROT_MDW.ROTATION_Z.isError"
                [RotationErrorLevel]="Thing.CMS_CONVER.CMS_ROT_MDW.ROTATION_X.status"
                [RotationValue]="[Thing.CMS_CONVER.CMS_ROT_MDW.ROTATION_X.TimeseriesData,
                                 Thing.CMS_CONVER.CMS_ROT_MDW.ROTATION_Y.TimeseriesData,
                                 Thing.CMS_CONVER.CMS_ROT_MDW.ROTATION_Z.TimeseriesData]"
                [AngularAccelerationError]="Thing.CMS_CONVER.CMS_ANGACL_MDW.ACCELERATION_X.isError || Thing.CMS_CONVER.CMS_ANGACL_MDW.ACCELERATION_Y.isError || Thing.CMS_CONVER.CMS_ANGACL_MDW.ACCELERATION_Z.isError"
                [AngularAccelerationErrorLevel]="Thing.CMS_CONVER.CMS_ANGACL_MDW.ACCELERATION_X.status"
                [AngularAccelerationValue]="[Thing.CMS_CONVER.CMS_ANGACL_MDW.ACCELERATION_X.TimeseriesData,
                                 Thing.CMS_CONVER.CMS_ANGACL_MDW.ACCELERATION_Y.TimeseriesData,
                                 Thing.CMS_CONVER.CMS_ANGACL_MDW.ACCELERATION_Z.TimeseriesData]"
                [AccelerationError]="Thing.CMS_CONVER.CMS_ACL_MDW.ACCELERATION_X.isError || Thing.CMS_CONVER.CMS_ACL_MDW.ACCELERATION_Y.isError || Thing.CMS_CONVER.CMS_ACL_MDW.ACCELERATION_Z.isError"
                [AccelerationErrorLevel]="Thing.CMS_CONVER.CMS_ACL_MDW.ACCELERATION_X.status"
                [AccelerationValue]="[Thing.CMS_CONVER.CMS_ACL_MDW.ACCELERATION_X.TimeseriesData,
                                 Thing.CMS_CONVER.CMS_ACL_MDW.ACCELERATION_Y.TimeseriesData,
                                 Thing.CMS_CONVER.CMS_ACL_MDW.ACCELERATION_Z.TimeseriesData]">
</app-cm-sensors>

<app-cm-sensors *ngIf="widget === 'cm-conver'"
                [resizeEvent]="resizeEvent"
                [Type]="'Aufhängung Links'"
                [TemperatureError]="Thing.CMS_CONVER.CMS_ENV_CONVER.TEMPERATURE.isError"
                [TemperatureErrorLevel]="Thing.CMS_CONVER.CMS_ENV_CONVER.TEMPERATURE.status"
                [TemperatureValue]="[Thing.CMS_CONVER.CMS_ENV_CONVER.TEMPERATURE.TimeseriesData]"
                [AirPressureError]="Thing.CMS_CONVER.CMS_ENV_CONVER.AIR_PRESSURE.isError"
                [AirPressureErrorLevel]="Thing.CMS_CONVER.CMS_ENV_CONVER.AIR_PRESSURE.status"
                [AirPressureValue]="[Thing.CMS_CONVER.CMS_ENV_CONVER.AIR_PRESSURE.TimeseriesData]"
                [HumidityError]="Thing.CMS_CONVER.CMS_ENV_CONVER.HUMIDITY.isError"
                [HumidityErrorLevel]="Thing.CMS_CONVER.CMS_ENV_CONVER.HUMIDITY.status"
                [HumidityValue]="[Thing.CMS_CONVER.CMS_ENV_CONVER.HUMIDITY.TimeseriesData]"
                [RotationError]="Thing.CMS_CONVER.CMS_ROT_CONVER.ROTATION_X.isError || Thing.CMS_CONVER.CMS_ROT_CONVER.ROTATION_Y.isError || Thing.CMS_CONVER.CMS_ROT_CONVER.ROTATION_Z.isError"
                [RotationErrorLevel]="Thing.CMS_CONVER.CMS_ROT_CONVER.ROTATION_X.status"
                [RotationValue]="[Thing.CMS_CONVER.CMS_ROT_CONVER.ROTATION_X.TimeseriesData,
                                 Thing.CMS_CONVER.CMS_ROT_CONVER.ROTATION_Y.TimeseriesData,
                                 Thing.CMS_CONVER.CMS_ROT_CONVER.ROTATION_Z.TimeseriesData]"
                [AngularAccelerationError]="Thing.CMS_CONVER.CMS_ANGACL_CONVER.ACCELERATION_X.isError || Thing.CMS_CONVER.CMS_ANGACL_CONVER.ACCELERATION_Y.isError || Thing.CMS_CONVER.CMS_ANGACL_CONVER.ACCELERATION_Z.isError"
                [AngularAccelerationErrorLevel]="Thing.CMS_CONVER.CMS_ANGACL_CONVER.ACCELERATION_X.status"
                [AngularAccelerationValue]="[Thing.CMS_CONVER.CMS_ANGACL_CONVER.ACCELERATION_X.TimeseriesData,
                                 Thing.CMS_CONVER.CMS_ANGACL_CONVER.ACCELERATION_Y.TimeseriesData,
                                 Thing.CMS_CONVER.CMS_ANGACL_CONVER.ACCELERATION_Z.TimeseriesData]"
                [AccelerationError]="Thing.CMS_CONVER.CMS_ACL_CONVER.ACCELERATION_X.isError || Thing.CMS_CONVER.CMS_ACL_CONVER.ACCELERATION_Y.isError || Thing.CMS_CONVER.CMS_ACL_CONVER.ACCELERATION_Z.isError"
                [AccelerationErrorLevel]="Thing.CMS_CONVER.CMS_ACL_CONVER.ACCELERATION_X.status"
                [AccelerationValue]="[Thing.CMS_CONVER.CMS_ACL_CONVER.ACCELERATION_X.TimeseriesData,
                                 Thing.CMS_CONVER.CMS_ACL_CONVER.ACCELERATION_Y.TimeseriesData,
                                 Thing.CMS_CONVER.CMS_ACL_CONVER.ACCELERATION_Z.TimeseriesData]">
</app-cm-sensors>

<app-cm-sensors *ngIf="widget === 'cm-lam'"
                [resizeEvent]="resizeEvent"
                [Type]="'Aufhängung Rechts'"
                [TemperatureError]="Thing.CMS_CONVER.CMS_ENV_DS.TEMPERATURE.isError"
                [TemperatureErrorLevel]="Thing.CMS_CONVER.CMS_ENV_DS.TEMPERATURE.status"
                [TemperatureValue]="[Thing.CMS_CONVER.CMS_ENV_DS.TEMPERATURE.TimeseriesData]"
                [AirPressureError]="Thing.CMS_CONVER.CMS_ENV_DS.AIR_PRESSURE.isError"
                [AirPressureErrorLevel]="Thing.CMS_CONVER.CMS_ENV_DS.AIR_PRESSURE.status"
                [AirPressureValue]="[Thing.CMS_CONVER.CMS_ENV_DS.AIR_PRESSURE.TimeseriesData]"
                [HumidityError]="Thing.CMS_CONVER.CMS_ENV_DS.HUMIDITY.isError"
                [HumidityErrorLevel]="Thing.CMS_CONVER.CMS_ENV_DS.HUMIDITY.status"
                [HumidityValue]="[Thing.CMS_CONVER.CMS_ENV_DS.HUMIDITY.TimeseriesData]"
                [RotationError]="Thing.CMS_CONVER.CMS_ROT_DS.ROTATION_X.isError || Thing.CMS_CONVER.CMS_ROT_DS.ROTATION_Y.isError || Thing.CMS_CONVER.CMS_ROT_DS.ROTATION_Z.isError"
                [RotationErrorLevel]="Thing.CMS_CONVER.CMS_ROT_DS.ROTATION_X.status"
                [RotationValue]="[Thing.CMS_CONVER.CMS_ROT_DS.ROTATION_X.TimeseriesData,
                                 Thing.CMS_CONVER.CMS_ROT_DS.ROTATION_Y.TimeseriesData,
                                 Thing.CMS_CONVER.CMS_ROT_DS.ROTATION_Z.TimeseriesData]"
                [AngularAccelerationError]="Thing.CMS_CONVER.CMS_ANGACL_DS.ACCELERATION_X.isError || Thing.CMS_CONVER.CMS_ANGACL_DS.ACCELERATION_Y.isError || Thing.CMS_CONVER.CMS_ANGACL_DS.ACCELERATION_Z.isError"
                [AngularAccelerationErrorLevel]="Thing.CMS_CONVER.CMS_ANGACL_DS.ACCELERATION_X.status"
                [AngularAccelerationValue]="[Thing.CMS_CONVER.CMS_ANGACL_DS.ACCELERATION_X.TimeseriesData,
                                 Thing.CMS_CONVER.CMS_ANGACL_DS.ACCELERATION_Y.TimeseriesData,
                                 Thing.CMS_CONVER.CMS_ANGACL_DS.ACCELERATION_Z.TimeseriesData]"
                [AccelerationError]="Thing.CMS_CONVER.CMS_ACL_DS.ACCELERATION_X.isError || Thing.CMS_CONVER.CMS_ACL_DS.ACCELERATION_Y.isError || Thing.CMS_CONVER.CMS_ACL_DS.ACCELERATION_Z.isError"
                [AccelerationErrorLevel]="Thing.CMS_CONVER.CMS_ACL_DS.ACCELERATION_X.status"
                [AccelerationValue]="[Thing.CMS_CONVER.CMS_ACL_DS.ACCELERATION_X.TimeseriesData,
                                 Thing.CMS_CONVER.CMS_ACL_DS.ACCELERATION_Y.TimeseriesData,
                                 Thing.CMS_CONVER.CMS_ACL_DS.ACCELERATION_Z.TimeseriesData]">
</app-cm-sensors>
