<app-help-page-banner [items]="" [returnAddress]="'/HelpPage'"></app-help-page-banner>
<div class="container">
    <h2>{{'Abkürzungen' | translate}} </h2>
    <table class="table table-striped table-hover">
        <thead class="th-dark">
        <tr>
            <th>{{'Abkürzung' | translate}}</th>
            <th>{{'Bedeutung' | translate}}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td><strong>{{'AIN' | translate}}</strong></td>
            <td>{{'Asset Intelligence Network' | translate}}</td>
        </tr>
        <tr>
            <td><strong>{{'ALH' | translate}}</strong></td>
            <td>{{'Auslagerheber' | translate}}</td>
        </tr>
        <tr>
            <td><strong>{{'ELH' | translate}}</strong></td>
            <td>{{'Einlagerheber' | translate}}</td>
        </tr>
        <tr>
            <td><strong>{{'LAM' | translate}}</strong></td>
            <td>{{'Lastaufnahmemittel' | translate}}</td>
        </tr>
        <tr>
            <td><strong>{{'MLS' | translate}}</strong></td>
            <td>{{'Multi-Level-Shuttle' | translate}}</td>
        </tr>
        <tr>
            <td><strong>{{'OLS' | translate}}</strong></td>
            <td>{{'One-Level-Shuttel' | translate}}</td>
        </tr>
        <tr>
            <td><strong>{{'RBG' | translate}}</strong></td>
            <td>{{'Regalbediengerät' | translate}}</td>
        </tr>
        <tr>
            <td><strong>{{'SHH' | translate}}</strong></td>
            <td>{{'Shuttleheber' | translate}}</td>
        </tr>
        </tbody>
    </table>
</div>