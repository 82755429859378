<div class="row">
    <div class="col-lg-12">
        <div class="heading">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-6">
                    <h2>{{thing?.displayName + ' ' + location?.name + ' - ' + system?.name}}</h2>
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                    <app-datepicker-popup [thing]="thing" (layoutReset)="resetLayout($event)"></app-datepicker-popup>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="thing?.lastMaintenance != null" class="row">
    <div class="col-lg-12 subheading">
        <h3>{{'Letzte Wartung:' | translate}} {{thing?.lastMaintenance}}</h3>
    </div>
</div>

<gridster *ngIf="dashboard != null; else layoutLoading" [options]="options">
    <gridster-item [item]="item" *ngFor="let item of dashboard">
        <app-conver-subcomp-parent [widget]="item.type" [resizeEvent]="resizeEvent" [Thing]="thing"
                                   [horizontalDate]="horizontalDate"
                                   [verticalDate]="verticalDate"></app-conver-subcomp-parent>
    </gridster-item>
</gridster>

<ng-template #layoutLoading>
    <app-loading [Colour]="'green'" [Text]="'Geräte Layout wird abgerufen'"></app-loading>
</ng-template>

<app-monitoring-console *ngIf="data.showMonitoring && thing.errorCount > 0"
                        [criticalThingToShowInConsole]="[thing]"></app-monitoring-console>
