<app-group-header [Heading]="'Statistik' | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>
<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Betriebsstunden' | translate"
                      [Error]="OperatingHours?.isError"
                      [ErrorLevel]="OperatingHours?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[OperatingHours?.TimeseriesData]"
                      [Unit]="'h'"
                      [Legend]="['Betriebsstunden' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card  class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Strecke Gesamt' | translate"
                      [Error]="DistanceTotal?.isError"
                      [ErrorLevel]="DistanceTotal?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[DistanceTotal?.TimeseriesData]"
                      [Unit]="'km'"
                      [Legend]="['Strecke Gesamt' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Sorter Geschwindigkeit' | translate"
                      [Error]="SorterSpeed?.isError"
                      [ErrorLevel]="SorterSpeed?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[sorterSpeed]"
                      [Unit]="'m/s'"
                      [Legend]="['Sorter Geschwindigkeit' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Einschleusungen' | translate"
                      [Error]="Input?.isError"
                      [ErrorLevel]="Input?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[Input?.TimeseriesData]"
                      [Legend]="['Einschleusungen' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Ausschleusungen' | translate"
                      [Error]="Output?.isError"
                      [ErrorLevel]="Output?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[Output?.TimeseriesData]"
                      [Legend]="['Ausschleusungen' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Crash' | translate"
                      [Error]="Crash?.isError"
                      [ErrorLevel]="Crash?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[Crash?.TimeseriesData]"
                      [Legend]="['Crash' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>
</div>
