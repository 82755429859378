<div class="container">

    <div>
        <h2 class="subheading" style="padding-bottom: 7px;">{{'Einleitung' | translate}}</h2>
        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-12 monitoring-card monitoring-card"
                 [routerLink]="['Introduction/Log_in_Log_out']">
                <div style="padding-top: 34px;"></div>
                <div
                        class="monitoring-text-card-body">
                    <h4 class="title-over-image-center text-center">
                        {{'Anmelden & Abmelden' | translate}}
                    </h4>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-12 monitoring-card monitoring-card"
                 [routerLink]="['Introduction/Menu_navigation']">
                <div style="padding-top: 34px;"></div>
                <div
                        class="monitoring-text-card-body">
                    <h4 class="title-over-image-center text-center">
                        {{'Menüführung' | translate}}
                    </h4>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-12 monitoring-card monitoring-card"
                 [routerLink]="['Introduction/Favorites']">
                <div style="padding-top: 34px;"></div>
                <div
                        class="monitoring-text-card-body">
                    <h4 class="title-over-image-center text-center">
                        {{'Favoriten' | translate}}
                    </h4>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-12 monitoring-card monitoring-card"
                 [routerLink]="['Introduction/Set_date']">
                <div style="padding-top: 34px;"></div>
                <div
                        class="monitoring-text-card-body">
                    <h4 class="title-over-image-center text-center">
                        {{'Datum einstellen' | translate}}
                    </h4>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-12 monitoring-card monitoring-card"
                 [routerLink]="['Introduction/Graphics']">
                <div style="padding-top: 34px;"></div>
                <div
                        class="monitoring-text-card-body">
                    <h4 class="title-over-image-center text-center">
                        {{'Grafiken' | translate}}
                    </h4>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-12 monitoring-card monitoring-card"
                 [routerLink]="['Introduction/Shortcuts']">
                <div style="padding-top: 34px;"></div>
                <div
                        class="monitoring-text-card-body">
                    <h4 class="title-over-image-center text-center">
                        {{'Abkürzungen' | translate}}
                    </h4>
                </div>
            </div>
            <!-- <div class="col-lg-3 col-md-4 col-sm-6 col-12 monitoring-card monitoring-card"
                 [routerLink]="['Introduction/Activity_Thing_Monitor']">
                <div style="padding-top: 34px;"></div>
                <div
                        class="monitoring-text-card-body">
                    <h4 class="title-over-image-center text-center">
                        {{'Activity Thing Monitor' | translate}}
                    </h4>
                </div>
            </div> -->
            <div class="col-lg-3 col-md-4 col-sm-6 col-12 monitoring-card monitoring-card"
                 [routerLink]="['Introduction/Link_customer_portal']">
                <div style="padding-top: 34px;"></div>
                <div
                        class="monitoring-text-card-body">
                    <h4 class="title-over-image-center text-center">
                        {{'Link Kundenportal' | translate}}
                    </h4>
                </div>
            </div>

        </div>

        <!-- Equipment allgemein --->
        <h2 class="subheading" style="padding-bottom: 7px;">{{'Allgemein' | translate}}</h2>
        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-12 monitoring-card monitoring-card"
                 [routerLink]="['Equipment_general/Dashboard']">
                <div style="padding-top: 34px;"></div>
                <div
                        class="monitoring-text-card-body">
                    <h4 class="title-over-image-center text-center">
                        {{'Dashboard' | translate}}
                    </h4>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-12 monitoring-card monitoring-card"
                 [routerLink]="['Equipment_general/Testdrive']">
                <div style="padding-top: 34px;"></div>
                <div
                        class="monitoring-text-card-body">
                    <h4 class="title-over-image-center text-center">
                        {{'Testfahrt' | translate}}
                    </h4>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-12 monitoring-card monitoring-card"
                 [routerLink]="['Equipment_general/Health_Page']">
                <div style="padding-top: 34px;"></div>
                <div
                        class="monitoring-text-card-body">
                    <h4 class="title-over-image-center text-center">
                        {{'Health Page' | translate}}
                    </h4>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-12 monitoring-card monitoring-card"
                 [routerLink]="['Equipment_general/GEBHARDT_Insight']">
                <div style="padding-top: 34px;"></div>
                <div
                        class="monitoring-text-card-body">
                    <h4 class="title-over-image-center text-center">
                        {{'GEBHARDT Insight' | translate}}
                    </h4>
                </div>
            </div>


        </div>

        <!-- Equipment --->
        <h2 class="subheading" style="padding-bottom: 7px;">{{'Geräte' | translate}}</h2>
        <div class="row">
            <!-- <div class="col-lg-2 col-md-3 col-sm-4 col-6 monitoring-card-E" [routerLink]="['Equipment/CONVER']">
                <div style="padding-top: 34px;">
                    <div class="monitoring-icon-card-body-E">
                        <div class="card-icon-area-E text-center">
                            <img src="{{environment.storageUrl}}/img/CONVER.png" class="card-icon img-fluid"
                                 alt="Thing Icon">
                        </div>
                        <div class="card-label-E text-center">
                            CONVER
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="col-lg-2 col-md-3 col-sm-4 col-6 monitoring-card-E" [routerLink]="['Equipment/CTH']">
                <div style="padding-top: 34px;">
                    <div class="monitoring-icon-card-body-E">
                        <div class="card-icon-area-E text-center">
                            <img src="{{environment.storageUrl}}/img/PRBG.png" class="card-icon img-fluid"
                                 alt="Thing Icon">
                        </div>
                        <div class="card-label-E text-center">
                            CTH
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-2 col-md-3 col-sm-4 col-6 monitoring-card-E" [routerLink]="['Equipment/LSC']">
                <div style="padding-top: 34px;">
                    <div class="monitoring-icon-card-body-E">
                        <div class="card-icon-area-E text-center">
                            <img src="{{environment.storageUrl}}/img/LSC.png" class="card-icon img-fluid"
                                 alt="Thing Icon">
                        </div>
                        <div class="card-label-E text-center">
                            LSC
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="col-lg-2 col-md-3 col-sm-4 col-6 monitoring-card-E" [routerLink]="['Equipment/MLS']">
                <div style="padding-top: 34px;">
                    <div class="monitoring-icon-card-body-E">
                        <div class="card-icon-area-E text-center">
                            <img src="{{environment.storageUrl}}/img/MLS.png" class="card-icon img-fluid"
                                 alt="Thing Icon">
                        </div>
                        <div class="card-label-E text-center">
                            MLS
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4 col-6 monitoring-card-E" [routerLink]="['Equipment/OLS_OLSE']">
                <div style="padding-top: 34px;">
                    <div class="monitoring-icon-card-body-E">
                        <div class="card-icon-area-E text-center">
                            <img src="{{environment.storageUrl}}/img/OLS.png" class="card-icon img-fluid"
                                 alt="Thing Icon">
                        </div>
                        <div class="card-label-E text-center">
                            OLS
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-2 col-md-3 col-sm-4 col-6 monitoring-card-E" [routerLink]="['Equipment/OLS_OLSE']">
                <div style="padding-top: 34px;">
                    <div class="monitoring-icon-card-body-E">
                        <div class="card-icon-area-E text-center">
                            <img src="{{environment.storageUrl}}/img/OLSE.png" class="card-icon img-fluid"
                                 alt="Thing Icon">
                        </div>
                        <div class="card-label-E text-center">
                            OLSE
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="col-lg-2 col-md-3 col-sm-4 col-6 monitoring-card-E" [routerLink]="['Equipment/RBG']">
                <div style="padding-top: 34px;">
                    <div class="monitoring-icon-card-body-E">
                        <div class="card-icon-area-E text-center">
                            <img src="{{environment.storageUrl}}/img/RBG.png" class="card-icon img-fluid"
                                 alt="Thing Icon">
                        </div>
                        <div class="card-label-E text-center">
                            RBG
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4 col-6 monitoring-card-E" [routerLink]="['Equipment/VCV']">
                <div style="padding-top: 34px;">
                    <div class="monitoring-icon-card-body-E">
                        <div class="card-icon-area-E text-center">
                            <img src="{{environment.storageUrl}}/img/VCV.png" class="card-icon img-fluid"
                                 alt="Thing Icon">
                        </div>
                        <div class="card-label-E text-center">
                            VCV
                        </div>
                    </div>
                </div>
            </div>


        </div>
        <!-- Frequently asked questions and contact --->
        <h2 class="subheading" style="padding-bottom: 7px;">{{'Häufige Fragen & Kontakt' | translate}}</h2>
        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-12 monitoring-card monitoring-card"
                 [routerLink]="['Frequent_questions_&_contact/Frequent_questions']">
                <div style="padding-top: 34px;"></div>
                <div
                        class="monitoring-text-card-body">
                    <h4 class="title-over-image-center text-center">
                        {{'Häufige Fragen' | translate}}
                    </h4>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-12 monitoring-card monitoring-card"
                 [routerLink]="['Frequent_questions_&_contact/Contact']">
                <div style="padding-top: 34px;"></div>
                <div
                        class="monitoring-text-card-body">
                    <h4 class="title-over-image-center text-center">
                        {{'Kontakt' | translate}}
                    </h4>
                </div>
            </div>
        </div>
    </div>

    <!--    -->

</div>
