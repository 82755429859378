/**
 * Basisklasse für Customer, Location und System
 */
export class CustomerDetail
{
    public name: string;
    public isFavorite = false;
    public type: string;
    public errorCount = 0;
    public warningCount = 0;
    public infoCount = 0;
}
