import {BaseOptions} from './base-options';

/**
 * Optionen für Gaugecharts
 */
export class GaugechartOptions extends BaseOptions
{
    /**
     * Untere Grenze
     */
    min = 0;

    /**
     * Obere Grenze
     */
    max = 100;

    /**
     * Konstruktor
     * @param pName
     * @param pTitle
     * @param pUnit
     * @param pMin
     * @param pMax
     */
    constructor(pName: string, pTitle: string, pUnit: string, pMin: number, pMax: number)
    {
        super(pName, pTitle, pUnit);
        this.min = pMin;
        this.max = pMax;
    }
}
