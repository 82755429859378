<app-image *ngIf="widget === 'image'"
           [resizeEvent]="resizeEvent"
           [heading]="'Darstellung Endstelle' | translate"
           [src]="Thing.model3D"
           [equipmentNumber]="Thing.equipmentNumber"
           [alt]="'Darstellung Endstelle' | translate"
           [ain]="Thing.ainUrl"
           [width]="'auto'">
</app-image>

<app-sses-statistic *ngIf="widget === 'sses-statistic'"
                    [resizeEvent]="resizeEvent"
                    [Endstelle]="Thing.propertySets.get('ENDSTELLE')">
</app-sses-statistic>

<app-sses-flaps *ngIf="widget === 'sses-flaps'"
                [resizeEvent]="resizeEvent"
                [Flaps]="Thing.propertySets.get('KLAPPENBEWEGUNGEN')">
</app-sses-flaps>
