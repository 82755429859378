<div class="showError" *ngIf="!dataLoadError && this.error === undefined; else errorTemplate">
</div>

<div
    *ngIf="dataLoaded; else loading"
    (contextmenu)="holdInfo($event)">
    <div class="row tile-space">

        <!--<div class="col-lg-12 subheading">-->
            <!--<div class="row">-->
                <!--<div class="col-12">-->
                    <!--<h3 id="All" style="float: left">{{'Gateways'}}</h3>-->
                    <!--<button [routerLink]="'/gateway/cache'" class="btn">{{'Cache Managment'}}</button>-->
                <!--</div>-->
            <!--</div>-->
        <!--</div>-->



        <div *ngFor="let gateway of gatewayServiceV2.gateways"
             class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-12 monitoring-card">

            <div style="padding-top: 34px;">
                <div [ngClass]="gateway | gatewayStatusV2" (click)="gotoGateway(gateway)"
                     (mouseenter)="me_displayDetails(gateway)" (mouseleave)="ml_displayDetails(gateway)"
                     class="monitoring-text-card-body">
                    <h4 class="title-over-image-center">
                        {{
                            gateway | gatewayStatusV2 : "provide-gateway-name"
                        }}</h4>
                </div>
            </div>
        </div>

    </div>
    <div
         class="details-container"
    >
        <div *ngIf="!showDetails" class="instruction-tile">
            <h3>Gateway Overview</h3>
            <h5>Hover over a tile for service details</h5>
            <h5>(Right click to enable <b>Hold Mode</b>)</h5>
        </div>

        <mat-card appearance="outlined" *ngIf="showDetails" class="summary-table">
            <mat-card-header>
                <h5><b>Connection Summary</b></h5>
            </mat-card-header>
            <mat-card-content *ngIf="summaryInfo !== undefined">
                <table
                    mat-table
                    class="content-mat-table"
                    [dataSource]="summaryInfo"
                >
                    <ng-container matColumnDef="Name">
                        <th mat-header-cell *matHeaderCellDef> Connection Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Message">
                        <th mat-header-cell *matHeaderCellDef> Message </th>
                        <td mat-cell *matCellDef="let element"> {{element.Message}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="summary_displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: summary_displayedColumns;"
                    ></tr>
                </table>
            </mat-card-content>
        </mat-card>

        <mat-card appearance="outlined" *ngIf="showDetails" class="details-table">
            <mat-card-header>
                <h5><b>Services Overview</b></h5>
            </mat-card-header>
            <mat-card-content *ngIf="servicesTableData !== undefined">
                <table
                    class="content-mat-table"
                    mat-table [dataSource]="servicesTableData">
                    <ng-container matColumnDef="Name">
                        <th mat-header-cell *matHeaderCellDef> Service Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Status">
                        <th mat-header-cell *matHeaderCellDef> Service Status </th>
                        <td mat-cell *matCellDef="let element"> {{element.Status}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.make-red]="row.Status === 'Stopped'"></tr>
                </table>
            </mat-card-content>
            <mat-card-content
                *ngIf="servicesTableData === undefined"
            >
                <h7> Gateway is probably offline </h7>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<ng-template #loading>
    <div *ngIf="!dataLoadError" class="row">
        <div class="col-lg-12 text-center">
            <div class="spinner-grow text-danger" role="status" style="height: 100px; width: 100px;">
                <span class="visually-hidden">Loading...</span>
            </div>
            <div style="font-size: 30px">
                {{'Daten werden abgerufen' | translate}}
            </div>
        </div>
    </div>
</ng-template>

<ng-template #errorTemplate>
    <div class="row">
        <div class="col-lg-12 text-center">
            <div style="font-size: 30px">
                {{'Thing-Health Daten konnten nicht abgerufen werden' | translate}}
            </div>
        </div>
    </div>
</ng-template>
