<app-help-page-banner [items]="" [returnAddress]="'/HelpPage'"></app-help-page-banner>
<div class="container">
    <!-- StartPage -->
    <h2>{{'Dashboard: Darstellung der Anlage 3D' | translate}}</h2>
    <p> {{'Eine 3D Darstellung der Anlage befindet sich unter dem Namen „Dashboard“.' | translate}}</p>
    <ul>
        <li>
            {{'Zugriff auf das Dashboard über den ' | translate}}<strong>{{'Button: ' | translate}}</strong> <img
                id="imageButtonDas"
                src="{{environment.storageUrl}}/models3d/general/helpPage/equipmentGeneral/dasboard/button.png">
        </li>
    </ul>
    <p>
        {{'Die 3D Darstellung verfügt über einen ' | translate}}
        <strong>{{'Mouseover Effekt. ' | translate}}</strong> {{'Daten, wie:' | translate}}
    </p>
    <ul>
        <li> {{'die Positionsnummer des Equipments in der Anlage' | translate}}</li>
        <li> {{'der Gerätetyp' | translate}}</li>
        <li> {{'die Gerätebezeichnung' | translate}}</li>
        <li> {{'der Status' | translate}}</li>
    </ul>
    <p>
        {{'werden bei den verschiedenen Equipments sichtbar, wenn der Mauszeiger auf einem Equipment stehen gelassen wird. Sobald der Mauszeiger außerhalb der Mouseover Fläche des Equipments weiterbewegt wird, verschwindet der Mouseover Effekt.' | translate}}
        <img id="imageDasEx" src="{{environment.storageUrl}}/models3d/general/helpPage/equipmentGeneral/dasboard/example.png"
             class="embed-responsive mx-auto d-block">
    </p>
    <p>
        {{'Bei vielen Equipments kann man durch einen ' | translate}}
        <strong>{{'Doppelklick' | translate}}</strong>{{' über die 3D Darstellung in die Equipmentdaten abspringen.' | translate}}
    </p>
</div>
