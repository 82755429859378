<app-group-header [Heading]="'Status' | translate"
                  [chart]="chart"
                  [info]="info"
                  [noChart]="true"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>

<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Position' | translate"
                      [Error]="PositionError"
                      [ErrorLevel]="PositionErrorLevel"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Unit]="'Ebene'"
                      [Values]="PositionValue"
                      [Legend]="['Position' | translate]"
                      [Info]="'In welcher Ebene befindet sich der ConVer Aufzug?'"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Ziel' | translate"
                      [Error]="DestinationError"
                      [ErrorLevel]="DestinationErrorLevel"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Unit]="'Ebene'"
                      [Values]="DestinationValue"
                      [Legend]="['Ziel' | translate]"
                      [Info]="'Welche Ebene ist das Ziel des ConVer Aufzugs?'"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <div *ngIf="DriveValue != null">

        <app-default-card class="card" style="border: none; border-radius: unset;"
                          [Heading]="'Fahrt' | translate"
                          [Error]="DriveError"
                          [ErrorLevel]="DriveErrorLevel"
                          [showChart]="chart"
                          [showInfo]="info"
                          [customTemplate]="customDrive"
                          [customNoData]="DriveValue[0].values.length === 0"
                          [Legend]="['Fahrt' | translate]"
                          [Info]="'Zeigt an, ob der Güteraufzug hält oder fährt.'"
                          [resizeEvent]="childResizeEvent"></app-default-card>
        <ng-template #customDrive>
            {{DriveValue[0].currentDatapoint.value | operatingMode: driveMap}}
        </ng-template>
    </div>


    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Betriebszustand' | translate"
                      [Error]="OperatingConditionError"
                      [ErrorLevel]="OperatingConditionErrorLevel"
                      [showChart]="chart"
                      [showInfo]="info"
                      [customTemplate]="customOperatingCondition"
                      [customNoData]="operatingCondition == null"
                      [Legend]="['Betriebszustand' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <ng-template #customOperatingCondition>
        {{operatingCondition | translate}}
    </ng-template>

</div>

