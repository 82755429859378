<app-group-header [Heading]="('CM Sensoren' | translate) + ' ' + Type"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>

<div class="card-group" [class.border-danger]="Error">

    <app-default-card *ngIf="this.Temperature != null" class="card" style="border: none; border-radius: unset;"
                      [Heading]="('Temperatur' | translate) + ' ' + Type"
                      [Error]="Temperature?.isError"
                      [ErrorLevel]="Temperature?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[Temperature?.TimeseriesData]"
                      [Unit]="'°C'"
                      [Legend]="['Temperatur' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card *ngIf="this.AirPressure != null" class="card" style="border: none; border-radius: unset;"
                      [Heading]="('Luftdruck' | translate) + ' ' + Type"
                      [Error]="AirPressure?.isError"
                      [ErrorLevel]="AirPressure?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[AirPressure?.TimeseriesData]"
                      [Unit]="'hpa'"
                      [Legend]="['Luftdruck' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card *ngIf="this.Humidity != null" class="card" style="border: none; border-radius: unset;"
                      [Heading]="('Luftfeuchtigkeit' | translate) + ' ' + Type"
                      [Error]="Humidity?.isError"
                      [ErrorLevel]="Humidity?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[Humidity?.TimeseriesData]"
                      [Unit]="'%'"
                      [Legend]="['Luftfeuchtigkeit' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="('Drehung' | translate) + ' ' + Type"
                      [Error]="RotationX?.isError || RotationY?.isError || RotationZ?.isError"
                      [ErrorLevel]="RotationX?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[RotationX?.TimeseriesData, RotationY?.TimeseriesData, RotationZ?.TimeseriesData]"
                      [Unit]="'°'"
                      [Legend]="['X Rotation' | translate, 'Y Rotation' | translate, 'Z Rotation' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>


    <app-default-card class="card"
                      style="border: none; border-radius: unset;"
                      [Heading]="('Winkelgeschwindigkeit' | translate) + ' ' + Type"
                      [Error]="AngularVelocityX?.isError || AngularVelocityY?.isError || AngularVelocityZ?.isError"
                      [ErrorLevel]="AngularVelocityX?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[AngularVelocityX?.TimeseriesData, AngularVelocityY?.TimeseriesData, AngularVelocityZ?.TimeseriesData]"
                      [Unit]="'°/s'"
                      [Legend]="['X Winkelgeschwindigkeit' | translate, 'Y Winkelgeschwindigkeit' | translate, 'Z Winkelgeschwindigkeit' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="('Beschleunigung' | translate) + ' ' + Type"
                      [Error]="AccelerationX?.isError || AccelerationY?.isError || AccelerationZ?.isError"
                      [ErrorLevel]="AccelerationX?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[AccelerationX?.TimeseriesData, AccelerationY?.TimeseriesData, AccelerationZ?.TimeseriesData]"
                      [Unit]="'m/s²'"
                      [Legend]="['X Beschleunigung' | translate, 'Y Beschleunigung' | translate, 'Z Beschleunigung' | translate]"
                      [resizeEvent]="childResizeEvent"></app-default-card>

</div>


<ng-template #noData>
    <app-placeholder-card class="card" style="height: calc(100% - 50px);"></app-placeholder-card>
</ng-template>

<!--

    <div class="card rounded-0" style="height: 100%;">

        <div class="card-header" style="padding:5px;">
            Temperatur {{Type}}
        </div>
        <div class="card-body" style="height: 100%; padding:5px;">

            <app-linechart [legend]="['Temperatur' | translate]"
                           [value]="TemperatureValue"
                           [resizeEvent]="childResizeEvent">
            </app-linechart>
        </div>
    </div>

    @Input() TemperatureError: boolean;
    @Input() TemperatureValue: DataPoint<number>[][];

    @Input() RotationError: boolean;
    @Input() RotationValue: DataPoint<number>[][];

    @Input() AngularAccelerationError: boolean;
    @Input() AngularAccelerationValue: DataPoint<number>[][];

    @Input() AccelerationError: boolean;
    @Input() AccelerationValue: DataPoint<number>[][];

    @Input() resizeEvent: EventEmitter<GridsterItem>;
-->
