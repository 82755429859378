import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
    selector: 'app-fine-positioning',
    templateUrl: './fine-positioning.component.html',
    styleUrls: ['./fine-positioning.component.scss',
        './../card.scss']
})
export class FinePositioningComponent extends BaseViewElement implements OnInit, OnChanges
{
    @Input() FinePositioning: PropertySet;

    Positioning: Property<any>;


    constructor()
    {
        super('fine-positioning');
        this.chart = true;
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit();
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (this.FinePositioning != null)
        {
            this.Positioning = this.FinePositioning.properties.get('TIME_FINE_POSITIONING');
        }
    }
}
