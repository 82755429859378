import {Component, OnInit} from '@angular/core';
import {HelpPageComponent} from '../../help-page.component';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../../../environments/environment';


@Component({
    selector: 'app-help-page-health-page',
    templateUrl: './help-page-health-page.component.html',
    styleUrls: ['./help-page-health-page.component.scss', '../../helpPageModule.scss']
})
export class HelpPageHealthPageComponent extends HelpPageComponent implements OnInit
{

    constructor(public translate: TranslateService)
    {
        super(translate);
    }

    ngOnInit(): void
    {
    }
    public readonly environment = environment;
}
