import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {GoldRoutingModule} from './gold-routing.module';

@NgModule({
    imports: [
        CommonModule,
        GoldRoutingModule
    ],
    declarations: []
})
export class GoldModule
{
}
