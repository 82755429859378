import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MaterialHealthDialogComponent} from '../../../../../dialog/material-dialog-health/material-dialog.component';
import {DataPoint} from '../../../../../../../../core/model/helper-models/datapoint.model';
import {FlatTreeControl} from '@angular/cdk/tree';
import {L_FlatNode} from '../../../../../../interfaces/tree-interfaces';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-status-button',
  templateUrl: './status-button.component.html',
  styleUrls: ['./status-button.component.scss']
})
export class StatusButtonComponent implements OnInit {

    @Input() node: any;
    @Input() treeControl: FlatTreeControl<L_FlatNode>;
    @Input() dialog: MatDialog;

    // Input Options (onlyName, onlyButton, default)
    @Input() option: string = 'default';

    @Output() nodeSelection = new EventEmitter<L_FlatNode>();
    isHealthDialogCardHidden: boolean = true;

  constructor(
      private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
  }

    openDialog(node: L_FlatNode): void {
      console.log(node);
        const dialogRef = this.dialog.open(MaterialHealthDialogComponent,
            {
                data: {
                    name: `${node.data.entity_type.toUpperCase()}: ${node.name}`,
                    data: node.data,
                    node: node,
                    iData: {
                        avg_health: new DataPoint(
                            node.data.live_aggregation.avg_health.toFixed(2),
                            0
                        ),
                        warning: node.data.live_aggregation.warning,
                        alerting: node.data.live_aggregation.alerting,
                    },
                    treeInfo: {
                        node: node,
                        treeControl: this.treeControl
                    }
                },
                height: '90%',
                width: '95%'
            }
        );
        /**
         * Dialog Reference for after closed operations
         * */
        // dialogRef.afterClosed();
        dialogRef.afterClosed().subscribe(d_results => {
            if(d_results){
                if (d_results.action === 'CLOSE_PARENT'){
                    this.nodeSelection.emit(d_results.result);
                }
            }
        });
    }
}
