export const environment = {
    production: false,
    name: 'stage',
    clientId: 'de5e428f-69b3-4919-8d57-ef0b80680be0',
    authority: 'https://login.microsoftonline.com/f5c6aec8-b5ac-44bb-b70d-e0a191024efb',
    redirectUri: 'https://stage.intralogistics.io/',
    apiUrl: 'https://galileoiot-api-management.azure-api.net/api',
    apiVersion: '/stage',
    subscriptionKey: '8efd15401beb43318406307e2bf2cd4a',
    signalRConnectionString: 'Endpoint=https://sigr-realtimemessages-dev.service.signalr.net;AccessKey=cPBAqhFcY7aisBP3By3DlIwGThmRAT/RUuA/lLkgT+M=;ClientEndpoint=https://galileoiot.azurewebsites.net;Version=1.0;',
    storageUrl: 'https://galileoiotstoragestage.blob.core.windows.net'
};
