import {Component, HostListener, Input} from '@angular/core';
import {Sort} from '@angular/material/sort';
import {Thing} from '../../../model/thing.model';
import {environment} from '../../../../../environments/environment';

@Component({
    selector: 'app-monitoring-console',
    templateUrl: './monitoring-console.component.html',
    styleUrls: ['./monitoring-console.component.scss']
})
/**
 * Monitoring Konsole von Kamuran
 */
export class MonitoringConsoleComponent
{
    /**
     * Oberes Ende der Konsole
     */
    top: number;

    /**
     * ???
     */
    pTop: number;

    /**
     * letzter gesetzter top Wert
     */
    lastTop: number;

    /**
     * Höhe der Konsole
     */
    height: number;

    /**
     * Gibt an ob Konsole verschiebbar ist oder nicht
     */
    draggingCorner: boolean;

    /**
     * Funktion zum verschieben des Fensters
     */
    public resizer: Function;

    /**
     * Gibt an ob Konsole offen oder geschlossen ist
     */
    public consoleOpen: boolean;

    /**
     * Liste mit allen Things die angezeigt werden sollen
     */
    @Input() criticalThingToShowInConsole: Thing[];

    /**
     * Konstruktor
     */
    constructor()
    {
        this.height = 305;
        this.top = window.innerHeight - 40;
        this.pTop = 0;
        this.lastTop = window.innerHeight - this.height;

        this.draggingCorner = false;
        this.consoleOpen = false;
    }

    /**
     * Methode zum verschieben der Konsole
     * @param offsetY
     */
    public horiziontResize(offsetY: number)
    {
        this.top += offsetY;
        this.height -= offsetY;
    }

    /**
     * Wird beim Klick auf die Konsole aufgerufen
     * @param event
     * @param resizer
     */
    public onCornerClick(event: MouseEvent, resizer?: Function)
    {
        this.draggingCorner = true;
        this.pTop = event.clientY;
        this.resizer = resizer;
        event.preventDefault();
        event.stopPropagation();
    }

    /**
     * Methode wenn Konsole verschoben wird
     * Click Move
     * @param event
     */
    @HostListener('document:mousemove', ['$event'])
    public onCornerMove(event: MouseEvent)
    {
        if (!this.draggingCorner || !this.consoleOpen)
        {
            return;
        }

        const offsetY = event.clientY - this.pTop;
        this.resizer(offsetY);
        this.pTop = event.clientY;
    }

    /**
     * Methode wenn Konsole verschoben wird
     * Click Release
     * @param event
     */
    @HostListener('document:mouseup', ['$event'])
    public onCornerRelease(event: MouseEvent)
    {
        this.draggingCorner = false;
        this.pTop = event.clientY;
    }

    /**
     * Click Event für das öffnen und schließen der Konsole
     */
    public toggleConsoleCloseIcon()
    {
        $('#console-window').toggleClass('card-info-window-open');

        const shouldConsoleOpen = $('#console-window').hasClass('card-info-window-open');
        if (shouldConsoleOpen)
        {
            this.openConsole();
        }
        else
        {
            this.closeConsole();
        }
    }

    /**
     * Schließt Monitoring Konsole
     */
    public closeConsole()
    {
        this.lastTop = this.top;
        this.top = window.innerHeight - 40;
        this.consoleOpen = false;
        $('#info-window-close-icon').attr('src', '/icons/arrow_up.png');
        document.getElementById('top-edge').style.cursor = 'default';
    }

    /**
     * Öffnet Monitoring Konsole
     */
    public openConsole()
    {
        this.consoleOpen = true;
        this.top = this.lastTop + 5;
        $('#info-window-close-icon').attr('src', '/icons/arrow_down.png');
        document.getElementById('top-edge').style.cursor = 'ns-resize';
    }

    /**
     * Sortiert die angezeigten Things in der Konsole
     * @param sort
     */
    public sortData(sort: Sort)
    {
        const data = this.criticalThingToShowInConsole.slice();
        if (!sort.active || sort.direction === '')
        {
            this.criticalThingToShowInConsole = data;
            return;
        }

        this.criticalThingToShowInConsole = data.sort((a, b) =>
        {
            const isAsc = sort.direction === 'asc';
            switch (sort.active)
            {
                case 'id':
                    return this.compare(a.id, b.id, isAsc);
                case 'name':
                    return this.compare(a.name, b.name, isAsc);
                case 'type':
                    return 1; // this.compare(a.type, b.type, isAsc);
                default:
                    return 0;
            }
        });
    }

    /**
     * Vergleicht zwei Nummern oder Strings und gibt je nach Ergebnis 1 oder -1 zurück
     * @param a
     * @param b
     * @param isAsc
     */
    private compare(a: number | string, b: number | string, isAsc: boolean)
    {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    protected readonly environment = environment;
}
