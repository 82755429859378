import {Component} from '@angular/core';
import {BaseSubcomp} from '../../../base-subcomp';

@Component({
    selector: 'app-olse-subcomp-parent',
    templateUrl: './olse-subcomp-parent.component.html',
    styleUrls: ['./olse-subcomp-parent.component.scss']
})
export class OlseSubcompParentComponent extends BaseSubcomp
{

}
