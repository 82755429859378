<app-current-voltage *ngIf="widget === 'current-voltage'"
                     [resizeEvent]="resizeEvent"
                     [Currentmeter]="Thing.controlCabinet.propertySets.get('CURRENTMETER')">
</app-current-voltage>

<app-sum-of-power *ngIf="widget === 'sum-of-power'"
                  [resizeEvent]="resizeEvent"
                  [Currentmeter]="Thing.controlCabinet.propertySets.get('CURRENTMETER')"
                  [ActivePowerUnit]="'kW'"
                  [ReactivePowerUnit]="'kVAR'"
                  [ApparentPowerUnit]="'kVA'">
</app-sum-of-power>

<app-energy-consumption *ngIf="widget === 'energy-consumption'"
                        [resizeEvent]="resizeEvent"
                        [Energy]="Thing.controlCabinet.propertySets.get('ENERGY')">
</app-energy-consumption>

<app-ssagm-statistic-std *ngIf="widget === 'ssagm-statistic-std'"
                         [resizeEvent]="resizeEvent"
                         [Allgemein]="Thing.propertySets.get('ALLGEMEIN')"
                         [Wartungseinheit]="Thing.propertySets.get('WARTUNGSEINHEIT')">
</app-ssagm-statistic-std>

<app-ssagm-statistic *ngIf="widget === 'ssagm-statistic'"
                     [resizeEvent]="resizeEvent"
                     [Motor]="firstDTM?.propertySets.get('MOTOR')"
                     [Allgemein]="Thing.propertySets.get('ALLGEMEIN')">
</app-ssagm-statistic>

<app-ssagm-carrier-statistic *ngIf="widget === 'ssagm-carrier-statistic'"
                             [resizeEvent]="resizeEvent"
                             [Allgemein]="Thing.propertySets.get('ALLGEMEIN')">
</app-ssagm-carrier-statistic>

<app-ssagm-stat-if *ngIf="widget === 'ssagm-stat-if'"
                   [resizeEvent]="resizeEvent"
                   [Infeeds]="Infeeds">
</app-ssagm-stat-if>

<app-ssagm-stat-es *ngIf="widget === 'ssagm-stat-es'"
                   [resizeEvent]="resizeEvent"
                   [Endstellen]="Endstellen">
</app-ssagm-stat-es>

<app-ssagm-stat-dtm *ngIf="widget === 'ssagm-stat-dtm'"
                    [resizeEvent]="resizeEvent"
                    [DTM]="DTM">
</app-ssagm-stat-dtm>
