import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from '../../../interfaces/dialog-data';

@Component({
  selector: 'app-hierarchy-dialog',
  templateUrl: './hierarchy-dialog.component.html',
  styleUrls: ['./hierarchy-dialog.component.scss']
})
export class HierarchyDialogComponent implements OnInit {

  constructor(
      public dialogRef: MatDialogRef<HierarchyDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
  }


    closeModal(): void {
        this.dialogRef.close();
    }


    onNoClick(): void {
        this.dialogRef.close();
    }

}
