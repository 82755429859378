import {Component} from '@angular/core';
import {BaseSubcomp} from '../../../base-subcomp';

@Component({
    selector: 'app-mls-subcomp-parent',
    templateUrl: './mls-subcomp-parent.component.html',
    styleUrls: ['./mls-subcomp-parent.component.scss']
})
export class MlsSubcompParentComponent extends BaseSubcomp
{

}
