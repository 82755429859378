import { Pipe, PipeTransform } from '@angular/core';
import {GatewayConnectionStatus} from '../../model/gateway_status.v2.model';
import {GatewayV2, Device} from '../../model/gateway.v2.model';

@Pipe({
  name: 'gatewayStatusV2'
})
export class GatewayStatusV2Pipe implements PipeTransform {
    /**
     * This Pipe transforms the status in a classname for the correct color rendering based on following events:
     * 1. (BLUE) Microsoft.Devices.DeviceCreated	Published when a device is registered to an IoT hub.
     * 2. (RED) Microsoft.Devices.DeviceDeleted	Published when a device is deleted from an IoT hub.
     * 3. (GREEN) Microsoft.Devices.DeviceConnected	Published when a device is connected to an IoT hub.
     * 4. (GRAY) Microsoft.Devices.DeviceDisconnected	Published when a device is disconnected from an IoT hub.
     * 5. (<NONE>) Microsoft.Devices.DeviceTelemetry	Published when a device telemetry message is sent to an IoT hub
     *
     * HINT: https://learn.microsoft.com/en-us/azure/iot-hub/iot-hub-event-grid
     *
     *
     * @param gatewayConnectionStatus
     * @param operation
     */
    private getColoredClass = (gatewayConnectionStatus: GatewayConnectionStatus): string => {
        if (gatewayConnectionStatus)
        {
            // Possible Connection states
            // 'Device.CombinedStatus.CONNECTED'
            // 'Device.CombinedStatus.EdgeCore.CONNECTED'
            // 'Device.CombinedStatus.DISCONNECTED'
            switch (gatewayConnectionStatus.EventType)
            {
                case 'Device.CombinedStatus.EdgeCore.CONNECTED':
                    return 'make-red';
                case 'Device.CombinedStatus.CONNECTED':
                    return 'make-green';
                case 'Microsoft.Devices.DeviceDeleted':
                    return 'make-red';
                case 'Device.CombinedStatus.DISCONNECTED':
                    return 'make-gray';
                default:
                    return 'make-gray';
            }
        }
        return 'make-gray';
    };

    /**
     * This converts and provides a gateway device information name by replacing underscores with spaces
     * @param device
     */
    private onGatwayName = (device: Device) : string =>{
        if (device) {
            return device.deviceId.replace('DMg_', '').replace(/_/g, ' ');
        }
        return "";
    };

    /**
     * Transforms the gateway info to render on the DOM with Angular.
     *
     * This supports multiple operations provided follwed:
     * 1. [DEFAULT] "provide-color-class": Provides a color class-name based on the status
     *      i. (make-blue) Microsoft.Devices.DeviceCreated	Published when a device is registered to an IoT hub.
     *      ii. (make-red) Microsoft.Devices.DeviceDeleted	Published when a device is deleted from an IoT hub.
     *      iii. (make-green) Microsoft.Devices.DeviceConnected	Published when a device is connected to an IoT hub.
     *      iv. (make-gray) Microsoft.Devices.DeviceDisconnected	Published when a device is disconnected from an IoT hub.
     *      v. (<NONE>) Microsoft.Devices.DeviceTelemetry
     * 2. "provice-gateway-name": Provides a valid name for the gateway edge device.
     *
     *
     * @param gateway
     * @param operation
     */
    transform(
        gateway: GatewayV2,
        operation: string = "provide-color-class"
    ): unknown {
        if(operation === "provide-color-class"){
            // ToDo Prüfen wieso gatewayStatus manchmal NULL ist
            return this.getColoredClass(gateway.connectionStatus)
        }
        if(operation === "provide-gateway-name"){
            return this.onGatwayName(gateway.device);
        }
  }

}
