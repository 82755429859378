/**
 * Klasse die verwendet wird um Tabs in der Tableiste anzuzeigen
 */
export class TabItem
{
    // Url des Tabs
    public path: string;
    // Angezeigter name
    public name: string;
    // location uns system des Tabs
    public location: string;
    public system: string;

    constructor(pName: string, pPath: string, pLocation: string, pSystem: string)
    {
        this.path = pPath;
        this.name = pName;
        this.location = pLocation;
        this.system = pSystem;
    }
}
