import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';
import {Property} from '../../../core/model/helper-models/property.model';

@Component({
    selector: 'app-temperature',
    templateUrl: './temperature.component.html',
    styleUrls: ['./temperature.component.scss',
        './../card.scss']
})
export class TemperatureComponent extends BaseViewElement implements OnInit, OnChanges
{
    @Input() Temperature: PropertySet;


    Temp1: Property<any>; //Teleskopmotor
    Temp2: Property<any>; //Fahrmotor

    constructor()
    {
        super('temperature');
        this.chart = true;
    }

    onResize(result: any)
    {
        this.childResizeEvent.emit();
    }

    ngOnChanges(changes: SimpleChanges)
    {
        if (this.Temperature != null)
        {
            this.Temp1 = this.Temperature.properties.get('MOTOR_DM1');
            this.Temp2 = this.Temperature.properties.get('MOTOR_DM3');
        }

    }

}
