/**
 * GatewayMachineInfo Model GatewayFlowHealth Model
 * author: Amey Mahadik (a.mahadik@gebhardt-group.com)
 *
 * Monitoring Information on edge gateways
 * */

export interface GatewayFlowHealth {
    /**
     * Crosser Flow ID
     *
     * @type {string}
     * @memberof FlowHealth
     */
    FlowId: string;

    /**
     * Timestamp string for the ingestion time
     *
     * @type {string}
     * @memberof FlowHealth
     */
    Timestamp: string;

    /**
     * Azuer IoT Hub Edge Device ID
     *
     * @type {string}
     * @memberof FlowHealth
     */
    DeviceId: string;

    /**
     * MongoDB object id for the Azuer IoT Hub Edge Device
     *
     * @type {string}
     * @memberof FlowHealth
     */
    DeviceOId: string;

    /**
     * Customer Name
     *
     * @type {string}
     * @memberof FlowHealth
     */
    CustomerName: string;

    /**
     * Customer ID
     *
     * @type {string}
     * @memberof FlowHealth
     */
    CustomerId: string;

    /**
     * Location Name
     *
     * @type {string}
     * @memberof FlowHealth
     */
    LocationName: string;

    /**
     * Location Identifier
     *
     * @type {string}
     * @memberof FlowHealth
     */
    LocationId: string;

    /**
     * System Name
     *
     * @type {string}
     * @memberof FlowHealth
     */
    SystemName: string;

    /**
     * System Identifier
     *
     * @type {string}
     * @memberof FlowHealth
     */
    SystemId: string;

    /**
     * Flow Name
     *
     * @type {string}
     * @memberof FlowHealth
     */
    Name: string;

    /**
     *  Flow Description
     *
     * @type {string}
     * @memberof FlowHealth
     */
    Description: string;

    /**
     * Flow Version
     *
     * @type {number}
     * @memberof FlowHealth
     */
    FlowVersion: number;

    /**
     * Checks if the flow is running healthy or not
     *
     * @type {boolean}
     * @memberof FlowHealth
     */
    Healthy: boolean;

    /**
     * The reason for the unhealthiness if the healthy parameter is false
     *
     * @type {string}
     * @memberof FlowHealth
     */
    UnhealthyReason: string;

    /**
     * Last start time for the flow in string
     *
     * @type {string}
     * @memberof FlowHealth
     */
    LastStartTime: string;

    /**
     * Status string for the flow
     *
     * @type {string}
     * @memberof FlowHealth
     */
    Status: string;

    /**
     * Checks if the flow is running or not
     *
     * @type {boolean}
     * @memberof FlowHealth
     */
    IsRunning: boolean;

    /**
     * Checks if the flow is enabled or not
     *
     * @type {boolean}
     * @memberof FlowHealth
     */
    IsEnabled: boolean;
}

export interface GatewayMachineInfo {
    /**
     * Azure IoT Hub Edge Device ID
     *
     * @type {string}
     * @memberof GatewayHealth
     */
    DeviceId: string;

    /**
     * Azuer IoT Hub Edge device MongoDB Object ID
     *
     * @type {string}
     * @memberof GatewayHealth
     */
    DeviceOId: string;

    /**
     * Timestamp string
     *
     * @type {string}
     * @memberof GatewayHealth
     */
    Timstamp: string;

    /**
     * Platform OS
     *
     * @type {string}
     * @memberof GatewayHealth
     */
    PlatformOS:  string;

    /**
     * Platform OS Release String
     *
     * @type {string}
     * @memberof GatewayHealth
     */
    PlatformRelease:  string;

    /**
     * Platform OS Version String
     *
     * @type {string}
     * @memberof GatewayHealth
     */
    PlatformVersion:  string;

    /**
     * CPU Load in percents at timestamp on Crosser Edge Gateway
     *
     * @type {number}
     * @memberof GatewayHealth
     */
    CPULoad:  number;

    /**
     * Disk related object information [MAP dynamic]
     *
     * @type {object}
     * @memberof GatewayHealth
     */
    Disk: object[];

    /**
     * Total Memory at timestamp on Crosser Edge Gateway
     *
     * @type {number}
     * @memberof GatewayHealth
     */
    MemRamTotal:  number;

    /**
     * Memory Load in percents at timestamp on Crosser Edge Gateway
     *
     * @type {number}
     * @memberof GatewayHealth
     */
    MemRamPercent:  number;

    /**
     * Memory Load in Gigabytes at timestamp on Crosser Edge Gateway
     *
     * @type {number}
     * @memberof GatewayHealth
     */
    MemRamGb:  number;

    /**
     * Crosser version identifier string
     *
     * @type {string}
     * @memberof GatewayHealth
     */
    CrosserVersion:  string;

    /**
     * Java version identifier string
     *
     * @type {string}
     * @memberof GatewayHealth
     */
    JavaVersion:  string;

    /**
     * GalileoEdgeCore version identifier string
     *
     * @type {string}
     * @memberof GatewayHealth
     */
    GalileoEdgeCoreVersion:  string;

    /**
     * DotNet version identifier string
     *
     * @type {string}
     * @memberof GatewayHealth
     */
    DotnetVersion:  string;

    /**
     * All objects pointing to the DotNet runtime environment with versions
     *
     * @type {object}
     * @memberof GatewayHealth
     */
    DotnetFull:  object;
}

export class GatewayTestdriveInfo
{
    /**
     * ID of the Thing
     *
     * @type {string}
     * @memberof TestdriveHealth
     */
    ThingId: string;

    /**
     * Name of the Thing
     *
     * @type {string}
     * @memberof TestdriveHealth
     */
    Name: string;

    /**
     * ToDo comment
     *
     * @type {boolean}
     * @memberof TestdriveHealth
     */
    Start: boolean;

    /**
     * Indicates if a Testdrive is active
     *
     * @type {boolean}
     * @memberof TestdriveHealth
     */
    Active: boolean;

    /**
     * ToDo comment
     *
     * @type {boolean}
     * @memberof TestdriveHealth
     */
    Reset: boolean;

    /**
     * Cpu time of the SPS in ms
     *
     * @type {number}
     * @memberof TestdriveHealth
     */
    CpuTime: number;

    /**
     * Indicates if the Testdrive is active
     *
     * @type {boolean}
     * @memberof TestdriveHealth
     */
    StartTimeTestrunActive: boolean;

    /**
     * 10 digit timestamp, when the testdrive should start
     *
     * @type {number}
     * @memberof TestdriveHealth
     */
    StartTimeTestrun: number;

    /**
     * 10 digit timestamp of the last start time of the testdrive
     *
     * @type {number}
     * @memberof TestdriveHealth
     */
    LastStart: number;

    /**
     * 10 digit timestamp of the last end time of the testdrive
     *
     * @type {number}
     * @memberof TestdriveHealth
     */
    LastEnd: number;

    /**
     * Indicated which testdrives will be executed
     *
     * @type {number}
     * @memberof TestdriveHealth
     */
    RecordingMode: number;
}

export class GatewayConnectionStatus{

    /**
     * An object ID pointing to the Connection status Object
     *
     * @type {string}
     * @memberof GatewayHealth
     */
    _id: string;

    /**
     * A string specifying the gateway ID encapsulating the CustomerName, CustomerLocation and System
     *
     * @type {string}
     * @memberof GatewayHealth
     */
    GatewayId: string;

    /**
     * A UTC datetime timestamp Covering up the status timestamp
     *
     * @type {string}
     * @memberof GatewayHealth
     */
    Timestamp: string;

    /**
     * Microsoft Azure IoT device connection states string
     *
     * @type {string}
     * @memberof GatewayHealth
     */
    EventType: string;

    /**
     * Gateway Version
     *
     * @type {string}
     * @memberof GatewayHealth
     */
    __v: number;
}
