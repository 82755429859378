<app-group-header [Heading]="'Summe der Leistungsdaten' | translate"
                  [chart]="chart"
                  [info]="info"
                  (chartEvent)="getChartEvent($event)"
                  (infoEvent)="getInfoEvent($event)">
</app-group-header>
<div class="card-group">

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Wirkleistung' | translate"
                      [Error]="ActivePower?.isError"
                      [ErrorLevel]="ActivePower?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[ActivePower?.TimeseriesData]"
                      [Unit]="ActivePowerUnit"
                      [Legend]="['Wirkleistung' | translate]"
                      [Info]="'Die Wirkleistung ist die Energie, die tatsächlich wirkt und in eine andere Energieform (z.B. Bewegungsenergie) umgewandelt werden kann.'"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Blindleistung' | translate"
                      [Error]="ReactivePower?.isError"
                      [ErrorLevel]="ReactivePower?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[ReactivePower?.TimeseriesData]"
                      [Unit]="ReactivePowerUnit"
                      [Legend]="['Blindleistung' | translate]"
                      [Info]="'Die Blindleistung befindet sich in Transformatoren und Kondensatoren, um Wirkleistung zu übertragen.'"
                      [resizeEvent]="childResizeEvent"></app-default-card>

    <app-default-card class="card" style="border: none; border-radius: unset;"
                      [Heading]="'Scheinleistung' | translate"
                      [Error]="ApparantPower?.isError"
                      [ErrorLevel]="ApparantPower?.status"
                      [showChart]="chart"
                      [showInfo]="info"
                      [Values]="[ApparantPower?.TimeseriesData]"
                      [Unit]="ApparentPowerUnit"
                      [Legend]="['Scheinleistung' | translate]"
                      [Info]="'Die Scheinleistung ist die geometrische Summe aus Wirk- und Blindleistung.'"
                      [resizeEvent]="childResizeEvent"></app-default-card>


</div>


<!--

<div class="card-header">
            Wirkleistung
        </div>
        <div class="card-body">
            <span [style.fontSize]="fontsize">{{ActivePowerValue | number:'1.0-2'}} W</span>
        </div>
    </div>
    <div class="card rounded-0">

        <div class="card-header">
            Blindleistung
        </div>
        <div class="card-body">
            <span [style.fontSize]="fontsize">{{ReactivePowerValue | number:'1.0-2'}} VAR</span>
        </div>
    </div>
    <div class="card rounded-0">

        <div class="card-header">
            Scheinleistung
        </div>
        <div class="card-body">

            <span [style.fontSize]="fontsize">{{ApparentPowerValue | number:'1.0-2'}} VA</span>
        </div>
    </div>
<div class="row">

    <div class="col-lg-12 subheading">
        <h3 id="SumPower">{{'Summe der Leistungsdaten' | translate}}</h3>
    </div>

    <app-text-tile class="col-4"
                   [heading]="'Wirkleistung' | translate"
                   [unit]="'(W)' | translate"
                   [error]="ActivePowerError"
                   [directInput]="ActivePowerValue | number:'1.2-2'"
                   [historicValues]="ActivePowerHistoric"
                   [historicLegend]="['Wirkleistung' | translate]">
    </app-text-tile>

    <app-text-tile class="col-4"
                   [heading]="'Blindleistung' | translate"
                   [unit]="'(VAR)' | translate"
                   [error]="ReactivePowerError"
                   [directInput]="ReactivePowerValue | number:'1.2-2'"
                   [historicValues]="ReactivePowerHistoric"
                   [historicLegend]="['Blindleistung' | translate]">
    </app-text-tile>

    <app-text-tile class="col-4"
                   [heading]="'Scheinleistung' | translate"
                   [unit]="'(VA)' | translate"
                   [error]="ApparentPowerError"
                   [directInput]="ApparentPowerValue | number:'1.2-2'"
                   [historicValues]="ApparentPowerHistoric"
                   [historicLegend]="['Scheinleistung' | translate]">
    </app-text-tile>
</div>
    -->
