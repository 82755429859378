import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BaseViewElement} from '../base-view-element';
import {Property} from '../../../core/model/helper-models/property.model';
import {PropertySet} from '../../../core/model/helper-models/property-set.model';

@Component({
    selector: 'app-cm-sensors',
    templateUrl: './cm-sensors.component.html',
    styleUrls: ['./cm-sensors.component.scss',
        './../card.scss']
})
export class CmSensorsComponent extends BaseViewElement implements OnInit, OnChanges
{
    @Input() Type: string;
    @Input() Environment: PropertySet;
    @Input() Rotation: PropertySet;
    @Input() AngularVelocity: PropertySet;
    @Input() Acceleration: PropertySet;
    @Input() Error = false;

    Temperature = new Property<any>(0);
    Humidity: Property<any>;
    AirPressure: Property<any>;
    RotationX: Property<any>;
    RotationY: Property<any>;
    RotationZ: Property<any>;
    AngularVelocityX: Property<any>;
    AngularVelocityY: Property<any>;
    AngularVelocityZ: Property<any>;
    AccelerationX: Property<any>;
    AccelerationY: Property<any>;
    AccelerationZ: Property<any>;


    constructor()
    {
        super('cm-sensors');
        this.chart = true;
    }

    onResize(item: any)
    {
        this.childResizeEvent.emit();
    }

    ngOnChanges(changes: SimpleChanges): void
    {

        if (this.Environment != null)
        {
            this.Temperature = this.Environment?.properties.get('TEMPERATURE');
            this.Humidity = this.Environment?.properties.get('HUMIDITY');
            this.AirPressure = this.Environment?.properties.get('AIR_PRESSURE');
        }

        if (this.Rotation != null)
        {
            this.RotationX = this.Rotation?.properties.get('ROTATION_X');
            this.RotationY = this.Rotation?.properties.get('ROTATION_Y');
            this.RotationZ = this.Rotation?.properties.get('ROTATION_Z');
        }

        if (this.AngularVelocity != null)
        {
            this.AngularVelocityX = this.AngularVelocity?.properties.get('ANGULAR_VELOCITY_X');
            this.AngularVelocityY = this.AngularVelocity?.properties.get('ANGULAR_VELOCITY_Y');
            this.AngularVelocityZ = this.AngularVelocity?.properties.get('ANGULAR_VELOCITY_Z');
        }

        if (this.Acceleration != null)
        {
            this.AccelerationX = this.Acceleration.properties?.get('ACCELERATION_X');
            this.AccelerationY = this.Acceleration.properties?.get('ACCELERATION_Y');
            this.AccelerationZ = this.Acceleration.properties?.get('ACCELERATION_Z');
        }

    }
}
